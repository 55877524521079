@import url('./flex.css');

@font-face {
  font-family: Montserrat-Regular;
  src: url('./assets/fonts/montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('./assets/fonts/montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: Montserrat-Medium;
  src: url('./assets/fonts/montserrat/Montserrat-Medium.ttf');
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url('./assets/fonts/montserrat/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: Montserrat-ExtraLight;
  src: url('./assets/fonts/montserrat/Montserrat-ExtraLight.ttf');
}

@font-face {
  font-family: Montserrat-Light;
  src: url('./assets/fonts/montserrat/Montserrat-Light.ttf');
}

@font-face {
  font-family: Montserrat-Thin;
  src: url('./assets/fonts/montserrat/Montserrat-Thin.ttf');
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: auto;
  text-rendering: optimizeLegibility;
}

code {
  font-family: 'Montserrat', sans-serif;
}
.ol-popup p {
  margin-bottom: 0px !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}

.pointer {
  cursor: pointer;
}

.ant-spin-nested-loading > div > .ant-spin.loader {
  max-height: inherit !important;
}

.tableEmpty {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 70vh;
}

.tableEmpty > img {
  margin: auto;
}
.tableEmptyTxt {
  letter-spacing: 1.71px !important;
  color: #000;
}

.ant-input:focus,
.ant-input-focused,
.ant-input:hover {
  border-color: transparent !important;
}
/* .ant-select:hover .ant-select-selector{
  border-color:transparent !important;
  box-shadow: 0px 0px 6px rgba(0,0,0,.29) !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0px 0px 6px rgba(0,0,0,.29) !important;
  border-color:transparent !important;
} */

.selectTractorDiag .ant-select:hover .ant-select-selector,
.selectTractorDiag .ant-picker:hover,
.selectRow .ant-select:hover .ant-select-selector,
.selectRow .ant-picker:hover,
.ant-picker-focused {
  border-color: #d9d9d9 !important;
}

.selectTractorDiag
  .ant-select:not(.ant-select-disabled):hover
  .ant-select-selector {
  border-color: #d9d9d9 !important;
}

.formRow {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 15px;
}
.formRow .formCol:first-child {
  margin-right: 30px !important;
}
.editTicketModal .formRowBtn {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0 25px !important;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.formRow .ant-form-item-label > label {
  font-size: 12px !important;
  color: #5e6c84 !important;
  height: inherit !important;
  font-family: Montserrat-SemiBold, sans-serif !important;
}
.formRow textarea {
  min-height: 75px !important;
}
.formRow:last-child {
  margin-bottom: 0px;
}
.formCol .ant-row.ant-form-item .ant-col,
.formCol .ant-row.ant-form-item .ant-col span {
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold', sans-serif !important;
}

.ant-col.ant-form-item-label {
  display: block;
  width: 100%;
  text-align: left;
  /* margin-bottom: 5px; */
  font-family: 'Montserrat-Medium', sans-serif;
  color: #5e6c84;
}
.input-align {
  text-align: left;
}
.ant-form-item-control .ant-input {
  border: 1px solid #dfe1e6;
  /* background-color: #f4f5f7 !important; */
  border-radius: 3.5px !important;
  height: 32px;
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 14px !important;
  color: #172b4d;
}
.ant-form-item-control textarea {
  /* border: 1px solid #cac2c2!important;
  background-color: #f7f7f7!important; */
  border: none !important;
  background-color: #f0f0f0 !important;
  border-radius: 4px !important;
  font-family: 'Montserrat-Medium', sans-serif;
  padding: 4px 11px;
}
.formCol {
  width: 50%;
  box-sizing: border-box;
}
.formRow .formCol:first-child {
  margin-right: 30px !important;
}
.formColW {
  width: 100%;
  box-sizing: border-box;
  margin-right: 0 !important;
}

.libraryContainer .ant-tabs-nav-wrap {
  justify-content: left !important;
}

.dbWidget {
  border-radius: 6px !important;
  border: 1px solid #cccddc !important;
}
.dbWidget .ant-card-head {
  height: 62px;
  border-bottom: 2px solid #e1e7f0;
  padding: 10px 20px 0 20px;
}
.dbWidget .ant-card-head-title {
  font-size: 20px;
  font-family: 'Montserrat-Medium', sans-serif;
  display: inline-block;
  margin-bottom: 0px;
  padding: 0 !important;
  text-transform: capitalize;
}

.dbWidget .ant-card-extra {
  float: right;
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.dbWidget .ant-card-body .ant-table-thead > tr > th,
.customTbl thead > tr > th {
  background: #f7f7f7;
  border-bottom: 1px solid #ebecf0;
  color: #242424;
  /* padding: 10px 15px 11px 15px !important; */
  /* white-space: nowrap; */
}

.dbWidget .ant-card-body .ant-table-tbody > tr > td,
.customTbl tbody > tr > td {
  height: 62px;
  border: none !important;
  /* padding: 15px; */
  color: #000;
  box-sizing: border-box;
  word-break: break-word;
}

.dbWidget .ant-card-body .ant-table-tbody > tr > td .tags-container {
  word-break: inherit !important;
}

.dbWidget .ant-btn-primary:hover,
.dbWidget .ant-btn-primary:focus {
  color: #fff;
  background: #fc7e09 !important;
  border-color: #c96202 !important;
  border-radius: 3px !important;
}

.empTbl {
  border: none !important;
}
.dbWidget.snapWidget {
  border: 1px solid #e7e7e7 !important;
  border-radius: 15px !important;
}

.libraryContainer .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 40px !important;
}
.dropdownStyle .ant-select-selector {
  border: none !important;
  padding-top: 7px !important;
}
.customModal .ant-modal-body {
  /* height: 400px; */
  overflow-y: auto;
  /* width: 1020px; */
  max-height: 400px;
}

.EmployeeModalContainer .ant-modal-content {
  border-radius: 15px !important;
}

.EmployeeModalContainer .ant-modal-header {
  border-radius: 15px 15px 0 0 !important;
}

.dbWidget.calTbl {
  border: 1px solid #e7e7e7 !important;
  border-radius: 15px !important;
}

.fc-toolbar-title {
  font-size: 24px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.fc-daygrid-event {
  font-size: 14px !important;
  font-family: 'Montserrat-regular', sans-serif;
  color: #67afb7;
}
.fc-timegrid-slot {
  font-family: 'Montserrat-regular', sans-serif;
  font-size: 13px;
}
.ant-breadcrumb {
  margin-bottom: 15px !important;
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 16px !important;
  text-transform: capitalize;
}
.ant-breadcrumb a {
  color: #1890ff !important;
}
.ant-breadcrumb a:hover {
  text-decoration: underline;
}

.fc-timegrid-event {
  font-family: 'Montserrat-regular', sans-serif;
  font-size: 13px !important;
}

.fc-list-event-title,
.fc-list-event-time {
  font-family: 'Montserrat-regular', sans-serif;
  font-size: 14px !important;
}

.fc .fc-button-primary {
  background-color: #1e3a46;
}

.fc-list-day-text {
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 16px;
}
.fc .fc-daygrid-day-number {
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 14px;
}
.flex {
  display: 'flex';
}
.flex-column {
  display: 'flex';
  flex-direction: column;
}
.flex-1 {
  flex: 1;
}

.swLogs .ant-table-header {
  top: -24px !important;
}

/* .ant-select-dropdown {
  z-index: 100 !important;
} */
.dpicker {
  border: 1px solid #dfe1e6;
  /* background-color: #f4f5f7 !important; */
  border-radius: 3.5px !important;
  height: 32px;
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 14px !important;
  color: #172b4d;
  width: 100%;
  padding: 4px 11px;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  top: 49% !important;
  left: 49% !important;
  width: 2em;
  height: 1em;
}

.disNone .flbUL li:first-child {
  display: none !important;
}

.rightSideWidegtMapMaker {
  position: fixed;
  right: -400px;
  top: 0px;
  width: 400px;
  background: #f7f7f7;
  height: 100vh;
  /* padding:38px; */
  z-index: 999;
  box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.29);
  transition: 1s;
}

.expandMapMaker {
  right: 0px !important;
  box-shadow: -2px 0px 6px rgba(0, 0, 0, 0.29);
}
.hideMapMaker {
  right: -400px;
  transition: 1s;
}

.mapMakerTitle {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 18px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mapMakerContainer {
  overflow-y: auto;
  height: calc(100vh - 90px);
  padding: 0px 20px 20px 20px;
}

.ant-modal-confirm-title {
  letter-spacing: 0.51px;
  font-family: Montserrat-Medium, sans-serif;
}
