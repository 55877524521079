.tableTitle {
  font-size: 18px;
  color: #000;
  line-height: 29px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.1px;
}

.table2 {
  margin-top: 15px;
}

.support_implements .infinite-scroll-component {
  height: 400px !important;
}

.support_implements .common_wrapper {
  height: unset !important;
}

.implement_action,
.implement_action:hover,
.implement_action:focus {
  background-color: #fff;
  border-radius: 6px;
  color: #366376;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  padding: 10px;
  letter-spacing: 0.1px !important;
  line-height: 12px !important;
  border: 1px solid #366376;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
