.dropdownContainer {
  margin-right: 10px;
}

.snapWidget .ant-space {
  margin-top: 10px;
}

.snapWidget .anticon-down {
  float: right;
  padding-top: 6px;
}

.resolved-unresolved-menu .tab-content .text-ellipsis {
  font-size: 11px !important;
}

.subFlex .alignBaseline .mb32 {
  flex-direction: column;
}

.moreTags {
  background-color: white !important;
  border: 1px solid #949292;
}

.createTicketSaveBtn .ant-btn {
  background-color: unset !important;
}

.checktag {
  border: 1px solid #67afb7 !important;
}

.checktag:hover {
  background: #e4efea !important;
  color: black;
  border: 1px solid #e4efea !important;
}
.tagsBlk .ant-tag-checkable-checked {
  background-color: #e4efea !important;
  color: #474747;
}

.snapWidget .ant-select-arrow {
  margin-top: -12px;
}
.subItem {
  position: absolute;
  height: 30px;
  width: 178px;
  top: 0px;
  left: 0px;
  padding-left: 8px;
  color: #fff;
  letter-spacing: 1.2px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 11px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.menuContainer .ant-tabs-tab {
  border: 1px solid #e6e6e6;
  height: 130px !important;
  width: 180px;
  margin-top: 7px !important;
}
.menuContainer .ant-tabs-content-holder {
  display: flex;
}
.snapWidget .ant-card-body {
  /* padding: 5px 30px 5px 30px; */
}
.menuContainer .ant-tabs-ink-bar {
  opacity: 0;
}
/* .ant-tabs-tab.ant-tabs-tab-active {
    border: 3px solid #EB921F !important;
  } */
.imageView img {
  /* width: 138px; */
  height: 128px;
  width: 178px;
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
  /* right: 25px; */
}
/* .ant-tabs-content {
  margin-top: 8px;
} */
.mainItem {
  position: absolute;
  color: #fff;
  z-index: 100;
  padding-left: 8px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  min-width: 180px;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.ant-tabs-nav-wrap {
  flex-direction: inherit !important;
}
.ant-tabs-tab.ant-tabs-tab-active img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuContainer .ant-tabs-tab.ant-tabs-tab-active,
.menuContainer .ant-tabs-tab {
  padding: 0px !important;
}

/* new snapshot UI 28-06-2021 */

.camWrapper {
  width: 100%;
  height: auto;
  display: flex;
  margin-left: -8px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.camBox {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  width: 300px;
  height: 210px;
  margin: 0 0px 15px 15px;
  float: left;
  box-sizing: border-box;
}
.camBox1 {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  width: 300px;
  height: 210px;
  margin: 0 0px 15px 15px;
  float: left;
  box-sizing: border-box;
}

.camBoxHead {
  min-height: 36px;
  border-radius: 8px 8px 0 0;
  background: #fff;
  align-items: center;
  display: flex;
  padding-left: 15px;
  color: #212121;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.camBoxContent {
  width: 298px;
  height: 172px;
  background: #fff;
  border-radius: 0 0 8px 8px;
}

.camBoxContent img {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 172px;
  border-radius: 0px 0px 8px 8px;
}

.mt0 {
  margin-top: 0px !important;
}

.addButton {
  /* margin-left: 1190px; */
  /* margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px; */
  height: 60px;
  width: 60px;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 40px;
}
/* new styles 18112021 */
.tcktsBlk {
  border: 0;
  margin-top: 5px;
  flex-wrap: wrap;
}
.tcktsBlk .ant-space {
  flex-wrap: wrap;
  margin-left: 0 !important;
  gap: 0px;
}
.tcktsBlk .searchSec.searchUnit {
  background: #f7f7f7;
  /* margin-right: 24px; */
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.tcktsBlk .searchSec.searchUnit .anticon-search {
  color: #959595;
}
/* .tcktsBlk .searchSec.searchUnit .ant-input{
    border: 1px solid #E7E7E7!important;
    border-radius: 10px;  
    min-height: 36px;
    background: #F7F7F7;
    height: 5.54vh;
    display: flex;
    align-items: center;
    width: 18.28vw !important;
    padding-left: 30px !important;
    min-height: 36px; 
    font-size: 16px;
    font-family: 'Montserrat-Medium', sans-serif;
  } */
.tcktsBlk .searchSec.searchUnit .undefined-action {
  position: absolute;
  /* top: 15px; */
  z-index: 9;
  left: 10px;
}
.dropdownStyle {
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  /* height: 5vh; */
  border: 0;
  /* margin-right: 20px; */
}
.tcktsBlk .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0 !important;
}
.dropdownStyle .ant-select-selection-item,
.dropdownStyle .ant-select-selection-placeholder {
  padding: 8px !important;
  /* font-size: 16px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #5c5c5c;
  letter-spacing: 0.15px; */
}
.selectTractorDiag .ant-select-selector {
  border-radius: 10px !important;
  min-height: 36px;
  background: #fff;
  height: 5.54vh !important;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 6px #00000029;
}
h4.tcktTitle {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 1.71px;
  line-height: 19px;
  color: #000;
  text-align: left;
}
.tagsBlk {
  display: flex;
  flex-direction: row;
  text-align: center;
  flex-wrap: wrap;
}
.tagsBlk .tags {
  /* font-size: 14px; */
  border: 1px solid #67afb7;
  border-radius: 10px;
  display: inline-block;
  color: #474747;
  /* letter-spacing: 0.1px;
  line-height: 18px; 
  font-family: 'Montserrat-SemiBold', sans-serif; */
  padding: 8px 16px;
  margin-right: 10px;
  margin-bottom: 12px;
  cursor: pointer;
}
.tagsBlk .tags:hover {
  background: #e4efea;
  border: 1px solid #e4efea !important;
}
.attachemtBlk .ant-upload.ant-upload-select-picture-card {
  width: 53px !important;
  height: 53px !important;
  border: 0 !important;
  /* padding-top: 16px; */
}

.attachemtBlk .ant-upload-list-picture-card-container {
  display: inline-block;
}

.attachemtBlk .ant-upload {
  width: 53px !important;
  height: 53px !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  /* border: 1px dashed #366376!important; */
}
.attachemtBlk .anticon-plus {
  color: #366376;
}
/* new styles end 18112021 */

.mr11 {
  margin-right: 11px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag-chip {
  display: inline-block;
  margin: 5px auto;
  padding: 3px;
  border: 1px solid;
  border-radius: 9px;
  border-color: #508b94;
  margin-right: 10px;
  cursor: pointer;
}

.tag-chip > span {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

/* .alignBaseline .tag-chip-selected {
  font-size: 12px !important;
} */

.tag-chip-selected {
  cursor: pointer;
  /* background-color: #e4efea; */
  background-color: #d9e5e6;
  color: #333333;
  border-radius: 10px;
  border: 1px solid #d9e5e6 !important;
  padding: 8px 10px 7px 10px;
  /* margin-right: 10px; */
  margin-right: 6px;
  margin-bottom: 5px;
  white-space: nowrap;
  height: 31px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.file-upload-icon {
  display: inline-block;
  margin: 5px auto;
  padding: 3px;
  border: 1px solid;
  border-radius: 9px;
  border-color: #050505;
  margin-right: 10px;
  border-style: dashed;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.file-upload-icon > div {
  font-size: 30px;
  margin-top: -11px;
  margin-left: 6px;
  margin-right: auto;
  color: #508b94;
}

.tkt-lbl {
  margin-right: 210px;
  font-size: 16px;
  font-weight: 600;
}

.tkt-cre {
  margin-right: 200px;
  font-size: 16px;
  font-weight: 600;
}

.dW120 {
  width: 6.25vw !important;
}
.dW180 {
  width: 9.375vw !important;
}
.dW214 {
  width: 11.15vw !important;
}
.dW215 {
  width: 11.15vw !important;
}
.dW250 {
  width: 14.5vw !important;
}
/* .dW250_date {
  width: 17vw !important;
} */

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  /* height: 5vh !important; */
  border-radius: 10px;
  /* width: inherit!important; */
}
.createTcktFlag img {
  border: 1px solid #366376;
  border-radius: 9px;
  padding: 11px 13px;
  cursor: pointer;
}
.ml24 {
  margin-left: 24px;
}
span.commentsTxt {
  display: block;
  font-size: 16px;
  font-family: 'Montserrat-Regular', sans-serif;
  color: #000;
  line-height: 22px;
  letter-spacing: 0.25px;
  padding-bottom: 10px;
  cursor: initial;
}
.alignBaseline {
  align-items: baseline;
  flex-direction: column;
}
.w100 {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .dropdownStyle .ant-select-selection-item,
  .dropdownStyle .ant-select-selection-placeholder {
    /* font-size: 14px !important; */
    display: flex;
    align-items: center;
  }
  .tcktsBlk .ant-picker-input > input {
    /* font-size: 14px !important; */
    display: flex;
    align-items: center;
  }
  .dW215 {
    width: 200px !important;
  }
  .dW180 {
    width: 180px !important;
  }
  .dW214 {
    width: 214px !important;
  }
  .dW250 {
    width: 260px !important;
  }
  /* .dW250_date {
    width: 270px !important;
  } */
  .dW280 {
    width: 280px !important;
  }
}

.searchSec .ant-input-search {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  /* min-height: 50px; */
  background: #f7f7f7;
  /* height: 50px; */
  display: flex;
  align-items: center;
  /* width: 18.28vw !important; */
}
/* .searchSec .ant-input-search input {
  padding-left: 45px !important;
  min-height: 50px;
  max-height: 50px;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.1px;
  line-height: 18px;
  background: none;
  border: none;
} */

.searchSec .ant-input,
.searchSec .ant-input:focus,
.searchSec .ant-input-group-addon,
.searchSec .ant-input-search-button {
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  border-color: none !important;
  box-shadow: none !important;
}

.txtareaAvatar {
  width: 38px;
  height: 38px;
  background-color: #67afb7;
  border: 1px solid #67afb7;
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat-Bold', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: absolute;
  z-index: 1040;
  left: 5px;
  top: 5px;
  box-shadow: 0px 0px 6px #00000029;
}
.commentsBlk {
  display: flex;
  padding-bottom: 15px;
}
.commentsIcon {
  width: 43px;
  height: 43px;
  /* background-color: #67afb7;
  border: 1px solid #67afb7; */
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat-Bold', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  box-shadow: 0px 0px 6px #00000029;
  margin: 5px;
}
.commentsContent {
  margin-left: 14px;
  margin-right: 14px;
}
.commentsContent h5 {
  font-size: 16px;
  font-family: 'Montserrat-Regular', sans-serif;
  letter-spacing: 0.25px;
  line-height: 22px;
  text-transform: initial;
  word-break: break-word;
}
.commentsContent h6 {
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.4px;
  line-height: 15px;
  margin-bottom: 10px;
  font-style: italic;
}
.addTcktCBtn img {
  width: 53px;
  height: 53px;
}

.tcktCommentBox textarea {
  padding-top: 15px !important;
  padding-right: 35px !important;
  padding-left: 65px !important;
}

.newCommentStyle {
  text-transform: capitalize;
}

.newCommentStyle textarea {
  padding-left: 15px !important;
}

.commentBtnBlk {
  display: flex;
  align-items: center;
}
.addTcktCBtn {
  margin-right: 0;
}
.addCommentBtn {
  margin-left: 14px;
}
.errorCodesBlk .errorCodeIcon {
  float: right;
  cursor: pointer;
}
.errorCodesBlk table tr td {
  /* font-size: 16px; */
  font-size: 12px !important;
  font-family: 'Montserrat-Regular', sans-serif;
  letter-spacing: 0.25px;
  line-height: 22px;
  background-color: inherit;
  color: #000;
}
.errorCodesBlk img {
  border-radius: 0;
  margin-right: 4px;
}

.w200 {
  width: 200px;
}
.w150 {
  width: 150px;
}
/* .positionAddBtn .addButton {
    bottom: 17vh !important;
  } */
.widgetBtns .fwdBtnsSec {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 12px; */
  gap: 20px;
  /* height: 70px; */
}
.proeditBtn .TktFwdBtn,
.proeditBtn .TktRslBtn {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 6px #00000029;
  /* height: 47px;
  width: 144px; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  /* font-size: 12px;
  letter-spacing: 1.07px;
  font-family: 'Montserrat-SemiBold' sans-serif; */
  position: inherit !important;
  right: inherit;
  bottom: inherit;
}
.proeditBtn .TktRslBtn {
  background: #366376;
  /* width: 220px; */
  min-height: 39px;
  color: #fff;
}
.fwdTicketProgressSec {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 470px;
}
.fwdTicketMKV {
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  text-align: center;
}
.fwdTicketrply {
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 24px;
  font-family: 'Montserrat-Regular', sans-serif;
  margin-bottom: 30px;
}

.crossIcon .ant-picker-clear {
  right: 24px !important;
}

.ticketArea.ant-input {
  padding: 15px;
}

.attachemtBlk
  .ant-upload-list-picture-card
  .ant-upload-list-item-file
  + .ant-upload-list-item-name {
  display: none;
}
.vedioinprogress {
  text-align: center;
  margin-top: 4px;
  color: #6c757d;
}

/* Forward, Resolve and Error codes modal styles */
.forwardTicketModal {
  position: relative;
}
.forwardTicketModal .anticon-close {
  display: none !important;
}
.forwardTicketModal .ant-modal-body {
  text-align: center;
  padding: 20px !important;
}
/* .forwardTicketModal .modal-logo {
  position: absolute;
  top: 36px;
  left: 36px;
} */
.forwardTicketModal h3 {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin-bottom: 15px;
  text-align: left;
}
.forwardTicketModal p {
  font-size: 12px;
  font-family: 'Montserrat-Regular', sans-serif;
  margin: 0;
  text-align: left;
}
.forwardTicketButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 40px;
}
.forwardTicketButtons .fwdTcktCancelBtn {
  /* border-radius: 10px; */
  /* box-shadow: 0 0 6px #00000029; */
  color: #366376;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 1.25px;
  line-height: 18px;
  padding: 8px 35px;
}
.forwardTicketButtons .fwdTcktForwardBtn {
  background: #366376;
  border-radius: 6px;
  box-shadow: 0 0 6px #00000029;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 1.25px;
  line-height: 18px;
  padding: 8px 35px;
}
.forwardTicketButtons .fwdTcktForwardBtn button {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fff !important;
  padding: 0px !important;
  line-height: 18px;
  height: 19px;
  display: inline;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 1.25px;
}
.statusModal .anticon-close {
  display: none !important;
}
.statusModal .ant-modal-body {
  padding: 20px 30px !important;
}
.statusModal h3 {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin-bottom: 15px;
  text-align: left;
}
.statusModal p {
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
  margin-bottom: 0 !important;
}
.statusModal .descLabel {
  margin-bottom: 10px !important;
}
.statusModal textarea,
.statusModal textarea:hover,
.statusModal textarea:focus {
  border: 1px solid #366376;
  border-radius: 10px;
  border-color: #366376 !important;
  padding: 10px !important;
}

.status_value {
  background-color: #f8dbd9;
  color: #333333;
  border-radius: 10px;
  /* padding: 8px 16px; */
  padding: 8px 12px !important;
}

.status_value_resolved {
  background-color: lightgray;
}

.status_value_forward {
  background-color: rgb(242, 232, 221);
  font-size: 14px;
}
.errorCodesModal .ant-modal-title {
  font-size: 18px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.35px;
  line-height: 19px;
  text-align: center;
}
.errorCodesModal .ant-modal-content {
  position: relative;
}
.errorCodesModal .ant-modal-close {
  position: absolute;
  display: inline-block;
  width: fit-content;
}
.errorCodesModal .ant-modal-title {
  font-size: 18px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.35px;
  line-height: 19px;
  text-align: center;
}
.errorCodesModal .ant-modal-header {
  border-radius: 15px 15px 0 0 !important;
}
.errorCodesModal .ant-modal-body {
  padding-bottom: 35px !important;
}
.editTxtBtn {
  color: #366376;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 1.25px;
  line-height: 18px;
  text-transform: uppercase;
}
.tags-container:hover .tags-no-hover,
.tags-container:not(:hover) .tags-hover {
  display: none;
}

.tags-no-hover {
  white-space: nowrap;
}

.tags-container:hover .tags-hover {
  display: flex;
  min-width: 200px;
  overflow-x: auto;
}

.previewModal {
  width: 900px !important;
}
.previewModal .ant-modal-title {
  color: #b6b6b7;
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 1.43px;
  line-height: 19px;
  text-align: right;
}
.previewModal .ant-modal-close {
  position: absolute;
  left: 0;
}
.previewModal .ant-modal-content {
  border-radius: 15px;
  position: relative;
}
.previewModal .ant-modal-header {
  border-radius: 15px 15px 0 0 !important;
}
.noTitle .ant-modal-title {
  display: none !important;
}
.previewModal .ant-modal-body {
  position: relative;
  height: 550px;
}
.ant-modal-body .previewmodalBody {
  height: 100%;
}
.ant-modal-body .previewmodalBody img {
  display: block;
  margin: auto;
  max-width: 100%;
  height: 100%;
}
.previewmodalBody .anticon-arrow-right {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 26px;
  color: #366376;
  cursor: pointer;
  box-shadow: 0 0 6px #00000029;
  padding: 13px;
}

.previewmodalBody .anticon-arrow-left {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 26px;
  color: #366376;
  cursor: pointer;
  box-shadow: 0 0 6px #00000029;
  padding: 13px;
}

.mb46 {
  margin-bottom: 46px !important;
}
.errorCodesTbl {
  height: calc(100vh - 40vh) !important;
  max-height: calc(100vh - 40vh) !important;
  min-height: calc(100vh - 40vh) !important;
  overflow-y: auto;
}
.createTcktTitle {
  color: #000;
  margin-bottom: 30px !important;
  /* font-size: 24px !important;
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  letter-spacing: 0.18px !important;
  line-height: 29px !important; */
}
.ticketTitle {
  color: #000;
  font-size: 24px !important;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.18px;
  line-height: 29px;
  margin-bottom: 9px;
}
.errorCodesList h4.tcktTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}
.ticketsAttachementBlk .ant-upload-list-picture-card {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.ticketsAttachementBlk h4.tcktTitle {
  margin: 0;
}
.editTcktBtns {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  bottom: 35px;
  height: auto;
}
.editTcktBtns .fwdTcktCancelBtn button {
  border-radius: 10px;
  /* font-size: 14px !important;
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  letter-spacing: 1.25px !important;
  line-height: 18px !important; */
  color: #366376 !important;
  box-shadow: 0 0 6px #00000029 !important;
  padding: 8px 35px !important;
  width: auto !important;
  height: auto !important;
  margin-right: 20px;
}
.editTcktBtns .fwdTcktForwardBtn .TktRslBtn {
  border-radius: 10px;
  font-size: 14px !important;
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  letter-spacing: 1.25px !important;
  line-height: 18px !important;
  background-color: #366376 !important;
  color: #fff !important;
  box-shadow: 0 0 6px #00000029 !important;
  padding: 8px 57px !important;
  width: auto !important;
  height: auto !important;
}
.editAttachementBlk h4.tcktTitle {
  margin-bottom: 0;
}
.editAttachementBlk .ant-upload-list-picture-card {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.frwdTcktProgrsssDesc {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat-Regular', sans-serif !important;
  letter-spacing: 0.25px;
  line-height: 22px;
  text-align: center !important;
  margin: 6px 0 26px 0 !important;
}
.frwdTcktLoadingImg {
  width: 55px;
}

.ticketComments {
  margin-left: 0 !important;
}
.profileView .ticketComments textarea {
  height: 130px !important;
}

.commentsCounts .commentSaveBtn {
  margin-top: 8px !important;
  position: absolute;
  right: 65px;
}

.vedioinfo {
  width: 35% !important;
  height: 35% !important;
  margin-bottom: 5px;
  margin-right: 10px;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px 0 0 5px;
  padding-right: 6px !important;
}

.videoImgStyle {
  display: flex;
  flex-wrap: wrap;
}

.fwdBtnsSecResolve {
  padding: 0 20px;
}
.profileUpdatePopup .newTicket.ant-input:focus,
.desBorder .float-label .newTicket.ant-input {
  border: 1px solid red !important;
}

.attachIcons .ant-upload-list-item-card-actions-btn.ant-btn-sm {
  position: absolute;
  top: -25px;
  background-color: #959595;
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background-image: url(../../assets/images/closeWhite.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 46% 46%;
}

.attachIcons .anticon.anticon-delete {
  display: none;
}

.commentsIcon .ant-avatar > img {
  width: auto !important;
  height: auto !important;
}
.searchSec .ticket-search input {
  padding-left: 0;
}
.uploadBtn {
  background-color: transparent;
  border: none;
  border-color: transparent;
  box-shadow: none;
  height: 50px;
  width: 50px;
  border-radius: 5px;
}
.uploadBtn-disabled {
  background-color: transparent;
  border: none;
  border-color: transparent;
  box-shadow: none;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  cursor: not-allowed;
}
/* .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
  color:transparent;
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
} */

.assignTxt {
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 12px;
  color: #333333;
  line-height: 15px;
  margin-bottom: 3px;
}

.assignNum {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 24px;
  line-height: 29px;
}
.assignTxt1 {
  font-family: 'Montserrat-Regular', sans-serif;
  color: #333333;
  font-size: 15px;
  line-height: 29px;
}

.forwardTxt {
  display: flex;
  align-items: center;
}

.squareCon {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  border: 1px solid rgba(235, 146, 35, 0.5);
}

.squareBg {
  background: rgba(235, 146, 35, 0.25);
}

.forwardTxt span {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  margin: 0 4px;
}

.forwardTxt h4 {
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 10px;
  margin: 0;
}

.mtRight div:last-child {
  margin-left: auto;
}

.createTicketStyle {
  min-width: 104px;
  height: 32px;
  background-color: #366376;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 10px;
  padding: 10px;
  border-radius: 6px;
  margin-left: auto;
  cursor: pointer;
  font-family: 'Montserrat-Medium', sans-serif;
}

.createTicketStyle span {
  margin-left: 6px;
}

.create_ticket_btn {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #fff;
}

.create_ticket_btn_sec {
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.create_ticket_btn_sec .ant-btn[disabled] {
  width: calc(100% - 64px);
  background-color: #e8e8e8;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #b5b5b6;
  border-radius: 6px;
  outline: none !important;
  border: none !important;
}
.create_ticket_btn_sec button,
.create_ticket_btn_sec button:hover,
.create_ticket_btn_sec button:focus {
  width: calc(100% - 64px);
  background-color: #366376;
  color: #fff;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  border-radius: 6px;
  outline: none !important;
  border: none !important;
}

.addTicketBtn:hover,
.addTicketBtn:focus {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.33) 0%,
      rgba(0, 0, 0, 0.33) 100%
    ),
    #366376 !important;
  color: #fff;
}

.create_ticket_btn_sec button.ticket_resolve_btn {
  border: 1px solid #366376 !important;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #fff !important;
  font-size: 10px;
  text-transform: inherit !important;
  color: #366376 !important;
  width: 100%;
  cursor: pointer;
}

.create_ticket_btn_sec button.ticket_fwd_btn {
  border: 1px solid #366376 !important;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #366376 !important;
  font-size: 10px;
  text-transform: inherit !important;
  color: #fff !important;
  cursor: pointer;
  width: 100%;
}
.create_ticket_btn_sec button.ticket_unresolved_btn {
  border: 1px solid #366376 !important;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #366376 !important;
  font-size: 10px;
  text-transform: inherit !important;
  color: #fff !important;
  cursor: pointer;
}

.create_ticket_btn_sec button.ticket_resolved_monarch_btn {
  border: 1px solid #366376 !important;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #366376 !important;
  font-size: 10px;
  text-transform: inherit !important;
  color: #fff !important;
  cursor: pointer;
  width: 100%;
}

.btnsSpace {
  justify-content: start !important;
}

.fwdBtnsSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 64px);
  gap: 20px;
}

.leftStyle {
  left: 44px;
}

.textAreaPlaceHolder .float-label .label,
.textAreaPlaceHolder textarea {
  font-family: 'Montserrat-Regular', sans-serif;
}

.forwardStyle {
  border-radius: 12px;
  border: 1px solid rgba(235, 146, 35, 0.25);
  color: rgba(235, 146, 35, 0.75);
}

.inProgressStyle {
  border-radius: 12px;
  background: rgba(235, 146, 35, 0.15);
  color: rgba(235, 146, 35, 0.75);
  border: none;
}

.tickStatus {
  font-family: 'Montserrat-Regular', sans-serif !important;
}

.openStyle {
  border: none;
  border-radius: 12px;
  color: #de827a;
  background-color: rgba(222, 130, 122, 0.15);
}

.resolveStyle {
  color: rgba(55, 149, 38, 0.5);
  background-color: rgba(55, 149, 38, 0.15);
  border: none;
}

.tableHover table tbody tr:hover td,
.tableHover table tbody tr:focus td,
.tableHover table tbody tr:active td,
.tableHover tr.row-selected td {
  background-color: #f7f7f7 !important;
  font-family: 'Montserrat-Medium', sans-serif !important;
}

.radioBtn .ant-radio-group span {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 13px;
}
