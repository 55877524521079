.knowledgeFilter {
  justify-content: center;
  /* height: 100% !important; */
  margin-top: 150px !important;
}
.knowledgeFilter .searchSec {
  position: relative;
  padding-top: 0;
}
.knowledgeFilter .searchSec .anticon-search {
  color: #959595 !important;
  position: absolute;
  left: 15px;
  top: 53%;
  transform: translateY(-50%);
  z-index: 999;
}
.knowledgeFilter .searchSec .anticon-close {
  color: #959595 !important;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 53%;
  transform: translateY(-50%);
  z-index: 999;
}
.backbuttonPad {
  padding-top: 50px;
}
.backBtnCursor {
  cursor: pointer;
}
.knowledgeBaseContent .searchSec .ant-select-selector,
.knowledgeBaseContent
  .searchSec
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.knowledgeBaseContent .searchSec .ant-select-selector:focus {
  background-color: #fff !important;
  border: 1px solid #e7e7e7 !important;
  border-radius: 25px !important;
  box-shadow: 0 3px 6px #00000029 !important;
  height: 45px !important;
  min-width: 500px !important;
  width: 45vw !important;
  padding-left: 43px !important;
}
.knowledgeBaseContent .searchSec .ant-select-selector input {
  background: none;
  border: none;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.22px;
  line-height: 18px;
  min-height: 45px;
  max-height: 45px;
  padding-left: 33px !important;
  width: 100%;
}

.knowledgeBaseContent .searchSec .ant-select-selector input::placeholder {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.22px;
  line-height: 18px;
  padding-left: 33px !important;
}
.knowledgeContainer ul {
  list-style: none;
  padding-left: 0 !important;
}
.knowledgeBaseList {
  display: flex;
  gap: 20px;
}
.knowledgeImg {
  /* width: 75px !important;
  height: 75px !important; */
  border-radius: 5px;
}
.knowledgeContainer ul li {
  background: #f7f7f7;
  border: 1px solid #cccddc !important;
  border-radius: 6px;
  padding: 15px 10px;
  margin-bottom: 15px;
}
.knowledgeContainer ul h4 {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  line-height: 18px;
}
.knowledgeContent p {
  margin: 0 !important;
  display: grid;
  grid-template-columns: 2fr 1fr;
  color: #172b4d !important;
  font-family: 'Montserrat-Medium', sans-serif !important;
  line-height: 30px;
  height: 30px;
}
.knowledgeContent p span {
  color: #000 !important;
  font-family: 'Montserrat-Regular', sans-serif !important;
}
.knowledgeposition {
  height: auto !important;
  margin-top: 2.55vh !important;
}
.knowledgeModal .ant-modal-content {
  margin-top: 25px !important;
}
.knowledgeFilter .anticon-close-circle {
  font-size: 16px !important;
}

/* new knowledge base ui screens css */
.knowledgeBaseBlk {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85vh;
}
.knowledgeBaseBlk .kb_logo {
  display: block;
  margin: auto;
}
.knowledgeBaseBlk .baseSearchFilter {
  /* margin: 49px 0 15px 0; */
  margin: 4.53vh 0 15px 0;
  justify-content: center;
  display: flex;
}
.knowledgeBaseBlk .baseSearchFilter .baseSearchFilterBlk {
  position: relative;
}
.knowledgeBaseBlk .baseSearchFilter .ant-select {
  min-width: 500px !important;
  width: 54vw !important;
  max-width: 850px !important;
  height: 70px;
}
.knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector {
  border-radius: 30px !important;
  width: 100% !important;
  padding-left: 58px !important;
}
.knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector,
.knowledgeBaseBlk
  .baseSearchFilter
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
  padding-left: 48px;
  font-size: 18px;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.18px;
  line-height: 22px;
}
.knowledgeBaseBlk .baseSearchFilter .anticon-search {
  color: #9aa0a4;
  position: absolute;
  left: 33px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}
.ant-select-dropdown .rc-virtual-list-holder-inner .anticon-search {
  color: #9aa0a4;
  font-size: 20px;
}
.knowledgeBaseBlk .recentPublishArticles {
  /* padding-top: 40px; */
  padding-top: 3.7vh;
  width: 90%;
  margin: 0 auto;
}
.knowledgeBaseBlk .knowledgeBaseTitle {
  color: #000;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.knowledgeBaseBlk .knowledgeBaseFlex {
  display: flex;
  align-items: center;
  gap: 0 10px;
  justify-content: center;
}
.knowledgeBaseFlex .knowledgeBaseBox {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000029;
  cursor: pointer;
  width: 120px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.knowledgeBaseFlex .knowledgeBaseBox .ant-image {
  width: 100%;
  height: 100%;
}
.knowledgeBaseFlex .knowledgeBaseBox img.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 37px;
  height: 37px;
  z-index: 99;
  margin-right: 0 !important;
}
.knowledgeBaseFlex .knowledgeBaseBox img.knowledgebaseimg,
.knowledgeBaseFlex .knowledgeBaseBox video {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.knowledgeBaseFlex p {
  margin-top: 10px;
}
.recentSearchesArticles {
  padding-top: 12px;
  width: 90%;
  margin: 0 auto;
}

/* Knowledge Base Details styles */
.knowledgeBaseDetailsBlk {
  width: 100% !important;
  margin: 0 !important;
}
.knowledgeBaseDetailsBlk .baseSearchFilter {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 25px;
}
.knowledgeBaseDetailsBlk .baseSearchFilter .baseSearchFilterBlk {
  width: calc(100% - (4.69vw + 30px)) !important;
  margin-left: 4.69vw !important;
  margin-right: 30px !important;
}
.knowledgeBaseDetailsBlk
  .baseSearchFilter
  .baseSearchFilterBlk
  .baseSearchFilterInrBlk {
  position: relative;
  width: fit-content;
}
.knowledgeBaseDetailsBlk
  .baseSearchFilter
  .baseSearchFilterBlk
  .baseSearchFilterInrBlk
  .anticon-close-circle {
  font-size: 18px !important;
}
.knowledgeBaseDetailsBlk .baseSearchFilter .ant-select {
  min-width: 500px !important;
  width: 44.27vw !important;
  max-width: 850px !important;
  height: 70px;
  margin-top: 26px;
  margin-bottom: 26px;
}
.knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector {
  border-radius: 10px !important;
  width: 100% !important;
  padding-left: 58px !important;
}
.knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector,
.knowledgeBaseDetailsBlk
  .baseSearchFilter
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
  padding-left: 48px;
  font-size: 18px;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.18px;
  line-height: 22px;
}
.knowledgeBaseDetailsBlk .baseSearchFilter .anticon-search {
  color: #9aa0a4;
  position: absolute;
  left: 33px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  z-index: 9;
}
.knowledgeBaseDetailsBlk .baseSearchFilter .anticon-close {
  color: #707070;
  cursor: pointer;
  position: absolute;
  right: 33px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  z-index: 9;
}
.knowledgeBaseDetailsFlex {
  width: calc(100% - (4.69vw + 30px)) !important;
  margin-left: 4.69vw !important;
  margin-right: 30px !important;
  height: calc(100vh - 295px);
  overflow-y: auto;
}
.knowledgeBaseDetailsFlex .knowledgeBaseDetailsBox {
  display: flex;
  gap: 15px;
  margin: 20px 0 20px 15px;
}
.knowledgeBaseDetailsFlex .knowledgeBaseDetailsImg {
  cursor: pointer;
  width: 250px;
  height: 145px;
  background-color: #fff;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 10px;
  position: relative;
}
.knowledgeBaseDetailsFlex .knowledgeBaseDetailsPdf {
  justify-content: center;
  align-items: center;
  display: flex;
}
.knowledgeBaseDetailsFlex .knowledgeBaseDetailsImg .ant-image,
.knowledgeBaseDetailsFlex .knowledgeBaseDetailsImg img.knowledgebaseimg,
.knowledgeBaseDetailsFlex .knowledgeBaseDetailsImg video {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}
.knowledgeBaseDetailsImg .playIcon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 37px;
  height: 37px;
  z-index: 99;
}
.knowledgeBaseDetailsFlex .knowledgeBaseDetailsBox p {
  color: #707070;
  margin-bottom: 5px;
}
.knowledgeBaseDetailsFlex .knowledgeBaseDetailsBox p span {
  color: #000000 !important;
}
.knowledgeBaseDetailsFlex
  .knowledgeBaseDetailsBox
  .knowledgeBaseDetailsContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5px;
}

.knowledgeBaseFile {
  width: 100% !important;
  margin: 0 !important;
}
.knowledgeFileHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  padding: 26px 0;
  position: relative;
}
.knowledgeFileHeader .backBtnIcon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 53px;
}
.knowledgeFileHeader .backBtnIcon .anticon-left {
  padding-right: 6px;
}
.knowledgeFileHeader h4 {
  margin: 0;
  text-transform: capitalize;
}
.knowledgeFileHeader ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0;
}
.knowledgeFileHeader ul li:first-child {
  border-right: 1px solid #000;
  padding-right: 6px;
}
.knowledgeFileHeader ul li {
  color: #707070;
}
.knowledgeFileHeader ul li span {
  color: #000;
}
.knowledgeBaseFileBlk {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.knowledgeFileBody {
  display: block;
  margin: auto;
  width: 59vw;
  max-width: 1118px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.knowledgeFileBody .knowledgeFileBodyContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.knowledgeFileBody .knowledgeFileVideo video {
  border-radius: 10px;
  max-height: calc(100vh - 260px) !important;
}

.knowledgeBaseFileBlk .pdf-document {
  max-height: 90vh;
  max-width: 90vw;
  overflow: auto;
}

.react-pdf__Page__canvas {
  margin: auto;
}

@media (min-width: 1681px) and (max-width: 1920px) {
  .knowledgeBaseBlk .baseSearchFilter .ant-select {
    height: 70px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .anticon-search {
    font-size: 20px !important;
    left: 33px !important;
  }
  .ant-select-dropdown .rc-virtual-list-holder-inner .anticon-search {
    font-size: 20px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 48px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 58px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select {
    height: 70px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-search {
    font-size: 20px !important;
    left: 33px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseDetailsBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 48px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 58px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-close {
    font-size: 20px !important;
    right: 33px !important;
  }
  .knowledgeBaseDetailsFlex .knowledgeBaseDetailsImg {
    width: 250px !important;
    height: 145px !important;
  }
  .knowledgeBaseDetailsFlex {
    height: calc(100vh - 295px) !important;
  }
}
@media (min-width: 1441px) and (max-width: 1680px) {
  .knowledgeBaseBlk .kb_logo {
    width: 300px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select {
    height: 60px !important;
  }

  .knowledgeBaseBlk .baseSearchFilter .anticon-search {
    font-size: 18px !important;
    left: 24px !important;
  }
  .ant-select-dropdown .rc-virtual-list-holder-inner .anticon-search {
    font-size: 18px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 38px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 48px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select {
    height: 60px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-search {
    font-size: 18px !important;
    left: 24px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseDetailsBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 38px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 48px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-close {
    font-size: 18px !important;
    right: 24px !important;
  }
  .knowledgeBaseDetailsFlex .knowledgeBaseDetailsImg {
    width: 240px !important;
    height: 135px !important;
  }
}
@media (min-width: 1367px) and (max-width: 1440px) {
  .knowledgeBaseFlex .knowledgeBaseBox {
    width: 100px !important;
    height: 70px !important;
  }
  .knowledgeBaseFlex p {
    width: 100px !important;
  }
  .knowledgeBaseBlk .kb_logo {
    width: 300px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select {
    height: 55px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .anticon-search {
    font-size: 18px !important;
    left: 20px !important;
  }
  .ant-select-dropdown .rc-virtual-list-holder-inner .anticon-search {
    font-size: 18px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 35px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 45px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select {
    height: 55px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-search {
    font-size: 18px !important;
    left: 20px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseDetailsBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 35px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 45px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-close {
    font-size: 18px !important;
    right: 20px !important;
  }
  .knowledgeBaseDetailsFlex .knowledgeBaseDetailsImg {
    width: 230px !important;
    height: 125px !important;
  }
  .knowledgeBaseDetailsFlex {
    height: calc(100vh - 260px) !important;
  }
}
@media (min-width: 1281px) and (max-width: 1366px) {
  .knowledgeBaseFlex .knowledgeBaseBox {
    width: 80px !important;
    height: 60px !important;
  }
  .knowledgeBaseFlex p {
    width: 80px !important;
  }
  .knowledgeBaseBlk .kb_logo {
    width: 300px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select {
    height: 50px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .anticon-search {
    font-size: 16px !important;
    left: 16px !important;
  }
  .ant-select-dropdown .rc-virtual-list-holder-inner .anticon-search {
    font-size: 16px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 30px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 40px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select {
    height: 50px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-search {
    font-size: 16px !important;
    left: 16px !important;
  }
  .knowlknowledgeBaseDetailsBlkedgeBaseBlk
    .baseSearchFilter
    .ant-select
    .ant-select-selector,
  .knowledgeBaseDetailsBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 30px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 40px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-close {
    font-size: 16px !important;
    right: 16px !important;
  }
  .knowledgeBaseDetailsFlex .knowledgeBaseDetailsImg {
    width: 225px !important;
    height: 120px !important;
  }
  .knowledgeBaseDetailsFlex {
    height: calc(100vh - 240px) !important;
  }
  .knowledgeFileHeader h4 {
    margin-left: 80px !important;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .knowledgeBaseFlex p {
    width: 80px !important;
  }
  .knowledgeBaseBlk .kb_logo {
    width: 280px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select {
    height: 45px !important;
    min-width: 600px !important;
  }
  .knowledgeBaseFlex .knowledgeBaseBox {
    width: 80px !important;
    height: 60px !important;
  }

  .knowledgeBaseviewerContent .knowledgeBaseFlex p {
    width: 80px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .anticon-search {
    font-size: 16px !important;
    left: 12px !important;
  }
  .ant-select-dropdown .rc-virtual-list-holder-inner .anticon-search {
    font-size: 16px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 20px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 30px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select {
    height: 45px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-search {
    font-size: 16px !important;
    left: 12px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseDetailsBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 20px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 30px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-close {
    font-size: 16px !important;
    right: 12px !important;
  }
  .knowledgeBaseDetailsFlex .knowledgeBaseDetailsImg {
    width: 220px !important;
    height: 115px !important;
  }
  .knowledgeBaseDetailsFlex {
    height: calc(100vh - 230px) !important;
  }
  .knowledgeFileHeader h4 {
    margin-left: 80px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .knowledgeBaseFlex p {
    width: 80px !important;
  }

  .knowledgeBaseBlk .kb_logo {
    width: 280px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select {
    height: 45px !important;
    min-width: 600px !important;
  }
  .knowledgeBaseFlex .knowledgeBaseBox {
    width: 80px !important;
    height: 60px !important;
  }

  .knowledgeBaseviewerContent .knowledgeBaseFlex p {
    width: 80px !important;
  }

  .knowledgeBaseBlk .baseSearchFilter .anticon-search {
    font-size: 16px !important;
    left: 12px !important;
  }
  .ant-select-dropdown .rc-virtual-list-holder-inner .anticon-search {
    font-size: 16px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 25px !important;
  }
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 35px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select {
    height: 45px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-search {
    font-size: 16px !important;
    left: 12px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseDetailsBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 20px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector {
    padding-left: 30px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .anticon-close {
    font-size: 16px !important;
    right: 12px !important;
  }
  .knowledgeBaseDetailsFlex .knowledgeBaseDetailsImg {
    width: 220px !important;
    height: 115px !important;
  }
  .knowledgeBaseDetailsFlex {
    height: calc(100vh - 230px) !important;
  }
  .attContainer .elTabs .tblContainer.knowledgeBaseFile {
    height: calc(100vh - 123px) !important;
  }
  .knowledgeBaseBlk .knowledgeBaseFlex {
    flex-wrap: wrap;
    justify-content: center;
  }
  .knowledgeFileHeader h4 {
    margin-left: 80px !important;
  }
}
@media (min-width: 1300px) and (max-width: 1599px) {
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    font-size: 16px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseDetailsBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    font-size: 16px !important;
  }
}
@media (min-width: 768px) and (max-width: 1299px) {
  .knowledgeBaseBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    font-size: 14px !important;
  }
  .knowledgeBaseDetailsBlk .baseSearchFilter .ant-select .ant-select-selector,
  .knowledgeBaseDetailsBlk
    .baseSearchFilter
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    font-size: 14px !important;
  }
}
/* new knowledge base ui screens css end here */
.basecloseIcon {
  display: flex;
  align-items: center;
  gap: 25px;
}

.kbCard {
  background-color: transparent;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
}

.kbCard:hover {
  background-color: #dedede;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
}

.kbCard .ant-image-mask {
  border-radius: 10px;
}

.knowledgeBaseFlex p {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px !important;
}

.chatIconBase {
  left: 30px !important;
}
