.mWidth120 {
  min-width: 120px;
}
.mWidth150 {
  min-width: 120px;
}
.autoDriveReportTbl .ad_reportTitle {
  color: #000;
  font-size: 24px;
  font-family: 'Montserrat-SemiBold';
  line-height: 32px;
  margin-bottom: 20px;
}
.autoDriveReportTbl .ant-table-container {
  border-left: 0 !important;
}
.autoDriveReportTbl .ant-table table {
  padding-left: 0;
  padding-right: 0;
}
.autoDriveReportTbl .ant-table-thead > tr > th {
  /* background: #fafafa !important; */
  background: #fff !important;
  border: 1px solid #f4f4f4 !important;
  font-size: 12px !important;
  border-top: 0 !important;
}
.autoDriveUsage,
.autoDriveUsage .ant-table-thead > tr > th {
  text-align: center !important;
}
.autoDriveReportTbl .ant-table-body {
  min-height: calc(100vh - 460px) !important;
  max-height: calc(100vh - 460px) !important;
}
.autoDriveReportTbl .ant-table-tbody > tr > td {
  font-size: 12px !important;
  /* border: 1px solid #f4f4f4 !important; */
  border: 0 !important;
  /* text-align: center; */
  padding: 11px !important;
  height: 30px !important;
  cursor: pointer;
}
.autoDriveReportTbl .ant-table-tbody > tr:hover > td {
  background: #fafafa;
}
.autoDriveReportTbl .ant-table-tbody > tr:first-child td {
  height: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
.autoDriveReportTbl .ant-table-tbody > tr > td:first-child {
  text-align: left;
}
.ad_autodriveReportTitleFlex {
  padding-top: 8px !important;
  margin-bottom: 10px;
}

.autoDriveReportTbl .ant-table-thead > tr:first-child > th:nth-child(6),
.autoDriveReportTbl .ant-table-thead > tr:first-child > th:nth-child(7) {
  height: 30px !important;
  padding: 5px !important;
}
.flexDirectionCol {
  flex-direction: column;
}
.reportWidget_wrapper .reportTotal {
  background: #ffffff;
  padding-left: 20px;
  padding-top: 0 !important;
  border-top: 1px solid #f1f1f1 !important;
  display: flex;
  align-items: center;
  min-height: 30px;
  border-radius: 0 0 10px 10px;
}
.reportWidget_wrapper .ad_filters_card {
  align-items: flex-start;
}
.common_wrapper.reportWidget_wrapper {
  height: calc(100vh - 105px) !important;
}
.common_wrapper.reportWidget_wrapper .reportTotal b {
  margin-right: 5px;
  font-family: 'Montserrat-Bold';
}

.widgets_block3 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 16px;
}

.widgets_block3 img {
  width: 56px;
  height: 56px;
}

.widgets_block3 .gridItem {
  height: 80px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #fff;
  box-sizing: border-box;
}

.widgets_block4 {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 16px;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
}

.widgets_block4 img {
  width: 56px;
  height: 56px;
}

.widgets_block4 .gridItem {
  height: 80px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #fff;
  box-sizing: border-box;
  min-width: 360px;
}

.labelBlock {
  display: flex;
  gap: 24px;
}

.labelItem.flex1 {
  flex: 1;
}
.labelItem.flex2 {
  flex: 2;
}

.reportTotal {
  padding-left: 10px;
  padding-top: 10px;
}

.ant-modal.jngDetails {
  padding-bottom: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jngDetails .ant-modal-body {
  height: 650px;
}

.jngTBl .ant-table.ant-table-bordered > .ant-table-container {
  border-left: none !important;
}
.jngDetails .ant-modal-content,
.jngDetails .ant-modal-header {
  border-radius: 15px;
}

.common_table.jngTBl {
  border-radius: 15px;
  border: 1px solid #f0f0f0;
  height: calc(100vh - 500px);
}
.jngTBl .ant-spin-nested-loading {
  height: inherit !important;
}
.gap-16 {
  gap: 16px;
}

.filters_card .ant-picker .ant-picker-input input,
.filters_card .ant-picker.ant-picker-focused .ant-picker-input input {
  font-size: 10px !important;
}

.capitalize {
  text-transform: capitalize;
}
.driveActionDetailsTable .ant-empty.ant-empty-normal {
  min-height: 480px;
}
