.tsm {
  overflow-y: auto;
}
.col2-styles {
  display: flex;
  margin-bottom: 8px;
}

.taskdetails {
  width: 80px;
  height: 35px;
  border: none;
  border-radius: 4px;
  background-color: #eb921f;
  color: #fff;
  margin-top: -15px;
}

.col3-styles {
  margin-bottom: 8px;
}

.baseModalContainer {
  position: relative;
  top: 150px;
}
.tractorTxt {
  font-family: 'Montserrat-Medium', sans-serif;
  margin: 16px 0;
  letter-spacing: 0.8px;
  font-size: 15px;
}
.baseModalContainer .ant-modal-content {
  min-width: 850px;
  /* min-height: 375px; */
  min-height: auto;
  overflow: hidden;
  border-radius: 5px;
}
.baseModalContainer .ant-table-column-sorter {
  position: absolute;
  right: 60px;
}
.baseModalContainer .ant-modal-title {
  font-family: 'Montserrat-SemiBold', sans-serif;
  /* font-size: 20px; */
  /* letter-spacing: 0.8px; */
}
/* .rowClassName1 {
    font-family: Montserrat-Thin;
  } */
.ant-table-thead tr .ant-table-selection-column div {
  display: none;
}
.loader {
  /* height: 100%; */
  align-items: center;
  display: flex;
  justify-content: center;
}
.nfo {
  overflow-y: auto;
  height: calc(100vh - 265px);
}

.gSearch {
  width: 392px !important;
}
.supervisorclass {
  margin-left: 10px !important;
}

.dbWidget .ant-btn-primary.btn-start,
.dbWidget .ant-btn-primary.btn-start:hover {
  background-color: #617424 !important;
  margin-right: 8px !important;
  border-color: #617424 !important;
}
/* .dbWidget .ant-btn-primary.btn-start:hover {
    background-color: #788e31 !important;
    margin-right: 8px !important;
    border-color: #617424!important;
  } */

.dbWidget .ant-btn-primary.btn-finish,
.dbWidget .ant-btn-primary.btn-finish:hover {
  background-color: #d73435 !important;
  margin-right: 8px !important;
  border-color: #d73435 !important;
}
/* .dbWidget .ant-btn-primary.btn-finish:hover {
    background-color: #e93d3d !important;
    margin-right: 8px !important;
    border-color: #d73435!important;
  } */
.editTxtLink,
.editTxtLink:hover {
  color: #366376;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #fff;
  padding: 2px 12px;
  border-radius: 50px;
  box-shadow: 0 0 6px #00000029;
}
/* .editTxtLink:hover {
    text-decoration: underline;
    background: #366376;
    color: #fff!important;
  } */

.addJNGClass tbody tr td:first-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.addJNGClass tbody tr:first-child td {
  padding: 0 !important;
}
.addJNGClass tbody tr td {
  white-space: pre;
}

.showClose {
  font-family: 'Montserrat-Medium', sans-serif;
}
.showClose .ant-checkbox {
  margin-bottom: 4px;
}

/* new styles 15-12-2021 */
.taskmasterWidget .ant-card-body {
  display: inline-block;
  width: 100%;
  padding: 20px !important;
}
.calTbl.taskmasterWidget .calendar-body .fc-direction-ltr.fc-theme-standard {
  height: calc(100vh - 225px) !important;
}
.taskmasterWidget {
  border-radius: 15px !important;
  padding: 0 !important;
  background: #fff !important;
  border: 1px solid #e7e7e7 !important;
}
.taskmasterWidget .ant-card-head-title {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  display: inline-block;
  margin-bottom: 0;
  padding: 0 !important;
  text-transform: capitalize;
}
.taskmasterWidget .ant-card-extra {
  float: right;
  display: flex;
  align-items: center;
  padding: 0 !important;
  gap: 10px;
}
.taskmasterWidget .search-box {
  width: 19.1vw;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  background: #f7f7f7;
}
.taskmasterWidget .fc-toolbar-title {
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.taskmasterWidget .ant-input {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.1px;
  line-height: 18px;
}
.taskmasterWidget .ant-layout-header {
  background: transparent !important;
  line-height: unset !important;
}
.taskmasterWidget .ant-card-head {
  display: inline-block;
  width: 100%;
  height: 52px;
  border-bottom: 2px solid #e1e7f0;
  padding: 10px 20px 0;
}
.taskmasterWidget .btn-create,
.taskmasterWidget .btn-create:hover,
.taskmasterWidget .btn-create:focus {
  height: 32px !important;
  background: #366376 !important;
  font-size: 14px;
  font-family: 'Montserrat-Bold', sans-serif;
  padding: 4px 15px !important;
  border-color: #366376 !important;
  border-radius: 3px !important;
}
.fc .fc-col-header-cell-cushion {
  color: #000 !important;
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 16px;
}
.fc .fc-daygrid-day-number {
  color: #000 !important;
}
.tmTbl tbody tr {
  cursor: pointer;
}
.taskmasterWidget tr:nth-child(2n) {
  /* background-color: #F7F7F7; */
  background-color: #fff;
}
.taskmasterWidget .ant-checkbox-checked .ant-checkbox-inner,
.betaModal .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
  border-color: #366376;
}
.taskmasterWidget .ant-checkbox-checked .ant-checkbox-inner::after,
.betaModal .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #366376;
  border-top: 0;
  border-left: 0;
  left: 25%;
}
.taskmasterWidget .ant-checkbox-checked::after,
.betaModal .ant-checkbox-checked::after {
  border: 0 !important;
}
@media screen and (max-width: 1366px) {
  .taskmasterWidget .search-box {
    width: 200px;
  }
}

/* modal Styles */
.dW280 {
  width: 280px !important;
}
.betaModal .ant-modal-title {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.betaModal .ant-modal-header {
  border-radius: 15px 15px 0 0;
}
.betaModal .ant-modal-content {
  border-radius: 15px;
}
.modalFormWrapper {
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
}
.betaRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.betaCol {
  width: 50%;
}
.betaRow .betaCol:first-child {
  margin-right: 30px !important;
}
.betaModal .ant-form-item {
  margin-bottom: 8px !important;
}
.betaRow .ant-form-item-label > label {
  font-size: 16px !important;
  color: #000 !important;
  height: inherit !important;
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  letter-spacing: 1.71px;
  line-height: 19px;
}
.betaModal .ant-form-item-control .ant-input {
  font-family: 'Montserrat-Medium', sans-serif;
  background-color: #fff !important;
  color: #5c5c5c;
  border: 1px solid #366376;
  border-radius: 10px !important;
  height: 47px;
  font-size: 16px !important;
  line-height: 19px;
  letter-spacing: 0.093rem;
}
.betaModal .ant-input[disabled] {
  background-color: #f5f5f5 !important;
}
.betaModal .ant-input:hover,
.betaModal .ant-input:focus,
.betaModal .ant-input-focused {
  box-shadow: none;
  border-color: #366376 !important;
}
.betaModal .ant-select {
  width: 100%;
}
.betaModal .ant-select-selector,
.betaModal
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.betaModal .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  width: 100%;
  border: 1px solid #366376 !important;
  border-radius: 10px !important;
  height: 47px !important;
  font-size: 16px !important;
  font-family: 'Montserrat-Medium', sans-serif !important;
  color: #5c5c5c !important;
  line-height: 19px;
  letter-spacing: 0.093rem;
}
.betaModal
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  display: flex;
  align-items: center;
  height: 100%;
}
.betaModal .ant-select-single .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  height: 100%;
}
.ql-container.ql-snow {
  border: 1px solid #ccc;
}
.ql-editor {
  color: #5c5c5c;
  font-size: 16px;
  font-family: 'Montserrat-Medium', sans-serif;
  line-height: 19px;
  letter-spacing: 0.093rem;
}
.betaModal .ant-picker {
  box-shadow: none;
  border: 1px solid #366376;
  border-radius: 10px;
  height: 47px;
}
.betaModal .ql-toolbar.ql-snow {
  border-radius: 10px 10px 0 0;
  border: 1px solid #366376 !important;
  border-bottom: 0 !important;
}
.betaModal .ql-container.ql-snow {
  border: 1px solid #366376 !important;
  border-top: 1px solid #ccc !important;
  border-radius: 0 0 10px 10px;
}
.betaRow .formColW {
  width: 100%;
  margin-right: 0 !important;
}
.editTicketModal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.editTicketModal .ant-tabs-tab .ant-tabs-tab-btn,
.editTicketModal .ant-tabs-tab:hover {
  color: #5c5c5c !important;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 12px;
}
.editTicketModal .ant-tabs-ink-bar {
  background: #366376;
}
.tcktComments .ant-form-item-control-input {
  border: 1px solid #ccc;
}
.betaRowBtn {
  margin-bottom: 0;
}
.betaRowBtn .formRowBtn {
  display: flex;
  justify-content: flex-end;
}
.betaModal .btnSave,
.okBtn {
  background-color: #366376 !important;
  border-color: #366376 !important;
  color: #fff !important;
  font-family: 'Montserrat-Bold', sans-serif;
  border-radius: 3px !important;
  margin-right: 10px !important;
  text-transform: uppercase;
}
.canelBtn,
.canelBtn:hover,
.canelBtn:focus,
.betaModal .btnCancel,
.betaModal .ant-btn:hover,
.betaModal .ant-btn:focus {
  color: #5c5c5c;
  font-family: 'Montserrat-Bold', sans-serif;
  border-radius: 3px;
  border-color: #d9d9d9;
}
.taskmasterblk {
  display: flex;
  align-items: center;
  height: 100vh;
  background: #fff;
}
.taskdetailsBlk .orgDetails {
  width: 40%;
}
.taskdetailsBlk .orgData {
  width: 60%;
}
.taskDetailTbl .ant-table-pagination.ant-pagination {
  padding: 0 20px;
}
.taskmasterblk .ant-breadcrumb {
  font-size: 16px !important;
  font-family: 'Montserrat-Medium', sans-serif;
  text-transform: capitalize;
  letter-spacing: 1.25px;
  line-height: 18px;
  cursor: pointer;
  margin-bottom: 15px !important;
}
.hr-line {
  margin: 0 20px;
}
.taskDetailTbl table {
  overflow-x: auto;
}
.taskDetailTbl table tr th,
.taskDetailTbl table tr td {
  white-space: nowrap;
}
.ant-pagination-item-active {
  border-color: #366376;
}
.ant-pagination-item-active a {
  color: #366376;
}
.taskDetailTbl .tblDft .ant-table {
  border-radius: 15px !important;
}
.taskmasterInrBlk {
  height: auto;
}
.taskmasterInrBlk .ant-card {
  border-radius: 15px;
}
.taskdetailsWidget {
  border: 0 !important;
}
.taskdetailsBlk {
  overflow-y: auto;
  align-items: start;
  padding: 50px 27px;
}
.taskDetailTbl .ant-card-body {
  padding: 0 !important;
}

.taskmasterblk .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  padding-left: 0px;
  padding-right: 0px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid #e7e7e7;
}
