.updateCount {
  background-color: #d43d38;
  height: 6px;
  width: 6px;
  border-radius: 5px;
  margin-right: 2px;
}

.updateCon {
  display: flex;
  align-items: center;
}

.updateGrid {
  grid-template-columns: repeat(3, 1fr) !important;
}

.overFlw {
  overflow: auto;
  /* height: calc(100vh - 60px); */
}

.tractorContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.padding1620 {
  padding: 12px 20px;
}

/* .selTractorsBtn{
    font-size: 10px;
    font-family: 'Montserrat-SemiBold', sans-serif;
    background-color: #366376;
    border-radius: 6px;
    color: #fff;
  } */

.updateGridItem {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #fff;
  margin-top: 12px;
}

.updateGridItem img {
  height: 92px;
  width: 98px;
}

.tractorNameTxt {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.progressBox {
  display: flex;
  padding: 4px 10px;
  border-radius: 5px;
  border: 1px solid #5c5c5c;
  align-items: center;
  justify-content: center;
  max-width: 108px;
}

.wt100 {
  width: 100%;
}

.updateDot {
  width: 5px;
  height: 5px;
  background-color: #de827a;
  border-radius: 5px;
}

.blueBg {
  background-color: rgba(34, 84, 236, 0.5);
}

.blueBorder {
  border: 0.5px solid rgba(34, 84, 236, 0.5);
}

.blueClr {
  color: rgba(34, 74, 236, 0.5) !important;
}

.scheduleBorder {
  border: 0.5px solid rgba(103, 175, 183, 0.25);
  position: relative;
}

.scheduleBg {
  background-color: #67afb7;
}

.scheduleClr {
  color: #67afb7 !important;
}

.outOfDateBg {
  background-color: #5c5c5c;
}

.outOfDateBorder {
  border: 0.5px solid #5c5c5c;
}

.outOfDateClr {
  color: #5c5c5c !important;
}

.yellowBg {
  background-color: rgba(236, 147, 34, 0.5);
}

.yellowBorder {
  border: 0.5px solid rgba(236, 147, 34, 0.5);
}

.upToDateBg {
  background-color: #4078c0;
}

.upToDateBorder {
  border: 0.5px solid rgba(64, 120, 192, 0.25);
}

.upToDateClr {
  color: #4078c0 !important;
}

.failedBorder {
  border: 1px solid rgba(222, 130, 122, 0.25);
}

.failedBg {
  background-color: #de827a;
}

.failedClr {
  color: #de827a;
}

.progressTxt {
  color: #de827a;
  font-size: 10px;
  font-family: 'Montserrat-Medium', sans-serif;
  margin-left: 5px;
  position: relative;
}

.yellowClr {
  color: rgba(236, 147, 34, 0.5);
}

.alertTxt {
  display: flex;
  align-items: center;
}

.alertTxt img {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}

.swVersionTxt {
  font-size: 10px;
  font-family: 'Montserrat-Regular', sans-serif;
  margin: 16px;
  overflow: auto;
  height: calc(100vh - 342px);
  position: relative;
}

.percentTxt {
  color: #007aff;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.posRel {
  position: relative;
}

.progressStyle {
  margin-bottom: 12px;
}

.progressStyle .ant-progress {
  position: absolute;
  top: 17px;
  width: 108px;
}

.progressStyle .ant-progress-inner {
  overflow: visible;
  background: #e8e8e8;
  border-radius: 4px;
}
.progressStyle .ant-progress-bg {
  border-radius: 4px;
  background-color: #007aff !important;
}

.etaTxt {
  font-size: 12px;
  color: #99999c;
  font-family: 'Montserrat-Regular', sans-serif;
}

.recContent {
  font-size: 12px;
  color: #5c5c5c;
  font-family: 'Montserrat-Regular', sans-serif;
  margin-top: 20px;
  margin-left: 20px;
}

.supportContainer {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 12px;
  padding: 8px 10px;
}

.scheduleTxt {
  font-size: 12px;
  font-family: 'Montserrat-Regular', sans-serif;
  margin-left: 16px;
}

.firstGrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.secGrid Button {
  height: 34px;
  border-radius: 10px;
  border: 1px solid #366376 !important;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.noteProcess button {
  color: #99999c;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 12px;
  background: none;
  border: 0;
  cursor: pointer;
}

.noteProcess span {
  border: 0.5px solid #cfcfcf;
}

.schBtn {
  color: #366376 !important;
  width: 173px;
  height: 35px;
  border-radius: 10px;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  border: 1px solid;
}

.upAvailableBtn {
  color: #007aff;
  border-radius: 8px;
  height: 24px;
  font-size: 10px;
  border: 1px solid #007aff;
  margin-left: 18px;
  display: flex;
  padding: 6px 10px;
  align-items: center;
}

.installNowBtn {
  color: #fff !important;
  background-color: #366376 !important;
  margin-right: 8px;
  width: 173px;
  height: 35px;
  border-radius: 10px;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.installNowBtn.ant-btn[disabled],
.schBtn.ant-btn[disabled] {
  opacity: 0.25;
}

.updateScroll {
  width: 100%;
}

.fleetTxt {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin-bottom: 0px;
  color: #333333;
}

.upContent {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

/* .asapTxt{
  font-size: 12px;
  font-family: 'Montserrat-Regular', sans-serif;
  color: #b6b6b7;
  margin-top: 12px;
} */

.updateVersionTxt {
  font-size: 14px;
  font-family: 'Montserrat-Regular', sans-serif;
  margin-left: 52px;
  color: #5c5c5c;
  height: 45px;
}

.scheduleGrid {
  display: grid;
  grid-template-columns: 40.68% 57.72%;
  gap: 20px;
  margin-top: 20px;
  height: calc(100vh - 195px);
}

.scheduleGrid .ant-collapse-borderless > .ant-collapse-item {
  background-color: #f7f7f7;
  margin-bottom: 6px;
  border-radius: 4px;
  border-bottom: 0 !important;
}

.borderBtm {
  border-bottom: 1px solid #cfcfcf;
  height: 64px;
  padding: 0 16px;
}

.schUpdateBtn {
  width: 280px;
  border-radius: 6px;
  background: #366376;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #fff;
  margin: 0 auto 16px;
}

.schUpTxt {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin-left: 0px !important;
}

.relNotesGrid {
  border: none;
}

.installRight {
  border-radius: 10px;
  /* border: 1px solid  #E8E8E8; */
  padding: 24px 26px;
  background-color: #fff;
}

.installRight .Kq:after {
  z-index: 1 !important;
}

.installRight ._3n {
  width: 70px !important;
  height: 70px !important;
  border-radius: 5px !important;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: center;
}

.installRight ._3n._2E,
.installRight ._2E .Lx {
  color: #333333;
}

.installRight .FC .Lx {
  color: #fff;
}

.installRight .FC,
.installRight .FC ._1g {
  background-color: #4078c0;
}

.updateScheduleModal.ant-modal {
  height: fit-content;
  width: 65vw !important;
}

.startingUpdateCOn.ant-modal {
  height: fit-content;
  width: 574px !important;
  top: 28%;
}

.updateScheduleModal .ant-modal-content,
.startingUpdateCOn .ant-modal-content {
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
}

.selectUpdate {
  font-size: 10px;
  font-family: 'Montserrat-Regular', sans-serif;
  margin-top: 10px;
  line-height: initial;
  color: #99999c;
}

.sevTractorTxt {
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
  margin-left: 5px;
}

.mlr {
  margin-top: 4px;
  margin-left: 28px;
}

.mlr .ant-checkbox-wrapper {
  font-size: 12px;
}

.schDatePicker.ant-picker {
  padding: 0 !important;
  margin-top: 12px;
  border: none;
}

/* .schDatePicker .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
  border-radius: 16px;
  background: #366376;
} */

.schDatePicker1 {
  margin-bottom: 20px;
}

.schDatePicker1 .ant-picker-input > input {
  font-size: 12px;
  font-family: 'Montserrat-Regular', sans-serif;
}

.schDatePicker .ant-picker-input > input {
  font-size: 12px;
  font-family: 'Montserrat-Regular', sans-serif;
}

.schDatePicker .ant-picker-input > input:hover,
.schDatePicker .ant-picker-input > input:focus {
  border-bottom: 1px solid #67afb7;
}

.schDatePicker.ant-picker:hover,
.schDatePicker.ant-picker-focused {
  box-shadow: none;
}

.schDatePicker .ant-picker-input {
  flex-direction: row-reverse;
}

.schDatePicker .ant-picker-suffix {
  margin-left: 0;
  margin-right: 12px;
}

.updateScheduleModal .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  padding: 12px 0 !important;
}

.installDetails {
  width: 100%;
}

.panelTxt {
  font-size: 12px;
  font-family: 'Montserrat-Regular', sans-serif;
  /* color: #DE827A; */
  display: flex;
  align-items: center;
  line-height: 15px;
  margin-top: 10px;
  margin-left: 8px;
  margin-bottom: 10px;
}
.panelTxt:last-child {
  margin-bottom: 0px !important;
}

.panelTxt img {
  margin-right: 4px !important;
}
.installGrid .ant-collapse-item.ant-collapse-item-active {
  border: 1px solid #de827a !important;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 12px;
  background-color: #fff;
}

.installGrid .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  padding: 0;
}

/* .installGrid .ant-collapse-item.ant-collapse-item-active .serialContainer {
  padding-left: 15px;
} */

.installGrid .ant-collapse-item.ant-collapse-item-active .serialContainer {
  background-color: #fff !important;
}

.releaseNotesTxt {
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #333333;
  line-height: 26px;
  margin-right: 10px;
}

.updateScroll .infinite-scroll-component {
  height: calc(100vh - 314px) !important;
}

.updateScroll div#total {
  top: 6px;
}

.swVersionTxt .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.fleetContent {
  font-size: 12px;
  font-family: 'Montserrat-Regular', sans-serif;
  color: #333333;
  /* margin-bottom: 16px; */
}

.upWidget_wrapper {
  height: calc(100vh - 290px);
}

.upWidget_wrapper .infinite-scroll-component {
  height: calc(100vh - 355px) !important;
}

.scheduleFleet {
  display: flex;
  align-items: center;
}

.languageTxt {
  font-size: 12px;
  /* color: #99999C; */
  font-family: 'Montserrat-Medium', sans-serif;
}

.tractorsLength {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  color: #b5b5b6;
}

.serialContainer {
  height: 40px;
  background-color: #f7f7f7;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  font-size: 12px;
  position: relative;
}

.plr15 {
  padding: 0 15px;
}

.height0 {
  height: 18px;
  margin-bottom: 4px;
}

.swVersionTxt .panelTxt {
  margin-top: 0;
}

.removeCon {
  position: absolute;
  right: 0;
}

.removeTxt {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #de827a;
  cursor: pointer;
  margin-right: 10px;
}

.dateTimeTxt {
  font-size: 12px;
  font-family: 'Montserrat-regular', sans-serif;
}

.floatRight {
  text-align: right;
}

.schRelNotes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nextBtn,
.nextBtn:hover,
.nextBtn:focus,
.nextBtn:active {
  width: 100%;
  background-color: #366376;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #fff;
  border-radius: 6px;
  height: 35px;
}

.confirmBtn,
.confirmBtn:hover,
.confirmBtn:focus,
.confirmBtn:active {
  width: 100%;
  background-color: #e1efdf;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #379526;
  border-radius: 6px;
  height: 35px;
  border: none;
}

.nextBtnW,
.nextBtnW:hover,
.nextBtnW:focus,
.nextBtnW:active {
  width: 187px;
}

.updateArrow {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-right: 23px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
}

.margin16 {
  margin: 16px;
}

.rightSchedule {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 25px;
  height: 734px;
}

.firstSch,
.secondSch {
  background-color: #fff;
  border-radius: 10px;
  padding: 24px 24px 40px 24px;
}

.common_table .ant-checkbox-wrapper span {
  font-size: 12px;
}

.updateTractorTxt {
  font-size: 14px;
  font-family: 'Montserrat-Regular', sans-serif;
}

.commonGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 28px;
  min-height: 157px;
  margin-top: 60px;
}

.gridItems {
  border-radius: 10px;
  background: rgba(133, 149, 202, 0.2);
  padding: 18px 16px;
  position: relative;
  text-align: center;
}

.gridItems img {
  margin-top: 16px;
  width: 48px;
  height: 48px;
}

.gridTxt {
  font-size: 12px;
  font-family: 'Montserrat-Regular', sans-serif;
  margin-top: 8px;
}

.gridTopTxt {
  width: 40px;
  height: 40px;
  border-radius: 24px;
  background: #8595ca;
  position: absolute;
  left: calc(42.5% - 0px);
  top: -22px;
  color: #fff;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updateWrapper {
  display: flex;
  align-items: center;
}

.updateWrapper .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 1px;
  background: #fff;
  border-color: #5c5c5c;
}

.updateWrapper .ant-checkbox-inner::after {
  left: 20%;
}

.updateWrapper .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #5c5c5c !important;
}

.releaseAllNotes {
  padding: 16px;
  background: #fff;
  border-radius: 10px;
  margin-top: 22px;
  height: calc(100vh - 180px);
}

.releaseAllNotes .commonReleaseTabs {
  height: 100% !important;
}

.upWidget_wrapper .ant-table-row:hover .hoverShow,
.upWidget_wrapper .ant-table-row .hoverShow1,
.upWidget_wrapper .ant-table-row:hover .hoverShow1 {
  display: block;
  height: 23px;
  margin-right: 8px;
}

.upWidget_wrapper .ant-table-row .hoverShow {
  display: none;
}

.schDateStyle {
  border-radius: 4px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10);
  padding: 10px;
  min-width: 162px; 
  height: 40px;
  text-align: center;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #4078c0;
}

.goToToday {
  font-size: 10px;
  color: #366376;
  font-family: 'Montserrat-SemiBold', sans-serif;
  cursor: pointer;
  margin-left: 62px;
}

.schUpdateGridBg {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  height: calc(100vh - 195px);
}
.rightSchedule {
  height: calc(100vh - 195px);
}

.schUpdateGridBg1 {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.upWidget_wrapper tr.ant-table-row.selected {
  background-color: #f7f7f7 !important;
}

.popOver.ant-popover-inner {
  border: 1px solid red !important;
}

.updateFail {
  position: relative;
}

.schRemarks {
  position: absolute;
  left: 120px;
  top: 22px;
  min-height: 100px;
  width: 220px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 12px;
  background-color: #fff;
  display: none;
  z-index: 999;
}

.viewTicketBtn,
.viewTicketBtn:hover,
.viewTicketBtn:focus {
  border-radius: 6px;
  padding: 6px 8px;
  background: #366376;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 8px;
  color: #fff;
  float: right;
  height: 24px;
  display: flex;
  align-items: center;
}

.progressBox {
  position: relative;
}

.infoIconNew:hover .schRemarks {
  display: block;
}
.infoImgHover:hover img + .schRemarks {
  display: block;
}

.infoImgHover > img {
  right: -36px;
  position: absolute;
  top: 4px;
  padding-bottom: 8px;
  padding-right: 8px;
}

.imgmr6 {
  margin-right: 6px;
}

.swVersionTxt .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.rNotesSec .releaseNotesTxt span p {
  margin: 0;
}

.fleerContainers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
}

.mainContainers {
  background: #fff;
  border-radius: 8px;
  padding: 16px 20px;
  border: 1px solid #eeeeee;
  height: 483px;
}

.mainContainers1 {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  height: calc(100vh - 275px);
  display: flex;
  flex-direction: column;
  position: relative;
}

.pad1620 {
  /* padding: 16px 20px; */
  height: calc(100% - 67px);
}

.outOfTxt {
  color: #5c5c5c;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.outOfBox {
  max-height: 137px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  /* padding: 10px 0 0 0; */
  /* margin-bottom: 14px; */
}
.outBoxContainer::-webkit-scrollbar,
.insBoxContainer::-webkit-scrollbar {
  width: 3px !important;
}
.outBoxContainer::-webkit-scrollbar-thumb,
.insBoxContainer::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

/* .outOfBox:last-child{
  margin-bottom: 0;
} */

.outOfBoxContent {
  height: 100vh;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 246px;
  text-align: center;
  color: #99999c;
  font-size: 14px;
}

.blueBorder {
  border: 1px solid #4078c0;
  cursor: pointer;
}

.swFwTxt {
  padding: 10px 12px 0;
  margin-bottom: 12px;
  /* height: 78px; */
}

.internetTxt {
  height: 45px;
  background: #f7f7f7b2;
  padding: 10px 12px;
  font-size: 12px;
  color: #de827a;
  position: relative;
  cursor: pointer;
  /* display: flex;
  align-items: center; */
}

.internetTxt .progressContainer {
  margin: 0 !important;
}

.internetTxt .estTime {
  top: 10px;
  position: absolute;
  right: 0;
  width: 50px;
  color: #99999c;
  text-align: center;
}

.numberTxt {
  color: #333333;
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.swUpdateTxt {
  color: #99999c;
  font-size: 12px;
  text-align: left;
}

.installationsCon {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  /* box-shadow: 0px 1px 6px 0px #0000001A; */
  /* margin-top: 12px;
  margin-bottom: 1px; */
}

.box_blur {
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
}

.installationsCon1 {
  height: 84px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #4078c0;
  /* box-shadow: 0px 1px 6px 0px #0000001A; */
  margin-top: 12px;
  margin-bottom: 1px;
}

.downloadTxt {
  color: #4078c0;
  font-size: 12px;
}

.pad1220 {
  padding: 12px 20px;
}

.schUpDate {
  font-size: 12px;
  color: #5c5c5c;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.schUpdatesListContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(388px, 1fr));
  gap: 8px;
}

.schListItems {
  /* width: 388px; */
  height: 88px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #eeeeee;
}

.updatePrTxt {
  color: #007aff;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 20px;
  padding: 2px 6px;
}

/* .outBoxContainer{
  height: calc(483px - 72px);
  overflow: scroll;
  margin-top: 12px;
} */

.outBoxContainer,
.insBoxContainer {
  min-height: 200px;
  height: 100%;
  overflow-y: auto;
  padding: 0px 20px 12px 20px;
  display: flex;
  flex-direction: column;
}
.outBoxContainer {
  gap: 12px;
}
.insBoxContainer {
  gap: 16px;
}

.upToDateBox {
  padding: 10px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  max-height: 100px;
}

.buttonsCon {
  border-top: 1px solid #eeeeee;
  padding: 16px;
  text-align: center;
  /* position: absolute;
  bottom: 0px;
  width: 100%; */
}

.upProcess1 {
  display: grid;
  grid-template-columns: 66px 1fr;
  /* margin-top: 30px; */
  align-items: flex-start;
  /* justify-content: center; */
}
.steps_items {
  display: flex;
  gap: 25px;
  flex-direction: column;
  padding-left: 30px;
}

.process1 {
  width: 45px;
  height: 45px;
  border: 1px solid #007aff;
  border-radius: 100px;
  color: #007aff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 20px;
  margin-right: 10px;
  position: relative;
}
.process1::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 85%;
  top: 51px;
  background-color: #007aff;
}
.upProcess1:last-child .process1::after {
  background: none;
}
.backgroundTxt {
  color: #5c5c5c;
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.bgTractorTxt {
  color: #5c5c5c;
  font-size: 14px;
  font-family: 'Montserrat-Regular', sans-serif;
  margin-top: 2px;
}
.ant-modal.schClose {
  width: 820px !important;
}
.schClose .ant-modal-close-x {
  font-size: 20px;
  width: 64px;
}
.schClose .ant-modal-body {
  padding: 30px;
}
.updatePrTxt {
  margin-bottom: 30px;
}
/* .processLine {
  border-left: 2px solid #007aff;
  height: 36px;
  position: absolute;
  left: 20px;
  top: 54px;
} */

.progressContainer {
  margin-top: 5px;
}

.progressContainer .ant-progress-text {
  display: none;
}

.internetTxt .progressContainer .ant-progress-bg {
  background-color: #99999c !important;
}

.progressContainer .ant-progress-bg {
  background-color: #007aff !important;
  height: 4px !important;
}

.instTxt {
  color: #007aff;
  font-size: 12px;
}

.progressContainer .ant-progress-show-info .ant-progress-outer {
  margin: 0;
  padding: 0;
}

.progressContainer .ant-progress {
  bottom: 6px;
}

.estTime {
  width: 120px;
  text-align: right;
  /* display: flex;
  justify-content: space-around; */
}

.selectedDateTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-checkbox .ant-checkbox-inner,
.ant-checkbox .ant-checkbox-inner:hover {
  width: 16px;
  height: 16px;
  border-color: #99999c;
  border-radius: 0 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #4078c0cc;
  background-color: #4078c0cc;
}

.backgroundInfo {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f7f7f7b2;
  padding: 10px 12px;
  font-size: 12px;
  color: #de827a;
  border-radius: 6px;
}

.grayColor {
  color: #99999c;
}

.common_tabs .commonReleaseTabs .ant-tabs-tab {
  padding: 10px 16px !important;
}

.common_tabs .commonReleaseTabs .ant-tabs-content-holder {
  height: calc(100ch - 226px) !important;
  background-color: #fff;
  overflow: auto;
}

.common_tabs .commonReleaseTabs .ant-tabs-tab {
  margin-left: 10px !important;
  background-color: transparent;
}

.common_tabs .commonReleaseTabs .ant-tabs-tab-active {
  background: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0.2));
  border-radius: 5px 5px 0 0 !important;
}

.rNotesSec .commonReleaseTabs .ant-tabs-content-holder {
  height: calc(100vh - 772px) !important;
}

.scheduleError {
  border-radius: 10px;
  /* width: 85%; */
  margin: auto;
  text-align: center;
}

.scheduleErrorBlock {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.scheduleError .ant-alert-message {
  color: red !important;
  font-weight: 600;
}

.ofHidden div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.scheduleAlertIcon {
  width: 18px;
  margin-top: -4px;
  margin-right: 4px;
}

.upToDateBox .progressContainer {
    display: inline-grid;
    width: 100%;
}

.upToDateBox .ant-progress {
  height: 4px !important;
  bottom: inherit !important;
}
.upToDateBox .ant-progress-inner  {
  top:-10px !important
}
.upToDateBox .mb8{
  margin-bottom: inherit;
}