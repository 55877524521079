/* remote access granted css */

.mbrag {
  margin-bottom: 10.55vh;
}

.ragHead {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 1.77vw;
  letter-spacing: 0.25px;
  margin-bottom: 4.44vh;
}
.ragImage {
  margin-bottom: 6.29vh;
  height: 20.09vh;
}
.ragImage img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}
.ragHelpTxt {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 0.93vw;
  letter-spacing: 0.13px;
  margin: 0 auto;
  max-width: 31.25vw;
}
