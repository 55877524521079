@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);
.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.site-layout .site-layout-background {
  background: #fff;
  border-bottom: 1px solid #707070;
  height: 65px;
}
[data-theme='‘dark’'] .site-layout .site-layout-background {
  background: #141414;
}
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #00aaff;
}
.avatarMT {
  /* background-color: black; */
  width: 13.02vw;
  height: 27.65vh;
}
.avatar-xl {
  width: 4rem !important;
  height: 4rem !important;
  line-height: 4rem;
  font-size: 1.75rem;
}
.mb1 {
  margin-bottom: 0.25rem !important;
}
.roundedCircle {
  border-radius: 150px !important;
}
.userPic {
  margin: 0px 0px 10px 0;
}
.userPic img {
  width: 64px;
  height: 64px;
  object-fit: cover;
}
.fontWeightBold {
  font-weight: bold !important;
  font-size: 15px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #fff;
  margin: 0px;
  text-align: center;
}
.textMuted {
  color: #b3bdca !important;
  font-size: 12px;
  font-weight: 400;
}
.userSec {
  padding: 10px 20px;
  border-bottom: 1px solid #252629;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
/***** ========== scrollbar code=================== *****/
.sideMenuSec ::-webkit-scrollbar {
  background-color: #2f333b;
  width: 10px;
}
/* background of the scrollbar except button or resizer */
.sideMenuSec ::-webkit-scrollbar-track {
  background-color: #1e3a46;
}
.sideMenuSec ::-webkit-scrollbar-track:hover {
  background-color: #3d3838;
}
/* scrollbar itself */
.sideMenuSec ::-webkit-scrollbar-thumb {
  background-color: #34636a;
  border-radius: 16px;
  border: 2px solid #2f333b;
}
.sideMenuSec ::-webkit-scrollbar-thumb:hover {
  background-color: #34636a;
  border: 2px solid #2f333b;
}
/* set button(top and bottom of the scrollbar) */
.sideMenuSec ::-webkit-scrollbar-button {
  display: none;
}
.sideMenuSec .listitems {
  height: calc(100vh - 200px);
  overflow-y: auto;
  padding-bottom: 40px;
}
.site-layout .site-layout-background {
  justify-content: flex-start !important;
}
.gSearch {
  width: 300px !important;
}
.posRel {
  position: relative;
}
/* .site-layout .ant-input-affix-wrapper {
  border: 1px solid #E7E7E7 !important;
  background-color: #F4F5F7 !important;
  border-radius: 3.5px !important;
  height: 32px !important;
  font-family: ‘Montserrat-Medium’ !important;
  font-size: 14px !important;
  color: #172B4D !important;
}
.site-layout .ant-input {
  border:1px solid #E7E7E7 !important;
  background-color: #F7F7F7 !important;
  border-radius: 10px !important;
  font-family: ‘Montserrat-Medium’;
  min-height: 44px;
} */
.switchC .ant-space-align-center {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.site-layout .ant-select {
  border: none !important;
  /* width: 100%; */
}
.switchC .ant-space-item:first-child {
  width: 100%;
  cursor: pointer;
}
.autoCompleteAPL {
  margin-left: 20px;
}
.appVer {
  font-size: 13px;
  position: absolute;
  bottom: 0px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  background: #222;
  min-height: 40px;
  padding-left: 30px;
  border-top: 2px solid #403b3b;
}
.fa-2x {
  font-size: 2em;
}
.fa {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}
.main-menu:hover,
nav.main-menu.expanded {
  /* width: 234px; */
  width: 200px !important;
  overflow: visible;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  animation-duration: 1.5s;
  overflow-y: auto;
  overflow-x: hidden;
}
.main-menu {
  background: #1e3a46;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100vh;
  left: 0;
  width: 100px;
  /* overflow-y: auto; */
  overflow-x: hidden;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  /* box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.29); */
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* min-height: 1080px; */
}
.main-menu > ul {
  margin: 0px 0;
}
.main-menu li {
  position: relative;
  display: block;
  /* width: 227px; */
  width: 200px !important;
}
.main-menu li > a {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #000;
  font-family: 'arial', sans-serif;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}
.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  /* width: 234px; */
  width: 115px;
  text-transform: uppercase;
  color: #e8e8e8b8;
}

/* .main-menu .nav-text:hover {
    position:relative;
    display:table-cell;
    vertical-align:middle;
    width:234px;
    font-family: Montserrat-Bold;
    text-transform: uppercase;
    color: #fff;
    } */
/* .main-menu>ul.logout {
  position:absolute;
  left:0;
  bottom:0;
  } */

.no-touch .scrollable.hover {
  overflow-y: hidden;
}
.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}
a:hover,
a:focus {
  text-decoration: none;
}
nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}
.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: ‘Titillium Web’;
  font-style: normal;
  font-weight: 300;
  src: local(‘Titillium WebLight’), local(‘TitilliumWeb-Light’),
    url(http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff)
      format(‘woff’);
}
.iconBox {
  /* width: 48px;
  height: 44px; */
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px;
  background: transparent;
  /* margin-left: 26px;
  margin-right: 26px; */
  margin-left: 22px !important;
  margin-right: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-menu:hover .iconBox,
.main-menu:hover .iconBox,
.main-menu:hover .iconBox:active,
.main-menu:hover .iconBox.mItemSel,
nav.main-menu.expanded .iconBox:active,
nav.main-menu.expanded .iconBox.mItemSel,
.main-menu:hover .iconBoxProfile,
nav.main-menu.expanded .iconBoxProfile {
  margin-right: 6px !important;
}
.iconBox:hover {
  /* width: 48px;
  height: 44px; */
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px;
  /* margin-left: 26px;
  margin-right: 26px; */
  margin-left: 22px !important;
  margin-right: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconBox:active,
.iconBox.mItemSel {
  /* width: 48px;
  height: 44px; */
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px;
  background: #366376;
  /* margin-left: 26px;
  margin-right: 26px; */
  margin-left: 22px !important;
  margin-right: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subnav-disable .iconBox:active {
  background: transparent;
  cursor: initial;
}
.subnav-disable a {
  cursor: initial;
}
.main-menu div.bottomLine {
  border-bottom: 1px solid #67afb7b1;
  margin-left: 15px;
  margin-right: 15px;
  height: 1px;
  margin-bottom: 5.55vh;
}
/* .main-menu:hover .nav-text {
  position: relative;
  left: -15px;
} */
.main-menu li > a i {
  color: #fff;
}
.sMenuUL {
  padding-top: 4.3vh;
}
.main-menu li {
  /* margin-bottom: 3.88vh; */
  margin-bottom: 25px;
}
.logout li {
  margin-bottom: 0px;
}
.hRightSec {
  margin-left: auto;
  align-items: center;
  display: flex;
  height: 100%;
}
.top-navbar-nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-direction: row !important;
  height: 100%;
  align-items: center;
}
.top-navbar-nav .nav-item {
  margin-left: 1.25rem;
}
.top-navbar-nav .nav-link {
  color: #212121;
  font-size: 12px;
  vertical-align: middle;
}
.top-navbar-nav .nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.rounded-circle {
  border-radius: 50% !important;
}
.bellIcon,
.bellIcon:hover {
  font-size: 20px;
  color: #c6c6c6;
}
.nCount {
  position: absolute;
  padding: 0.25rem 0.375rem;
  font-size: 0.5rem;
  left: 50%;
  top: 30%;
  width: 16px;
  height: 16px;
}
.count-indicator {
  position: relative;
  padding: 0;
  text-align: center;
}
.badgeCricle {
  color: #fff;
  background-color: #c4183c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}
.top-navbar-nav .nav-link,
.top-navbar-nav li {
  height: 100%;
  display: flex;
  align-items: center;
}
.gearIcon,
.gearIcon:hover {
  font-size: 20px;
  color: #c6c6c6;
}
.aSize {
  width: 40px;
  height: 40px;
}
.itemIcon {
  color: #fff;
  font-size: 18px;
}
/* li.logoutLink {
  visibility: hidden;
}
.main-menu:hover .logoutLink {
  visibility: visible;
} */

.iconBoxProfile {
  /* width: 50px;
  height: 50px; */
  border: 1px solid #67afb7;
  border-radius: 80px;
  background: #989898;
  /* margin-left: 26px;
  margin-right: 26px; */
  margin-left: 22px !important;
  margin-right: 22px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconBoxProfile img {
  max-width: 100%;
  /* height: auto; */
  width: 100%;
  border-radius: 80px;
  /* border: 1px solid #67afb7; */
}
.main-menu li.mtop57 {
  margin-bottom: 6.3vh;
}
.main-menu .logout li {
  /* margin-bottom: 2.66vh; */
  margin-bottom: 25px;
}

.main-menu > ul.logoutLink {
  position: absolute;
  left: 0;
  bottom: 0;
}
.layerName img {
  padding-right: 15px;
}
.iconW {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  margin-right: 15px;
}
.mapIconSec {
  width: 60px;
  position: absolute;
  right: 0px;
  height: calc(100% - 180px);
  margin-top: 150px;
  /* z-index: 4; */
  transition: 1s;
}
.tglexpand {
  /* right: 336px; */
  right: 475px;
}
.btMap {
  bottom: 290px !important;
}
.main-menu .nav-text.disable {
  color: #959595;
}

.mItemSel + .nav-text {
  /* font-family: Montserrat-Bold !important; */
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  color: #fff;
}

.site-layout .searchMapBlk .ant-select {
  width: 100%;
}

.allDay .ant-checkbox {
  position: relative;
  /* top: 5px; */
}
.allDay .layerName {
  width: 120px !important;
}

.render-pdf-layout {
  overflow-y: auto;
  background: #fff;
  text-align: center;
}

.edit-loader {
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
  position: absolute;
  z-index: 9;
}

.notificationbtn .btnView {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  align-items: center;
  justify-self: center;
  background-color: #8a9a48;
  margin-left: 0 !important;
  outline: none;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
}

@media (min-width: 768px) and (max-width: 1439px) {
  /* .sideMenuSec {
    min-width: 80px !important;
    width: 80px !important;
    max-width: 80px !important;
    flex: 0 0 80px !important;
  } */
  /* .main-menu {
    width: 80px !important;
  } */
  /* .main-menu:hover,
  nav.main-menu.expanded {
    width: 227px !important;
  } */
  /* .main-menu:hover .nav-text {
    left: -8px;
  } */
  /* .iconBoxProfile {
    margin-left: 15px;
    margin-right: 15px;
  } */
  .iconBox,
  .iconBox:active,
  .iconBox.mItemSel,
  .iconBox:hover {
    margin-left: 16px;
    margin-right: 16px;
  }
}
@media (min-width: 1440px) and (max-width: 1920px) {
  /* .sideMenuSec {
    min-width: 5.2vw !important;
    width: 5.2vw !important;
    max-width: 5.2vw !important;
    flex: 0 0 52vw !important;
  } */
  .iconBoxProfile .ant-avatar {
    /* width: 2.5vw !important;
    height: 2.5vw !important; */
    /* font-size: 0.72vw; */
    position: relative;
    left: 0px;
  }
  /* .iconBoxProfile img {
    width: 2.6vw;
    height: 2.6vw;
  } */
  /* .main-menu {
    width: 5.2vw !important;
  } */
  /* .main-menu:hover,
  nav.main-menu.expanded {
    width: 12.18vw !important;
  } */
  .main-menu li.mtop57 {
    width: 11.82vw !important;
  }
  /* .main-menu .nav-text {
    width: 12.18vw !important;
  } */
  /* .main-menu:hover .nav-text {
    font-size: 0.72vw !important;
    left: -0.781vw;
  } */
  .iconBoxProfile {
    /* margin-left: 1.3vw !important;
    margin-right: 1.3vw !important; */
    /* width: 2.6vw!important; */
    /* height: 5.54vh!important; */
    background: transparent !important;
    border: 0 !important;
  }
  /* .iconBox {
    margin-left: 1.35vw !important;
    margin-right: 1.35vw !important;
    width: 2.5vw !important;
    height: 4.88vh !important;
  } */
  .wv16 {
    width: 18px;
    height: 18px;
  }
  .wv20 {
    width: 1.04vw;
    height: 1.04vw;
  }
  .wv21 {
    width: 1.09vw;
    height: 1.09vw;
  }
  .wv22 {
    width: 1.14vw;
    height: 1.14vw;
  }
  .wv25 {
    width: 1.3vw;
    height: 1.3vw;
  }
}
/* @media (min-width: 1440px) and (max-width: 1599px) {
  .iconBoxProfile {
    margin-left: 1vw !important;
    margin-right: 0.8vw !important;
  }
} */
/* @media (min-width: 1600px) and (max-width: 1699px) {
  .iconBoxProfile {
    margin-left: 1.13vw !important;
    margin-right: 1.13vw !important;
  }
} */
/* @media (min-width: 1700px) and (max-width: 1849px) {
  .iconBoxProfile {
    margin-left: 1.22vw !important;
    margin-right: 1.22vw !important;
  }
} */
/* @media (min-width: 1921px) and (max-width: 4830px) {
  .tglexpand {
    right: 488px;
  }
  .afterMapRightAlign .positionMap-inner {
    right: 490px !important;
    transition: 1s !important;
}
} */

@media (min-width: 1441px) and (max-width: 1920px) {
  /* .tglexpand {
    right: 488px;
  } */
  /* .afterMapRightAlign .positionMap-inner {
    right: 493px !important;
    transition: 1s !important;
} */
}

.flSearchSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.flSearchName {
  /* width: calc(100% - 30px); */
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px;
}

.main-menu,
.sideMenuSec {
  width: 84px !important;
  flex: 0 0 84px !important;
  min-width: 84px !important;
}

.mtl15 {
  margin-top: 15px;
  margin-left: 15px;
}

[data-theme='dark'] .site-layout .site-layout-background {
    background: #1E3A46;
  }
