.completedWorkTable .ant-table-row {
  cursor: pointer;
}

.implementDropdown {
  width: 100%;
}

.op-title {
  font-size: 12px !important;
}

.empty-ticket-block {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}

.item-active {
  background-color: #67afb7 !important;
  color: white;
}

.feet-card-active {
  background-color: #e1e1e1 !important;
}

.operators-names-active {
  background-color: #e1e1e1 !important;
}

.chip-item-active {
  background-color: #e1e1e1 !important;
  color: white;
}
.op-chip-item-active {
  background-color: #f7f7f7 !important;
  color: #5c5c5c !important;
  border: 1px solid #366376 !important;
}

.tractor-efficiecny {
  padding: 0px 10px;
  width: 100%;
  height: 180px;
  overflow-y: auto;
}

.top-settings:hover .op-search-bar .ant-input::placeholder {
  color: #333;
}

.select-box {
  font-size: 16px !important;
}

.hrsText {
  color: #333;
}
.operationsTableRowClassName {
  padding: 2px 5px;
}

.opTableClass {
  height: 45px;
}

.tableParent .ant-table-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 55px;
}

.inheritHeight {
  height: inherit !important;
}

.lShape {
  position: relative;
  margin-left: 25%;
  bottom: 50%;
  height: 12px;
  border-bottom: 1px solid transparent;
  border-bottom-color: #ccc;
  border-left: 1px solid #ccc;
  border-top: 0;
  transform: translateY(-50%);
  content: '';
  width: 100%;
}
.centerLine {
  position: relative;
  // margin-left: 25%;
  bottom: 50%;
  height: 14px;
  border-bottom: 1px solid transparent;
  border-bottom-color: #ccc;
  border-top: 0;
  transform: translateY(-50%);
  content: '';
  position: relative;
  left: -5px;
}
.lineBlock {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.tblImpName {
  white-space: inherit !important;
  word-break: break-word !important;
  overflow: hidden !important;
}

.alertsCotainerTabs .ant-tabs-nav {
  height: 23px;
}

.alertsCotainerTabs .ant-tabs-nav-operations button {
    display: none;
}
.alertsCotainerTabs .ticket-number {
  width: 80px;
  word-break: break-word;
  min-width: 80px;
  white-space: inherit;
  overflow: inherit;
  line-height: 15px;
  text-align: left;
}
.hazard-img-block img {
  max-width: 100%;
  border-radius: 4px;
  min-height: 62px;
}

.sshotDTime {
  font-size: 8px;
  line-height: 10px;
  color: #5C5C5C;
  font-family: 'Montserrat-Regular';
  display: flex;
  gap: 4px;
}
.sshotHBlock .assigned-to-title {
  width: 130px;
  align-items: center;
}
.alertsCotainerTabs .sshotHBlock .ticket-number {
  width: 95px !important;
    min-width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.sshotImgCam {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.sShotCamName {
  font-size: 8px;
  line-height: 10px;
  color: #1F1F1F;
  font-family: 'Montserrat-Regular';
  text-align: center;
}
.no-incidents-reported {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.noIncidentIcon {
  padding-top: 12px;
}
.noIncidentTitle {
   font-size: 12px;
  line-height: 15px;
  color: #5C5C5C;
  font-family: 'Montserrat-Medium';
}
.noIncidentDesc {
   font-size: 10px;
  line-height: 12px;
  color: #B5B5B6;
  font-family: 'Montserrat-Regular';
}

.assigned-to .ant-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-code-block .ant-avatar > img {
  width: 14px;
  height: 14px;
}