.ptb16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.plr38 {
  padding-left: 38px;
  padding-right: 38px;
}
.notificationEdit {
  padding: 0px 0 20px 0;
}
.notificationDate1 {
  padding-left: 38px;
  padding-bottom: 16px;
}
.notificationDayWise ul {
  padding-left: 0;
  cursor: pointer;
}
.notificationActive {
  background-color: #fff;
}
.notificationEdit .notifyBadge {
  right: -5px;
}

.notificationEdit h2 {
  font-size: 30px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  text-align: center;
  line-height: 42px;
  letter-spacing: 0.25px;
  color: #000;
}
.notificationContainer {
  /* overflow-y: auto; */
  /* height: calc(100vh - 150px); */
}

.nfItem {
  display: flex;
  align-items: center;
}
.nfUL {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.notificationDate {
  /* font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 1.71px;
  line-height: 19px; */
  color: #959595;
  text-transform: uppercase;
  margin-bottom: 0;
}
.nfToday li.active {
  background: #fff;
}
.nfTopIcon {
  border: 1px solid #e7e7e7;
  width: 65px;
  height: 65px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #fff;
}

.nfBottomIcon {
  border: 1px solid #e7e7e7;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: #000;
  font-size: 12px;
  background: #fff;
  font-family: 'Montserrat-Regular', sans-serif;
}

.nfIcon {
  width: 70px;
  height: 70px;
  margin-right: 25px;
}
.nfContent {
  width: calc(100% - 120px);
}
.nfTime {
  color: #959595;
  margin-top: 10px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.flagIcon {
  margin-right: 2px;
  height: 14px;
}

.mb32 {
  margin-bottom: 32px !important;
}

.notificationEdit .infinite-scroll-component {
  height: calc(100vh - 135px) !important;
  max-height: calc(100vh - 135px) !important;
}

.edit-loader {
  bottom: 0px !important;
}

/* .notificationWidget,
.ant-modal-wrap,
.ant-notification {
  z-index: 9999 !important;
} */

img.ack {
  position: absolute;
  top: 35%;
  right: -30px;
}

.ackByTxt {
  color: #000 !important;
}

.nfBottomIcon .ant-avatar-circle {
  width: 100% !important;
  height: 100% !important;
}

.notification-filter {
  position: absolute;
  right: 12vw;
  z-index: 1;
}
.modal-theme-1 .ant-modal-header {
  background-color: #67afb7;
}
.ant-modal.modal-theme-1
  > .ant-modal-content
  > .ant-modal-header
  .ant-modal-title {
  color: #ffffff;
}

.progressText {
  /* text-align: center; */
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 16px;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-right-label {
  position: absolute;
  right: 6vw;
  z-index: 1;
}

.notification-header-right {
  display: flex;
  position: absolute;
  top: 0;
  right: 3vw;
}
