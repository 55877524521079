.orgDetailsStyles {
  /* width: 100%;
    height: 250px;
    margin: 5px;
    border: 0.01px solid #cccddc;
    border-radius: 5px 5px 5px 5px; */
  font-family: 'Montserrat-Medium', sans-serif;
}

.jumpblk .formRow:last-child {
  margin-bottom: 15px !important;
}
.tm-modal {
  width: 880px !important;
}
.astm-modal {
  width: 600px !important;
}
.dpickerWidth {
  width: 205px;
}
.fleetIndicatorHistoryBlk .dbWidget .ant-space {
  gap: 5px !important;
}

.col {
  width: 656px;
}
.detailTxt {
  font-family: 'Montserrat-Medium', sans-serif;
  word-break: break-all;
}

.detailsTab .ant-card-body {
  padding-bottom: 0;
}
.taLeft {
  text-align: left !important;
}

.datepick {
  margin-right: 20px;
}

.datepick .react-datepicker-popper {
  width: unset !important;
  z-index: 999 !important;
}

.datepick .react-datepicker__month-container {
  width: unset !important;
}

.datepick input {
  background: #fff;
  border: none;
  height: 35px;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.29);
  font-size: 16px;
  font-family: 'Montserrat-Medium', sans-serif;
}
.datepick input:focus-visible {
  border: none !important;
  outline: none;
}

/* .datepick .react-datepicker-popper {
  transform: translate(218px, 35px) !important;
} */

.mainContainer {
  display: inline-block !important;
  margin-bottom: 30px !important;
  border-radius: 6px !important;
  padding: 0 0 0px 0px !important;
  background: #fff !important;
  width: 100% !important;
  border: 1px solid #cccddc !important;
}

.mainContainer .ant-card {
  border-radius: 6px !important;
}

.mainContainer .ant-card-body {
  display: inline-block;
  width: 100%;
  padding: 20px 20px 20px 20px !important;
}

.taskTitle {
  color: #000;
  font-size: 15px;
  font-family: 'Montserrat-Medium', sans-serif;
  font-weight: 700;
}

.addbtn,
.addBtn:hover,
.addBtn:focus {
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #1e3a46 !important;
  color: #fff !important;
  font-size: 14px;
  padding: 4px 15px;
}

.curSor {
  cursor: pointer !important;
}

.slkttractor .ant-select-selector .ant-select-selection-item {
  justify-content: space-between;
}

.slkttractor .ant-select-selector {
  min-height: 35px !important;
}
