.inviteBtn {
  display: flex;
  width: 70px;
  height: 23.94px;
  align-items: center;
  padding: 6px 8px 6px 8px;
  gap: 6px;
  border-radius: 6px;
  background: #366376;
  color: #ffffff;
  font-size: 8px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.inviteBtn span {
  color: #ffffff;
  font-size: 8px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.inviteBtn:hover,
.inviteBtn:focus,
.inviteBtn:active,
.inviteCancelBtn:hover,
.inviteCancelBtn:focus,
.inviteCancelBtn:active {
  background: #1e3a46 !important;
  color: #ffffff !important;
  font-size: 8px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.inviteCancelBtn {
  justify-content: center;
}

.inviteBtn.ant-btn-click-animating::after {
  animation: none !important;
  display: none !important;
}

.inviteBtn[ant-click-animating-without-extra-node='true']::after {
  text-decoration: none;
  background-color: transparent !important; /* Remove the white overlay */
  animation: none !important;
}

.inviteCancelBtn[ant-click-animating-without-extra-node='true']::after {
  text-decoration: none;
  background-color: transparent !important; /* Remove the white overlay */
  animation: none !important;
}

.createBtn {
  position: absolute;
  right: 0;
}
.createBtn .ant-btn:disabled {
  background: none;
}

.customerEmail {
  width: 300px !important;
}

.customer_wrapper .filterContainer {
  margin-left: 10px !important;
}
