.dealer_filters
  .ant-select-selection-overflow-item:nth-child(1)
  .ant-select-selection-item,
.dealer_filters
  .ant-select-selection-overflow-item:nth-child(2)
  .ant-select-selection-item {
  background-color: transparent !important;
  border: none;
  font-size: 10px !important;
  color: #99999c !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  line-height: 20px !important;
}
.dealer_filters
  .ant-select-selection-overflow-item:nth-child(2)
  .ant-select-selection-item {
  background-color: #f4f4f4 !important;
  border-radius: 10px;
  height: 18px;
  position: relative;
  bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
