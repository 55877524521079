.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}

.commonPopup .ant-modal-mask {
  z-index: 800 !important;
}

.map-maker-popup {
  z-index: 95000 !important;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #00aaff;
}

.avatarMT {
  background-color: black;
}

.mapItems {
  list-style-type: none;
  background: #2f333b !important;
  margin-top: 14px;
}
.mapItems li {
  border-left: 3px solid #2f333b;
  margin-bottom: 2px !important;
  margin-top: 0px !important;
  line-height: 48px !important;
  color: #8a8d97;
}
.mapItems li:hover,
.mapItems .ant-menu-item-selected {
  background: #2f333b !important;
  border-left: 3px solid #f17e03;
}
.mapItems li a {
  font-size: 16px;
  font-family: 'Montserrat-Medium', sans-serif;
}
.mapItems li img {
  margin-right: 15px !important;
}
.mapItems li img {
  position: relative;
  top: -2px;
}

.mapItems .ant-tooltip-open a,
.ant-tooltip a {
  color: #1c1c1c !important;
}
.map-side-bar {
  background: rgb(47, 51, 59);
}
.ant-list-item-meta-avatar {
  margin-left: 15px;
}
.ant-list-item {
  padding: 3px 0;
}
.ant-avatar > img {
  /* width: 20px; */
  /* height: 20px; */
  /* max-width: 100%;
  width: auto;
  height: auto; */
}
.ant-card-body {
  padding: 5px;
}
.ant-divider-horizontal {
  margin: 15px;
  border: 1px solid #ffffff;
}
.ant-list-split .ant-list-item {
  border: 1px solid #1c1c1c;
}

.zoomIn {
  background: transparent;
  width: 46px;
  height: 44px;
  position: relative;
  left: 4px;
  border: none;
  top: 4px;
  cursor: pointer;
  padding: 0;
}
.zoomOut {
  background: transparent;
  width: 46px;
  height: 38px;
  position: relative;
  left: 4px;
  border: none;
  top: 0px;
  cursor: pointer;
  padding: 0;
}
/* tractor details popup ui */
.betaBox {
  min-width: 450px;
  width: 100%;
  max-width: 100%;
  margin: auto;
  /* background: #2a2a2a; */
  border-radius: 10px;
}
.betaBox .card {
  margin: 0 !important;
}
.betaBox .card-header {
  background: #ffffff;
  color: #1c1c1c;
  padding-top: 15px;
  padding-bottom: 15px;
}
.betaBox .card-header img {
  width: 16px;
  height: 16px;
}
.betaBox .card-header a {
  margin-left: 12px;
}
.betaBox .card-body {
  /* background: #2a2a2a; */
}
.betaBox h3 {
  font-size: 20px;
  margin: 0;
  letter-spacing: 1px;
}
.betaBox h3 span {
  float: right;
}
.betaBox ul {
  background: #ffffff;
  padding: 12px;
}
.betaBox ul li {
  padding: 0 25px;
}
.betaBox ul li img {
  width: 32px;
  display: block;
  margin: auto;
}
.betaBox .boxContent {
  background: #ffffff;
  padding: 15px 20px;
  border-radius: 0.25rem;
  /* margin-top: 15px; */
  margin: 0px 15px;
  /* margin-top: 15px; */
}
.boxRow {
  display: -webkit-flex;
  display: flex;
}
.boxContent .boxRow:last-child p {
  margin: 0;
}
.boxCol {
  width: 50%;
  display: inline-block;
  margin: auto;
  color: #212121;
  font-size: 14px;
  text-transform: capitalize;
  font-family: 'Montserrat-Medium', sans-serif;
}
.boxContent .boxCol:first-child {
  color: #999;
}
.betaBox .weatherSec {
  background: #ffffff;
  color: #212121;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 15px 20px;
  border-radius: 0.25rem;
  margin: 0px 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: 'Montserrat-Medium', sans-serif;
}
.weatherRow {
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  font-size: 12px;
  font-weight: 500;
  color: #8e8e8e;
  line-height: 30px;
}
.weatherRow:last-child {
  border: 0;
}
.weatherRow .weatherCol {
  width: 60%;
  display: inline-block;
}
.weatherRow .weatherCol2 {
  width: 40%;
  text-align: right;
}
.weatherSec .weatherIcons {
  display: flex;
  color: #8e8e8e;
}
.weatherSec .weatherIcons figure {
  padding-right: 10px;
  margin: 0;
}
.weatherSec .weatherIcons h1 sup {
  font-size: 14px;
  top: -20px;
  left: -5px;
}
.weatherSec .weatherIcons h1 sub {
  font-size: 14px;
  left: -18px;
  top: 0px;
}
.weatherSec .weatherIcons span,
.weatherSec h6 {
  font-size: 12px;
}
.popUpIndicatorImg {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0px 15px;
  /* margin-top: 15px; */
  margin-top: 15px;
  border-radius: 0.25rem;
}
.popClose {
  float: right;
}
.indIcons {
  display: flex;
}
/* end here */

.openWindowCont .ant-tabs-nav-wrap {
  justify-content: flex-start !important;
  padding: 0 15px;
}

.openWindowCont .ant-tabs-nav {
  background: #fff !important;
  border-bottom: 1px solid rgb(208, 208, 208);
  height: 50px !important;
  margin: 0 15px;
  border-radius: 3px;
}
.openWindowCont .ant-tabs-content {
  margin-top: 15px !important;
}
.openWindowCont .card-header {
  /* border-radius: 0.25rem; */
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 16px;
  padding: 0.75rem 1.25rem;
  background: #2f333b;
  border-radius: 0;
  margin-bottom: 10px;
}
.openWindowCont .card-header h3 {
  color: #fff !important;
  margin: 0px;
}
.openWindowCont .ant-tabs-tab-btn {
  color: #f17e03 !important;
}

.ant-tabs-ink-bar {
  background: #f17e03 !important;
}
.openWindowCont .ant-tabs-tab {
  font-size: 15px;
  font-family: 'Montserrat-Medium', sans-serif;
}

.popClose img {
  width: 16px;
  height: 16px;
}
.mb0 {
  margin-bottom: 0 !important;
}
.indIcons img {
  /* width: 18px; */
  height: 16px;
  margin-right: 5px;
}

.w30 {
  width: 30px;
}
.lmSide .ant-card-head-wrapper {
  position: relative;
}
.lmSide .ant-card-head-title {
  margin-left: 35px;
}
.lmapBack {
  position: absolute;
  top: 12px;
  left: 15px;
  z-index: 9;
}
.lmapBack .anticon svg {
  font-size: 24px !important;
  color: #333;
  cursor: pointer;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0px 3px 6px #00000016;
  padding: 9px;
  border-radius: 6px;
  border: 1px solid #cccccc;
  bottom: 9px;
  left: -50px;
  min-width: 180px;
  /* white-space: nowrap; */
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: #2f333b;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #2f333b;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: '✖';
}
.jumpAndGo {
  width: 100%;
  margin: 15px;
  border-radius: 0.25rem;
}

.mapMaker .mapView {
  width: 42px;
  height: 42px;
  right: 7px;
  position: absolute;
  top: 120px;
  z-index: 99;
  cursor: pointer;
}
.jumpAndGoOperator {
  float: right;
}
.formCol-30 {
  width: 30%;
}

.formCol-70 {
  width: 60%;
}

.commandBox {
  border: 1px solid #dadce0;
  margin: 20px 10px 10px 10px;
  position: relative;
  padding: 10px;
}

.commandBox .ant-card-bordered {
  border: none !important;
  border-radius: 0px !important;
}
.commandBox .ant-card-body {
  border-radius: 0 !important;
  padding: 20px 10px !important;
}

.commandPrint {
  margin: 0px 10px 10px 10px;
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px 8px;
}
.tractorName {
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px 10px;
}

.commandList > ul {
  position: relative;
  /* width: 4px; */
  border-left: 2px dotted #d8d8d8;
  /* border-right: 1px solid white; */
  /* margin: 0 auto; */
  padding: 0;
  list-style: none;
  margin: 0px 20px;
  top: 25px;
  width: 96%;
}

.commandList > ul > li {
  position: relative;
  padding: 0.3em 0;
  min-height: 45px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.commandList:last-child > ul {
  border: none !important;
}
.commandList > ul li.life_event:hover div.event_icn,
.commandList > ul li.life_event.active div.event_icn {
  background: #0ed1e7;
}
.commandList > ul li > div.event_icn {
  padding: 5px;
  font-size: 10px;
  border-radius: 50px;
  background: #f7f7f7;
  border: 2px solid #dadce0;
  margin-left: -16px;
  color: #444444;
  box-shadow: 1px 1px 1px white;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  top: -10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.commandList > ul > li div {
  position: absolute;
}
.commandList > ul > li:nth-child(odd) div.event_content {
  left: 25px;
}
.commandList > ul > li:hover div.event_content,
.commandList > ul li.active div.event_content {
  opacity: 1;
}
.commandList > ul > li div.event_content {
  width: 93%;
  padding: 5px 10px;
  border: 1px solid #dddbda;
  border-radius: 4px;
  top: -12px;
  background: white;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ravCross {
  right: -5px;
  top: -7px;
}

.commandBox .ant-form-item-control-input input {
  border: 1px solid #dfe1e6 !important;
  background-color: #f4f5f7 !important;
  border-radius: 3.5px !important;
  height: 32px !important;
  font-family: 'Montserrat-Medium', sans-serif !important;
  font-size: 14px !important;
  color: #172b4d !important;
  padding-left: 10px;
  padding-right: 10px;
}

.commandBtn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.cSaveBtn.ant-btn,
.cSaveBtn.ant-btn:hover,
.cSaveBtn.ant-btn:focus {
  height: 32px !important;
  border: none !important;
  border-radius: 3px !important;
  background-color: #48a361 !important;
  border-color: #56b870 !important;
  font-size: 14px !important;
  padding: 4px 15px !important;
  font-family: 'Montserrat-Bold', sans-serif;
  color: #fff;
  min-width: 100px;
}
.cCancelBtn.ant-btn,
.cCancelBtn.ant-btn:hover,
.cCancelBtn.ant-btn:focus {
  margin-right: 8px !important;
  height: 32px !important;
  /* border: none !important; */
  border-radius: 3px !important;
  background-color: #fff !important;
  border: 1px solid #d6d6d6 !important;
  font-size: 14px !important;
  padding: 4px 15px !important;
  font-family: 'Montserrat-Bold', sans-serif;
  min-width: 100px;
}

.addCBtn {
  display: inline-block;
  /* margin-left: 8px; */
  /* margin-top: 10px; */
  cursor: pointer;
}
.addCBtn span {
  display: inline-block;
  margin-left: 10px;
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 14px;
}
.selPMapTxt {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  color: #212121;
}

.startBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 25px 10px 0px;
}
.startBtn .cStartBtn {
  height: 32px !important;
  border: none !important;
  border-radius: 3px !important;
  background-color: #e16d01 !important;
  font-size: 14px !important;
  padding: 4px 15px !important;
  font-family: 'Montserrat-Bold', sans-serif;
  color: #fff;
  min-width: 100px;
}
.cwCross {
  position: absolute;
  right: 0px;
  z-index: 99;
  top: -15px;
  cursor: pointer;
}

.topSwitchSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f4;
  border-bottom: 1px solid #d9d9d9;
  min-height: 36px;
  padding: 0px 15px;
  margin-bottom: 10px;
}

.rAVSec {
  padding: 0px !important;
  margin: 0px !important;
}

.livemapWidget .ant-tabs-content,
.livePopup .livemapWidget .ant-tabs-tabpane {
  padding: 0px !important;
  margin: 0px !important;
  min-height: 545px;
}

.topSwitchSec .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 21px - -1px);
}

.dblDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  /* min-height: calc(100vh - 115px); */
  min-height: calc(100vh - 240px);
}

.cmdBtn {
  background: #fff;
  border: 1px solid #d9d9d9;
  min-height: 30px;
  border-radius: 50px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 16%);
  padding: 0px 20px;
  font-size: 13px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin-right: 8px;
}

.cmdBtnSel {
  border: 1px solid #f17e03;
  color: #f17e03;
}

.topSwitchSec .ant-switch-checked {
  background-color: #f17e03;
}

.startBtn .cStartBtn {
  height: 32px !important;
  border: none !important;
  border-radius: 3px !important;
  background-color: #e16d01 !important;
  font-size: 14px !important;
  padding: 4px 15px !important;
  font-family: 'Montserrat-Bold', sans-serif;
  color: #fff;
  min-width: 100px;
}

.cPriviewBtn.ant-btn,
.cPriviewBtn.ant-btn:hover,
.cPriviewBtn.ant-btn:focus {
  margin-right: 8px !important;
  height: 32px !important;
  border-radius: 3px !important;
  background-color: #fff !important;
  border: 1px solid #d6d6d6 !important;
  font-size: 14px !important;
  padding: 4px 15px !important;
  font-family: 'Montserrat-Bold', sans-serif;
}
.cPriviewBtn.ant-btn:hover,
.cPriviewBtn.ant-btn:hover,
.cPriviewBtn.ant-btn:focus {
  color: #f17e03 !important;
}
.mapMaker .positionMap {
  position: absolute !important;
  left: 5px !important;
  bottom: 5px !important;
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
  width: 132px;
  height: 17px;
}

.mapMaker .positionMap-inner {
  position: absolute;
  bottom: 0px !important;
  right: 25px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 5px;
  color: #000;
}
.mapMaker .positionMap-inner::before {
  display: none;
}

.headingAngleLabel {
  position: absolute;
  background-color: white;
  display: inline-block;
  border-radius: 50px;
  border: 1px solid #fb8c24;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  padding: 3px 8px;
  font-size: 10px;
}

.mapMaker .remoteav {
  line-height: 30px !important;
  border-bottom: 1px solid #d2cad2 !important;
  margin: 0px 15px !important;
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  font-size: 14px !important;
  cursor: default;
  color: #f17e03;
}
.mapMaker .ol-ctx-menu-container {
  padding: 0px !important;
  width: 200px !important;
  border-radius: 10px !important;
  background-color: #fff;
}
.mapMaker .ol-ctx-menu-container ul {
  list-style: none;
  padding-left: 0 !important;
}
.mapMaker .ol-ctx-menu-container ul li {
  position: relative;
  padding: 2px 5px;
}
.mapMaker .remoteav:hover {
  cursor: default !important;
  background: transparent !important;
  color: #f17e03 !important;
}

.mapMaker .rvactions {
  line-height: 20px !important;
  margin: 5px 15px !important;
  font-family: 'Montserrat-Medium', sans-serif !important;
  font-size: 13px !important;
  color: #b1a9b1 !important;
  cursor: default !important;
}
.mapMaker .rvactions:hover {
  cursor: default !important;
  background: transparent !important;
}

.mapMaker .rvactionItem {
  line-height: 20px !important;
  margin: 3px 15px !important;
  font-family: 'Montserrat-Medium', sans-serif !important;
  font-size: 12px !important;
  color: #232223 !important;
}
.mapMaker .rvactionItemLast {
  line-height: 20px !important;
  margin: 3px 15px !important;
  font-family: 'Montserrat-Medium', sans-serif !important;
  font-size: 12px !important;
  color: #232223 !important;
  border-bottom: 1px solid #d2cad2 !important;
}

.mapMaker .ol-ctx-menu-submenu {
  width: 195px;
  padding-left: 19px !important;
  font-family: 'Montserrat-Medium', sans-serif !important;
  font-size: 12px !important;
  color: #232223 !important;
}

.mapMaker .ol-ctx-menu-container {
  z-index: 10000;
}

.mapMaker .ol-ctx-menu-container li:not(.ol-ctx-menu-separator):hover {
  background: #eeeeee;
  color: #232223;
}

.some-style-class,
.ol-ctx-menu-zoom-in,
.ol-ctx-menu-zoom-out {
  margin: 3px 15px !important;
  line-height: 30px !important;
}
.ol-ctx-menu-zoom-in,
.ol-ctx-menu-zoom-in:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABaUlEQVQ4T72U7VHCQBCGn90GtAMuNGCswFiBWIFQgWMFxg6wArECsQKhArEBiB1Qwa1zgQn5IAYcxv13k71n3919L8KJQ07M47+BzgG9TRfZ/JBuWhS6BJFHRJICYrZGZIz3z5Ct2+B7gG6I6kt+wewdkQVwjtkAkR5mC8yu26A1oItR/cTsOweQBdgutD8G7jGm2PJ2n8oqUKIpIjd4HxTM8gvaT/F+AlmWnyWaIXKF95eNguFzTYFhNsdWu9kFgFlaFMANUH3D8wDLoLgSTSD2il8NCe2ZXQBxWDGwxmyUzzOMBZ7wy7Qb2K0wQfXjMOBuhlFpZtNty5sFaTQBuTusZdymeqs1SpYKcO9HkE3KbTd9WFijMHJQ5hBNEAYNq5Qd0dhyke0GiE4QzjqfW23mHT8Hl4DG4Lce3FPE7AtbBSdsbNqpoJLgYkRnNeUV+xwJDHTnUEkxHGbhBXUs5TjJjew/KPy94g+NRaIVRYmMXwAAAABJRU5ErkJggg==) !important;
  background-repeat: no-repeat !important;
  background-position: center left !important;
}

.ol-ctx-menu-zoom-out,
.ol-ctx-menu-zoom-out:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABU0lEQVQ4T72U7VECMRRFz3sNaAdkacC1AtcKxApcKnCsQOwAK3CtQKxAqEBsANYOqCDPyTIC+8WCw5jfybn33dxEOPGSE/P4b6BzQG89RT47ZJoWhy5B5BGRZAMxWyEyxvtnyFdt8AagS1F9KQ6YvSMyB84xGyDSw2yO2XUbtAJ0MaqfmH0XAPIA2y7tj4F7jAm2uG1yWQZKNEHkBu+Dg2njWBJNEbnC+8uaIFRuWfuG2QxbbrOrUd0A1Tc8D7AIjkur7DAAsVf8MiWMZ3ZR2m02LPIMscATfjHqBnY7TFD9OAy4zTCCPG/MUKMM5O6wkXFr9dZq7FQqqHk/hDzbFa73cFONTZFDdRyiCcKg5rrSiLaXkiI6RjjrfG6VzDs+B5eAxuDXeYpmNRGzL2wZ/wof+du4GNFpBVqqz5HA4MM5VEYYDrOs+1I6Q9u/4Q8O9wN/AGgWjBVqQjjgAAAAAElFTkSuQmCC) !important;
  background-repeat: no-repeat !important;
  background-position: center left !important;
}
.some-style-class,
.ol-ctx-menu-zoom-in,
.ol-ctx-menu-zoom-out {
  font-family: 'Montserrat-Medium', sans-serif !important;
  font-size: 12px !important;
  color: #232223 !important;
}

.some-style-class {
  border-bottom: 1px solid #ccc !important;
}

.red-box .ant-checkbox .ant-checkbox-inner {
  background-color: #f86161 !important;
  border-color: grey !important;
}

.red-box .ant-checkbox-checked .ant-checkbox-inner::after,
.green-box .ant-checkbox-checked .ant-checkbox-inner::after,
.blue-box .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #fff !important;
  border-top: 0 !important;
  border-left: 0 !important;
}

.white-box .ant-checkbox .ant-checkbox-inner {
  background-color: white !important;
  border-color: grey !important;
}

.green-box .ant-checkbox .ant-checkbox-inner {
  background-color: #4cb859 !important;
  border-color: grey !important;
}

.yellow-box .ant-checkbox .ant-checkbox-inner {
  background-color: rgb(255, 255, 159) !important;
  border-color: grey !important;
}

.blue-box .ant-checkbox .ant-checkbox-inner {
  background-color: #5656f7 !important;
  border-color: grey !important;
}
.poly-header {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: bold;
}

#rc-menu-uuid-18060-2-Blocked-popup .ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border: 0 !important;
}

.poly-class li {
  height: 30px !important;
  background: none !important;
}

.poly-class .mapNames {
  word-break: unset;
  white-space: unset;
}

.poly-menu-class {
  color: black !important;
}

.width-class {
  width: '100%';
}
.nameOverlay {
  display: inline-block;
  min-width: 220px;
  max-width: 420px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.markImage img {
  width: 16px !important;
  margin-left: 6px !important;
}
.vinBlockContainer.ant-collapse {
  margin: 0 !important;
  border-radius: 6px;
  background: #fff !important;
}
.vinBlockContainer {
  width: 270px !important;
  margin-left: -15px !important;
}

/* .vinBlockContainer .accordionContainer-margin .anticon{
  left: 0px !important;
} */

.mmVineBlock .ant-collapse {
  min-width: inherit !important;
}

.mmVineBlock {
  width: inherit !important;
  border: none !important;
  padding: 0px !important;
  overflow: inherit !important;
}

.mmVineBlock table.base {
  left: inherit !important;
  width: 100% !important;
}

.mmVineBlock {
  margin-left: inherit !important;
}

.mmVineBlock .accordionContainer.accordionContainer-margin {
  padding-left: inherit !important;
}

.mmVineBlock .base td span {
  font-size: 14px;
  font-family: 'Montserrat-Regular', sans-serif;
  font-weight: normal;
}
.mmVineBlock td img {
  width: 28px;
  height: 28px;
}
.mmVineBlock .ant-checkbox + span {
  padding-right: 0px !important;
}
.mmVineBlock .mapNames {
  width: 145px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.drawItemsPop .ant-input-number {
  width: 100% !important;
}

.modalCol {
  width: 100%;
  position: relative;
}

.modalCol .btnRemove {
  bottom: 46px;
  position: absolute;
  left: 25px;
}

.mapMaker {
  position: relative;
}

.mapMaker .mapIcons {
  bottom: 15px;
  right: 7px;
}

.mapMaker .image-upload {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 260px;
}

.mapMaker .file-upload {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 220px;
}

.mapMaker .graticule-holder {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 142px;
  right: 0px;
}

.mapMaker .layer-holder {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 180px;
}

.mapMaker .f_screen {
  background-image: url(../../assets/images/f_screen_mapmaker.svg);
  width: 42px;
  height: 42px;
  position: relative;
  bottom: -10px;
  cursor: pointer;
}

.mapMaker .zoom_in_out {
  background-image: url(../../assets/images/zoom_in_out.svg);
  width: 42px;
  height: 72px;
}

.mapmakerSidebar {
  height: 100vh;
  z-index: 999;
}

.mapmakerSidebarExpand {
  right: 300px !important;
}

.btnSavefile {
  position: absolute;
  top: 12px;
  left: 187px;
  cursor: pointer;
}

/* mapmaker new styles goes here */
.livemapTopBar {
  position: absolute;
  background: #00000080;
  z-index: 9;
  /* width: calc(100% - 75px); */
  width: calc(100% - 0px);
  height: 85px;
}

.livemapTopBar ul {
  list-style: none;
  background: #ffffff;
  display: flex;
  position: absolute;
  left: 49%;
  top: 8px;
  transform: translateX(-50%);
  border-radius: 5px;
  padding-left: 0;
  margin-bottom: 0;
}

.livemapTopBar ul li {
  cursor: pointer;
  display: inline-flex;
  padding: 12px 20px;
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.28px;
  line-height: 18px;
  color: #333333;
  border-right: 1px solid #cececf;
  white-space: nowrap;
  align-items: center;
}

.livemapTopBar ul li a {
  color: #333333;
}

.livemapTopBar ul li:last-child {
  border-right: 0;
}

.livemapTopBar ul li img {
  margin-right: 10px;
}

.livemapTopBar h5 {
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 60px;
  color: #ffffff;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.7px;
  line-height: 18px;
  font-size: 13px;
}

.menuBtn {
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 46px;
}

.menuBtn img {
  min-width: 39px;
  width: 3.07vw;
}

.menuBtn p {
  color: #ffffff;
  font-size: 13px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.7px;
  line-height: 18px;
  margin-bottom: 0;
  padding-top: 6px;
}

.livemapSideMenu {
  position: absolute;
  right: 0;
  top: 150px;
  z-index: 999;
}

.livemapSideMenu ul {
  background: #00000080;
  border-radius: 5px 0 0 5px;
  padding-left: 0;
  list-style: none;
}
.livemapSideMenu ul li {
  background: transparent;
  width: 3.5vw;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.livemapSideMenu ul li:hover {
  background: #00000080;
  border-radius: 0;
}

.livemapSideMenu ul li:first-child:hover {
  background: #00000080;
  border-radius: 5px 0 0 0px;
}
.livemapSideMenu ul li:last-child:hover {
  background: #00000080;
  border-radius: 0 0 0 5px;
}

.livemapSideMenu ul li img {
  min-width: 18px;
  width: 1.1vw;
}

.ant-dropdown .ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
}

.ant-dropdown .ant-dropdown-menu-title-content img {
  margin-right: 18px;
}

.topMenuTypeTxt {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.pMenu {
  min-width: 150px;
}
.suggestedPoly {
  position: absolute;
  right: 50px;
  top: 12px;
}

/* Mapmaker Discover popup styles goes here */

.mapmakerDiscover {
  padding-bottom: 0;
  top: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.mapmakerDiscover .ant-modal-content {
  width: 83.3vw !important;
  height: 83vh !important;
}
.mapmakerDiscover .ant-modal-content,
.mapmakerDiscover .ant-modal-header {
  border-radius: 10px;
}

.discoverUL {
  list-style-type: none;
  padding-left: 30px;
  margin: 0;
  height: calc(83vh - 103px);
  overflow-y: auto;
}

ul.discoverUL li.discoverfield {
  padding: 8px 0px;
}

ul.discoverUL li.discoverfield {
  padding: 8px 0px;
  display: flex;
  align-items: center;
}
.dFieldName {
  margin-right: 10px;
  font-size: 16px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #030303;
  white-space: nowrap;
  max-width: 22vw;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 22vw;
  display: inline-block;
  position: relative;
}
.dFieldName img {
  margin-right: 8px;
  cursor: pointer;
}

.btnDisc,
.btnDisc:hover {
  border-radius: 8px;
  min-height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  margin-right: 8px;
  border: none;
  outline: none;
  min-width: 75px;
  cursor: pointer;
  color: #fff;
}
.btnDisc:last-child {
  margin-right: 0px;
}

.dContainer {
  display: flex;
  height: 100%;
  overflow-y: auto;
}

.fileDownLoad .ant-modal-body {
  text-align: left;
  margin-left: 14px;
}

.findBtn,
.findBtn:hover,
.findBtn:focus {
  background-color: #366376;
  border-radius: 10px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  color: #fff;
  position: absolute;
  right: 50px;
  top: 10px;
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
}
.findBtn1 {
  right: 385px !important;
}

.findBtn2 {
  right: 240px !important;
}

.findBtn3 {
  right: 60px !important;
}

.btnApprove,
.btnApprove:hover,
.btnApprove:focus {
  background-image: url(../../assets/images/approveBtn.svg);
  width: 25px !important;
  height: 25px;
  background-size: 25px;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  visibility: visible !important;
  margin-right: 3px;
  cursor: pointer;
}
.btnReject,
.btnReject:hover,
.btnReject:focus {
  background-image: url(../../assets/images/rejectBtn.svg);
  width: 25px !important;
  height: 25px;
  background-size: 25px;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  visibility: visible !important;
  cursor: pointer;
}
.dMapSec {
  width: 60vw;
  height: calc(83vh - 103px);
}
.dFieldsSec {
  width: 40vw;
  height: 100%;
}

.dMapSec .ol-viewport {
  border-radius: 10px;
}

li.discoverfield:first-child {
  padding-top: 0 !important;
}

.drawBtnsSec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85px;
  flex-direction: column;
}

.psaveBtn,
.psaveBtn:hover,
.psaveBtn:focus {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  align-items: center;
  justify-self: center;
  /* background-color: #366376; */
  background-color: #8a9a48;
  outline: none;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
  text-transform: uppercase !important;
}
.pcancelBtn,
.pcancelBtn:hover,
.pcancelBtn:focus {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  align-items: center;
  justify-self: center;
  /* background-color: #b6b6b7; */
  background-color: white;
  margin-right: 5px;
  outline: none;
  /* border: none; */
  border: 1px solid #366376;
  /* color: #000; */
  color: #366376;
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
  text-transform: uppercase !important;
}
.pBtnsRow {
  display: flex;
  margin-bottom: 5px;
}
.pHelpTxt {
  color: #ffffff;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.7px;
  line-height: 18px;
  font-size: 13px;
}

.commonPopup {
  padding-bottom: 0;
  top: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.commonPopup .ant-modal-content,
.commonPopup .ant-modal-header {
  border-radius: 10px;
}
.addPolygon .ant-modal-content {
  width: 60vw;
}

.commonPopup .formRow .formLabel {
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #000;
  line-height: 24px;
}

.commonPopup .formRow .commInput,
.commonPopup .formRow .ant-select {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #5c5c5c;
  line-height: 19px;
  border: 1px solid #366376 !important;
  border-radius: 10px;
  height: 44px;
  display: block;
  align-items: center;
}
.commonPopup .formRow .commInput .ant-input-number-input {
  min-height: 44px !important;
}
.commonPopup .formRow .ant-select-selector {
  border: none;
  outline: none;
}

.commonPopup .ant-modal-title {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 20px;
  letter-spacing: 0.15px;
  color: #000;
  line-height: 24px;
}
.formColFull {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.verticsSec {
  padding: 20px;
  border: 1px solid #366376 !important;
  border-radius: 10px;
  min-height: 150px;
  height: 250px;
  overflow-y: auto;
}
.checkSec {
  position: absolute;
  margin-left: 5px;
}
.commonPopup .btnCancel {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  align-items: center;
  justify-self: center;
  background-color: #b6b6b7;
  margin-right: 5px;
  outline: none;
  border: none;
  color: #000;
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
}
.commonPopup .btnSave {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  align-items: center;
  justify-self: center;
  background-color: #8a9a48;
  margin-left: 0 !important;
  outline: none;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
  text-transform: uppercase;
}

.commonPopup button[disabled].btnSave {
  background-color: #8f9479 !important;
}

.swapBtn {
  width: 160px !important;
  background: #366376 !important;
  height: 44px !important;
}
.commonPopup .swapBtnAddOn {
  float: 'right' !important;
  margin-left: 40px !important;
  position: relative;
  top: 24px;
  height: 44px;
  cursor: pointer;
}
.w100 {
  width: 100%;
}
.dfright {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.lblHeads {
  font-size: 16px !important;
}
.vineRowListItem {
  border: 1px solid #366376;
  border-radius: 10px;
  padding: 5px 8px;
  margin-bottom: 5px;
}

.fileUploadInput {
  display: inline-block;
  text-align: left;
  background: #fff;
  padding: 0px 16px 0px 0px;
  width: 100%;
  position: relative;
  border-radius: 3px;
}

.fileUploadInput > [type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.fileUploadInput > .button {
  /* display: inline-block; */
  cursor: pointer;
  background: #eee;
  padding: 8px 16px;
  border-radius: 8px 0 0 8px;
  margin-right: 8px;
  height: 42px;
  display: flex;
  align-items: center;
}

.fileUploadInput:hover > .button,
.fileUploadInput:focus > .button {
  background: #366376;
  color: white;
}

.fileUploadInput > .label {
  color: #333;
  white-space: nowrap;
  opacity: 0.3;
}

.fileUploadInput.-chosen > .label {
  opacity: 1;
}
.pl10 {
  padding-left: 10px;
}
.commonPopup .ant-upload {
  margin-left: 0px !important;
}

.vineBlocksNewStyle .ant-collapse {
  background-color: #fff !important;
  border: none;
}

.vineBlocksNewStyle .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

.vineBlocksNewStyle .ant-collapse-content {
  border-top: none;
}

.mmBeta {
  height: calc(100vh - 85px);
  margin-top: 85px;
  padding-right: 0px;
  /* width: 340px !important;
  max-width: 340px !important; */
}
.mmBeta .ant-list-items {
  padding-right: 50px;
}

.mmBeta .ant-list-header {
  font-size: 22px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.5px;
  padding-left: 20px;
}
.mmBeta .ant-card-body {
  margin-right: 55px !important;
  height: calc(100vh - 155px);
}

.mmBeta .ant-col-8 {
  width: 100%;
}
.mmBeta .mapCheckbox {
  display: flex;
  align-items: center;
}
.mmBeta .ant-checkbox {
  top: 0 !important;
}

.mmBeta .ant-checkbox + span {
  top: 0 !important;
  display: flex;
  align-items: center;
}
.mapNames {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mmBeta .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e8e8e8;
  border-color: #e8e8e8 !important;
}

.mmBeta .ant-checkbox-checked .ant-checkbox-inner:after {
  border: 2px solid #eb921f;
  border-top: 0;
  border-left: 0;
  content: ' ';
  left: 4px;
  top: 8px;
  width: 6px;
  height: 11px;
}
.mmBeta .ant-checkbox-inner,
.mmBeta .ant-checkbox-input:focus + .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background: #e8e8e8;
  margin-left: 10px;
  border-color: #e8e8e8 !important;
}

.mmBeta .ant-card-head {
  min-height: 60px !important;
  display: flex;
  align-items: center;
}
.mmBeta .lmapBack,
.mmBeta .btnSavefile {
  top: 18px;
}

.mmBeta .ant-menu-title-content {
  font-size: 14px;
  color: #212121;
  font-family: 'Montserrat-Medium', sans-serif;
}

.mmBeta .demo-loadmore-list ul li {
  padding-left: 20px;
  padding-right: 30px;
}
.mmBeta .ant-menu-submenu-title {
  padding-left: 0px !important;
}

.mmBeta .ant-menu-inline {
  border-right: 0px !important;
}
.mmBeta .ant-menu-submenu-arrow {
  right: 15px;
  width: 10px;
}
.mmBeta .accordionContainer .anticon {
  right: 64px;
}
.mmBeta .vinBlockContainer {
  overflow: hidden !important;
  width: inherit !important;
}
.mmBeta table.base tr td {
  padding: 0 !important;
}
/* .mmBeta .accordionContainer .anticon{
  right: 0;
} */
/* .mmBeta .accordionContainer .mapNames {
  width: 155px;
} */

.vineBlocksNewStyle .mapNames {
  width: 180px;
}

.vineBlocksNewStyle .ant-collapse-item-active .mapNames {
  width: 165px;
}
.vineBlocksNewStyle .ant-collapse-arrow {
  margin-right: 0 !important;
}

.vineBlocksNewStyle .ant-collapse-item-active .ant-collapse-arrow {
  /* right: 40px; */
}

.imgUploadStyle .ant-upload-list-picture-card-container {
  display: none;
  margin: 0 !important;
}

.imgUploadStyle .ant-upload {
  display: contents;
}

.imgUploadStyle .ant-upload-list-picture-card .ant-upload-list-item-info {
  overflow: visible;
}

.imgUploadStyle .btnUpload {
  margin-right: 0;
  display: block;
  margin-top: 28px;
}

.imgUploadStyle .ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: contents;
}

.vinrowAuto .ant-modal-body {
  height: calc(100vh - 25vh);
  overflow-y: auto;
}
.vinrowAutoJumpAndGo .ant-modal-body {
  height: calc(100vh - 55vh) !important;
  overflow-y: auto;
}
.vinrowAuto
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto !important;
  max-height: auto !important;
}
.vinrowAuto .ant-input-number-input-wrap {
  width: 100% !important;
}
.disoverLoaderLeft {
  left: 82% !important;
}

.imageTooltip .ant-tooltip-inner {
  display: none;
}

.jump-and-go-expand {
  width: 380px !important;
  max-width: 380px !important;
}

.jump-and-go-expand .react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 80px;
  right: 0px;
  position: relative;
}
.jumpAndGoList .react-datepicker {
  width: 100% !important;
}
.jumpAndGoList .react-datepicker__month-container {
  width: 72% !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 5px 0px;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  width: 92%;
}
.react-datepicker__month {
  margin: 5px 0px;
  text-align: center;
}
.ul.react-datepicker__time-list {
  height: 200px;
}
.react-datepicker__header.react-datepicker__header--time {
  height: 65px;
}
.commInput > .ant-input-number-handler-wrap {
  display: none;
}
.jumpgoModal .ant-modal-body {
  min-height: 250px;
}
.captalize {
  text-transform: uppercase !important;
}
.btnCancel {
  text-transform: uppercase !important;
}
.canelBtn {
  text-transform: uppercase !important;
}
.customSelect {
  width: 100%;
  padding-top: 6px;
}

.imgUploadStyle .loader {
  height: inherit !important;
}

.commonPopup .formRow .ant-picker.datePickerSel {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #5c5c5c;
  line-height: 19px;
  border: 1px solid #366376 !important;
  border-radius: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkList {
  overflow-y: auto !important;
  height: 200px;
}

/** ------------------------------------------ **/
/** ---error code popup styles--- **/
/** ------------------------------------------ **/

.errorCode_popup {
  background: #fff;
  min-height: 48px;
}

.erp_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.erp_row_tname_full {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.erp_row_tname {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin-right: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
}
.erp_row_status {
  background: #e8e8e8;
  border-radius: 5px;
  padding: 6px;
  min-width: 60px;
}

.erp_row_status_ur {
  background: #f3ccc9;
  border-radius: 5px;
  padding: 6px;
  min-width: 60px;
}
.erp_row_bt10 {
  margin-bottom: 10px;
}

.erp_row_time,
.erp_row_duration {
  font-size: 10px;
  font-family: 'Montserrat-Medium', sans-serif;
}
.erp_row_time {
  margin-right: 20px;
}

.erp_row span {
  color: #5c5c5c !important;
}
.erp_row_tname_minMax {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  min-width: 150px;
  max-width: 300px;
  word-break: break-word;
}
.tn_wrap {
  white-space: nowrap;
}
.swapBtnPosition {
  margin-top: -20px;
  margin-bottom: 15px;
  width: 180px !important;
}

.mapMakerContainer .ant-input,
.mapMakerContainer .ant-select-selector,
.mapMakerContainer .ant-input-number {
  border: 1px solid #366376 !important;
  min-height: 37px !important;
  border-radius: 10px !important;
}

.mapMakerContainer .ant-input-number.commInput.w100 {
  margin-bottom: 0 !important;
}

.mapMakerActiveBtn,
.mapMakerActiveBtn:hover,
.mapMakerActiveBtn:active,
.mapMakerActiveBtn:focus {
  font-family: 'Montserrat-SemiBold', sans-serif;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  background: #366376;
  color: #fff;
  height: 34px;
  padding: 8px 15px;
  /* width: 150px; */
  letter-spacing: 0.86px;
  text-transform: uppercase;
  margin-left: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  outline: none !important;
  border: none !important;
}
.formRowBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.mapMakerContainer .btnSave {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  align-items: center;
  justify-self: center;
  background-color: #8a9a48;
  margin-left: 0 !important;
  outline: none;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
  text-transform: uppercase;
}

.mapMakerContainer .btnCancel {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  align-items: center;
  justify-self: center;
  background-color: #b6b6b7;
  margin-right: 5px;
  outline: none;
  border: none;
  color: #000;
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
}

.mapMakerContainer .pl15 {
  padding-left: 15px;
}

.mapMakerContainer .ant-btn[disabled],
.mapMakerContainer .ant-btn[disabled]:hover,
.mapMakerContainer .ant-btn[disabled]:focus,
.mapMakerContainer .ant-btn[disabled]:active {
  background-color: #8e9865;
  color: #cfcfcf;
  cursor: not-allowed;
}

.mapMakerContainer .formRowBtns button[disabled],
.mapMakerContainer .formRowBtns button[disabled]:hover,
.mapMakerContainer .formRowBtns button[disabled]:focus,
.mapMakerContainer .formRowBtns button[disabled]:active {
  background: #4b6874;
  color: #cfcfcf;
  cursor: not-allowed;
}

.wdCheckbox {
  width: 135px;
}
.checkboxTitle {
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
}
.chargepointDropDown {
  margin: 0 !important;
}
.chargepointDropDown .ant-select-selector {
  height: 100% !important;
}

.inlineTxt {
  display: inline-flex;
  line-height: 22px;
}

.ploy_icon {
  margin-right: 5px;
  width: 12px;
  height: 12px;
  position: relative;
  top: -2px;
}
.poly_color_name {
  text-transform: uppercase;
}

.adpSpl .rc-virtual-list-holder-inner .ant-select-item-option-content {
  gap: 40px;
}
