/* ******************* skeleton animation ******************* */

.operations-graph {
  position: relative;
}

.animationDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  left: 0;
  top: 0;
}
.bgNone {
  background: none;
}

.card_a {
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  /* border: 1px solid #d9d9d9; */
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
}
.card_a .header_a {
  display: flex;
  align-items: center;
}
.header_a .img_a {
  height: 75px;
  width: 75px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.header_a .details_a {
  margin-left: 20px;
}
.details_a span {
  display: block;
  background: #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.details_a .name_a {
  height: 10px;
  width: 100px;
}
.details_a .about_a {
  height: 10px;
  width: 150px;
  margin-top: 10px;
}
.description_a {
  width: 100%;
}

.description_a .line_a {
  background: #d9d9d9;
  border-radius: 10px;
  height: 10px;
  margin: 10px 0;
  overflow: hidden;
  position: relative;
}
.description_a .line-1 {
  width: calc(100% - 15%);
}
.description_a .line-3 {
  width: calc(100% - 40%);
}
.card_a .btns_a {
  display: flex;
}
.card_a .btns_a .btn_a {
  height: 45px;
  width: 100%;
  background: #d9d9d9;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
}
.btns_a .btn-1 {
  margin-right: 8px;
}
.btns_a .btn-2 {
  margin-left: 8px;
}

.items-baseline .rounded {
  width: 100%;
  background: #d9d9d9;
  position: relative;
  overflow: hidden;
}

.header_a .img_a::before {
  background-size: 650px 600px;
}

.header_a .img_a::before,
.details_a span::before,
.description_a .line_a::before,
.btns_a .btn_a::before,
.items-baseline .rounded::before,
.ani_cards_container .ani_cards::before,
.pgram::before,
.pgram2::before,
.pgram3::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #d9d9d9 0%,
    rgba(0, 0, 0, 0.05) 20%,
    #d9d9d9 40%,
    #d9d9d9 100%
  );
  background-repeat: no-repeat;
  background-size: 450px 400px;
  animation: shimmer 1s linear infinite;
  left: 0;
  top: 0;
}

.details_a span::before {
  animation-delay: 0.2s;
}
.btns_a .btn-2::before {
  animation-delay: 0.22s;
}
@keyframes shimmer {
  0% {
    background-position: -450px 0;
  }
  100% {
    background-position: 450px 0;
  }
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.rounded {
  border-radius: 0.25rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.h-24 {
  height: 2.5vh;
}
.h-36 {
  height: 5vh;
}
.h-48 {
  height: 7.5vh;
}
.h-64 {
  height: 10vh;
}
.h-72 {
  height: 12.5vh;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1 1 0%;
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.items-baseline {
  align-items: baseline;
}

.ani_cards_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px;
  height: 100%;
}
.ani_cards {
  height: 75px;
  width: 100%;
  background: #d9d9d9;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.graph_ani_container {
  display: flex;
  gap: 30px;
  width: 100%;
  padding: 30px;
  height: 100%;
  align-items: center;
  position: relative;
}

.box_ani {
  height: 75px;
  min-width: 75px;
  width: 75px;
  background: #d9d9d9;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}

.box_ani::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #d9d9d9 0%,
    rgba(0, 0, 0, 0.05) 20%,
    #d9d9d9 40%,
    #d9d9d9 100%
  );
  background-repeat: no-repeat;
  background-size: 450px 400px;
  animation: shimmer 1s linear infinite;
  left: 0;
  top: 0;
}

.pgram {
  width: 12vw;
  height: 24vh;
  transform: skewY(10deg);
  background: #d9d9d9;
  position: relative;
  overflow: hidden;
}
.pgram2 {
  transform: skewY(-10deg);
  margin-left: 10px;
}
.pgram3 {
  transform: skewY(10deg);
  margin-left: 10px;
}
.map_cont_loading {
  margin: 20px;
  display: flex;
}

.map-legend {
  width: 88px;
  height: 82px;
  border-radius: 6px;
  padding: 6px;
  background-color: #fff;
  margin: 0 auto;
  position: absolute;
  bottom: 7px;
  left: 9px;
}

.topLine {
  width: 19px;
  height: 9px;
  border-radius: 12.5px;
  background-color: #e6e6e6;
  margin-bottom: 10px;
}
.bullet_lines {
  display: flex;
  gap: 4px;
  align-items: center;
}
.bullet {
  background-color: #e6e6e6;
  border-radius: 100px;
  width: 9px;
  height: 9px;
}
.w16 {
  width: 16px !important;
}
.w40 {
  width: 40px !important;
}
.w53 {
  width: 53px !important;
}
.w63 {
  width: 63px !important;
}

.w153 {
  width: 153px !important;
}
.mline {
  background-color: #e6e6e6;
  border-radius: 100px;
  width: 9px;
  height: 9px;
}
.bullet-line-block {
  display: flex;
  gap: 4px;
  flex-direction: column;
}
.map-legend .bullet {
  height: 9px;
  border-radius: 10px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;
}
.map-legend .mline,
.map-legend .topLine,
.map-top-line .mline {
  height: 7px;
  border-radius: 10px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.map-bg {
  background-image: url(../../../assets/images/Coverage-Map.png);
  animation: mapbganim 3s infinite ease-out;
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
}
// @keyframes mapbganim {
//     0% {
//         opacity: 80%;
// transition: opacity 0.5s;
//     }
//      50% {
//        opacity: 50%;
// transition: opacity 0.5s;
//     }
//       100% {
//        opacity: 0%;
// transition: opacity 0.5s;
//     }

// }
.map-animation {
  position: relative;
  width: 100%;
  height: 100%;
}
.map-top-line {
  background-color: #fff;
  padding: 12px 16px;
  height: 44px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
}
.map-top-line-sec {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: calc(100% - 32px);
}
.map-top-small-lines {
  display: flex;
  gap: 6px;
}
.map-top-line .h17 {
  height: 17px !important;
}
