.ext-widget {
  position: relative;
}

ul.ext-dropdown {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 5px 10px;
  z-index: 4;
  background: #fff;
  border: 1px solid #e8e8e8;
  max-height: 320px;
  overflow-y: auto;
  max-width: 250px;
  top: 37px;
  right: 0;
}
ul.ext-dropdown > li > label {
  padding-right: 8px;
}
ul.ext-dropdown > li {
  padding: 4px 8px 4px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-family: Montserrat-Regular, sans-serif;
}
