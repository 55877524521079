.multiSelectDropdown .ant-select-selector {
  height: 32px !important;
}

.multiple .ant-select-selector {
  height: 32px !important;
}

.ant-modal.errorCodeModal {  
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 0;
  justify-content: center;
}
.ant-modal.errorCodeModal .ant-table-content {
  min-height: 300px;
  height: calc(100vh - 500px);
  overflow-y: auto;
  width: 800px;
}
.errorCodeModal .ant-modal-body .ant-table-thead > tr > th {
  background-color: #fff !important;
}
.errorCodeModal.loghistory .ant-modal-content{
  width: 800px !important;
}
