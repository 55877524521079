.total-hours {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 10px;
  line-height: 12.19px;
  text-align: center;
  color: #99999c;
}

.category {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 10px;
  line-height: 12.19px;
  text-align: center;
  color: #1f1f1f;
}

.msBarChart {
  /* height: calc(100vh - 536px) !important; */
  overflow-y: hidden;
}
.ophBOmsBarChart {
  /* height: calc(100vh - 536px) !important; */
  overflow-y: hidden;
}
.msBarChart1 {
  /* height: calc(100vh - 606px) !important; */
  overflow-y: hidden;
}
.mowing-chart {
  height: calc(100vh - 543px) !important;
  overflow-y: hidden;
}

.savingsGraph {
  height: calc(100vh - 520px);
  overflow-y: hidden;
}
.gph1 {
  overflow-y: hidden;
  height: 310px;
}
.ophBOmsBarChart {
    overflow-y: hidden;
    height: 310px;
}

.efficiencyGraph,
.savings-graph {
  width: 100% !important;
  height: 100% !important;
  min-width: inherit !important;
  overflow: hidden !important;
}

/* .bulletTooltip {
  background-color: #eeeeee;
} */
/* 
.tooltipDrive {
  font-size: 10px;
  font-weight: 600;
  color: '#99999c';
}

.tooltipUser {
  font-size: 14px;
  font-weight: 500;
  color: '#474747';
}

.tooltipTime {
  font-size: 10px;
  font-weight: 400;
  color: '#5c5c5c';
} */

.tWidth ~ .video_progress_tooltip {
  left: 83% !important;
}

.startTime {
  min-width: 48px;
  position: absolute;
  top: 47px;
  left: 0px;
  color: #99999c;
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.endTime {
  min-width: 48px;
  position: absolute;
  top: 47px;
  right: 0px;
  color: #99999c;
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
