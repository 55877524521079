.menuTitle {
  padding: 19px 27px !important;
  background-color: #f7f7f7;
  height: calc(100vh - 60px);
}

.menuTab {
  background: #fff;
  display: flex;
  align-items: center;
  height: 59px !important;
  border-bottom: 1px px solid #e7e7e7 !important;
  padding: 0 30px;
}

.gridTabsContainer {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  margin-bottom: 15px;
}
.tractorHlth {
  cursor: pointer;
}
.gridbackground {
  background-color: #d9e5e6;
}
.gridItem {
  height: 80px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 18px;
}
.widget_content {
  margin-left: 16px;
}
.assignTxt {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #5c5c5c;
  line-height: 15px;
  margin-bottom: 3px;
}
.assignNum {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  line-height: 29px;
}
@media screen and (max-width: 1280px) {
  .gridTabsContainer {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
