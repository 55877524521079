.common_tabs > .ant-tabs-nav {
  height: 59px !important;
  border-bottom: 1px solid #e7e7e7 !important;
  padding: 0 30px;
}

.common_tabs .ant-tabs-tab {
  padding: 3px 5px 0px 5px !important;
  cursor: pointer;
}

.common_tabs .ant-tabs-tab-btn,
.common_tabs .ant-tabs-tab:hover {
  color: #b5b5b6;
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold';
  text-transform: uppercase;
}

.common_tabs .op_tabs_sec .ant-tabs-ink-bar {
  border-bottom: 3px solid #67afb7 !important;
  background: #67afb7 !important;
  border-radius: 0px 0px 5px 5px !important;
}
.common_tabs .op_tabs_sec .ant-tabs-tab:hover:before {
  content: '';
  position: absolute;
  z-index: 999;
  background: rgba(103, 175, 183, 0.3);
  height: 3px;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  top: -14px;
}

.common_tabs .ant-tabs-nav-wrap {
  align-items: center;
  /* justify-content: center; */
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1f1f1f !important;
  text-shadow: 0 0 0.25px currentColor;
}

.common_tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 6vw;
}

.common_tabs .ant-tabs-nav {
  margin: 0 0 0px 0 !important;
}

.common_tabs .ant-tabs-content-holder {
  padding: 19px 27px !important;
  background-color: #f7f7f7;
  height: calc(100vh - 60px);
}

.newOpAnalytic .ant-tabs-content-holder {
  padding: 0 !important;
  /* padding: 16px 27px 23px 27px !important; */
  background-color: #f7f7f7;
  height: calc(100vh - 60px);
}

.tblContainer {
  width: calc(100% - 0px) !important;
  margin: 0 !important;
}

.common_tabs .gridContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  margin: 0;
  margin-bottom: 24px;
}

.common_tabs .gridItem {
  height: 80px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background-color: #fff;
}
