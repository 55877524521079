.filterContainer {
  margin-left: auto;
}

.refreshIcon {
  width: 32px;
  height: 32px;
  padding: 5px;
  border: 1px solid #0000001f;
  border-radius: 5px;
  margin-top: 14px;
  cursor: pointer;
}

.refreshContainer {
  text-align: right;
  margin-right: 24px;
}

.refreshIcon {
  background-image: url(../../../assets//images/refresh_gray.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}
