/* Farm Observation Container Ends */

.cropBtn {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
}
.cancelBtn {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}

.cancelBtn:hover {
  border: 1px solid #366376 !important;
  color: #366376 !important;
  background: transparent !important;
}

.cropBtn Button.addNewBtn {
  font-size: 12px !important;
  padding: 12px 20px !important;
}
.cropBtn Button.addNewBtn:hover {
  background-color: #366376 !important;
  border-color: #366376 !important;
  color: #fff !important;
}

.editImageModal .ant-modal-content {
  height: 600px !important;
}
.editImageModal .ant-modal-header {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.15px;
  line-height: 19px;
  border-radius: 10px 10px 0 0;
}
.editImageModal .ant-modal-close-x {
  top: 15px !important;
  right: 10px !important;
  background-color: transparent !important;
}
