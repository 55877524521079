/* @import '../../constants/color';
@import '../../constants/fonts'; */
.topContainer {
  display: flex;
  margin-top: 1px;
  margin-left: 20px;
}
.leftCircleArrow {
  height: 60px;
  width: 60px;
  align-self: center;
  margin-right: 20px;
}
.middleContainer {
  margin-left: 20px;
  margin-top: 70px;
}
/* // drive assist styles */
.driveAssistContainer {
  display: flex;
  justify-content: space-evenly;
}
.boxContainer {
  width: 437px;
  height: 460px;
  background-color: #cccddc;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.boxText {
  font-family: 'Montserrat-Bold', sans-serif;
  letter-spacing: 0.08px;
  line-height: 20px;
  font-size: 15px;
  width: 100%;
  text-align: center;
  padding-top: 20px;
}
.boxText2 {
  font-family: 'Montserrat-Bold', sans-serif;
  letter-spacing: 0.08px;
  line-height: 23px;
  font-size: 19px;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  text-transform: uppercase;
}
.driveAssistText {
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 20px;
  display: flex;
  width: 100%;
}
.textUpperCase {
  text-transform: uppercase;
}
.newRoutesButtonContainer {
  width: 320px;
  height: 60px;
  background-color: #768034;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 22px auto;
}
.buttonText {
  color: #fff;
  font-family: 'Montserrat-Bold', sans-serif;
  text-transform: uppercase;
  font-size: 17px;
  letter-spacing: 0.08em;
  line-height: 21px;
}
.shadowButtonText {
  color: #000;
  font-family: 'Montserrat-Bold', sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: 21px;
  margin-bottom: 3px;
}
.shadowButtonTextNight {
  color: #fff;
  font-family: 'Montserrat-Bold', sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.08em;
  line-height: 21px;
}
.taskDetailsContainer {
  background-color: #f1f3f4;
  height: 90px;
  width: 774px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 40px;
}
.sidebarContainer {
  width: 100px;
  height: 400px;
  display: grid;
  justify-content: center;
  margin-top: 100px;
}
.tasksContainer {
  height: 500px;
  overflow-y: hidden;
  margin-top: 10px;
}
.iconContainer {
  background: url('../../assets/images/pathIcon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 66px;
  width: 66px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.middleAreaWrapper {
  display: flex;
  justify-content: space-between;
  width: 780px;
  align-items: center;
}
.pathSelectedContainer {
  width: 320px;
  height: 85px;
  padding-left: 20px;
  background-color: silver;
}
.playIcon {
  margin-right: 15px;
}
.routeRow {
  padding-left: 25px;
}
.routeDesc {
  color: #58595b;
  font-family: 'Montserrat-SemiBold', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 29px;
}
.routeHead {
  color: #000;
  font-family: 'Montserrat-Medium', sans-serif;
  text-transform: uppercase;
  font-size: 19px;
  letter-spacing: 0.08em;
  line-height: 29px;
  margin-top: 20px;
}
/** Recording new routes */
.newRoutesContainer {
  padding: 0 20px;
  background-color: #fff;
  height: 768px;
}
.backgroundHighlighted {
  background-color: #f1f3f4;
}
.headerWrapper {
  height: 104px;
  display: flex;
  align-items: center;
  margin-bottom: 130px;
  justify-content: space-between;
}
.headerText {
  color: #323232;
  font-size: 27px;
  font-weight: 500;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  padding-top: 45px;
}
.newRoutesMiddleContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2px;
}
.buttonWrapper {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonContainer {
  min-height: 168px;
  min-width: 324px;
  background-color: #768034;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonText {
  font-size: 21px;
  font-weight: bold;
  line-height: 25px;
  letter-spacing: 0.08em;
}
.saveButtonDetailsContainer {
  display: grid;
  margin-left: 20px;
}
.distanceAndDurationTex {
  font-size: 15px;
  letter-spacing: 0.08em;
  color: #fff;
  text-transform: uppercase;
}
.discardButtonContainer {
  width: 150px;
  height: 60px;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: #f1f3f4;
  margin-left: 80px;
}
.discardButtonText {
  text-transform: uppercase;
}
.routeDetailsContainer > label {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #58595b;
  line-height: 15px;
  margin-bottom: 5px;
  padding-left: 15px;
  font-weight: 500;
}
.detailsContainer {
  min-height: 53px;
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 20px;
}
.detailsText {
  font-size: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #000;
}
/* // search */
.searchContainer {
  width: 114px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/* searchContainer */
.searchInnerContainer {
  width: 74px;
  height: 40px;
  background-color: #f1f3f4;
  border-radius: 10px;
  text-align: center;
  padding: 3px 10px;
}
.searchInnerContainerNight {
  width: 74px;
  height: 40px;
  background-color: #3f444f;
  border-radius: 10px;
  text-align: center;
  padding: 3px 10px;
}
.searchText {
  font-size: 8px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.08em;
  line-height: 10px;
  text-transform: uppercase;
}
/* searchContainer */
/* // Recording new routine Modal */
.newRouteTxt {
  font-size: 20px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #2f333b;
  margin-bottom: 25px;
  text-transform: uppercase;
  margin-left: 15px;
}
.newRouteButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}
.NewRouteDesc {
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.09em;
  color: #2f333b;
  width: 417px;
  margin-left: -45px;
  margin-top: -15px;
}
/* .btnCancel {
  width: 800px;
  height: 40px;
  border-radius: 25px;
  margin-left: 10px;
  background: #e94334;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  outline: none;
  border: none;
  cursor: pointer;
} */
.btnStart {
  width: 800px;
  height: 40px;
  border-radius: 25px;
  margin-left: 30px;
  background: #768034;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  outline: none;
  border: none;
  cursor: pointer;
}
.selectedRouteContainer {
  min-height: 560px;
  padding: 0 20px;
  width: 936px;
}
/* selectedRouteContainer */
.middleWrapper {
  background-color: #f0f2f3;
  padding: 0 20px;
  height: 505px;
  border-radius: 10px;
  width: 770px;
}
.routeStartButtonContainer {
  width: 226px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  margin-top: -16px;
}
.mapContainer {
  background: #fff;
  height: 315px;
  display: flex;
  padding: 0;
  width: 350px;
}
.mapContainerNight {
  background: #262b3a;
  height: 315px;
  display: flex;
  padding: 0;
  width: 350px;
}
.routeResumeAndDiscardButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
/* // selectedRouteContainer */
/** off tractor mode styles */
.offTractorModeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 737px;
}
.offTractorModeContainer > .gestureAndShadowImageContainer {
  height: 353px;
  width: 316px;
  border-radius: 32px;
  background-color: #f0f1f1;
  border: 1px solid #58595b;
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.offTractorModeContainer
  > .gestureAndShadowImageContainer
  > .gestureAndShadowImageText {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 0.08rem;
}
.offTractorModeContainer > .gestureAndShadowImageContainerNight {
  height: 353px;
  width: 316px;
  border-radius: 32px;
  background-color: #262b3a;
  border: 1px solid #58595b;
  display: grid;
  align-items: center;
  justify-content: center;
}
.offTractorModeContainer
  > .gestureAndShadowImageContainerNight
  > .gestureAndShadowImageText {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 0.08rem;
  color: #fff;
}
/* } */
.shadowHeaderText {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 38px;
  letter-spacing: 0.08rem;
  margin-top: 37px;
}
.shadowHeaderTextNight {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 38px;
  letter-spacing: 0.08rem;
  color: #fff;
  margin-top: 37px;
}
.flexContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 485px;
  box-sizing: border-box;
  height: 96px;
  margin: 0 auto;
  cursor: pointer;
}
.shadowTextContainer {
  width: 800px;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: #2f333b;
  color: #fff;
  padding: 20px;
  margin-top: 35px;
  border-radius: 8px;
}
.shadowText {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 22px;
  margin-left: 15px;
  line-height: 24px;
  letter-spacing: 0.08rem;
}
/* } */
.shadowIconContainer {
  width: 232px;
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f3f4;
}
.shadowMiddleContainer {
  margin-top: 20px;
  text-align: center;
  display: grid;
  justify-content: center;
}
.engageAndDisEngageText {
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: black;
}
.engageAndDisEngageTextNight {
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: white;
}
.width200 {
  width: 200px;
}
.startButtonContainer {
  border-radius: 60px;
  background-color: #738023;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.startButtonContainer > .startButtonText {
  font-family: 'Montserrat-Bold', sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
}
/* } */
.overallIconContainer {
  display: grid;
  width: 62%;
}
.gestureModeiconsContainer {
  display: flex;
  width: 68%;
}
.gestureMiddleContainer {
  margin-top: 20px;
  display: flex;
  width: 937px;
  padding: 0 100px;
  box-sizing: border-box;
}
.gestureMiddleContainer > .engageAndDisEngageContainer {
  width: 30%;
  text-align: center;
  margin-right: 18px;
}
.gestureMiddleContainer > .iconWithTextWrapper {
  width: 150px;
  height: 190px;
  text-align: center;
  margin-right: 18px;
}
.gestureMiddleContainer > .iconWithTextWrapper > .innerDivContainer {
  background-color: #f1f3f4;
  height: 150px;
  width: 150px;
  padding-top: 10px;
  margin: 10px;
}
/* } */
/* } */
.gestureOrShadowModeEnd {
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.gestureOrShadowModeEnd > .faceTractorAndEngageText {
  font-size: 18px;
  font-family: 'Montserrat-Bold', sans-serif;
  margin-top: -55px;
  width: 322px;
  color: black;
}
.gestureOrShadowModeEnd > .faceTractorAndEngageTextNight {
  font-size: 18px;
  font-family: 'Montserrat-Bold', sans-serif;
  margin-top: -55px;
  width: 322px;
  color: white;
}
.gestureOrShadowModeEnd > .width200 {
  width: 200px;
}
.gestureOrShadowModeEnd > .endButtonContainer {
  margin-top: 30px;
  border-radius: 60px;
  background-color: #4c5fdb;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gestureOrShadowModeEnd > .endButtonContainer > .endButtonText {
  font-family: 'Montserrat-Bold', sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
}
/* } */
/* } */
.sidekickTopTabs {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-left: 80px;
}
.offTractorModeFullContainer {
  display: inline-block;
  width: 937px;
  box-sizing: border-box;
}
.offTractorModeFullContainerNight {
  display: inline-block;
  width: 937px;
  box-sizing: border-box;
  height: 658px;
  background-color: #3f444f;
}
.shadowImage {
  width: 400px;
  height: 400px;
}
.unFinishedRecord {
  border-left: 10px solid #4c5fdb;
}
.roundIconName {
  margin-top: 20px;
}
.marginTop30 {
  margin-top: 30px;
}
.borderContent {
  border: 1px solid #ccc6c6;
  width: 773px;
  height: 508px;
  border-radius: 10px;
}
.shadowNightBackground {
  height: 664px;
}
.descriptionText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.driveSettingWrapper {
  background: #dfe4f4;
  border-radius: 12px;
  height: 100%;
  padding: 22px;
}
.driveSettingSpeedSec {
  display: flex;
  height: 180px;
  width: 100%;
  justify-content: center;
  position: relative;
}
.driveSettingHead {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  color: #000;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}
.dSetSpeedBg {
  background: url('../../assets/images/dSpeed.svg');
  background-repeat: no-repeat;
  background-position: center center;
  width: 157px;
  height: 157px;
  position: absolute;
}
.speedSec {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 35px;
  text-align: center;
}
.speedSecTitle {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 13px;
  color: #696969;
  margin-bottom: 0;
}
.speedSecNo {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 37px;
  color: #474747;
  margin-bottom: 0;
  position: relative;
  top: 0;
  line-height: 43px;
}
.speedSecmph {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 12px;
  color: #474747;
  margin-bottom: 0;
  position: relative;
  top: -3px;
}
.dsPlusBtn {
  background: url('../../assets/images/ds_plus.svg');
  position: absolute;
  width: 56px;
  height: 54px;
  top: 32%;
  right: -22px;
  cursor: pointer;
}
.dsMinusBtn {
  background: url('../../assets/images/ds_minus.svg');
  position: absolute;
  width: 56px;
  height: 54px;
  top: 32%;
  left: -24px;
  cursor: pointer;
}
.driveSettingBlocks {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.driveSettingBox {
  display: block;
  /* height: 293px; */
  width: 33.3%;
  margin-right: 25px;
  border: 1px solid #7a8ac0;
  border-radius: 4px;
  padding: 20px;
}
.driveSettingBox:last-child {
  margin-right: 0;
}
.settingBoxHead {
  display: flex;
  margin-bottom: 25px;
  height: 35px;
}
.settingBoxHead img {
  margin-right: 25px;
}
.settingBoxHeadTxt {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 18px;
  color: #000;
  margin-bottom: 0;
  letter-spacing: 0.13px;
}
.settingBoxPartner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 60px);
}
.settingBoxPartnerName {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 19px;
  color: #000;
  margin-bottom: 0;
}
.settingBoxPartnerIdentity {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  color: #959595;
  margin-bottom: 0;
  position: relative;
  top: -5px;
}
.settingBoxPtoCont {
  display: block;
  text-align: center;
  position: relative;
}
.settingBoxPtoContShow {
  opacity: 0.6;
}
.settingBoxPtobtnOn {
  border-radius: 5px;
  width: 152px;
  height: 44px;
  text-align: center;
  background: #d8d8d8 !important;
  color: #333 !important;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 12px;
}
.settingBoxPtobtnOnActive {
  background: #546cb2 !important;
  color: #fff !important;
}
.settingBoxPtobtnOffActive {
  background: #546cb2 !important;
  color: #fff !important;
}
.settingBoxPtobtnOff {
  border-radius: 5px;
  width: 152px;
  height: 44px;
  text-align: center;
  background: #d8d8d8;
  color: #333;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
}
.settingBoxPtobtnOff,
.settingBoxPtobtnOn {
  outline: none;
  border: none;
}
.settingBoxPlusBtn {
  background: url('../../assets/images/ds_plus.svg');
  position: absolute;
  width: 56px;
  height: 54px;
  top: 0;
  right: 0;
  cursor: pointer;
}
.disableMousePointer {
  cursor: default !important;
}
.settingBoxMinusBtn {
  background: url('../../assets/images/ds_minus.svg');
  position: absolute;
  width: 56px;
  height: 54px;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}
.dsPTOCount {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 39px;
  color: #474747;
  margin-bottom: 0;
  position: relative;
  top: 0;
  line-height: 48px;
}
.dsPTOrpm {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 16px;
  color: #474747;
  margin-bottom: 0;
  position: relative;
  top: -3px;
  line-height: 19px;
  text-transform: uppercase;
}
.dsHitchBoxCont {
  display: block;
  position: relative;
}
.dsInrow {
  margin-bottom: 35px;
}
.dsInrowBlock {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  position: relative;
}
.dsProgressBar {
  display: block;
}
.inrowTxt {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 16px;
  color: #1e1e1e;
  width: 140px;
  padding-right: 10px;
  display: inline-block;
  letter-spacing: 1.28px;
}
.hSettingIcon {
  margin-right: 20px;
}
.hitchFloatIcon {
  margin: 0;
}
.hPPercent {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 20px;
  color: #1e1e1e;
  margin-bottom: 0;
  direction: rtl;
  text-align: right;
  min-width: 62px;
}
.dsOutrow {
  margin-bottom: 35px;
}
.mr0 {
  margin-right: 0 !important;
}
.slider {
  -webkit-appearance: none;
  height: 22px;
  overflow: hidden;
  width: 100%;
  background: #cececf;
  outline: none;
  border-radius: 15px;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #fff;
  border-radius: 50%;
  box-shadow: -210px 0 0 200px #546cb2;
  cursor: pointer;
  height: 22px;
  width: 22px;
  border: 0;
}
.autoSetBtn {
  border-radius: 7px;
  width: 152px;
  height: 51px;
  text-align: center;
  margin-top: 28px;
  background: #8a9a48;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  float: right;
  outline: none;
}
.rowsSideBlock {
  border: 2px solid #6d6e71;
  background: #dfe4f4;
  border-radius: 0 15px 15px 0;
  width: 237px;
  position: absolute;
  top: -2px;
  right: -2px;
  height: 531px;
}
.selectRowsHead {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 18px;
  color: #1e1e1e;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.selRowTypeWrapper {
  display: flex;
  margin-top: 50px;
  margin-bottom: 95px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.selRowType {
  background: #e7e7e7;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  width: 152px;
  height: 52px;
  color: #333;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 33px;
  cursor: pointer;
}
.selRowType:last-child {
  margin-bottom: 0;
}
.selRowType:hover {
  box-shadow: inset 0 2px 6px #00000029;
  border: 1px solid #d8d8d8;
  background: #dfe4f4;
}
.selectedSelRowType {
  box-shadow: inset 0 2px 6px #00000029;
  border: 1px solid #d8d8d8;
  background: #dfe4f4;
}
.sideImplementBlock {
  border: 2px solid #6d6e71;
  background: #dfe4f4;
  border-radius: 0 15px 15px 0;
  width: 237px;
  position: absolute;
  top: -2px;
  right: -2px;
  height: 531px;
  display: flex;
  justify-content: center;
  z-index: 2;
}
.dSetSpeedBg2 {
  background: url('../../assets/images/dSpeed.svg');
  background-repeat: no-repeat;
  background-position: center center;
  width: 139px;
  height: 139px;
  background-size: 139px;
}
.sideImpSec {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sideInsideWrapper {
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  position: relative;
}
.sidePartImp {
  margin-bottom: 10px;
}
.sidePartHeadTxt {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
  letter-spacing: 0.13px;
}
.sideRpmSec {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sidePtoHeadTxt {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
  letter-spacing: 0.13px;
}
.sidePtoInrowTxt {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 12px;
  color: #707071;
  margin-bottom: 0;
  letter-spacing: 0.13px;
}
.sideHitchSec {
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sideHitchSubSec {
  display: flex;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: space-around;
  min-width: 120px;
}
.sideH1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sideHBottom {
  margin-bottom: 0;
}
.sidePartHeadTxtDbl {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 14px;
  color: #959595;
  margin-bottom: 0;
  letter-spacing: 0.13px;
}
.sideImpSecNot {
  display: flex;
  margin-top: 75px;
  margin-bottom: 75px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sideHitchSecNot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sideImpSecN {
  display: flex;
  margin-top: 70px;
  margin-bottom: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sideImpSecNArpm {
  display: flex;
  margin-top: 35px;
  margin-bottom: 35px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sideImpSecNA {
  display: flex;
  margin-top: 75px;
  margin-bottom: 75px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lcMapHeadTxt {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 16px;
  color: #2f333b;
  margin-bottom: 0;
  letter-spacing: 1.43px;
  position: absolute;
  top: -38px;
  left: 0;
}
.hitchSetIconBlock {
  border: 1px solid #707070;
  width: 241px;
  height: 85px;
  background: #fff;
  z-index: 99;
  padding: 10px 15px;
  position: absolute;
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  left: -72px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}
.hitchSetIconPos {
  right: 11px;
  position: absolute;
  top: 31px;
}
.hSTxt {
  text-transform: uppercase;
  color: #fff;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 10px;
}
.hPosBox {
  border: 1px solid #707070;
  border-radius: 8px;
  background: #fff;
  width: 74px;
  height: 63px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.hPosBox:hover,
.hActive {
  border: 1px solid #707070;
  border-radius: 8px;
  background: #546cb2;
  width: 74px;
  height: 63px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hPosBox .hSTxt {
  color: #414042;
}
.hFltBox .hSTxt {
  color: #414042;
}
.hPosBox:hover .hSTxt {
  color: #fff;
}
.hFltBox:hover .hSTxt {
  color: #fff;
}
.hFltBox {
  border: 1px solid #707070;
  border-radius: 8px;
  background: #fff;
  width: 74px;
  height: 63px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.hFltBox:hover,
.hActive {
  border: 1px solid #707070;
  border-radius: 8px;
  background: #546cb2;
  width: 74px;
  height: 63px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hPosBox .hPosIcon {
  background-image: url('../../assets/images/hitchPosIconB.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 28px;
  width: 30px;
  position: relative;
  margin-bottom: 6px;
}
.hActive .hPosIcon,
.hPosBox:hover .hPosIcon {
  background-image: url('../../assets/images/hitchPosIconW.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 28px;
  width: 30px;
  position: relative;
  margin-bottom: 6px;
}
.hFltBox .hFltIcon {
  background-image: url('../../assets/images/hitchFltIconB.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  width: 35px;
  position: relative;
  margin-bottom: 6px;
}
.hActive .hFltIcon,
.hFltBox:hover .hFltIcon {
  background-image: url('../../assets/images/hitchFltIconW.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  width: 35px;
  position: relative;
  margin-bottom: 6px;
}

.autoDrivePopup .dSetSpeedBg2 {
  background-size: 110px;
  width: 110px;
  height: 110px;
}
.autoDrivePopup .dSetSpeedBg2 .speedSecNo {
  font-size: 24px;
}
.autoDrivePopup .dSetSpeedBg2 .speedSec {
  padding: 20px;
}
.autoDrivePopup .sideImplementBlock {
  width: 200px;
}

.rDriveMapSec {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.rHeadSec {
  width: 100%;
  height: 6.5vh;
  padding-left: 30px;
  padding-right: 30px;
  max-height: 62px;
  background-color: #546cb2;
  border: 1px solid #707070;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rDriveMap {
  height: 100%;
}

.tDriveName {
  margin-right: 10px;
}
.tTitleLeft,
.tTitleMiddle {
  font-size: 0.83vw;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #fff;
  letter-spacing: 1.08px;
  text-transform: uppercase;
}
.tTitleMiddle {
  text-transform: inherit !important;
}

.rDriveCancelBtn {
  border-radius: 28px;
  background-color: #ec9322;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #fff;
  box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.16);
  outline: none;
  border: none;
  font-size: 13px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  cursor: pointer;
}

.rBtnSec {
  width: 100%;
  height: 6.5vh;
  padding-left: 30px;
  padding-right: 30px;
  max-height: 62px;
  position: absolute;
  top: 60px;
  left: 0px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rDriveNextBtn {
  border-radius: 7px;
  background-color: #8a9a48;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #fff;
  outline: none;
  border: none;
  font-size: 15px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  cursor: pointer;
}

.mL10 {
  margin-left: 10px;
}

.tBackBtnSec {
  /* padding-left: 1.75vw; */
  min-height: 6.5vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.3vh;
}
.tBackBtn {
  font-size: 0.73vw;
  font-family: 'Montserrat-SemiBold', sans-serif;
  cursor: pointer;
  letter-spacing: 1.25px;
  color: #366376;
  padding-left: 1vw;
  padding-right: 1vw;
}

.rDriveRightSec {
  position: fixed;
  right: -25.3125vw;
  top: 0px;
  width: 25.3125vw;
  height: 100vh;
  /* padding:38px; */
  z-index: 999;
  box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.29);
  transition: 1s;
  background: #fff;
  /* max-width: 486px; */
}

.rDriveRightWrapper {
  height: 100%;
  /* padding-left: 1vw;
  padding-right: 1vw; */
}

.routineDtl {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15.25vh;
}
.rName {
  font-size: 1.77vw;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #000;
  letter-spacing: 0.25px;
  margin-bottom: 0.55vh;
  padding: 0;
  line-height: 3.88vh;
}

.rType {
  font-size: 1.05vw;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #000;
  letter-spacing: 0.15px;
  margin-bottom: 1.3vh;
  padding: 0;
  line-height: 2.22vh;
}

.rDate {
  font-size: 0.73vw;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #000;
  letter-spacing: 0.1px;
  padding: 0;
  margin: 0;
  line-height: 1.66vh;
}

.routineValues {
  padding-left: 4.21vw;
  padding-right: 1vw;
  min-height: 15.55vh;
  margin-bottom: 3.5vh;
}

.rRowSec {
  display: flex;
}

.rDis {
  font-size: 1.05vw;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.15px;
  margin-right: 5px;
  line-height: 4.4vh;
}
.rDisValue {
  font-size: 1.05vw;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.15px;
  line-height: 4.4vh;
}

.impBoxSec {
  background-color: #fff;
  border-radius: 16px;
  padding: 5px 10px;
  border: 1px solid #cececf;
  /* margin-left: 2.03vw;
  margin-right: 2.03vw; */
}
.impTxt {
  font-size: 0.73vw;
  font-family: 'Montserrat-SemiBOld', sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 1.38vh;
}

.impCompTxt {
  font-size: 0.83vw;
  font-family: 'Montserrat-SemiBOld', sans-serif;
  text-align: center;
  width: 100%;
  margin-bottom: 0.46vh;
  line-height: 2.03vh;
  letter-spacing: 0.25px;
}
.impCompTxt2 {
  font-size: 0.46vw;
  font-family: 'Montserrat-Regular', sans-serif;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  color: #5c5c5c;
  letter-spacing: 0.06px;
}

.startBtnSec {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.rStartBtn,
.rStartBtn:hover {
  border-radius: 1.45vw;
  background-color: #8a9a48;
  min-height: 5.09vh;
  padding: 1.38vh 2.4vw;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.14vw;
  font-family: 'Montserrat-SemiBold', sans-serif;
  line-height: 2.5vh;
}

.rightBgGray {
  background: #f7f7f7;
}

/* operation settings */

.mbop {
  margin-bottom: 7.4vh;
}

.opSpeed {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.opSpeedBg {
  background-image: url('../../assets/images/opSpeedBg.svg');
  background-repeat: no-repeat;
  background-position: center center;
  width: 130px;
  height: 130px;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 7.22vh;
  margin-bottom: 1.94vh;
  position: relative;
}

.opSpeedTxt {
  font-size: 0.677vw;
  color: #696969;
  font-family: 'Montserrat-Medium', sans-serif;
  line-height: 0.83vh;
  margin-bottom: 6px;
}
.opSpeedCount {
  font-size: 1.82vw;
  line-height: 3.98vh;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #707070;
}
.opSpeedMes {
  font-size: 0.57vw;
  color: #696969;
  font-family: 'Montserrat-Medium', sans-serif;
  line-height: 0.83vh;
}

.mbipb {
  margin-bottom: 28.24vh;
}
.mbips {
  margin-bottom: 1.11vh;
}
.mlrips {
  margin-left: 1.03vw !important;
  margin-right: 1.03vw !important;
}
.opMinIcon,
.opMinIcon:hover {
  background-image: url('../../assets/images/opMinBg.png');
  background-repeat: no-repeat;
  background-position: center center;
  width: 51px;
  height: 51px;
  border: none;
  outline: none;
  position: absolute;
  left: -20px;
  top: 33%;
  background-color: transparent;
  cursor: pointer;
}
.opPlusIcon,
.opPlusIcon:hover {
  background-image: url('../../assets/images/opPlusBg.png');
  background-repeat: no-repeat;
  background-position: center center;
  width: 51px;
  height: 51px;
  border: none;
  outline: none;
  position: absolute;
  right: -20px;
  top: 33%;
  background-color: transparent;
  cursor: pointer;
}
.plr10 {
  padding-left: 10px;
  padding-right: 10px;
}
.plr20 {
  padding-left: 20px;
  padding-right: 20px;
}
.ipbMh {
  min-height: 10vh;
}
.hBtnSec {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 12px;
  font-size: 0.625vw;
  color: #1e1e1e;
  font-family: 'Montserrat-Medium', sans-serif;
  cursor: pointer;
}
.hBtnSec:last-child {
  margin-right: 0;
}

.hBtn,
.hBtn:hover {
  width: 48px;
  height: 45px;
  border: 1px solid #cececf;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  outline: none;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 0.625vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hBtnSel button,
.hBtnSel:hover button {
  background: #8595ca;
  border: 1px solid #546cb2;
  cursor: pointer;
}

.hBtnSel .hsTxt {
  font-size: 0.625vw;
  color: #1e1e1e;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.mb12 {
  margin-bottom: 12px;
}

.hBtnSec .hPosIcon {
  background-image: url('../../assets/images/hitchPosIconB.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 28px;
  width: 30px;
  position: relative;
  display: flex;
}
.hBtnSel .hPosIcon {
  background-image: url('../../assets/images/hitchPosIconW.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 28px;
  width: 30px;
  position: relative;
  display: flex;
  margin: 0;
}
.hBtnSec .hFltIcon {
  background-image: url('../../assets/images/hitchFltIconB.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  width: 35px;
  position: relative;
  display: flex;
}
.hBtnSel .hFltIcon {
  background-image: url('../../assets/images/hitchFltIconW.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  width: 35px;
  position: relative;
  display: flex;
}

.aBtw {
  justify-content: space-between;
}
.hsBtnSec,
.hsPbar,
.hHsec {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.hHTxt {
  font-size: 0.625vw;
  /* margin-right: 2.08vw; */
  font-family: 'Montserrat-SemiBold', sans-serif;
  height: 2.4vh;
  display: flex;
  align-items: center;
  text-align: left;
  width: 5.5vw;
}
.hPer {
  background-color: #fff;
  border: 1px solid #b6b6b7;
  border-radius: 5px;
  padding: 3px 5px;
  height: 2.4vh;
  font-size: 0.57vw;
  color: #1e1e1e;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hsBarSec {
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
  flex-direction: column;
  min-width: 8vw;
}
.hsBarSec img {
  max-width: 100%;
  width: 100%;
}
.hpBarWrapper {
  width: 80%;
  margin-top: 10px;
}

.cViewsTxt {
  font-size: 0.73vw;
  font-family: 'Montserrat-SemiBOld', sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 2.12vh;
}
.br100 {
  border-radius: 100px !important;
  width: 42px;
  height: 42px;
}

.pad0 {
  padding: 0px !important;
}
.pad510 {
  padding: 5px 10px;
}

.contOverflow {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 58px);
  padding-bottom: 2.75vh;
}

.camSel {
  background-color: #8595ca !important;
  border: 1px solid #546cb2;
  box-shadow: inset 0 3px 6px #00000016;
}
.camCircle {
  border-radius: 100px !important;
  width: 42px;
  height: 42px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b6b6b7;
  /* cursor: default; */
}

.hpBarWrapper .ant-slider-track {
  background-color: #546cb2;
}
.hpBarWrapper .ant-slider-rail {
  background-color: #cececf;
}
.hpBarWrapper .ant-slider-handle:focus,
.hpBarWrapper .ant-slider-handle:hover,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #546cb2;
}

/* operation setting row selection styles goes here */

.impBoxSecWB {
  background-color: #fff;
  border-radius: 16px;
  padding: 5px 10px;
  /* border:1px solid #CECECF; */
  margin-left: 2.03vw;
  margin-right: 2.03vw;
}
.mbrow {
  margin-bottom: 4.16vh;
}
.cfqcTxt {
  font-size: 0.625vw;
  color: #d33c39;
  font-family: 'Montserrat-Medium', sans-serif;
  margin-bottom: 1.48vh;
}

.cfqRadioBtn .ant-radio-group {
  display: block;
}

.cfqRadioBtn .ant-space-horizontal {
  gap: 22px !important;
  display: grid;
  grid-template-columns: auto auto;
}
.cfqRadioBtn .ant-radio-wrapper span:nth-child(2) {
  padding-left: 12px !important;
}
.cfqRadioBtn .ant-radio-wrapper {
  font-size: 0.833vw;
  color: #474747;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.12px;
}

.ptoRadio {
  display: grid;
  gap: 10px;
}
.ptoRadio .ant-radio-wrapper {
  font-size: 0.57vw;
  color: #474747;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.88px;
}
.ptoRpm {
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.ptoRpm .opMinIcon,
.ptoRpm .opPlusIcon {
  background-size: 100%;
  top: 8px;
  width: 36px;
  height: 36px;
}
.ptoRpmTxt {
  min-width: 6.25vw;
  font-size: 1.25vw;
  font-family: 'Montserrat-Medium', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  line-height: 2.68vh;
}
.ptoRpmM {
  font-size: 0.53vw;
  font-family: 'Montserrat-Medium', sans-serif;
  line-height: 1;
}

.hw {
  width: 2.6vw;
}

.opsError {
  font-size: 0.625vw;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #d33c39;
  margin-bottom: 1.38vh;
  letter-spacing: 1px;
}

.alwaysOffOpacity {
  opacity: 0.4;
  cursor: default !important;
}

/* Routine Dialog popup styles goes here  */

.RoutineContainer {
  display: block;
  width: 100%;
}
.rPopup {
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.rPopup .ant-modal-footer {
  display: none;
}

.rPopup {
  width: 735px !important;
}
.rPopup .ant-modal-content {
  border-radius: 12px;
  width: 916px !important;
}
.rPopup .ant-modal-body {
  padding: 0;
}
.rPopup .rHeaderSec {
  min-height: 11.66vh;
  background-color: #1e3a46;
  padding: 34px 34px 14px 34px;
  border-radius: 12px 12px 0 0;
  width: 100%;
  display: flex;
  /* align-items: flex-start; */
  justify-content: space-between;
}
.rUserDtl {
  display: flex;
  align-items: center;
}
.rUser {
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #fff;
}
.rUserLock {
  margin-right: 10px;
}
.rUserLockSec {
  margin-right: 10px;
}
.rTractorName {
  /* font-size: 24px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 1.68px; */
  color: #fff;
  /* line-height: 29px; */
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.rNames {
  font-size: 19px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  line-height: 2.12vh;
  color: #fff;
  letter-spacing: 1.33px;
}
.rSearchSec {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
  height: 24px;
}

.rSearchSec .ant-input-group-wrapper {
  width: 182px !important;
}

.rSearchSec .ant-input-group .ant-input {
  padding-left: 35px;
  background: transparent;
  border-radius: 12px;
  border: 1px solid #b6b6b7;
  height: 24px;
  width: 182px;
  color: #fff;
}
.RoutineContainer .rSearchSec .ant-input:hover,
.RoutineContainer .rSearchSec .ant-input:focus,
.RoutineContainer .rSearchSec .ant-input:focus-visible {
  border: 1px solid #8595ca !important;
  border-color: #8595ca !important;
}
.rSearchSec .ant-btn {
  border: none;
  border-radius: 20px;
  background: transparent;
}
.rSearchSec .ant-input-group-addon {
  background: none;
  width: 0;
}

.rSearchSec .anticon-search {
  display: flex !important;
  align-items: center !important;
  color: #fff;
}
.rSearchSec .ant-input-search-button {
  height: 24px;
}
.RoutineContainer .rSearchSec .anticon-search {
  display: flex !important;
  align-items: center !important;
  background-color: transparent !important;
}
.rSearchSec .ant-btn-icon-only > * {
  font-size: 13px;
}
.rPopup span.anticon.anticon-close.ant-modal-close-icon {
  color: #fff;
}

.rBodySec {
  background: #fff;
  border: 1px solid #707070;
  min-height: 200px;
  display: block;
  border-radius: 0 0 12px 12px;
}
.rBodyWrapper {
  /* padding: 40px 30px 40px 40px; */
  padding: 40px 25px 40px 25px;
  /* height: 520px; */
  /* height: 400px;
  overflow-y: auto; */
}
.rRowItem {
  display: flex;
  /* min-height: 103px; */
  align-items: center;
  justify-content: space-between;
  /* cursor: pointer; */
}
.rDateUser {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  min-width: 350px;
  max-width: 350px;
}
.rduTxt {
  font-size: 9px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #58595b;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  /* margin-right: 10px; */
  margin-right: 5px;
}
.rduREDTxt {
  color: #d33c39 !important;
}
.rNamesSec {
  font-size: 16px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #000;
  letter-spacing: 1.28px;
}
.rImpSec {
  display: flex;
  align-items: center;
}
.rImpName {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #000;
  letter-spacing: 0.25px;
}
.m15 {
  margin-bottom: 15px;
}
.mt8 {
  margin-top: 8px !important;
}
.rImageSec {
  display: flex;
  align-items: center;
}

.cameras_down {
  background: #f3ccc912;
  border: 1px solid #d33c39;
  border-radius: 5px 5px 0 0;
  text-align: center;
  height: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0;
}
.cameras_down img {
  width: 85px;
}
.cameras_down h6 {
  color: #333333;
  text-transform: uppercase;
  margin: 0;
  margin-top: 18px !important;
}
.adrTractorSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adrTractorSec img {
  margin-right: 8px;
}
.adrImplementName {
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.13px;
  text-transform: uppercase;
  color: #fff;
}
.mb10 {
  margin-bottom: 10px;
}
.rrBy {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.adrouteName {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.adrImpleName {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.adrImpName {
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.19px;
}
.adrDuration {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: 120px;
}
.adrFreqency {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: 120px;
}
.adrDvalue {
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 1.03px;
}
.adrDLabel {
  font-size: 8px;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.13px;
  color: #959595;
}
.adrSelectBtn,
.adrSelectBtn:focus {
  border: none;
  background: #ec9322;
  outline: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 1.07px;
  width: 204px;
  height: 35px;
  cursor: pointer;
}
.seePathSec {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 10px;
}

.adRoutinesBlock {
  border-bottom: 1px solid #cececf;
  padding: 0 0px;
  min-height: 103px;
  margin-bottom: 20px;
  padding-left: 20px;
}
.adRoutinesRedBlock {
  /* border-left: 4px solid #d33c39 !important; */
  padding: 0 0px;
  min-height: 103px;
  margin-bottom: 20px;
  padding-left: 15px !important;
}
.seePathSec .ant-collapse-header {
  border: none !important;
  padding: 0 !important;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.95px;
  background: none !important;
  color: #959595 !important;
}
.seePathSec .ant-collapse-content,
.seePathSec .ant-collapse-item {
  border: none !important;
}

.seePathSec .ant-collapse {
  background: none !important;
}
.seePathSec .anticon svg {
  transform: rotate(90deg);
}
.seePathSec .ant-collapse-item-active svg {
  transform: rotate(270deg) !important;
}
.seePathSec .ant-collapse-content-box {
  padding: 10px 0 0 0;
}

.seePathSec .posHd {
  width: 100% !important;
}

.adrSelBtn {
  min-width: 105px;
  display: flex;
  align-items: center;
  /* flex-direction: column-reverse; */
  gap: 10px;
}
