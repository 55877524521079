.autoDriveContainer {
  margin: 50px 18px 0 18px;
  position: relative;
}

.autoDriveContainer .mapZoomBG {
  height: 83px;
  top: 0;
}

.autoDriveContainer .autoMapZoomBG {
  background: url('../../assets/images/autoMapZoomBG.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 212px;
  height: 44px;
  position: absolute;
  display: flex;
  left: calc(50% - 106px);
}

.autoDriveWrapper {
  margin: 0;
  padding: 0;
  border: 2px solid #6d6e71;
  border-radius: 15px;
  height: 531px;
  position: relative;
}

.autoDriveWrapper .cardBox,
.autoDriveWrapper .cardBox .cardBody {
  height: 527px !important;
  border-radius: 12px;
}
.cardBox {
  width: 100%;
  display: inline-block;
  height: auto;
  overflow: hidden;
}
.autoDrivemapHight .cardBox,
.autoDrivemapHight .mapViewH {
  height: 100% !important;
  border: 0;
  top: 0;
}
.cardBody {
  position: relative;
  height: 485px;
}
.autoDrivemapHight {
  height: 527px;
  border-radius: 15px;
}
.autoDrivemapHight .mapIconsLeft {
  left: 0;
  top: 0;
}
.autoDrivemapHight .mapIconsRight {
  right: 0;
  top: 0;
  width: 42px;
  height: 42px;
}
.autoDrivemapHight .mapIconsRight img {
  right: 0;
  top: 22px;
  transform: translate3d(-22px, 22px, 10px);
}

.mapIconsLeft {
  position: absolute;
  top: 38px;
  z-index: 500;
  left: 18px;
}
.mapIconsRight {
  position: absolute;
  top: 16px;
  z-index: 500;
  right: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
}
.mapIconsRight img {
  transform: translate3d(24px, 24px, 10px);
}
.mapViewH {
  height: 398px !important;
  border: 1px solid #d1d3d4;
  box-shadow: 1px 1px 4px #f8f9fb;
  display: flex;
  position: relative;
  top: -6px;
}
.autoZoomBtnSec {
  width: 100%;
  height: 83px;
  display: flex;
  /* // align-items: center; */
  justify-content: space-between;
  position: relative;
}
.autoBackBtn {
  border-radius: 7px;
  width: 152px;
  height: 52px;
  text-align: center;
  margin-top: 15px;
  background: #b6b6b7;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  position: absolute;
  left: 0;
}
.autoBackBtn,
.autoStartBtn,
.autoBackBtn:focus,
.autoStartBtn:focus {
  outline: none;
  border: none;
}
.auto_backArrow {
  background: url('../../assets/images/auto_backArrow.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 18px;
  position: absolute;
  left: 15px;
  top: 17px;
}
.auto_nextArrow {
  background: url('../../assets/images/auto_nextArrow.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 18px;
  position: absolute;
  right: 15px;
  top: 17px;
}
.zoomPlus {
  width: 104px;
  height: 70px;
  display: flex;
  justify-content: center;
  position: relative;
  left: 0;
  top: 4px;
  align-items: center;
  cursor: pointer;
}
.zoomPlus img {
  position: absolute;
  top: 14px;
  right: 54px;
}
.autoMapZoomBG .zoomPlus {
  width: 100px;
  height: 44px;
  top: 0;
  left: 3px;
}
.autoMapZoomBG .zoomPlus img {
  left: 54px;
}
.zoomMinus {
  width: 104px;
  height: 70px;
  display: flex;
  justify-content: center;
  position: relative;
  left: 0;
  top: 4px;
  align-items: center;
  cursor: pointer;
}
.zoomMinus img {
  position: absolute;
  top: 18px;
  right: 30px;
}
.autoMapZoomBG .zoomMinus {
  width: 104px;
  height: 44px;
  top: 0;
  left: 4px;
}
.autoMapZoomBG .zoomMinus img {
  left: 34px;
}

.autoStartBtn {
  border-radius: 7px;
  width: 152px;
  height: 52px;
  text-align: center;
  margin-top: 15px;
  background: #8a9a48;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  position: absolute;
  right: 0;
}
.Routineblk {
  display: flex;
  width: 100%;
  /* align-items: center; */
}
.RoutineCls {
  width: 500px !important;
  overflow-y: auto;
  margin-right: 15px;
  padding-top: 15px;
  background: #e4efea;
  padding-right: 10px;
  height: calc(80vh - 20px);
  position: relative;
}
.routineItem {
  background-color: #fafafa !important;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  border: 1px solid #fff;
}

.routineMap {
  height: calc(100vh - 167px) !important;
  border: 1px solid #d1d3d4;
  box-shadow: 1px 1px 4px #f8f9fb;
  display: flex;
  position: relative;
}
.zoomicon {
  position: absolute !important;
  right: 0 !important;
}
.mapBlk {
  width: 70%;
  position: relative;
}
.cancelButton {
  display: none;
}

.routinePopup {
  width: 90% !important;
  top: 0;
  display: flex;
  /* margin-top: 8%; */
  padding-bottom: 0 !important;
  /* height: 80vh; */
  align-items: center;
}
.routinePopup .ant-modal-content,
.routinePopup .ant-modal-header {
  border-radius: 15px;
}
.routinePopup .ant-modal-footer {
  min-height: 75px;
}
.routinePopup .ant-modal-body {
  padding: 0px 15px !important;
  background: #e4efea;
}
.routinePopup.ant-modal {
  padding-bottom: 0px !important;
}
.Routineblk .ant-input:hover {
  border: 1px solid #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.searchMapBlk .ant-input:focus,
.searchMapBlk .ant-input-focused {
  box-shadow: none;
  border: 1px solid #d9d9d9 !important;
}
.searchMapBlk .ant-input {
  height: 50px !important;
  padding-left: 30px !important;
  border-radius: 8px !important;
}
.Routineblk .searchMapBlk {
  margin-bottom: 10px !important;
}
.Routineblk .anticon-search {
  display: flex !important;
  align-items: center !important;
  background-color: #fff !important;
}

.rRow {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 5px;
}
.rLbl {
  font-size: 14px;
  color: #58595b;
}
.rValue {
  font-size: 14px;
  color: #000;
}

/* Scrollbar width */
.RoutineCls::-webkit-scrollbar {
  width: 5px;
}
/* Scrollbar Track */
.RoutineCls::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Scrollbar Handle */
.RoutineCls::-webkit-scrollbar-thumb {
  background: #959595;
}

/* Scrollbar Handle on hover */
.RoutineCls::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.selectedcls {
  background-color: #fafafa !important;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  border: 1px solid #d0d2d3;
}

.routineStartBtn,
.routineStartBtn:hover,
.routineStartBtn:focus,
.routineStartBtn:active {
  border-radius: 7px;
  width: 152px;
  height: 52px;
  text-align: center;
  background: #8a9a48;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 15px !important;
  border-color: none !important;
  box-shadow: none !important;
  border: none !important;
}

.rColSec .ant-collapse-item {
  background-color: #fafafa !important;
  border-radius: 10px !important;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  cursor: pointer;
  border: 1px solid #fff;
}
.rAuthor {
  width: calc(100% - 200px);
}
.rDate {
  margin-right: 10px;
}
.rhRow1 {
  font-size: 10px;
  font-family: 'Montserrat-Medium', sans-serif;
  text-transform: uppercase;
}
.rhRow2 {
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
  text-transform: uppercase;
}
.rColSec .ant-collapse-content {
  border-top: none;
  background-color: #fafafa !important;
  border-radius: 0 0 10px 10px !important;
}
.rColSec .ant-collapse-item,
.rColSec {
  border: none !important;
  background: transparent;
}

.rColSec .ant-collapse-header {
  padding: 5px 8px !important;
}
.rColSec .rBodySec {
  display: grid;
  grid-template-columns: 50% 50%;
}
.rColSec .rLbl {
  margin-right: 10px;
}
.rColSec .ant-collapse-item-active {
  border: 1px solid #707070 !important;
}

.tStartPopup {
  width: 600px !important;
  top: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.tStartPopup .ant-modal-content,
.tStartPopup .ant-modal-header {
  border-radius: 15px;
}

.tStartPopup .ant-modal-body,
.tStartPopup .ant-modal-content {
  justify-content: center;
  text-align: center;
}
.tStartPopup {
  padding-bottom: 0px !important;
}
.tStartPopup .liveEndBtn:last-child {
  margin-right: 0px;
}

.tStartPopup .resumeBtn {
  border-radius: 7px;
  width: 152px;
  height: 52px;
  text-align: center;
  margin-top: 15px;
  background: #8a9a48;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  margin-right: 30px;
}
.tStartPopup .liveEndBtn {
  border-radius: 7px;
  width: 152px;
  height: 52px;
  text-align: center;
  margin-top: 15px;
  background: #d33c39;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  margin-right: 30px;
}

.routinePopup .ant-modal-content {
  width: 100%;
}
.rSearchSort {
  display: flex;
  align-items: center;
}

.rSearchSort div:first-child {
  margin-right: 10px;
}
.rSearchSort .w6 {
  width: 160%;
}
.rSearchSort .searchMapBlk .ant-input {
  height: 37px !important;
}

.routinePopup .edit-loader {
  position: absolute !important;
  top: 45% !important;
  margin-top: 0px !important;
  height: 100px;
}

/* 14/03/2022 */

.tStartPopupNew {
  width: 90% !important;
  top: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px !important;
  /* padding-bottom: 0px !important;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
}
.tStartPopupNewCont {
  display: flex;
}
.tStartPopupCamera {
  width: 400px;
  margin-right: 20px;
  background: #e4efea;
  border-radius: 15px;
  padding: 22px;
}
.tStartPopupSetting {
  width: calc(100% - 420px);
}
.tCameraSec {
  border: 1px solid #d0d2d3;
  width: 100%;
  height: 356px;
  margin-bottom: 20px;
}

.tStartPopupNew .autoDriveTxt {
  margin-bottom: 25px;
}
.autoDriveTxtNew {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  color: #000;
  margin: 0 0 20px 0;
}
.tStartPopupNew .ant-modal-close {
  top: -10px;
  right: -10px;
}
.tBtnSec {
  display: flex;
  flex-direction: column;
  /* width: 80%; */
  margin: 0 auto;
  align-items: center;
}
.tStartPopupNew .resumeBtn {
  border-radius: 7px;
  width: 152px;
  height: 52px;
  text-align: center;
  margin-bottom: 15px;
  background: #8a9a48;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  margin-right: 0px;
}
.tStartPopupNew .liveEndBtn {
  border-radius: 7px;
  width: 152px;
  height: 52px;
  text-align: center;
  /* margin-top: 15px; */
  background: #d33c39;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  margin-right: 0px;
}
.tStartPopupNew .ant-modal-content {
  border-radius: 15px;
}
.tStartPopupNew .dSetSpeedBg {
  width: 130px;
  height: 130px;
  background-size: 130px;
}
.tStartPopupNew .dsMinusBtn,
.tStartPopupNew .dsPlusBtn,
.tStartPopupNew .settingBoxMinusBtn,
.tStartPopupNew .settingBoxPlusBtn {
  background-size: 45px;
  width: 45px;
  height: 43px;
}
.tStartPopupNew .speedSecNo,
.tStartPopupNew .dsPTOCount {
  font-size: 32px;
}
.tStartPopupNew .speedSec {
  padding: 26px;
}
.tStartPopupNew .settingBoxHeadTxt,
.tStartPopupNew .settingBoxPartnerName {
  font-size: 16px;
}
.tStartPopupNew .driveSettingSpeedSec {
  height: 150px;
}
.tStartPopupNew .settingBoxPtobtnOn,
.tStartPopupNew .settingBoxPtobtnOff,
.tStartPopupNew .inrowTxt,
.tStartPopupNew .dsPTOrpm {
  font-size: 14px;
}
.tStartPopupNew .dsOutrow {
  margin-bottom: 15px;
}
.tStartPopupNew .slider {
  padding: 7px 12px 7px 12px;
}
.tStartPopupNew .driveSettingBox {
  height: 275px;
}
.tStartPopupNew .settingBoxPtobtnOff {
  margin-bottom: 20px;
}
.tStartPopupNew .driveSettingBox {
  width: 50%;
}
.tStartPopupNew .ant-modal-content {
  width: 100%;
}
.tStartPopupNew .driveSettingWrapper {
  position: relative;
}
.tStartPopupNew .setBtn {
  position: absolute;
  right: 22px;
  bottom: 7px;
}

.tStartPopupCameraOnly {
  width: 552px;
  /* margin-right: 20px; */
  background: #e4efea;
  border-radius: 15px;
  padding: 22px;
}
.tStartPopupNewOnly {
  width: 600px !important;
  top: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px !important;
}

.tStartPopupNewOnly .resumeBtn {
  border-radius: 7px;
  width: 152px;
  height: 52px;
  text-align: center;
  margin-bottom: 15px;
  background: #8a9a48;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  margin-right: 0px;
}
.tStartPopupNewOnly .liveEndBtn {
  border-radius: 7px;
  width: 152px;
  height: 52px;
  text-align: center;
  /* margin-top: 15px; */
  background: #d33c39;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  margin-right: 0px;
}

.tStartPopupNew
  .tCameraSec
  .ant-spin-nested-loading
  .tStartPopupNewOnly
  .tCameraSec
  .ant-spin-nested-loading {
  position: relative;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.tStartPopupNewOnly .ant-modal-content,
.tStartPopupNewOnly .ant-modal-header {
  border-radius: 15px;
}

div.ant-image-error {
  overflow: hidden;
}

div.ant-image-error img[alt] {
  margin-left: -16px;
  text-indent: 16px;
}

.tBtnSecOnly {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}
.tBtnSecOnly button:first-child {
  margin-right: 20px;
}

img.inline-icon.rotate90 {
  transform: rotate(90deg);
  height: 18px;
}
img.inline-icon.rotatei90 {
  transform: rotate(-90deg);
  height: 18px;
}

.routineName {
  position: absolute;
  left: 530px;
  top: 15px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  color: #67afb7;
}

.rNameHeadSec {
  position: absolute;
  right: 16px;
  padding: 0px;
  min-width: 150px;
  height: 175px;
  overflow-y: auto;
  z-index: 99;
  top: 20px;
  /* padding-right: 5px; */
  display: inline-block;
}

.rNameHead {
  border-radius: 60px;
  width: 100%;
  height: 40px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  background: rgba(242, 232, 221, 0.8);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) inset;
  cursor: pointer;
  margin-bottom: 5px;
}
.rNameLbl {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  color: #000;
  margin-right: 10px;
}

.sViewPos {
  right: 190px !important;
}

.mapRecenterR {
  cursor: pointer;
  text-align: center;
  background-color: #f7f7f7;
  position: absolute;
  margin: 0px 20px 0px 10px;
  border-radius: 50%;
  top: -40px;
  width: 40px;
  height: 40px;
}

.rt-align {
  right: 165px;
}

#videos .panel-body {
  border: 1px solid black;
  height: 360px;
}

#videos {
  display: flex;
}

#videos .row {
  flex: 1;
  margin-right: 10px;
}

.hide {
  height: 0px;
}

.routines-empty-block {
  height: -webkit-fill-available;
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
}
.routines-empty-block > div {
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  color: gray;
}

.cv-open {
  transition: 0.25s;
  transition-timing-function: ease-out;
  transform: translateY(0);
  display: block;
}

.cv-close {
  transform: translateY(-130%);
  transition: 0.2s;
  transition-timing-function: ease-in;
  display: none;
}

.alert {
  opacity: 0;
  top: 0;
  height: 0;
}

.live-stream-container {
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.t-connect img {
  margin: 0 auto;
}

.rNewOperation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #b6b6b7;
  margin-top: 36px;
}
.rNewOperation .newOprBtn {
  cursor: pointer;
  font-size: 15px;
  font-family: 'Montserrat-Bold', sans-serif;
  letter-spacing: 1.61px;
  line-height: 19px;
  text-transform: uppercase;
  background-color: #366376;
  color: #fff;
  padding: 9px 30px;
  display: inline-block;
  border-radius: 6px;
  width: 306px;
  margin: auto;
}
.rNewOperation .newOprBtn:hover {
  background-color: #1e3a46;
}
.rNewOperation .newOprTxt {
  color: #959595;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 0.84px;
  line-height: 15px;
  display: inline-block;
  margin-top: 23px;
  margin-bottom: 20px;
}

.rNewPopoup.rPopup {
  width: 825px !important;
}
.adSeedNameBlk {
  display: flex;
  align-items: center;
}
.adSeedName {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 1.03px;
}
