.elTabs .ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  /* font-size: 14px; */
  background: 0 0;
  border: 0;
  outline: none;
  padding: 8px 8px;
  cursor: pointer;
}
.elTabs .ant-tabs-ink-bar {
  position: absolute;
  background: #67afb7;
  pointer-events: none;
  min-height: 3px;
}
.elTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000;
  text-shadow: 0 0 0.25px currentColor;
  z-index: 1;
}
/* .elTabs .ant-tabs-nav {
  height: 94px;
} */
.elTabs .ant-tabs-nav-wrap {
  padding-left: 30px;
  padding-right: 30px;
  align-items: flex-end;
  justify-content: center;
  /* padding-bottom: 20px; */
}
/*
.elTabs .ant-tabs-nav {
height: 94px;
border-top: 200px solid #DBDBDB;
border-left: 100px solid transparent;
border-right: 100px solid transparent;
} */
.elTabs > .ant-tabs-nav {
  /* height: 111px; */
  border-bottom: 1px solid #e7e7e7;
  width: auto;
  /* margin-bottom: 20px; */
}

.elTabs .ant-tabs-nav::before {
  border-bottom: none !important;
}

.padt0 .ant-card-body {
  padding-top: 0px !important;
}
/* .elTabs .ant-tabs-tab-btn {
  text-transform: uppercase;
}
.elTabs .ant-tabs-tab:hover{
  color: #959595 !important;
  font-family: Montserrat-SemiBold;
}
.elTabs .ant-tabs-tab, .elTabs .ant-tabs-tab-btn:active{
  color: #959595 !important;
  font-family: Montserrat-SemiBold;
} */

.dftBtn {
  background: #fff;
}
.ant-card-body {
  padding: 0px !important;
}
.elTabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 8.35vw;
}

.elTabs .ant-tabs-tab.ant-tabs-tab-disabled {
  color: #cececf !important;
  cursor: initial;
}

.save_settings_chk {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.save_settings_chk label {
  position: inherit;
  display: flex;
  align-items: center;
}

.save_settings_chk input {
  background-color: #fff;
  border-color: #366376;
  width: 22px;
  height: 22px;
  border-radius: 2px;
}

.checkBoxItem3 {
  padding: 10px 12px 10px 0px;
  margin-bottom: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  /* margin-left: 30px; */
}
.checkBoxItem3 .ant-checkbox-input {
  background-color: #fff;
  border-color: #366376;
  width: 16px;
  height: 16px;
  border-radius: 2px;
}
.checkBoxItem3 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff;
  border-color: #366376;
  width: 16px !important;
  height: 16px !important;
  border-radius: 2px;
}
.checkBoxItem3 .ant-checkbox-inner {
  background-color: #fff;
  border-color: #366376;
  width: 16px !important;
  height: 16px !important;
  border-radius: 2px;
  margin-top: -3px;
}
.checkBoxItem3 .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #1e3a46;
  transform: rotate(45deg) scale(0.85) translate(-50%, -50%);
}

.save_settings_chk .checkBoxItem3 .ant-checkbox {
  height: 16px;
  width: 16px;
}
.checkBoxItem3 label {
  pointer-events: unset !important;
}
