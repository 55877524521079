@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  line-height: normal;
  font-family: 'Montserrat', sans-serif !important;
}

:root {
  /* fonts */
  --headline-h5: Montserrat;

  /* font sizes */
  --headline-h5-size: 12px;
  --iosweb-status-2-size: 8px;
  --body-3-size: 10px;
  --headline-h3-size: 16px;
  --headline-h1-size: 24px;
  --iosweb-headline-h4-size: 14px;
  --headline-h2-size: 20px;

  /* Colors */
  --neutrals-ffffff: #fff;
  --neutrals-cfcfcf: #cfcfcf;
  --neutrals-474747: #474747;
  --primary-deepteal-366376: #366376;
  --neutrals-333333: #333;
  --primary-deepteal-1e3a46: #1e3a46;
  --color-darkslategray-100: rgba(54, 99, 118, 0.2);
  --accents-red-d43d38: #d43d38;
  --color-firebrick-100: rgba(212, 61, 56, 0.16);
  --primary-techblue-67afb7: #67afb7;
  --neutrals-b5b5b6: #b5b5b6;
  --neutrals-dddddd: #ddd;
  --neutrals-f7f7f7: #f7f7f7;
  --neutrals-E8E8E8: #e8e8e8;
  --neutrals-eeeeee: #eee;
  --accents-violet-546cb2: #546cb2;
  --hover-title: rgba(84, 108, 178, 0.6);
  --hover-line: rgba(84, 108, 178, 0.2);
  --neutrals-1f1f1f: #1f1f1f;
  --color-gray-100: rgba(255, 255, 255, 0.6);
  --color-gray-247: rgba(247, 247, 247, 0.6);
  --neutrals-5c5c5c: #5c5c5c;
  --color-dimgray-100: rgba(92, 92, 92, 0.8);
  --neutrals-99999c: #99999c;
  --color-darkgray-100: #959595;
  --color-black: #000;
  --color-forestgreen-100: #379526;
  --color-forestgreen-200: rgba(55, 149, 38, 0.12);
  --f1ce90: #f1ce90;
  --ec9322: #ec9322;
  --007AFF: #007aff;
  --EB922305: rgba(235, 146, 35, 0.05);
  --EB922325: rgba(235, 146, 35, 0.25);
  --EB922375: rgba(235, 146, 35, 0.75);

  /* Gaps */
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-3xs: 10px;
  --gap-11xs: 2px;
  --gap-xl: 20px;
  --gap-6xs: 6px;
  --gap-4xs: 9px;
  --gap-7xs: 7px;
  --gap-6xl: 25px;
  --gap-9xs: 4px;
  --gap-xs: 12px;
  --gap-11xs-5: 1.5px;
  --gap-sm: 14px;
  --gap-17xs: 17px;
  --gap-16xs: 16px;

  /* Paddings */
  --padding-mid: 17px;
  --padding-29xl: 48px;
  --padding-8xs: 5px;
  --padding-9xs: 4px;
  --padding-base: 16px;
  --padding-sm: 14px;
  --padding-mini: 15px;
  --padding-lg: 18px;
  --padding-4xs: 9px;
  --padding-4xl: 23px;
  --padding-11xs: 2px;
  --padding-7xs: 6px;
  --padding-base-5: 16.5px;
  --padding-6xs: 7px;
  --padding-3xs: 10px;
  --padding-5xs: 8px;
  --padding-xs: 12px;
  --padding-2xs: 11px;
  --padding-14xl: 33px;
  --padding-12xs: 1px;
  --padding-10xs: 3px;
  --padding-34xl: 53px;
  --padding-smi: 13px;
  --padding-sm-5: 13.5px;
  --padding-lgi: 19px;

  /* Border radiuses */
  --br-7xs: 6px;
  --br-3xs: 10px;
  --br-5xs: 8px;
  --br-9xs: 4px;
  --br-xs: 12px;
  --br-10xs: 3px;
  --br-8xs: 5px;
  --br-smi-5: 12.5px;

  /* Effects */
  --level-1: 0px 2px 6px rgba(0, 0, 0, 0.16);
  --level-2: 0px 4px 6px rgba(0, 0, 0, 0.1);
  --level-11: 0px 1px 6px rgba(0, 0, 0, 0.1);
}
