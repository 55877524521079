.ad_posrel {
  position: relative;
}

.ad_cards_Blk {
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 9;
  width: 358px !important;
}

.ad-site-layout .ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 24px;
  left: -50px;
  min-width: 150px;
}
.ad_searchMapBlk {
  position: relative;
  margin-bottom: 20px !important;
}

.ad_cards_Blk .ad_searchMapBlk .ant-input {
  width: 100%;
  height: 44px !important;
  border-radius: 5px !important;
  padding-left: 49px !important;
  backdrop-filter: blur(1px) brightness(54%) grayscale(0%);
  -webkit-backdrop-filter: blur(1px) brightness(54%) grayscale(0%);
  background: none !important;
  border: none !important;
  color: #e7e7e7;
}

.ad_cards_Blk .ad_searchMapBlk .anticon-search {
  position: absolute;
  left: 15px;
  z-index: 1;
  top: 50% !important;
  color: #fff;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
}
.ad_cards_Blk .ad_searchMapBlk input.fListSearch::placeholder {
  font-family: 'Montserrat-Medium' !important;
  font-size: 14px !important;
  letter-spacing: 0.1px;
  line-height: 18px !important;
  color: #e7e7e7 !important;
}

.ad_searchMapBlk .anticon-search {
  position: absolute;
  left: 10px;
  z-index: 1;
  top: 40%;
  color: #939393;
  width: 12px;
  height: 12px;
}
.ad-site-layout .ad_searchMapBlk .ant-select {
  width: 100%;
}

.ad-site-layout {
  background: #f7f7f7;
}

.ad_searchMapBlk .ant-input:focus,
.ad_searchMapBlk .ant-input-focused {
  box-shadow: none;
  border: none;
  background: none;
}

.ad_cards_Blk .ad_searchMapBlk.ad_searchMain input.fListSearch::placeholder {
  font-family: 'Montserrat-Medium' !important;
  font-size: 14px !important;
  letter-spacing: 0.1px;
  line-height: 18px !important;
  color: #e7e7e7 !important;
}

.ad_operationsBlk {
  width: 100%;
}
.ad_operationsBlk .ant-collapse {
  border: none !important;
}

.ad_parentAccordion {
  background-color: #e7e7e7;
  border-radius: 10px;
}

.ad_parentAccordion .ant-collapse-content-box {
  background-color: #e7e7e7;
  border-radius: 0px 0px 10px 10px;
  padding: 0;
  padding-bottom: 1px;
}
.ad_parentAccordion .ant-collapse-item:last-child > .ant-collapse-content {
  background: none !important;
  border-top: none;
}
.ad_parentAccordion .ant-collapse-item {
  border-bottom: none;
}

.ad_cards_Blk .ad_parentAccordion .ad_cardBlk_cont {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 6px !important;
}

.ad_parentAccordion .ad_cardBlk {
  width: 100%;
  background: #ffffff;
  border: 1px solid #cececf;
  border-radius: 10px !important;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 12px 18px 8px 14px;
  cursor: pointer;
  min-height: 92px;
  max-height: 97px;
  box-sizing: border-box;
  position: relative;
  /* width: 326px; */
}
.ad_parentAccordion .ad_cardBlk:hover,
.ad_cards_Blk .imp_tractor_card:hover,
.ad_parentAccordion .ad_cardBlk.selected {
  border: 1px solid #366376;
}

.ad_operationsBlk .operationsTitle .ant-collapse-header {
  /* color: #99999C !important; */
  color: #333333 !important;
  font-size: 12px !important;
  font-family: 'Montserrat-Medium' !important;
  letter-spacing: 1.07px !important;
  line-height: 15px !important;
  height: auto !important;
  padding: 16px 16px 15px 16px !important;
  text-transform: uppercase !important;
}
.ad_cardBlk:first-child {
  margin-top: 0px;
}

.ad_card_row {
  display: flex;
}
.vineyardColor {
  background: #6d1872 !important;
}
.dairyColor {
  background: #019eee !important;
}
.ad_org_title {
  margin-right: 12px;
  width: 50px;
  height: 50px;
  background: #1f3a46;
  /* border: 3px solid #508a95; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-family: Montserrat-Bold;
  text-transform: uppercase;
  border-radius: 80px;
}
.ad_org_details {
  width: calc(100% - 82px);
}
.ad_org_name {
  font-family: Montserrat-Medium;
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
  line-height: 18px;
}
.ad_org_location {
  font-family: Montserrat-Regular;
  font-size: 11px;
  color: #aeaebb;
  margin-bottom: 5px;
  line-height: 14px;
}
.ad_org_country {
  font-family: Montserrat-Regular;
  font-size: 11px;
  color: #000;
  margin-bottom: 5px;
  line-height: 14px;
}

.ad_locate_icon {
  display: flex;
  align-items: center;
}

.ad_cardBlk_cont {
  min-height: 240px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  position: relative;
}

.ad_RightSec {
  position: fixed;
  right: 0;
  top: 0px;
  width: 482px;
  /* width: 339px; */
  height: 100vh;
  z-index: 999;
  box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.29);
  /* transition: 1s; */
  background: #fff;
  /* padding: 32px 26px; */
}
.impact_side_head {
  font-size: 20px;
  font-family: Montserrat-Medium;
  color: #366376;
  text-transform: uppercase;
  margin-bottom: 16px;
  padding: 32px 26px 0px 26px;
}
.impact_cards_sec {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.impact_cards_sec .card {
  background: #f8f9f9;
  border-radius: 6px;
  border: 1px solid #8cc1c7;
  height: 90px;
  padding: 16px;
}

.imp_card_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
.card_name {
  font-size: 12px;
  font-family: Montserrat-Medium;
  color: #a5a5a5;
  line-height: 32px;
}
.card_value {
  font-size: 24px;
  font-family: Montserrat-SemiBold;
  color: #333333;
  line-height: 29px;
}
.imp_card_row div img {
  width: 32px;
  height: 32px;
}
.imp_close_cross {
  position: absolute;
  top: 12px;
  right: 28px;
  cursor: pointer;
}
.mb20 {
  margin-bottom: 20px;
}
.imp_block_head {
  font-size: 16px;
  font-family: Montserrat-SemiBold;
  color: #000;
  line-height: 19px;
}
.imp_graph {
  display: flex;
  align-items: center;
  justify-content: center;
}
.imp_block_one {
  padding: 0px 26px 0 26px;
}
.imp_block_two {
  padding: 24px 26px 0 26px;
}

.imp_bdr {
  border-bottom: 1px solid #ebebeb;
}
.imp_scroll {
  height: calc(100vh - 100px);
  overflow-y: auto;
  margin-right: 5px;
  padding-right: -5px;
}
.imp_tractor_card {
  border: 1px solid #cececf;
  border-radius: 10px;
  padding: 14px 16px;
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
}
.imp_t_name_buble {
  border-radius: 100px;
  background: #f0f0f0;
  border: 1px solid #dedddd;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  color: #4d4d4d;
  margin-right: 15px;
}
.imp_t_name_desc {
  width: calc(100% - 140px);
}
.imp_tname {
  text-transform: uppercase;
  font-size: 14px;
  font-family: Montserrat-Medium;
  color: #4d4d4d;
  line-height: 18px;
  margin-bottom: 3px;
  word-break: break-word;
  width: calc(100% - 10px);
}
.imp_toperator {
  font-size: 8px;
  font-family: Montserrat-Regular;
  color: #333333;
  line-height: 10px;
}

.imp_t_status {
  font-size: 8px;
  font-family: Montserrat-SemiBold;
  color: #f7f7f7;
  line-height: 10px;
  border-radius: 12px;
  min-width: 85px;
  height: 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg_manual {
  background-color: #67afb7;
}
.bg_available {
  background-color: #3e4f7c;
}
.bg_charging {
  background-color: #a9bf51;
}
.bg_off {
  background-color: #cececf;
}

.admin_t_available {
  background: #343b5e;
}

.admin_t_service {
  background: #cfcfcf;
}

.admin_t_off {
  background: #cfcfcf;
}

.admin_t_faulty {
  background: #d33c39;
}

.admin_t_routines {
  background: #ec9322;
}

.admin_t_charging {
  background: #a9bf51;
}

.admin_t_manual {
  background: #67afb7;
}

.admin_t_copycat {
  background: #ec9322;
}

.admin_t_follow_me {
  background: #67afb7;
}

.admin_t_vinerow {
  background: #67afb7;
}

.admin_t_remote_av {
  background: #ec9322;
}

.adTabsContentSec1 {
  max-height: unset !important;
}

.rautoDriveRightSecWidth {
  /* width: 484px !important; */
  padding: 0px !important;
  z-index: 10;
}

/* rightside widgets styles responsive */

.ad_RightSec {
  position: fixed;
  right: 0;
  top: 0px;
  width: 482px;
  height: 100vh;
  z-index: 999;
  box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.29);
  background: #fff;
  /* padding: 35px; */
}

@media (min-width: 1681px) and (max-width: 1920px) {
  .ad_RightSec {
    width: 482px !important;
  }

  .impact_side_head {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .imp_block_one {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .imp_block_two {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media (min-width: 1441px) and (max-width: 1680px) {
  .ad_RightSec {
    width: 422px !important;
  }

  .impact_side_head {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .imp_block_one {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .imp_block_two {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

@media (min-width: 1367px) and (max-width: 1440px) {
  .ad_RightSec {
    width: 362px !important;
  }

  .impact_side_head {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .imp_block_one {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .imp_block_two {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  .ad_RightSec {
    width: 339px !important;
  }

  .impact_side_head {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .imp_block_one {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .imp_block_two {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .ad_RightSec {
    width: 339px !important;
  }

  .impact_side_head {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .imp_block_one {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .imp_block_two {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ad_RightSec {
    width: 339px !important;
  }

  .impact_side_head {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .imp_block_one {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .imp_block_two {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.ad_operationsBlk .ant-collapse-header {
  cursor: pointer !important;
}

.impactFilters .ant-picker-input > input {
  font-size: 10px;
}
.ad_filters_card .float-label input {
  background: none !important;
  border: none !important;
}

/* map zoom in, out buttons css styles */

.ad_mapIconSec {
  width: 60px;
  position: absolute;
  right: 0px;
  height: calc(100% - 180px);
  margin-top: 150px;
  transition: 1s;
  bottom: 30px;
}
.ad_mapIconSec.ad_map_expand {
  right: 400px !important;
}

.ad_mapIcons {
  position: absolute;
  right: 30px;
  bottom: 35px;
  z-index: 999;
}

.ad_mapRecenter {
  margin-left: 8px;
  position: relative;
  top: -35px;
  cursor: pointer;
}

.ad_mapRecenter img {
  width: 32px;
}

.ad_zoom_in_out {
  position: relative;
  bottom: 25px;
  left: 8px;
}

.ad_zoom_in_out {
  background-image: url(../../../assets/images/z_in_out.svg);
  width: 32px;
  height: 74px;
  background-repeat: no-repeat;
}

.ad_liveZoomIn {
  background: transparent;
  width: 32px;
  height: 32px;
  position: relative;
  left: 0px;
  border: none;
  top: 0px;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.ad_liveZoomOut {
  background: transparent;
  width: 32px;
  height: 32px;
  position: relative;
  left: 0px;
  border: none;
  top: 4px;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.ad_mapView {
  position: relative;
  right: -7px;
  top: 0;
  z-index: 1;
  cursor: pointer;
}

.ad_mapView img {
  width: 32px;
}
.refreshAlign {
  cursor: pointer;
}

.ad_layers .checkBoxItem.ml50 {
  margin: 0px !important;
}

.bottomMapLayers.mpOpen.ad_layers {
  width: 360px;
  height: 330px;
}

.bottomMapLayers.mpOpen.ad_layers .dataLayerSec {
  height: 330px;
}

.t_tractor_icon_layer {
  width: 20px;
  height: 20px;
}

.t_org_icon_layer {
  width: 16px;
  height: 20px;
}
.adminMapLayerIcon {
  z-index: 999 !important;
}
