.description {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.description-image {
  width: 10%;
}

.description-text {
  width: 100%;
  margin-left: 5px; 
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  /* font-family: 'Montserrat-Regular'; */
}

.fleetBlock .ant-space {
  flex-wrap: wrap;
  margin-left: 0 !important;
  grid-gap: 0px !important;
  gap: 12px !important;
}
