/* Date Picker CSS Starts */
.hideDatePicker {
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0 !important;
}

.date-picker {
  height: 26px;
  width: 26px;
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: var(--iosweb-headline-h4-size);
  border-radius: var(--br-9xs);
  background-color: var(--neutrals-ffffff);
  box-shadow: var(--level-11);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

// .calendarIcon {
//   width: 10px;
//   height: 10px;
// }

.dpIcon {
  background-image: url(../../../components/operationalAnalytics/images/taskplanner-outline01.svg);
  width: 14px;
  height: 14px;
  display: block;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-position: -1px 0px;
}
.dpIcon:hover {
  background-image: url(../../../components/operationalAnalytics/images/taskplanner_outline-hover.svg);
  width: 14px;
  height: 14px;
  display: block;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-position: -1px 0px;
}
.dpIconhover {
  background-image: url(../../../components/operationalAnalytics/images/taskplanner_outline-sel.svg);
  width: 14px;
  height: 14px;
  display: block;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-position: -1px 0px;
}
.dhover {
  background-color: #c8d1d5 !important;
}

div#dateRangePicker {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datepickerBtn {
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  font-size: 10px;
  background: var(--neutrals-f7f7f7);
  color: var(--neutrals-b5b5b6);
  border: none !important;
  border-radius: 6px !important;
  cursor: pointer;
}

.datepickerBtn:hover {
  background-color: #d6e0e3;
  color: var(--primary-deepteal-366376);
}

.datepickerBtn.datepickerBtnSubmit {
  background: var(--primary-deepteal-366376);
  color: white;
}

.datepickerBtn.datepickerBtnSubmit:hover {
  background: var(--primary-deepteal-366376);
  color: white;
}

/* Date Picker CSS Ends */

// [ant-click-animating-without-extra-node='true'] {
//   /* Your styles here */
//   /* For example: */
//   background-color: #eeeeee !important;
//   color: #366376 !important;
//   font-size: 10px !important;
//   font-weight: 600 !important;
// }
// [ant-click-animating-without-extra-node="false"] {
//   /* Your styles here */
//   /* For example: */
//   border: none !important;
// }

.singleMonth .ant-picker-panel-container,
.doubleMonth .ant-picker-panel-container {
  border-radius: 0px 0px 8px 8px !important;
  border-top: 2px solid #366376;
  margin-left: -5px !important;
  margin-top: 5px;
}

.singleMonth .ant-picker-date-panel {
  width: 303px;
}

.date-picker div#datePicker {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-picker-footer-extra {
  padding: 8px;
}

.scDatepicker .ant-picker-input > input {
  width: 44px !important;
}
.scDatepicker .ant-picker-range .ant-picker-active-bar {
  width: 39px !important;
}

.scDatepicker .ant-picker-range .ant-picker-active-bar {
  margin-left: 0px !important;
}

.scDatepicker .ant-picker-range .ant-picker-clear {
  right: -25px !important;
}
.scDatepicker .ant-picker-range-separator {
  padding: 0 4px !important;
}
.hideRangeSeparator .ant-picker-range-separator {
  display: none;
}

.searchBarDate .ant-picker-panel-container {
  border-radius: 20px !important;
  margin-left: -50px !important;
  margin-top: 15px !important;
  border-top: none !important;
}

/* new date picker styles goes here ************** */

.date-picker-block {
  background-color: white;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  box-shadow: 0px 1px 6px #0000001a;
  border-top: 2px solid #366376;
  padding: 12px 16px 16px 16px;
}
.react-datepicker {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}
.react-datepicker {
  width: 271px !important;
  border: none !important;
}
.react-datepicker__navigation {
  width: 25px;
  height: 25px;
}
.react-datepicker__week {
  display: flex;
  gap: 16px;
  margin-bottom: 5px;
}
.react-datepicker__month {
  margin: 0px !important;
}
.react-datepicker__current-month {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}
.date-picker-block .react-datepicker__header {
  background-color: #fff !important;
  padding: 0px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-width: 1.5px 1.5px 0 0 !important;
  border-color: #99999c !important;
}
.ant-space-align-center {
  // align-items: flex-start;
  // width: 100%;
}
.datepickerBtn {
  height: 24px;
  font-size: 10px;
  font-weight: 600;
  color: #b5b5b6;
  background: #f7f7f7;
  border-radius: 6px !important;
}

.datepickerBtn:active,
.datepickerBtn:hover {
  background: #eeeeee;
  color: #366376;
}
.date-picker-block .react-datepicker__header {
  border: none !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover,
.react-datepicker__day:hover {
  background-color: #366376;
  border-radius: 50%;
}
.react-datepicker__day--keyboard-selected {
  color: #fff;
}

.react-datepicker__day-names {
  gap: 16px;
  display: flex;
  margin-bottom: 5px !important;
}
.react-datepicker__day,
.react-datepicker__time-name {
  width: 25px !important;
  height: 25px !important;
  box-sizing: border-box;
  margin: inherit;
}
.react-datepicker__day-name {
  width: 25px !important;
  height: 17px !important;
  margin: 0px;
  line-height: inherit !important;
  color: #99999c;
}
