.fleetStatusFilterIcons {
  display: flex;
  margin-left: auto;
  gap: 8px;
}

.orgTractorUsageReportTable .ant-table-summary > tr > td {
  padding: 12px 12px !important;
}

.fleetStatusFilterIcons {
  display: flex;
  margin-left: auto;
  gap: 8px;
}
.drivingHoursSection {
  margin: 0px 0px 16px;
  border-bottom: 1px solid #d9d9d9;
}

.csatDashboard  {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
}
.csatDashboard > .drivingHours  {
  display: grid;
  grid-template-columns: auto auto auto;
}
.csatDashboard > .trendingData  {
  display: grid;
  grid-template-columns: auto auto;
}
.bold {
  font-weight: bold;
}
.text-center{
  text-align: center;
}
