@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-picker-content th,
.ant-picker-content td {
  text-align: center;
}

/* .bNone .elTabs .ant-tabs-content {
  margin-top: 10px !important;
} */

.bNone,
.bNone > .ant-card-bordered {
  border: none !important;
}

.attContainer {
  height: calc(100vh - 0px);
}

.attContainer .elTabs {
  height: calc(100vh - 0px);
}

/* .attContainer .elTabs .ant-tabs-content-holder,
.attContainer .elTabs .ant-tabs-content {
  height: calc(100vh - 100px) !important;
} */

.mt5.tcktsBlk,
.tblTopOutSec.mt5 {
  margin-top: 5px !important;
}

.mb40.mt5 {
  margin-bottom: 4vh !important;
}

.ant-picker-range.dropdownStyle {
  border: none !important;
}

.fi table {
  table-layout: inherit !important;
}

@media (max-width: 1440px) {
  /* .dsTbl.farmTabsTbl .infinite-scroll-component {
    height: calc(100vh - 62vh);
    max-height: calc(100vh - 62vh);
    min-height: calc(100vh - 62vh);
  } */
  /* .tblDft.dsTbl {
    height: calc(100vh - 58vh);
    max-height: calc(100vh - 58vh);
    min-height: calc(100vh - 58vh);
  } */
  /* .dsTbl div#empTbl {
    height: calc(100vh - 62vh);
    max-height: calc(100vh - 62vh);
    min-height: calc(100vh - 62vh);
  } */
  /* div#empTbl {
    min-height: calc(100vh - 38vh);
  } */
  /* .reportsTbl div#empTbl {
    min-height: calc(100vh - 38vh);
  } */
  /* .viewportContainer .tblDft,
  .tblDft {
    max-height: calc(100vh - 313px) !important;
  } */
  /* .addButton {
    bottom: 12vh !important;
  } */
  .lbCont .ant-tabs-top {
    height: calc(100vh - 15vh);
    max-height: calc(100vh - 15vh);
    min-height: calc(100vh - 15vh);
  }
  /* .lbTbls div#empTbl {
    min-height: calc(100vh - 42vh);
  } */
  .tblDft.mx-ht {
    max-height: calc(100vh - 40vh) !important;
  }
}

@media (min-width: 1441px) and (max-width: 1920px) {
  /* .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    height: calc(100vh - 57vh) !important;
    max-height: calc(100vh - 57vh) !important;
    min-height: calc(100vh - 57vh) !important;
  } */
  /* .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 54vh) !important;
    max-height: calc(100vh - 54vh) !important;
    min-height: calc(100vh - 54vh) !important;
  } */
  /* .dsTbl div#empTbl {
    height: calc(100vh - 58vh) !important;
    max-height: calc(100vh - 58vh) !important;
    min-height: calc(100vh - 58vh) !important;
  } */
}

@media (min-width: 1921px) and (max-width: 3200px) {
  /* .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    height: calc(100vh - 47vh) !important;
    max-height: calc(100vh - 47vh) !important;
    min-height: calc(100vh - 47vh) !important;
  }
  .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 45vh) !important;
    max-height: calc(100vh - 45vh) !important;
    min-height: calc(100vh - 45vh) !important;
  }
  .dsTbl div#empTbl {
    height: calc(100vh - 48vh) !important;
    max-height: calc(100vh - 48vh) !important;
    min-height: calc(100vh - 48vh) !important;
  } */
}

@media (min-width: 1441px) and (max-width: 3200px) {
  /* div#empTbl {
    min-height: calc(100vh - 38vh);
  } */
  /* .reportsTbl div#empTbl {
    min-height: calc(100vh - 30vh);
  } */
  /* .addButton {
    bottom: 3vh !important;
  } */
  /* .tblDft.lbTbls.h42vh {
    height: calc(100vh - 390px);
    max-height: calc(100vh - 390px);
    min-height: calc(100vh - 390px);
  } */
  .lbCont .ant-tabs-top {
    height: calc(100vh - 15vh);
    max-height: calc(100vh - 15vh);
    min-height: calc(100vh - 15vh);
  }
  /* .tblDft.lbTbls.h42vh .infinite-scroll-component {
    height: calc(100vh - 400px) !important;
    max-height: calc(100vh - 400px) !important;
  } */
  /* .lbTbls div#empTbl {
    min-height: calc(100vh - 30vh);
  } */
  .chartTabsContainer.noFlex {
    height: calc(100vh - 30vh);
  }
}

@media (min-width: 1441px) and (max-width: 1600px) {
  /* .addButton {
    bottom: 10vh !important;
  } */
}

@media (min-width: 1601px) and (max-width: 1800px) {
  /* .addButton {
    bottom: 10vh !important;
  } */
}

@media (min-width: 1801px) and (max-width: 1920px) {
  /* .addButton {
    bottom: 12vh !important;
  } */
}

@media (min-width: 1921px) and (max-width: 2159px) {
  /* .addButton {
    bottom: 11vh !important;
  } */
}

@media (min-width: 2160px) and (max-width: 2880px) {
  /* .addButton {
    bottom: 8vh !important;
  } */
}

.elTabs .chartsTabs .ant-tabs-content-holder {
  height: calc(100vh - 26vh) !important;
}

/* .chartGraph {
  height: calc(100vh - 32vh) !important;
} */

.w50.chartGraph,
.w51.chartGraph {
  height: inherit !important;
}

::-webkit-scrollbar {
  height: 4px !important;
}
.antBdrNone .ant-card-bordered {
  border: none !important;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container:after {
  box-shadow: none !important;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: none !important;
}

.employeesTeams .ant-tag-geekblue {
  border-radius: 30px !important;
  background: #e4f6f8 !important;
  color: #282c34 !important;
  font-size: 12px !important;
  padding-left: 12px !important ;
  padding-right: 12px !important;
  font-family: Montserrat-SemiBold, sans-serif;
}

*,
body {
  font-feature-settings: initial !important;
  font-variant-numeric: normal !important;

  -moz-font-feature-settings: initial !important;
  -ms-font-feature-settings: initial !important;
  -o-font-feature-settings: initial !important;
  -webkit-font-feature-settings: initial !important;

  -moz-font-variant-numeric: normal !important;
  -ms-font-variant-numeric: normal !important;
  -o-font-variant-numeric: normal !important;
  -webkit-font-variant-numeric: normal !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
