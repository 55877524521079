.snapContainer {
  display: inline-flex;
  flex-wrap: wrap;
  height: calc(100vh - 220px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.videoSnaps {
  width: 24%;
  max-height: 200px;
  margin: 0 1% 1% 0;
  box-shadow: 4px 7px 15px -10px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  border: 1px solid #ccccccbd;
  position: relative;
}

.videoSnaps video {
  border-radius: 4px;
}

.snapText {
  position: absolute;
  min-height: 35px;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  width: 100%;
  padding-left: 10px;
  padding-top: 8px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  border-bottom: 1px solid #ccc;
  z-index: 10;
}

.jumpandgo-section .barDisplay {
  width: 100% !important;
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
  margin-left: 15px;
  margin-right: 15px;
  align-items: center;
  position: relative;
  top: 5px;
}

.timeLine:last-child ~ div {
  display: flex;
}

.progress {
  flex: 1;
  position: relative;
  display: flex;
  flex-basis: 100%;
  height: 20px;
  background: #38647466;
  cursor: pointer;
  margin: 4px 14px;
  border-radius: 10px;
}

.progress__filled {
  width: 100%;
  background: #386474;
  flex: 0;
  flex-basis: 0%;
  border-radius: 10px;
}

.fullScreenVideo {
  position: absolute;
  top: 11px;
  font-size: 14px;
  right: 16px;
  z-index: 10;
}

.libraryContainer .fc .fc-toolbar.fc-header-toolbar {
  min-height: 62px;
  padding: 0px 15px;
  margin-bottom: inherit;
}

.recordedVideoTab .fc .fc-view-harness {
  height: calc(100vh - 195px) !important;
}

.videoDbCon .ant-card-body {
  display: inline-block;
  width: 100%;
  /* padding: 20px 20px 20px 20px !important; */
  padding: 0px;
  position: relative;
}

.videoDbCon .ant-card-head {
  height: 52px;
}

.videosCon .fc-scroller-liquid-absolute {
  right: -4px !important;
  left: -1px !important;
}

.recordVediosprogress {
  flex: 1;
  position: relative;
  display: flex;
  flex-basis: 100%;
  height: 20px;
  background: #38647466;
  cursor: pointer;
  margin: 4px 14px;
  border-radius: 10px;
}

.recordVediosprogress__filled {
  width: 100%;
  background: #386474;
  flex: 0;
  flex-basis: 0%;
  border-radius: 10px;
}
.day-view-height .fc .fc-view-harness {
  height: calc(100vh - 320px) !important;
}

.library-no-video {
  height: calc(80vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: #1f3a46;
  color: #fff;
}

.audioContainer {
  display: flex;
  align-items: center;
  margin: 8px 0px;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 8px;
}
.hitchLabel {
  padding-right: 16px;
}
.audioBlock {
  height: 30px;
  width: -webkit-fill-available;
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {
  .day-view-height .fc .fc-view-harness {
    height: calc(100vh - 340px) !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 990px) {
  .day-view-height .fc .fc-view-harness {
    height: calc(100vh - 345px) !important;
  }
}

.recordedVideoTab .table tr th,
table tr td,
.recordedVideoTab .table tr th,
table tr th {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.recordedVideoTab .fc-daygrid-dot-event .fc-event-title {
  flex-grow: inherit !important;
  flex-shrink: inherit !important;
}
.recordedVideoTab .fc-theme-standard .fc-scrollgrid {
  border: none !important;
}
.recordedVideoTab .fc-theme-standard tr:last-child > td {
  border-bottom: none !important;
}

.rcvideos_cont.common_wrapper .common_wrapper {
  height: calc(100vh - 136px) !important;
}

.rcvideos_cont .ant-breadcrumb {
  padding: 10px;
  margin-bottom: 0px !important;
  border: 1px solid #e8e8e8;
}

.rcvideos_cont .dbWidget.calTbl {
  border: none !important;
  border-radius: 0px !important;
}
.rcvideos_cont .fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 10px !important;
  padding-left: 10px;
  padding-top: 10px;
}
.rcvideos_cont .day-view-height .fc .fc-view-harness {
  height: calc(100vh - 241px) !important;
}
