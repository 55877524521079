.requestContainer {
  border: 1px solid #ccc;
  padding: 35px 35px 72px 35px;
  border-radius: 10px;
  margin: 212px auto;
  max-width: 500px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.29) !important;
}

.startConTxt {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 16px;
}

.conTxt {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}

.requestContainer .ant-input {
  border: 1px solid #ccc !important;
  width: 440px;
}

.wdtCenter {
  position: relative;
  width: 100%;
  /* text-align: right; */
}

.conStartBtn {
  position: absolute;
  right: 0;
  width: 104px;
  height: 36px;
  margin-top: 20px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  letter-spacing: 0.38px;
  background-color: #366376;
  color: #fff;
  border-radius: 6px;
  text-transform: uppercase;
}

.userMsgBox {
  margin: 20px;
  display: inline-block;
  position: relative;
  max-width: 548px;
  height: auto;
  background-color: #f5f5dc;
  border-radius: 6px;
}

.white {
  background-color: #e7e7e7;
}

.triangle.left-top:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: #f5f5dc transparent transparent transparent;
}

.triangle.right-top:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 0;
  bottom: auto;
  border: 22px solid;
  border-color: #e7e7e7 transparent transparent transparent;
}

.msgText img {
  width: 100% !important;
  height: 100% !important;
}

.talkText {
  color: #000;
  padding: 1em;
  letter-spacing: 0.28px;
}

.orgTractorName {
  font-size: 15px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 19px;
}

.msgTime {
  font-size: 10px;
  font-family: 'Montserrat-Medium', sans-serif;
  margin-left: 20px;
  color: #a7a7a7;
}
.chatDate {
  text-align: center;
  position: sticky;
  top: 0;
}

.msgText {
  display: flex;
  align-items: center;
}

.chatMsgsBody .msgText .ant-avatar {
  font-size: 15px !important;
  min-width: 30px !important;
  width: 2.6vw !important;
  min-height: 30px !important;
  height: 2.6vw !important;
  line-height: unset !important;
}
.chatMsgsBody .msgText .ant-avatar .ant-avatar-string {
  top: 50%;
  transform: translate(-50%, -50%) !important;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.rowReverse {
  flex-direction: row-reverse;
  text-align: right;
}

.rowReverse .msgTime {
  float: left;
}

.talkText .body1 {
  font-size: 12px;
  font-family: 'Montserrat-Regular', sans-serif;
  line-height: 20px;
  text-align: left;
  word-break: break-word;
}

.converseChat .chatMsgsBody p {
  padding-top: 14px;
  background-color: #fff;
  z-index: 1;
}

.converseChat .chatSendingOptions .msgSendBtn {
  background-color: #366376;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 60px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  line-height: 16px;
  padding: 8px 14px;
}

.chatSendingOptions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.converseChat .chatMsgsBody {
  height: calc(100vh - 220px);
  padding-right: 20px;
  padding-left: 30px;
  overflow-y: auto;
}

.chatCloseBtn {
  height: 34px;
  background-color: #366376;
  border-radius: 4px;
  color: #fff;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-style: italic;
  letter-spacing: 0.28px;
  font-size: 12px;
}

.bgGray {
  background-color: #c3c3c3 !important;
}

.bgGray:focus,
.bgGray:hover {
  background-color: #fff !important;
}

.endConverse {
  text-align: -webkit-center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #ccc;
}

.endConverse p {
  font-size: 16px;
  font-family: 'Montserrat-Regular', sans-serif;
  font-style: italic;
  letter-spacing: 0.38px;
  line-height: 26px;
}

.chatSendingBlk input {
  border: 0 !important;
}

.chatSendingBlk .ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}

.chatSendingBlk {
  border: 1px solid #e7e7e7;
  border-radius: 90px;
  max-width: 1000px;
  width: 100%;
  height: 54px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  padding: 8px 14px;
}

.chatCount {
  width: 62px;
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
}

.selectRoomDropDown {
  margin-left: 20px;
  width: 156px;
}

.closeChatTxt {
  width: 100%;
  margin-top: 28px;
  font-family: 'Montserrat-Medium', sans-serif;
  text-align: center;
}

.closeChatTxt div {
  margin-bottom: 12px;
  font-size: 14px;
}

.backToChat {
  background-color: #366376;
  color: #fff;
  border-radius: 4px;
}

.convoTitle {
  margin-top: 10px;
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
