/* .ticket_table {
  height: calc(100vh - 500px);
} */

.ticket_table .infinite-scroll-component {
  /* height: calc(100vh - 365px) !important; */
  /* max-height: calc(100vh - 365px) !important; */
}

.msupTickets {
  min-height: calc(100vh - 232px) !important;
  max-height: calc(100vh - 232px) !important;
}

.msupTickets .filterContainer {
  margin-left: inherit !important;
}

.tractorHealthMark {
  grid-template-columns: repeat(3, 1fr);
}

.ticketModal2 .col2-styles {
  display: flex !important;
}

.ticketModal2 .orgData {
  width: auto;
  text-align: left;
}

.ticketModal2 .indiContainer .orgDetails p {
  color: #000 !important;
  font-family: 'Montserrat-SemiBold', sans-serif;
  line-height: 18px;
  font-size: 12px;
}

.ticketModal2 .indiContainer .orgData p {
  color: #000 !important;
  font-family: 'Montserrat-Regular', sans-serif;
  line-height: 18px;
  font-size: 12px;
}

.ticketModal2 .indiContainer {
  padding: 12px 20px;
}
.ticketModal2 .indiContainer2 .orgData p,
.ticketModal2 .indiContainer2 .orgDetails p {
  margin-bottom: 0px !important;
}

.ticketModal2 .indiBlockRow {
  padding: 0px 10px;
}

.ticketCatSec {
  display: block;
  flex-direction: row;
  margin: 15px 0px;
}

.indiContainer2 {
  display: block;
  flex-direction: row;
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #cccddc;
  margin-bottom: 30px;
  padding: 12px;
  border-radius: 6px;
}

.tSecHead {
  font-size: 14px;
  font-family: 'Montserrat-Bold', sans-serif;
  margin-bottom: 12px;
  position: relative;
  top: -24px;
  left: 0px;
  padding: 3px 16px;
  background: #ffffff;
  display: inline-block;
  border: 1px solid #cccddc;
  border-radius: 6px;
}

.indiBlockRow {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
}

.indiBlockRow .col2-styles {
  margin-bottom: 12px;
}

.col2-styles .heading {
  color: #000000;
}

.orgDetails {
  width: 150px;
  display: flex;
}

.mw190 {
  min-width: 190px;
}
.detailTxt {
  width: 50% !important;
}
.orgData {
  width: 650px;
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
}

.px10 {
  padding-left: 10px;
  padding-right: 10px;
}
.ticketModal2 .supportLogin {
  position: absolute;
  top: 10px;
  right: 60px;
}

.indiHead {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin-bottom: 12px;
  margin-left: 280px;
}

.networkSpeedContainer {
  position: relative;
  display: flex;
  gap: 8px;
}

.checkSpeedBtn {
  position: absolute;
  right: 0;
  min-width: auto;
}
.displaynone {
  display: none;
}

.errorCodeModal .ant-modal-body .ant-table-thead > tr > th {
  background-color: #fff !important;
}
.errorCodeModal .ant-modal-content {
  width: 800px !important;
}

/* Tractor Migration */

.stepsContent {
  min-height: 180px;
  margin-top: 29px;
  padding: 23px 6px 14px;
  background-color: #fafafa;
  border: 2px dashed #e9e9e9;
  border-radius: 2px;
  font-family: 'Montserrat-Medium', sans-serif;
  text-align: left;
}

.stepsContent ol li,
.stepsContent ul li {
  padding: 8px;
  text-align: left;
}

.stepsAction {
  margin-top: 24px;
}

.migrateContainer {
  width: 1158px !important;
}

.migrateContainer .ant-modal-body {
  padding: 38px !important;
}

.migrateContainer .ant-steps-item-title {
  font-size: 12px;
}

.migrateTbContainer {
  width: 100%;
}

.migrateTbContainer th,
.migrateTbContainer td {
  border: 1px solid #ebecf0 !important;
  text-align: left;
  padding: 10px 15px 11px 15px;
}

.migrateTbContainer th {
  font-family: 'Montserrat-SemiBold', sans-serif;
  background-color: #f7f7f7;
  position: sticky !important;
  top: 0;
  z-index: 1;
}

.migrateTbContainer td {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 12px;
}

.common_tabs .commentsTab .ant-tabs-content-holder {
  height: auto !important;
}

.commentsWidth {
  margin-top: 16px;
}

.min_width {
  min-width: 200px !important;
}

.common_tabs .maintenance-tabs .ant-tabs-tab {
  margin: 0 0 0 2vw;
}

.maintenance-tabs .ant-tabs-nav-wrap {
  justify-content: inherit !important;
}
.proRowHealth {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
}
.rightSideContainer {
  height: 100vh;
  width: 100%;
}
.commentIcon {
  margin-right: 10px;
}

/* Tractor Migration Ends*/

.fleetMargin {
  margin-right: 10px;
}
.proMargin{
  margin-bottom: 15px;
}
.ant-modal.basestationSurvey {
padding-bottom: 0px;
top: 0px;
display: flex;
align-items: center;
justify-content: center;
}

.bshrs .ant-input:hover {
  border: 1px solid #dfe1e6 !important;
}
