
.hm_Container {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: calc(100vh - 64px) !important;
  min-height: inherit !important;
}

.hm_rightWrapper {
  width: calc(100% - 300px);
  padding: 20px;
}

.manualMenu .hm_listitems {
  /* padding-bottom: 20px; */
  width: 270px;
  padding: 0px 0px 0px 15px;
}

.manualMenu {
  overflow-y: auto;
  /* display: flex; */
  /* padding: 0px 0px 0px 15px; */
}

.manual_Main_section .hm_listitems {
  /* padding-bottom: 20px; */
  width: 100% !important;
  padding: 0px 15px 0px 15px;
}

.manual_Main_section .ant-menu-submenu li.ant-menu-item {
  padding-left: 15px !important;
}

.manualMenu.ant-layout-sider {
  background-color: #f3f3f3 !important;
  color: #000 !important;
  border-right: 1px solid #e5e5e5;
}

.manual_Main_section .hm_listitems {
  height: 100%;
}
.manualMenu .hm_listitems {
  background-color: #f3f3f3 !important;
  color: #000 !important;
  list-style-type: none;
}

.manualMenu .hm_listitems li,
.manualMenu .hm_listitems li span {
  color: #000;
  font-size: 13px;
  font-family: "Montserrat-Medium", sans-serif;
}

.manualMenu .hm_listitems li,
.manualMenu .hm_listitems li:hover {
  border-left: none !important;
}

/* .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected, */
/* .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-active {
  background-color: #255061 !important;
} */
     
.manualMenu .ant-menu-submenu-arrow:before,
.manualMenu .ant-menu-submenu-arrow:after {
  background-color: #000 !important;

}

.hm_listitems ::-webkit-scrollbar {
  background-color: #1e3a46 !important;
  width: 5px;
}

.hm_listitems ::-webkit-scrollbar-track {
  background-color: #d2d2d2;
}

.hm_listitems ::-webkit-scrollbar-track:hover {
  background-color: #1e3a46;
}

.hm_listitems ::-webkit-scrollbar-thumb {
  border-radius: 16px;
  border: 5px solid #606060;
}

.hm_listitems ::-webkit-scrollbar-thumb:hover {
  background-color: #606060;
  border: 4px solid #606060;
}

.hm_listitems ::-webkit-scrollbar-button {
  display: none;
}

.hm_listitems .ant-menu.ant-menu-sub {
  background-color: #F5F5F5 !important;
}

.hm_listitems li li:hover {
  background-color: #DDDCDC !important;
}

/* .hm_listitems .ant-menu-submenu-inline ~ li {
  padding-left: 0 !important;
  padding-right: 0px !important;
} */

.ht_Head {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 46px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}

/* .ant-upload-list-text-container{
  display: none !important;
} */
.mb16{
  margin-bottom: 16px !important;
}
.ht_right{
  /* position: relative;
  right: 20px;
  z-index: 1;
  top:75px; */
  display: flex;
  justify-content: flex-end;
}
.w50{
  width: 45vw !important;
}
.w25{
  width: 25vw !important;
}

/* li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child > span,  */
li.ant-menu-item.ant-menu-item-only-child.topic-selected > span
{
    color: #FFFF !important;
}
.topic-selected {
  background-color: #255061 !important;
}

