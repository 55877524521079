/* .ant-select-selection-item{
    line-height: 18px !important
} */

.software-update-items-list {
  background: #f2e8dd 0% 0% no-repeat padding-box;
  border: 1px solid #ec9322;
  border-radius: 20px;
  opacity: 1;
}
.version-block {
  height: 400px;
  overflow-y: auto;
}

.version-block ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  /* width: max-content; */
  width: 95%;
}
.version-block ul > li {
  padding: 8px 0px;
  border-bottom: 2px solid #e7e7e7;
  position: relative;
  margin-bottom: 10px;
}

.vHistorySec {
  position: absolute;
  right: 0px;
  top: -25px;
}
.vHtxt {
  /* font-size: 14px;
        font-family: Montserrat-SemiBold; */
  color: #366376;
  text-transform: uppercase;
  /* letter-spacing: 1.25px;
        line-height: 18px; */
  cursor: pointer;
}

.editTabs .ant-tabs-nav {
  margin-bottom: 0;
}
.editTabs .ant-tabs-content {
  margin-top: 0;
}
.editTabs .ant-tabs-tab {
  border-radius: 10px 10px 0 0 !important;
  padding: 9px 32px 6px 32px;
  background: #e7e7e7;
  margin-left: 0px;
  min-height: 34px;
}
.editTabs .ant-tabs-tab.ant-tabs-tab-active {
  background: #fff;
}
.editTabs .ant-tabs-ink-bar {
  border-bottom: 2px solid #fff;
}
.ant-tabs-top.editTabs > .ant-tabs-nav::before,
.ant-tabs-bottom.editTabs > .ant-tabs-nav::before,
.ant-tabs-top.editTabs > div > .ant-tabs-nav::before,
.ant-tabs-bottom.editTabs > div > .ant-tabs-nav::before {
  border-bottom: 0 !important;
}
.editTabs .ant-tabs-tab-btn {
  color: #000 !important;
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  line-height: 18px;
}
.editTabs .ant-tabs-nav-add {
  background-color: #e7e7e7 !important;
  border-radius: 20px !important;
  min-width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  margin-top: 8px !important;
  margin-left: 5px !important;
}
.softwareCard {
  border: 0 !important;
}
.softwareTabs {
  margin-top: -33px;
  margin-left: 0px;
}

.versionHistPopup {
  /* width: 47.65vw !important; */
  /* height: 72vh; */
  width: 768px !important;
}
.versionHistPopup .ant-modal-body {
  padding: 0px;
}
.versionHistPopup .softwareCard,
.versionHistPopup .ant-modal-content {
  border-radius: 0px 15px 15px 15px;
}
.verHistTxt {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  margin-bottom: 31px;
  line-height: 24px;
}

.verTractorList .ant-checkbox-checked .ant-checkbox-inner,
.verTractorList .ant-checkbox-input,
.verTractorList .ant-checkbox-inner {
  background-color: #fff;
  border-color: #366376;
  width: 22px;
  height: 22px;
  border-radius: 5px;
}
.verTractorList .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #366376;
}
.verTractorList .ant-checkbox-inner {
  margin-right: 24px;
}
.verTractorList .ant-checkbox-wrapper span {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #000;
}
.verTractorList .ant-checkbox:hover,
.verTractorList .ant-checkbox-checked::after {
  outline: none;
  border: none;
}
.mLogo {
  position: absolute;
  right: 0px;
  top: 0px;
}

.proPic .ant-avatar-string {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 60px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.ml10 {
  margin-left: 60px;
}
.ml80 {
  margin-left: 3.5vw;
}

.softwareTabs .ant-tabs-content,
.softwareInstall .ant-modal-body {
  padding: 57px 64px;
}

.softwareInstall {
  width: 47.65vw !important;
  height: 72vh;
  min-width: 800px;
}

.softwareInstall .software-update-items-list {
  margin: 0px;
  padding: 32px;
}

.installIns {
  font-size: 16px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #000;
  letter-spacing: 0.15px;
  line-height: 19px;
  display: flex;
  align-items: center;
}
.mr16 {
  margin-right: 16px;
}
.installInsUL {
  list-style: none;
  margin: 0px 0px 0px 60px;
  padding: 0px;
}

.installInsUL li::before {
  content: '';
  background: #fbb313;
  width: 9px;
  height: 9px;
  border-radius: 20px;
  position: absolute;
  top: 8px;
}
.installInsUL li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #000;
  position: relative;
}
.installInsUL .listClass {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #000;
  position: relative;
}
.installInsUL li span {
  margin-left: 20px;
}

.softwareInstall .ant-modal-content {
  border-radius: 15px;
}
.installDec {
  font-size: 16px;
  font-family: 'Montserrat-Regular', sans-serif;
  color: #000000;
  line-height: 22px;
  letter-spacing: 0.25px;
  padding-bottom: 10px;
}
.instBtnSec {
  position: relative;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
}
.installBtn,
.installBtn:hover,
.installBtn:focus {
  background: #366376;
  border-radius: 10px;
  min-height: 34px;
  min-width: 136px;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  letter-spacing: 1.25px;
  line-height: 18px;
  outline: none;
  border: none;
  text-transform: uppercase;
}
.mr20Btn {
  margin-right: 20px !important;
}
.installBtn.dblBtn {
  background: #b6b6b7;
  color: #fff;
}
.ant-popover-arrow {
  display: none !important;
}
.softwarePopover .ant-popover-inner {
  font-size: 16px !important;
  font-family: 'Montserrat-Medium', sans-serif !important;
  line-height: 19px !important;
  background: #fff !important;
  color: #000 !important;
  letter-spacing: 0.15px !important;
  padding: 24px 15px !important;
  border: 1px solid #ec9322 !important;
  border-radius: 15px 15px 0 15px !important;
  min-width: 276px !important;
  max-width: 281px !important;
  height: 88px !important;
}
.ant-popover-title {
  padding: 0 !important;
  border-bottom: 0 !important;
}

.vSNo {
  width: 180px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.listClassColor {
  color: green !important;
}
.softwareUpdate .ant-space-item {
  margin-bottom: 0 !important;
}

.instBtn {
  margin-bottom: 20px !important;
}

.gold-tag {
  position: absolute;
  right: 10px;
  width: unset !important;
}

.ant-select-dropdown .ant-select-item-option-content .swOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
}

.ant-select-dropdown .ant-select-item-option-content .swOption span {
  flex-grow: 1;
}

.toolTipStyle {
  /* min-width: 280px !important; */
  white-space: pre-wrap;
  padding: 10px;
  max-width: 300px !important;
}
.ant-tooltip {
  max-width: 300px !important;
}
.ant-tooltip-inner {
  /* border-radius: 20px 20px 0px 20px; */
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.6px;
}

.upMl {
  margin-left: 20px;
}

.upTname {
  min-width: 62px;
}
.jfStart {
  justify-content: flex-start !important;
}

.versionList {
  display: flex;
  align-items: center;
}

.updateCount {
  color: #fff;
  background-color: #d43d38;
  border-radius: 10px;
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 10px;
}
