$acd-container-bgcolor: #e8e8e880;
$acd-container-border-color: #d33c39;

.all-camera-down {
  .acd-container {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 18px;
    background-color: $acd-container-bgcolor;
    text-align: center;
    height: 197px;
  }
  .acd-img-block {
    margin: 0px 0px 14px 0px;
  }
  .acd-icon {
    width: 32px;
    margin: auto;
    height: 32px;
  }
  .acd-title {
    font-size: 12px;
    font-weight: 600;
    color: #333333;
  }
  .acd-desc {
    font-size: 10px;
    color: #99999c;
  }
}

.all-camera-down.all-cam {
  .acd-container {
    height: 203px !important;
    border: 1px solid $acd-container-border-color;
  }
  .acd-img-block {
    margin: 0px 0px 14px 24px;
  }
  .acd-icon {
    width: 100%;
    margin: auto;
    height: 110px;
  }
}
