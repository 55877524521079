.remoteTimedout {
  width: auto !important;
}

.remoteTimedout .ant-modal-content {
  width: 600px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 16px;
  padding: 36px 55px;
  text-align: center;
}

.remoteTimedout .ant-modal-body {
  padding: 0;
}

.timeCount {
  background-color: #e9f0fd;
  color: #3265ca;
  font-size: 19px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.14px;
  line-height: 23px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.remoteTimedout h3 {
  font-size: 24px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.44px;
  line-height: 29px;
  color: #606366;
  padding-top: 40px;
  padding-bottom: 38px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
.remoteTimedout p {
  font-size: 20px;
  font-family: Montserrat-SemiBold;
  line-height: 30px;
  color: #606366;
  text-align: center;
  padding-bottom: 57px;
  margin-top: 0;
  margin-bottom: 0;
}
.timedoutBtns {
  text-align: right;
}
.timedoutBtns a {
  font-size: 24px;
  font-family: Montserrat-SemiBold;
  line-height: 29px;
  padding-left: 48px;
  color: #3d74e0;
  text-transform: capitalize;
  text-decoration: none;
}

.trip-meter {
  color: #fff;
}

.remoteDrive.mini {
  width: 300px !important;
}

.confirm-end-operation {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
  width: 450px !important;
}
.confirm-end-operation .ant-modal-content {
  border-radius: 12px;
  border: 3px solid #c34840;
}

.confirm-end-operation .ant-modal-body {
  padding: 25px 25px 15px 25px;
}

.endOptIcon {
  padding: 0px;
  margin: 0 0 15px 0;
}

.confirm-end-operation .ant-modal-confirm-title {
  font-size: 17px;
  letter-spacing: 0.51px;
  font-family: Montserrat-SemiBold;
  margin-bottom: 0px;
}
.confirm-end-operation .ant-modal-confirm-content {
  font-size: 14px;
  letter-spacing: 0.7px;
  font-family: montserrat-medium;
  margin-bottom: 20px;
  padding: 0 30px;
  text-align: center;
}

.confirm-end-operation .ant-modal-confirm-btns {
  width: 100%;
  text-align: center;
}

.confirm-end-operation .ant-modal-confirm-btns .ant-btn {
  background-color: #8a9a48;
  border-radius: 7px !important;
  height: 31px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  color: #fff;
}

.confirm-end-operation .ant-modal-confirm-btns .ant-btn:nth-child(1),
.confirm-end-operation .ant-modal-confirm-btns .ant-btn:nth-child(1):hover,
.confirm-end-operation .ant-modal-confirm-btns .ant-btn:nth-child(1):focus {
  background-color: #8a9a48;
  border-color: #8a9a48;
}

.confirm-end-operation .ant-modal-confirm-btns .ant-btn-dangerous,
.confirm-end-operation .ant-modal-confirm-btns .ant-btn-dangerous:hover,
.confirm-end-operation .ant-modal-confirm-btns .ant-btn-dangerous:focus {
  background-color: #d33c39;
  border-color: #d33c39;
  margin-left: 20px !important;
}
