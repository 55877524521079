.november-3rd {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 24px;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 16px;
}

.date-picker {
  height: 26px;
  width: 26px;
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: var(--iosweb-headline-h4-size);
  border-radius: var(--br-9xs);
  background-color: var(--neutrals-ffffff);
  box-shadow: var(--level-11);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.date-picker {
  height: 26px;
  width: 26px;
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: var(--iosweb-headline-h4-size);
  border-radius: var(--br-9xs);
  background-color: var(--neutrals-ffffff);
  box-shadow: var(--level-11);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.november-3rd-9th-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
  position: relative;
}
.select-an-operation {
  // position: relative;
  font-size: var(--iosweb-headline-h4-size);
  font-weight: 400;
  color: var(--neutrals-5c5c5c);
  // z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  line-height: 15px;
}

// .select-an-operation:not(:hover) {
//   text-overflow: ellipsis;
// }

// .select-an-operation:hover {
//   display: inline-block;
//   animation-name: scroll-text;
//   animation-duration: 7s;
//   animation-timing-function: linear;
//   animation-delay: 0s;
//   animation-iteration-count: initial;
//   animation-direction: normal;
// }
// @keyframes scroll-text {
//   0% {
//     transform: translateX(0%);
//   }
//   90% {
//     transform: translateX(-100%);
//   }
//   95% {
//     transform: translateX(0%);
//   }
//   100% {
//     transform: translateX(0%);
//   }
// }

.select-an-operation {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 35vw;
  overflow: hidden;
}

.frame-group,
.frame-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  // overflow: hidden;
}
.frame-group {
  flex-direction: column;
  gap: var(--gap-7xs);
}
.frame-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-8xs);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--headline-h2-size);
  color: var(--neutrals-333333);
  min-height: 48px;
  z-index: 2;
}
.frame-wrapper2 {
  min-height: 48px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.span {
  font-weight: 600;
}
.h {
  font-size: var(--iosweb-headline-h4-size);
  font-weight: 500;
  text-transform: none;
}
.span1 {
  font-weight: 600;
}
.m1 {
  font-size: var(--iosweb-headline-h4-size);
  font-weight: 500;
  text-transform: none;
}
.div34 {
  position: relative;
  line-height: 22px;
  display: inline-block;
  min-width: 79px;
  white-space: nowrap;
}
.graph-call-outs,
.graph-header,
.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.wrapper {
  flex-direction: row;
  padding: 0 var(--padding-base) 0 var(--padding-base-5);
  text-align: left;
  font-size: var(--headline-h2-size);
  color: var(--neutrals-99999c);
}
.op_tabs_sec .ant-tabs-tab.ant-tabs-tab-active .wrapper {
  color: var(--neutrals-333333);
}
.op_tabs_sec .ant-tabs-tab.ant-tabs-tab-active .container {
  color: var(--neutrals-333333);
}
.graph-call-outs,
.graph-header {
  flex-direction: column;
}
.graph-call-outs {
  align-self: stretch;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-3xs);
}
.graph-header {
  flex: 1;
  padding: var(--padding-12xs) 0;
  gap: var(--gap-sm);
}
.graph-header-item {
  align-self: stretch;
  height: 3px;
  position: relative;
  border-radius: 0 0 var(--br-8xs) var(--br-8xs);
  background-color: var(--primary-techblue-67afb7);
  opacity: 0;
}
.div35,
.operations1 {
  position: relative;
  display: inline-block;
}
.operations1 {
  align-self: stretch;
  font-weight: 500;
  min-width: 83px;
}
.div35 {
  font-weight: 600;
  min-width: 40px;
}
.container {
  flex-direction: row;
  padding: 0 21px 0 21.5px;
  text-align: left;
  font-size: var(--headline-h2-size);
}
.container,
.graph-call-outs1,
.graph-header1,
.graph-options {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.graph-call-outs1 {
  align-self: stretch;
  flex-direction: column;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-3xs);
}
.graph-header1,
.graph-options {
  box-sizing: border-box;
}
.graph-header1 {
  width: 87px;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  gap: var(--gap-sm);
  color: var(--neutrals-99999c);
}
.graph-options {
  flex: 1;
  flex-direction: row;
  padding: 0 var(--padding-base) 0 0;
  gap: 18px;
  min-width: 153px;
}
.implement-1-icon {
  height: 14px;
  width: 14px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 14px;
}
.div36 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 58px;
}
.frame,
.operations2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
}
.operations2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  flex-direction: row;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  width: 100%;
}
.selected-box {
  position: absolute;
  top: 3px;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--neutrals-ffffff);
  box-shadow: var(--level-11);
  width: 92px;
  height: 24px;
  z-index: 1;
}
.operations-parent {
  height: 30px;
  flex: 1;
  position: relative;
}
.user-fill-1-icon {
  height: 14px;
  width: 14px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 14px;
}
.operators-child {
  width: calc(100% - 36px);
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 13px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
  box-sizing: border-box;
  font-family: var(--headline-h5);
  font-weight: 600;
  font-size: var(--body-3-size);
  color: var(--neutrals-cfcfcf);
  min-width: 31px;
}
.operations-graph-toggle,
.operators {
  align-self: stretch;
  background-color: var(--neutrals-f7f7f7);
  gap: var(--gap-9xs);
}
.operators {
  flex: 0.7609;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-5xs)
    var(--padding-sm);
}
.operations-graph-toggle {
  flex: 1;
  border-radius: var(--br-7xs);
  padding: 0 var(--padding-10xs);
}
.header1,
.operations-graph-toggle,
.toggle-placement {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
}
.toggle-placement {
  height: 48px;
  width: 194px;
  padding: var(--padding-lg) 0 0;
  box-sizing: border-box;
  text-align: left;
  font-size: var(--body-3-size);
  color: var(--neutrals-b5b5b6);
}
.header1 {
  align-self: stretch;
  padding: 0 var(--padding-lg);
  gap: 33.5px;
}
.hrs {
  width: 12px;
  position: relative;
  font-weight: 500;
  display: inline-block;
  transform: rotate(-90deg);
}

.imp-container .header {
  gap: 10px;
}

.data-aggregator {
  width: 11px;
  min-width: 11px;
}
.data-aggregator,
.data-aggregator1,
.data-aggregator2 {
  position: relative;
  display: flex;
  align-items: center;
}
.data-aggregator1 {
  width: 7px;
  min-width: 7px;
}
.data-aggregator2 {
  width: 13px;
}
.data-aggregator3 {
  align-self: stretch;
  position: relative;
}
.data-aggregator4,
.data-aggregator5 {
  width: 13px;
  position: relative;
  display: flex;
  align-items: center;
}
.data-aggregator5 {
  width: 7px;
  min-width: 7px;
}
.data-points {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 182px;
  text-align: right;
  color: var(--neutrals-5c5c5c);
}
.axis {
  width: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.graph-lines-child,
.graph-lines-child1,
.graph-lines-child2,
.graph-lines-inner,
.graph-lines-item,
.line-icon {
  align-self: stretch;
  height: 1px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.graph-lines {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  box-sizing: border-box;
  min-width: 263px;
  max-width: 100%;
}
.axis-line {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  max-width: 104%;
  flex-shrink: 0;
}
.manual {
  height: 100%;
  width: 14px;
  position: relative;
  border-radius: var(--br-10xs);
  background-color: var(--primary-techblue-67afb7);
  min-height: 1px;
  max-height: 160px;
}
.autodrive,
.operator-assist {
  width: 14px;
  height: 36px;
  position: relative;
  background-color: var(--f1ce90);
}
.autodrive {
  height: 16px;
  background-color: var(--ec9322);
}
.idle,
.while-loop {
  border-radius: var(--br-10xs);
}
.while-loop {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-11xs-5);
}
.idle {
  height: 12px;
  width: 14px;
  position: relative;
  background-color: var(--neutrals-cfcfcf);
  min-height: 1px;
  max-height: 160px;
}
.bars {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 74px var(--padding-lgi) 0;
  gap: var(--gap-11xs);
}
.mowing {
  align-self: stretch;
  position: relative;
}
.plus-01-icon5 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.button {
  width: 39px;
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 39px;
}
.arrow-dropdown-01-icon5 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.primary-buttons-fill {
  border-radius: var(--br-7xs);
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-7xs);
}
.assignment-operator,
.column-1,
.primary-buttons-fill,
.type-select {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.assignment-operator {
  flex-direction: row;
  padding: 0 var(--padding-xs) 0 12.5px;
  color: var(--neutrals-99999c);
}
.column-1,
.type-select {
  flex-direction: column;
}
.type-select {
  align-self: stretch;
  padding: var(--padding-10xs) 0;
  gap: var(--gap-11xs);
}
.column-1 {
  height: 211px;
  width: 84px;
  gap: var(--gap-4xs);
}
.manual1 {
  height: 100%;
  width: 14px;
  position: relative;
  border-radius: var(--br-10xs);
  background-color: var(--primary-techblue-67afb7);
  min-height: 1px;
  max-height: 160px;
}
.autodrive1,
.operator-assist1 {
  width: 14px;
  height: 9px;
  position: relative;
  background-color: var(--f1ce90);
}
.autodrive1 {
  height: 29px;
  background-color: var(--ec9322);
}
.idle1,
.orange-bars {
  border-radius: var(--br-10xs);
}
.orange-bars {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-11xs-5);
}
.idle1 {
  height: 28px;
  width: 14px;
  position: relative;
  background-color: var(--neutrals-cfcfcf);
  min-height: 1px;
  max-height: 160px;
}
.bars1,
.division-operator {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bars1 {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-11xs);
}
.division-operator {
  flex: 1;
  align-items: flex-start;
  padding: 0 var(--padding-lgi);
}
.seeding {
  align-self: stretch;
  position: relative;
}
.plus-01-icon6 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.button1 {
  width: 38px;
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 38px;
}
.arrow-dropdown-01-icon6 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.primary-buttons-fill1,
.variable-declaration {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.primary-buttons-fill1 {
  border-radius: var(--br-7xs);
  overflow: hidden;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-7xs);
}
.variable-declaration {
  padding: 0 var(--padding-smi);
  color: var(--neutrals-99999c);
}
.column-2,
.type-select1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.type-select1 {
  align-self: stretch;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0;
  gap: var(--gap-11xs);
}
.column-2 {
  height: 211px;
  width: 84px;
  justify-content: flex-end;
  padding: 87px 0 0;
  box-sizing: border-box;
  gap: var(--gap-4xs);
}
.manual2,
.operator-assist2 {
  width: 14px;
  position: relative;
}
.manual2 {
  height: 100%;
  border-radius: var(--br-10xs);
  background-color: var(--primary-techblue-67afb7);
  min-height: 1px;
  max-height: 160px;
}
.operator-assist2 {
  height: 36px;
  background-color: var(--f1ce90);
}
.idle2,
.orange-bars1 {
  height: 3px;
  border-radius: var(--br-10xs);
}
.orange-bars1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.idle2 {
  width: 14px;
  position: relative;
  background-color: var(--neutrals-cfcfcf);
  min-height: 1px;
  max-height: 160px;
}
.bars2,
.labeled-statement {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bars2 {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-11xs);
}
.labeled-statement {
  flex: 1;
  align-items: flex-start;
  padding: 0 var(--padding-lgi);
}
.tilling {
  align-self: stretch;
  position: relative;
}
.plus-01-icon7 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.button2 {
  width: 38px;
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 38px;
}
.arrow-dropdown-01-icon7 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.primary-buttons-fill2 {
  border-radius: var(--br-7xs);
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-7xs);
}
.array-literal,
.column-3,
.primary-buttons-fill2,
.type-select2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.array-literal {
  flex-direction: row;
  padding: 0 var(--padding-smi);
  color: var(--neutrals-99999c);
}
.column-3,
.type-select2 {
  flex-direction: column;
}
.type-select2 {
  align-self: stretch;
  padding: var(--padding-10xs) 0;
  gap: var(--gap-11xs);
}
.column-3 {
  width: 84px;
  height: 211px;
  padding: var(--padding-4xl) 0 0;
  box-sizing: border-box;
  gap: var(--gap-4xs);
  flex-shrink: 0;
}
.manual3,
.operator-assist3 {
  width: 14px;
  position: relative;
}
.manual3 {
  height: 100%;
  border-radius: var(--br-10xs);
  background-color: var(--primary-techblue-67afb7);
  min-height: 1px;
  max-height: 160px;
}
.operator-assist3 {
  align-self: stretch;
  background-color: var(--f1ce90);
}
.idle3,
.orange-bars2 {
  border-radius: var(--br-10xs);
}
.orange-bars2 {
  height: 66px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.idle3 {
  height: 48px;
  width: 14px;
  position: relative;
  background-color: var(--neutrals-cfcfcf);
  min-height: 1px;
  max-height: 160px;
}
.bars-wrapper,
.bars3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bars3 {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-11xs);
}
.bars-wrapper {
  flex: 1;
  align-items: flex-start;
  padding: 0 var(--padding-lgi);
}
.no-implement {
  align-self: stretch;
  position: relative;
  display: inline-block;
  min-width: 84px;
}
.plus-01-icon8 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.button3 {
  width: 37px;
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 37px;
}
.arrow-dropdown-01-icon8 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.expression,
.primary-buttons-fill3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.primary-buttons-fill3 {
  border-radius: var(--br-7xs);
  overflow: hidden;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-7xs);
}
.expression {
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
  color: var(--neutrals-99999c);
}
.type-select3 {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0;
  gap: var(--gap-11xs);
}
.block-statement,
.column-4,
.type-select3 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.column-4 {
  width: 84px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-34xl) 0 0;
  box-sizing: border-box;
  gap: var(--gap-4xs);
  flex-shrink: 0;
}
.block-statement {
  height: 211px;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -211px;
}
.manual4,
.operator-assist4 {
  width: 14px;
  position: relative;
}
.manual4 {
  height: 100%;
  border-radius: var(--br-10xs);
  background-color: var(--primary-techblue-67afb7);
  min-height: 1px;
  max-height: 160px;
}
.operator-assist4 {
  align-self: stretch;
  background-color: var(--f1ce90);
}
.idle4,
.orange-bars3 {
  border-radius: var(--br-10xs);
}
.orange-bars3 {
  height: 66px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.idle4 {
  height: 48px;
  width: 14px;
  position: relative;
  background-color: var(--neutrals-cfcfcf);
  min-height: 1px;
  max-height: 160px;
}
.bars-container,
.bars4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bars4 {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-11xs);
}
.bars-container {
  flex: 1;
  align-items: flex-start;
  padding: 0 var(--padding-lgi);
}
.unselected {
  align-self: stretch;
  position: relative;
}
.plus-01-icon9 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.button4 {
  flex: 1;
  position: relative;
  font-weight: 500;
}
.arrow-dropdown-01-icon9 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.primary-buttons-fill4 {
  flex: 1;
  border-radius: var(--br-7xs);
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-7xs);
}
.column-5,
.primary-buttons-fill-wrapper,
.primary-buttons-fill4,
.type-select4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.primary-buttons-fill-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
  color: var(--neutrals-99999c);
}
.column-5,
.type-select4 {
  flex-direction: column;
}
.type-select4 {
  align-self: stretch;
  padding: var(--padding-10xs) 0;
  gap: var(--gap-11xs);
}
.column-5 {
  width: 84px;
  height: 211px;
  padding: var(--padding-34xl) 0 0;
  box-sizing: border-box;
  gap: var(--gap-4xs);
  opacity: 0;
  flex-shrink: 0;
  z-index: 1;
  margin-top: -211px;
}
.manual5,
.operator-assist5 {
  width: 14px;
  position: relative;
}
.manual5 {
  height: 100%;
  border-radius: var(--br-10xs);
  background-color: var(--primary-techblue-67afb7);
  min-height: 1px;
  max-height: 160px;
}
.operator-assist5 {
  align-self: stretch;
  background-color: var(--f1ce90);
}
.idle5,
.orange-bars4 {
  border-radius: var(--br-10xs);
}
.orange-bars4 {
  height: 66px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.idle5 {
  height: 48px;
  width: 14px;
  position: relative;
  background-color: var(--neutrals-cfcfcf);
  min-height: 1px;
  max-height: 160px;
}
.bars-frame,
.bars5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bars5 {
  align-items: flex-end;
  gap: var(--gap-11xs);
}
.bars-frame {
  flex: 1;
  align-items: flex-start;
  padding: 0 var(--padding-lgi);
}
.unselected1 {
  align-self: stretch;
  height: 12px;
  position: relative;
  display: inline-block;
}
.plus-01-icon10 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.button5 {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-weight: 500;
}
.arrow-dropdown-01-icon10 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.primary-buttons-fill-container,
.primary-buttons-fill5 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.primary-buttons-fill5 {
  border-radius: var(--br-7xs);
  overflow: hidden;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-7xs);
}
.primary-buttons-fill-container {
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
  color: var(--neutrals-99999c);
}
.type-select5 {
  height: 32px;
  flex-direction: column;
  padding: var(--padding-10xs) 0;
  box-sizing: border-box;
  gap: var(--gap-11xs);
}
.block-statement1,
.column-6,
.type-select5 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.column-6 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-34xl) 0 0;
  gap: var(--gap-4xs);
  opacity: 0;
  flex-shrink: 0;
  z-index: 2;
}
.block-statement1 {
  height: 211px;
  flex-direction: row;
  padding: 0 var(--padding-29xl);
  box-sizing: border-box;
  margin-top: -211px;
}
.manual6,
.operator-assist6 {
  width: 14px;
  position: relative;
}
.manual6 {
  height: 100%;
  border-radius: var(--br-10xs);
  background-color: var(--primary-techblue-67afb7);
  min-height: 1px;
  max-height: 160px;
}
.operator-assist6 {
  align-self: stretch;
  background-color: var(--f1ce90);
}
.idle6,
.orange-bars5 {
  border-radius: var(--br-10xs);
}
.orange-bars5 {
  height: 66px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.idle6 {
  height: 48px;
  width: 14px;
  position: relative;
  background-color: var(--neutrals-cfcfcf);
  min-height: 1px;
  max-height: 160px;
}
.bars6,
.frame-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bars6 {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-11xs);
}
.frame-div {
  flex: 1;
  align-items: flex-start;
  padding: 0 var(--padding-lgi);
}
.unselected2 {
  align-self: stretch;
  position: relative;
}
.plus-01-icon11 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.button6 {
  flex: 1;
  position: relative;
  font-weight: 500;
}
.arrow-dropdown-01-icon11 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.primary-buttons-fill-frame,
.primary-buttons-fill6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.primary-buttons-fill6 {
  flex: 1;
  border-radius: var(--br-7xs);
  overflow: hidden;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-7xs);
}
.primary-buttons-fill-frame {
  align-self: stretch;
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
  color: var(--neutrals-99999c);
}
.type-select6 {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0;
  gap: var(--gap-11xs);
}
.block-statement2,
.column-7,
.type-select6 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.column-7 {
  width: 84px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-34xl) 0 0;
  box-sizing: border-box;
  gap: var(--gap-4xs);
  opacity: 0;
  flex-shrink: 0;
  cursor: pointer;
  z-index: 3;
}
.block-statement2 {
  height: 211px;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -211px;
}
.data,
.expression-statement {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.expression-statement {
  flex: 1;
  flex-direction: column;
  min-width: 117px;
}
.data {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-xs);
  flex-shrink: 0;
  z-index: 1;
}
.number-range {
  height: 182px;
  width: 372px;
  flex-direction: column;
  padding: var(--padding-7xs) 0 0;
  margin-left: -388px;
  color: var(--neutrals-1f1f1f);
}
.graph,
.graph-wrapper,
.number-range,
.operations-graph {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.graph {
  flex: 1;
  flex-direction: row;
  padding: 0 var(--padding-base) 34px 0;
}
.graph-wrapper,
.operations-graph {
  align-self: stretch;
}
.graph-wrapper {
  flex-direction: row;
  padding: 0 25px;
  font-size: var(--body-3-size);
  color: var(--neutrals-333333);
}
.operations-graph {
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  overflow: hidden;
  flex-direction: column;
  padding: 0px 0 0px 0;
  gap: 44px;
  z-index: 1;
  // height: calc(100vh - 422px);
}
.work-overview {
  align-self: stretch;
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 103px;
}
.expression-list {
  width: 103px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
}
.implement-1-icon1 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.image-parser {
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 10px;
}
.header2,
.shape-compiler {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.shape-compiler {
  height: 23px;
  border-radius: var(--br-3xs);
  background-color: var(--neutrals-f7f7f7);
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-7xs);
  text-align: left;
  font-size: var(--iosweb-headline-h4-size);
  color: var(--neutrals-b5b5b6);
}
.header2 {
  width: 100%;
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  background-color: var(--color-gray-100);
  backdrop-filter: blur(10px);
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.operations3 {
  font-weight: 500;
}
.vector-icon {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm {
  //   width: 16px;
  //   position: absolute;
  //   margin: 0 !important;
  //   top: 25%;
  //   left: 10%;
  font-weight: 600;
  //   display: inline-block;
  //   min-width: 16px;
  //   z-index: 1;
}
.avatar-circles {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  background-color: var(--neutrals-E8E8E8);
  border-radius: var(--br-3xs);
  width: 20px;
  height: 20px;
  border: 1px solid var(--neutrals-ffffff);
  position: relative;
  z-index: 4;
}
.vector-icon1 {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm1 {
  font-weight: 600;
}
.avatar-circles1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  background-color: var(--neutrals-E8E8E8);
  border-radius: var(--br-3xs);
  width: 20px;
  height: 20px;
  border: 1px solid var(--neutrals-f7f7f7);
  position: relative;
  z-index: 3;
  margin-left: -3px;
}
.star-creator-icon {
  height: 20px;
  width: 20px;
  position: relative;
}
.div37 {
  font-weight: 600;
}
.avatar-circles2 {
  justify-content: center;
  gap: var(--gap-3xs);
  margin-left: -4px;
  color: var(--neutrals-ffffff);
  background-color: var(--neutrals-cfcfcf);
  border-radius: var(--br-3xs);
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 1;
}
.avatar-circles2,
.operator-icons,
.positioner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 20px;
}
.operator-icons {
  justify-content: flex-end;
  padding: 0 0 0 var(--padding-12xs);
  font-size: var(--iosweb-status-2-size);
  color: var(--primary-deepteal-366376);
}
.positioner {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.span2 {
  font-weight: 600;
}
.achr {
  font-size: var(--headline-h5-size);
  font-weight: 500;
}
.layer-llama {
  position: relative;
  display: flex;
  min-width: 59px;
  min-height: 20px;
  gap: 5px;
  align-items: center;
}
.layer-llama .span2 {
  line-height: 20px;
}
.increase-icon {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 12px;
}
.blend-brewer {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 19px;
}
.comparative-statistic {
  height: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-forestgreen-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  font-size: var(--body-3-size);
  color: var(--color-forestgreen-100);
}
.proximity-provider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--headline-h3-size);
  color: var(--neutrals-333333);
  min-height: 20px;
}
.efficiency-cards {
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-ffffff);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 12px;
  box-sizing: border-box;
  gap: var(--gap-xl);
  cursor: pointer;
}

.vector-icon2 {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm2 {
  width: 16px;
  position: absolute;
  margin: 0 !important;
  top: 25%;
  left: 10%;
  font-weight: 600;
  display: inline-block;
  min-width: 16px;
  z-index: 1;
}
.avatar-circles3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  background-color: var(--neutrals-dddddd);
}
.vector-icon3 {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm3 {
  width: 13px;
  position: absolute;
  margin: 0 !important;
  top: 25%;
  left: 20%;
  font-weight: 600;
  display: inline-block;
  min-width: 13px;
  z-index: 1;
}
.avatar-circles4 {
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 2;
  margin-left: -4px;
}
.avatar-circles4,
.operations-group,
.operator-icons1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.operator-icons1 {
  justify-content: flex-end;
  padding: 0 0 0 var(--padding-mid);
  font-size: var(--iosweb-status-2-size);
  color: var(--primary-deepteal-366376);
}
.operations-group {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.span3 {
  font-weight: 600;
}
.achr1 {
  font-size: var(--headline-h5-size);
  font-weight: 500;
}
.div38 {
  position: relative;
  display: inline-block;
  min-width: 59px;
}
.decrease-icon {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 12px;
}
.div39 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 18px;
}
.comparative-statistic1 {
  height: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-firebrick-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  font-size: var(--body-3-size);
  color: var(--accents-red-d43d38);
}

.vector-icon4 {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm4 {
  width: 16px;
  position: absolute;
  margin: 0 !important;
  top: 25%;
  left: 10%;
  font-weight: 600;
  display: inline-block;
  min-width: 16px;
  z-index: 1;
}
.avatar-circles5 {
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
}
.avatar-circles5,
.operations-container,
.operator-icons2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.operator-icons2 {
  justify-content: flex-end;
  padding: 0 0 0 var(--padding-14xl);
  font-size: var(--iosweb-status-2-size);
  color: var(--primary-deepteal-366376);
}
.operations-container {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.span4 {
  font-weight: 600;
}
.achr2 {
  font-size: var(--headline-h5-size);
  font-weight: 500;
}
.div40 {
  position: relative;
  display: inline-block;
  min-width: 59px;
}
.decrease-icon1 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 12px;
}
.div41 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 21px;
}
.comparative-statistic2 {
  height: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-firebrick-100);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  font-size: var(--body-3-size);
  color: var(--accents-red-d43d38);
}

.vector-icon5 {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm5 {
  width: 16px;
  position: absolute;
  margin: 0 !important;
  top: 25%;
  left: 10%;
  font-weight: 600;
  display: inline-block;
  min-width: 16px;
  z-index: 1;
}
.avatar-circles6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
}
.vector-icon6 {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm6 {
  width: 13px;
  position: absolute;
  margin: 0 !important;
  top: 25%;
  left: 20%;
  font-weight: 600;
  display: inline-block;
  min-width: 13px;
  z-index: 1;
}
.avatar-circles7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 2;
  margin-left: -4px;
}
.vector-icon7 {
  height: 20px;
  width: 20px;
  position: relative;
}
.div42 {
  width: 10px;
  position: absolute;
  margin: 0 !important;
  top: 25%;
  left: 25%;
  font-weight: 600;
  display: inline-block;
  min-width: 10px;
  z-index: 1;
}
.avatar-circles8 {
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 3;
  margin-left: -4px;
  color: var(--neutrals-ffffff);
}
.avatar-circles8,
.operations-parent1,
.operator-icons3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.operator-icons3 {
  justify-content: flex-end;
  padding: 0 0 0 var(--padding-12xs);
  font-size: var(--iosweb-status-2-size);
  color: var(--primary-deepteal-366376);
}
.operations-parent1 {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.span5 {
  font-weight: 600;
}
.achr3 {
  font-size: var(--headline-h5-size);
  font-weight: 500;
}
.div43 {
  position: relative;
  display: inline-block;
  min-width: 61px;
}
.increase-icon1 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 12px;
}
.div44 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 20px;
}
.comparative-statistic3 {
  height: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-forestgreen-200);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  font-size: var(--body-3-size);
  color: var(--color-forestgreen-100);
}
.parent1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--headline-h3-size);
  color: var(--neutrals-333333);
}
.color-coder {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 3px;
  padding-bottom: 3px;
}
.rotation-ranger {
  align-self: stretch;
  width: 2px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--neutrals-cfcfcf);
}
.opacity-optimizer,
.scrollbar {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.scrollbar {
  width: 11px;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs) var(--padding-3xs);
  opacity: 0;
}
.opacity-optimizer {
  height: 84px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
}

.bottom-left-graphs,
.bottom-left-graphs1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.link-aggregator {
  display: block;
  width: auto;
  height: auto;
  // overflow-y: auto;
  position: relative;
  // right: -12px;
  // padding-right: 10px;
  // min-height: 180px;
  // overflow-y: auto;
}
.bottom-left-graphs,
.bottom-left-graphs1,
.frame-parent {
  flex-direction: column;
}
.bottom-left-graphs {
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  box-sizing: border-box;
  overflow: hidden;
  padding: 12px var(--padding-base) var(--padding-base) var(--padding-base);
  gap: var(--gap-xl);
  z-index: 1;
}
.frame-parent {
  //   width: 500px;
  gap: 17px;
  //   min-width: 500px;
  display: grid;
  // grid-template-rows: 48px 216px minmax(250px, 250px);
}
.feather-feature,
.rounding-ranger {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--primary-techblue-67afb7);
}
.feather-feature {
  height: 75%;
  width: 37.5%;
  top: 12.86%;
  right: 6.07%;
  bottom: 12.14%;
  left: 56.43%;
  background-color: var(--neutrals-ffffff);
  z-index: 1;
}
.toggle {
  height: 14px;
  width: 28px;
  position: relative;
}
.display-weekends {
  flex: 1;
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 96px;
}
.toggle-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.stroke-styler {
  height: 14px;
  width: 2px;
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--neutrals-cfcfcf);
}
.search-01-icon {
  height: 14px;
  width: 14px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 14px;
}
.button7 {
  align-self: stretch;
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 96px;
}
.transition-tinkerer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
}
.arrow-dropdown-01-icon12 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.top-settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.mask-group-icon {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.autonomous {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 19px;
  line-height: 10px;
}
.path-8134-icon {
  height: 9px;
  width: 9px;
  position: relative;
}
.autonomous1 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 53px;
  line-height: 10px;
}
.history-hero {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.path-8134-icon1 {
  height: 9px;
  width: 9px;
  position: relative;
}
.autonomous2 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 40px;
}
.history-hero1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.path-8134-icon2 {
  height: 9px;
  width: 9px;
  position: relative;
}
.autonomous3 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 63px;
}
.history-hero2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.path-8134-icon3 {
  height: 9px;
  width: 9px;
  position: relative;
}
.autonomous4 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 16px;
}
.history-hero3 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.legend,
.version-vitality {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.version-vitality {
  flex: 1;
  gap: var(--gap-9xs);
  font-size: var(--iosweb-status-2-size);
  color: var(--color-darkgray-100);
}
.legend {
  position: absolute;
  left: 10px;
  bottom: 8px;
  border-radius: var(--br-7xs);
  background-color: rgba(255, 255, 255, 0.85);
  padding: var(--padding-7xs);
  box-sizing: border-box;
  gap: var(--gap-6xs);
  z-index: 5;
}
.time-legend {
  position: absolute;
  left: 10px;
  bottom: 8px;
  border-radius: var(--br-7xs);
  background-color: #0d0d0d;
  padding: var(--padding-7xs);
  box-sizing: border-box;
  gap: var(--gap-6xs);
  z-index: 5;
  color: #ffffff;
}
.bottom-icon,
.right-icon {
  position: absolute;
  margin: 0 !important;
}
.bottom-icon {
  height: 254.4px;
  width: 184.8px;
  bottom: -87.8px;
  left: -6.7px;
}
.right-icon {
  height: 164.5px;
  width: 277.2px;
  top: -0.1px;
  right: -96.8px;
  z-index: 1;
}
.middle-icon {
  height: 165.3px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  z-index: 2;
}
.overlay-icon,
.prototype-protector {
  margin: 0 !important;
  position: absolute;
}
.overlay-icon {
  height: 166.7px;
  width: 113.3px;
  bottom: -2.1px;
  left: -4.1px;
  z-index: 3;
}
.prototype-protector {
  height: 165.3px;
  width: 180.5px;
  top: 24.5px;
  left: 187.7px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.first-icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 4;
}
.pattern-set {
  height: 85.4px;
  width: 188.1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6.100000000000364px 0 0;
  box-sizing: border-box;
}
.numerical-matrix,
.operations7 {
  align-self: stretch;
  position: relative;
  font-weight: 500;
  display: inline-block;
}
.operations7 {
  width: 68px;
}
.numerical-matrix {
  height: 15px;
}
.data-processor {
  width: 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.connection-network {
  align-self: stretch;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.filter-stack,
.value-combiner {
  align-self: stretch;
  position: relative;
}
.value-combiner {
  width: 29px;
  display: inline-block;
  flex-shrink: 0;
}
.filter-stack {
  flex: 1;
}
.value-combiner-parent {
  height: 12px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-9xs);
}
.logic-junction-icon {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm7 {
  height: 10px;
  width: 16px;
  position: absolute;
  margin: 0 !important;
  top: 25%;
  left: 10%;
  font-weight: 600;
  display: inline-block;
  z-index: 1;
}
.avatar-circles9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
}
.vector-icon8 {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm8 {
  height: 10px;
  width: 13px;
  position: absolute;
  margin: 0 !important;
  top: 25%;
  left: 20%;
  font-weight: 600;
  display: inline-block;
  z-index: 1;
}
.avatar-circles10 {
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 2;
  margin-left: -4px;
}
.avatar-circles10,
.operations-layout,
.operator-icons4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.operator-icons4 {
  justify-content: flex-end;
  padding: 0 0 0 var(--padding-mid);
  text-align: center;
  font-size: var(--iosweb-status-2-size);
  color: var(--primary-deepteal-366376);
}
.operations-layout {
  flex: 1;
  border-radius: var(--br-9xs);
  background-color: var(--neutrals-ffffff);
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-6xl);
}
.div45,
.div46 {
  align-self: stretch;
  position: relative;
}
.div45 {
  width: 29px;
  display: inline-block;
  flex-shrink: 0;
}
.div46 {
  flex: 1;
}
.parent2 {
  height: 12px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-9xs);
}
.vector-icon9 {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm9 {
  height: 10px;
  width: 12px;
  position: absolute;
  margin: 0 !important;
  top: 25%;
  left: 20%;
  font-weight: 600;
  display: inline-block;
  z-index: 1;
}
.avatar-circles11 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
}
.vector-icon10 {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm10 {
  height: 10px;
  width: 14px;
  position: absolute;
  margin: 0 !important;
  top: 25%;
  left: 15%;
  font-weight: 600;
  display: inline-block;
  z-index: 1;
}
.avatar-circles12 {
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 2;
  margin-left: -4px;
}
.avatar-circles12,
.operations-layout1,
.operator-icons5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.operator-icons5 {
  justify-content: flex-end;
  padding: 0 0 0 var(--padding-mid);
  text-align: center;
  font-size: var(--iosweb-status-2-size);
  color: var(--primary-deepteal-366376);
}
.operations-layout1 {
  flex: 1;
  border-radius: var(--br-9xs);
  background-color: var(--neutrals-ffffff);
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-6xl);
}
.div47,
.div48 {
  align-self: stretch;
  position: relative;
}
.div47 {
  width: 29px;
  display: inline-block;
  flex-shrink: 0;
}
.div48 {
  flex: 1;
}
.parent3 {
  height: 12px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-9xs);
}
.vector-icon11 {
  height: 20px;
  width: 20px;
  position: relative;
}
.mm11 {
  height: 10px;
  width: 13px;
  position: absolute;
  margin: 0 !important;
  top: 25%;
  left: 20%;
  font-weight: 600;
  display: inline-block;
  z-index: 1;
}
.avatar-circles13,
.operator-icons6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.avatar-circles13 {
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
}
.operator-icons6 {
  justify-content: flex-end;
  padding: 0 0 0 var(--padding-14xl);
  text-align: center;
  font-size: var(--iosweb-status-2-size);
  color: var(--primary-deepteal-366376);
}
.operations-layout2,
.string-assembler {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
}
.operations-layout2 {
  border-radius: var(--br-9xs);
  background-color: var(--neutrals-ffffff);
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-6xl);
}
.string-assembler {
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-11xs);
  text-align: left;
  font-size: var(--body-3-size);
  color: var(--neutrals-5c5c5c);
}
.component-118,
.previews {
  box-shadow: var(--level-2);
  opacity: 0;
}
.previews {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-3xs);
  background-color: var(--neutrals-ffffff);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
  gap: var(--gap-5xs);
}
.component-118 {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: var(--primary-techblue-67afb7);
  border: 1px solid var(--neutrals-ffffff);
  box-sizing: border-box;
}
.paths,
.tags {
  align-items: flex-start;
}
.tags {
  height: 117px;
  width: 179px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: var(--gap-7xs);
  min-width: 179px;
  z-index: 4;
}
.paths {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 160.5px;
  position: relative;
  gap: 6.899999999999636px;
  z-index: 1;
}
.map,
.paths,
.share-sage {
  display: flex;
  justify-content: flex-start;
}
.share-sage {
  width: 465px;
  flex-direction: column;
  align-items: flex-start;
  min-height: 327px;
  max-width: 100%;
  margin-left: -51px;
  text-align: center;
  font-size: var(--headline-h5-size);
  color: var(--color-black);
}
.map {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  padding: 83px var(--padding-3xs) var(--padding-7xs);
  box-sizing: border-box;
  position: relative;
  max-width: 102%;
  flex-shrink: 0;
  row-gap: 20px;
}
.operations8 {
  position: relative;
  font-weight: 500;
  display: inline-block;
}
.plus-01-icon12 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.mowing1 {
  position: relative;
  font-weight: 500;
  display: inline-block;
}
.filters:hover {
  color: var(--007AFF);
}
.filters.sel {
  border: 1px solid var(--primary-deepteal-366376);
  color: var(--primary-deepteal-366376);
}
.arrow-dropdown-01-icon13 {
  height: 12px;
  width: 12px;
  position: relative;
  display: none;
  min-height: 12px;
}
.arrow-dropdown-01-icon13,
.filters,
.plus-01-icon13 {
  overflow: hidden;
  flex-shrink: 0;
}
.filters {
  //   width: 66px;
  border-radius: var(--br-xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-2xs);
  gap: var(--gap-7xs);
  cursor: pointer;
}
.plus-01-icon13 {
  height: 12px;
  width: 12px;
  position: relative;
  display: none;
  min-height: 12px;
}
.mowing2 {
  width: 42px;
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 42px;
}
.arrow-dropdown-01-icon14 {
  height: 12px;
  width: 12px;
  position: relative;
  display: none;
  min-height: 12px;
}
.arrow-dropdown-01-icon14,
.filters1,
.plus-01-icon14 {
  overflow: hidden;
  flex-shrink: 0;
}
.filters1 {
  width: 66px;
  border-radius: var(--br-3xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-2xs);
  gap: var(--gap-7xs);
}
.plus-01-icon14 {
  height: 12px;
  width: 12px;
  position: relative;
  display: none;
  min-height: 12px;
}
.mowing3 {
  width: 31px;
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 31px;
}
.arrow-dropdown-01-icon15 {
  height: 12px;
  width: 12px;
  position: relative;
  display: none;
  min-height: 12px;
}
.arrow-dropdown-01-icon15,
.filters2,
.plus-01-icon15 {
  overflow: hidden;
  flex-shrink: 0;
}
.filters2 {
  width: 55px;
  border-radius: var(--br-3xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-2xs);
  gap: var(--gap-7xs);
}
.plus-01-icon15 {
  height: 12px;
  width: 12px;
  position: relative;
  display: none;
  min-height: 12px;
}
.mowing4 {
  width: 58px;
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 58px;
}
.arrow-dropdown-01-icon16 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 12px;
}
.filters3,
.map-feature-op-type-filter {
  display: flex;
  flex-direction: row;
  gap: var(--gap-7xs);
}
.filters3 {
  flex: 1;
  border-radius: var(--br-3xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-2xs);
}
.map-feature-op-type-filter {
  //   width: 287px;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--body-3-size);
  color: var(--color-dimgray-100);
}
.header3 {
  align-self: stretch;
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  background-color: var(--color-gray-100);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
  z-index: 6;
  height: 44px;
  padding: var(--padding-xs) var(--padding-base);
}
.coverage-map,
.image-enhancer {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.image-enhancer {
  flex-direction: column;

  text-align: center;
  font-size: var(--headline-h5-size);
  color: var(--neutrals-5c5c5c);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 44px;
}
.coverage-map {
  align-self: stretch;
  border-radius: var(--br-5xs);
  border: 1px solid var(--neutrals-eeeeee);
  overflow: hidden;
  flex-direction: row;
  padding: 0;
  z-index: 1;
  text-align: left;
  color: var(--neutrals-333333);
  // height: calc(100vh - 355px);
  // height: calc(100vh - 355px);
  position: relative;
}
.coverage-map-video {
  align-self: stretch;
  // border-radius: var(--br-5xs);
  border: 1px solid var(--neutrals-eeeeee);
  overflow: hidden;
  flex-direction: row;
  padding: 0;
  z-index: 1;
  text-align: left;
  color: var(--neutrals-333333);
  // height: calc(100vh - 355px);
  // height: calc(100vh - 342px);
  position: relative;
}
.item1 > .coverage-map-video {
  // height: unset;
  min-height: 63px;
  max-height: 15em;
  height: 100% !important;
}
.operations9 {
  flex: 1;
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 80px;
}
.info-3-icon {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.savings-are-calculated {
  height: 80px;
  flex: 1;
  position: relative;
  line-height: 16px;
  display: inline-block;
}
.copy {
  width: 232px;
  height: 8px;
  border-radius: var(--br-9xs);
  background-color: var(--neutrals-ffffff);
  box-shadow: var(--level-2);
  border: 1px solid var(--primary-deepteal-366376);
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-5xs) var(--padding-4xs) 0;
}
.copy,
.info-bubbles,
.title-info {
  display: flex;
  justify-content: flex-start;
}
.info-bubbles {
  height: 16px;
  width: 16px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-6xs);
  text-align: left;
  cursor: pointer;
}
.title-info {
  // align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-7xs);
}
.filter-merger {
  position: relative;
  font-size: var(--headline-h1-size);
  font-weight: 600;
  color: var(--neutrals-333333);
  text-align: left;
  display: inline-block;
  min-width: 93px;
  white-space: nowrap;
}
.graph-call-outs2 {
  border-radius: var(--br-3xs);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.div49,
.operations10 {
  position: relative;
  display: inline-block;
}
.operations10 {
  min-width: 74px;
  white-space: nowrap;
}
.div49 {
  font-size: var(--headline-h3-size);
  font-weight: 600;
  text-align: left;
  min-width: 63px;
}
.graph-call-outs3 {
  border-radius: var(--br-3xs);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.co2-icon {
  height: 14px;
  width: 14px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.numerical-summarizer {
  height: 22px;
  margin: 0 !important;
  position: absolute;
  top: 7px;
  left: 136px;
  border-radius: var(--br-9xs);
  background-color: rgba(221, 221, 221, 0.8);
  flex-direction: row;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-cropper {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-f7f7f7);
  flex-direction: row;
  padding: var(--padding-6xs) var(--padding-6xs) var(--padding-3xs)
    var(--padding-3xs);
  position: relative;
  color: var(--neutrals-99999c);
}
.left-side {
  height: 142px;
  width: 165px;
  flex-direction: column;
  gap: var(--gap-3xs);
  display: flex;
  justify-content: center;
}
.usd-saved {
  align-self: stretch;
  position: relative;
  font-weight: 500;
  transform: rotate(-90deg);
}
.data-collector {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base-5) 0 0;
}
.image-processor,
.image-processor1 {
  position: relative;
  display: inline-block;
}
.image-processor {
  width: 10px;
  min-width: 10px;
}
.image-processor1 {
  align-self: stretch;
  min-width: 11px;
}
.image-processor2,
.image-processor3,
.image-processor4,
.image-processor5 {
  width: 10px;
  position: relative;
  display: inline-block;
  min-width: 10px;
}
.image-processor4,
.image-processor5 {
  width: 8px;
  min-width: 8px;
}
.image-processor5 {
  width: 6px;
  min-width: 6px;
}
.data-points1,
.y-axis {
  display: flex;
  justify-content: flex-start;
}
.data-points1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-4xs);
  text-align: right;
  font-size: var(--iosweb-status-2-size);
  color: var(--neutrals-5c5c5c);
}
.y-axis {
  width: 33px;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.circle-container,
.circle-container1,
.circle-container2,
.circle-container3,
.circle-container4,
.circle-container5 {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: var(--neutrals-f7f7f7);
}
.horizontal-dividers {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 19.8px;
  max-width: 100%;
}
.rectangle-div,
.vertical-dividers-child,
.vertical-dividers-child1,
.vertical-dividers-child2,
.vertical-dividers-child3,
.vertical-dividers-inner,
.vertical-dividers-item {
  height: 105px;
  width: 1px;
  position: relative;
  background-color: var(--neutrals-f7f7f7);
}
.graph-lines1,
.vertical-dividers,
.y-axis-graph-lines {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.vertical-dividers {
  width: 100%;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: space-between;
  gap: var(--gap-xl);
  height: 100%;
  z-index: 1;
}
.graph-lines1,
.y-axis-graph-lines {
  justify-content: flex-start;
}
.graph-lines1 {
  flex: 1;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  min-width: 292px;
}
.y-axis-graph-lines {
  align-self: stretch;
  gap: var(--gap-5xs);
}
.div50,
.div51 {
  position: relative;
  display: inline-block;
  min-width: 17px;
}
.filter-merger1,
.x-axis {
  display: flex;
  max-width: 100%;
}
.x-axis {
  width: 449px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  text-align: left;
  color: var(--neutrals-1f1f1f);
}
.filter-merger1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.fill-shadow-icon {
  width: 100%;
  height: 97px;
  right: 0;
  bottom: 0.5px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.fill-shadow-icon,
.hover-space,
.line-icon1 {
  position: absolute;
  margin: 0 !important;
}
.line-icon1 {
  width: 100%;
  height: 96.5px;
  right: 0;
  bottom: 1.5px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.hover-space {
  height: 104.5px;
  width: 220.7px;
  bottom: 0.5px;
  left: -78.6px;
  background-color: #d9d9d9;
  border: 1px solid var(--accents-violet-546cb2);
  box-sizing: border-box;
  opacity: 0;
  z-index: 2;
}
.point-call-out {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 3px;
  transform: scale(2.714);
}
.wrapper-point-call-out {
  width: 7px;
  height: 7px;
  position: absolute;
  margin: 0 !important;
  bottom: -1px;
  left: calc(50% - 3.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.grid-container,
.row-container {
  position: relative;
  display: inline-block;
}
.grid-container {
  width: 23px;
  height: 10px;
  font-weight: 500;
}
.row-container {
  align-self: stretch;
  height: 15px;
  font-size: var(--headline-h5-size);
  font-weight: 600;
  color: var(--accents-violet-546cb2);
  white-space: nowrap;
}
.text {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1px;
}
.min-width {
  height: 0;
  width: 24px;
  position: relative;
  background-color: var(--primary-techblue-67afb7);
}
.column-container,
.tooltipsolid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.column-container {
  height: 0;
  flex-direction: row;
  padding: 0 var(--padding-8xs) 0 5.5px;
}
.tooltipsolid {
  width: 47px;
  border-radius: var(--br-7xs);
  background-color: var(--neutrals-f7f7f7);
  flex-direction: column;
  padding: var(--padding-11xs) var(--padding-7xs);
}
.filter-factory {
  align-self: stretch;
  width: 1px;
  position: relative;
  background-color: var(--hover-line);
  box-shadow: var(--level-2);
  z-index: 1;
}
.transformer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-4xl);
}
.hover {
  align-self: stretch;
  flex: 1;
  border: 1px solid var(--accents-violet-546cb2);
  flex-direction: column;
  padding: 0 var(--padding-4xs);
  gap: var(--gap-11xs);
  opacity: 0;
  z-index: 3;
}
.graph1,
.hover,
.styler {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.styler {
  width: 67px;
  flex: 1;
  flex-direction: row;
}
.graph1 {
  align-self: stretch;
  height: 137px;
  flex-direction: column;
  padding: 0 115.69999999999982px;
  box-sizing: border-box;
  z-index: 1;
}
.aligner,
.savings-graph {
  display: flex;
  max-width: 100%;
}
.aligner {
  height: 123px;
  width: 449.5px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-lg);
  box-sizing: border-box;
  margin-left: -449px;
  font-size: var(--iosweb-status-2-size);
  color: var(--hover-title);
}
.savings-graph {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  min-width: 320px;
  font-size: var(--body-3-size);
  color: var(--neutrals-333333);
  min-height: unset !important;
}
.savings {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-mini) var(--padding-base);
  gap: 19px;
  z-index: 1;
  font-size: var(--headline-h5-size);
  color: var(--neutrals-5c5c5c);
}

.scale-scientist {
  // display: flex;
  // align-items: flex-end;
  // justify-content: center;
  // font-size: var(--body-3-size);
  color: var(--neutrals-99999c);
  gap: 16px;
  display: grid;
  // grid-template-rows: 52px 1fr minmax(172px, 172px);
  // min-width: 460px;
  // font-size: var(--body-3-size);
  // color: var(--neutrals-99999c);
  // display: grid;
  // grid-template-rows: 52px 1fr minmax(172px, 172px);
}
.background-parent {
  box-sizing: border-box;
  gap: var(--gap-xl);
  text-align: center;
  font-size: var(--headline-h5-size);
  color: var(--neutrals-5c5c5c);
  font-family: var(--headline-h5);
}
.layer-controller {
  position: absolute;
  top: 58.5px;
  left: 21.4px;
  background-color: #e7e7e7;
  box-shadow: var(--level-1);
  width: 1366px;
  height: 1px;
}
.recordings {
  position: absolute;
  top: 21.5px;
  left: 630px;
  min-width: 85px;
}
.operations11,
.recordings,
.reports {
  font-weight: 600;
  display: inline-block;
}
.reports {
  position: absolute;
  top: 21.5px;
  left: 943px;
  width: 84px;
  height: 15px;
}
.operations11 {
  flex: 1;
  position: relative;
  min-width: 84px;
}
.opacity-controller {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
}
.scale-controller {
  align-self: stretch;
  height: 2px;
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--primary-techblue-67afb7);
}
.blending-mode-mixer {
  position: absolute;
  top: 21.5px;
  left: 314px;
  width: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  color: var(--neutrals-333333);
}
.bg-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.monarch-tech-logo {
  width: 88px;
  height: 16px;
  position: relative;
  display: none;
  z-index: 1;
}
.wingspanai-logo-icon {
  align-self: stretch;
  height: 23px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.icon-selector {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--primary-deepteal-1e3a46);
}
.liveops-1-icon {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 8px;
  left: 8px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.image-selector {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
}
.live-map {
  width: 57px;
  position: relative;
  font-weight: 500;
  display: none;
}
.live-ops {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frame-child {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--primary-deepteal-1e3a46);
}
.farm-profile-01-icon {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 8px;
  left: 8px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
}
.live-map1 {
  width: 94px;
  position: relative;
  font-weight: 500;
  display: none;
}
.farm-prof {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.instance-child {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--primary-deepteal-1e3a46);
}
.maintenance-2-icon {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 8px;
  left: 8px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.stylesheet-manager {
  height: 10px;
  width: 10px;
  position: absolute;
  margin: 0 !important;
  top: 22px;
  left: 22px;
  border-radius: var(--br-3xs);
  background-color: var(--accents-red-d43d38);
  border: 1.5px solid var(--primary-deepteal-1e3a46);
  box-sizing: border-box;
  z-index: 2;
}
.rectangle-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
}
.live-map2 {
  width: 94px;
  position: relative;
  font-weight: 500;
  display: none;
}
.maintenance {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frame-item {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--primary-deepteal-366376);
}
.analytics-1-icon {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 8px;
  left: 8px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.rectangle-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
}
.live-map3 {
  width: 70px;
  position: relative;
  font-weight: 600;
  display: none;
}
.analytics {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--neutrals-ffffff);
}
.instance-item {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--primary-deepteal-1e3a46);
}
.knowledgebase-2-icon {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 8px;
  left: 8px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.rectangle-parent1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
}
.live-map4 {
  width: 88px;
  position: relative;
  font-weight: 500;
  display: none;
}
.help-center {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.line-icon2 {
  width: 40px;
  height: 1px;
  position: relative;
}
.upper1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.upper {
  flex-direction: row;
  z-index: 1;
}
.proximity-controller,
.upper,
.upper-wrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.upper-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-8xs);
}
.proximity-controller {
  flex-direction: column;
  gap: 47px;
}
.collaboration-manager {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--primary-deepteal-1e3a46);
}
.notification-outline-01-icon {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 8px;
  left: 8px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.settings-controller {
  height: 10px;
  width: 10px;
  position: absolute;
  margin: 0 !important;
  top: 22px;
  left: 22px;
  border-radius: var(--br-3xs);
  background-color: var(--accents-red-d43d38);
  border: 1.5px solid var(--primary-deepteal-1e3a46);
  box-sizing: border-box;
  z-index: 2;
}
.collaboration-manager-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
}
.live-map5 {
  width: 97px;
  position: relative;
  font-weight: 500;
  display: none;
}
.notifications {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.instance-inner {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--primary-deepteal-1e3a46);
}
.logout-01-icon {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 8px;
  left: 8px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.rectangle-parent2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
}
.live-map6 {
  width: 53px;
  position: relative;
  font-weight: 500;
  display: none;
}
.log-out {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.mm12,
.output-merger-icon {
  position: absolute;
  margin: 0 !important;
}
.output-merger-icon {
  height: 36px;
  width: 36px;
  top: 0;
  left: 0;
}
.mm12 {
  width: 26px;
  top: 27.78%;
  left: calc(50% - 13px);
  font-size: var(--headline-h5-size);
  font-weight: 600;
  font-family: var(--headline-h5);
  color: var(--neutrals-ffffff);
  text-align: center;
  display: inline-block;
  min-width: 26px;
  z-index: 1;
}
.avatar-circles14 {
  height: 36px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
}
.live-map7 {
  width: 111px;
  position: relative;
  font-size: var(--headline-h5-size);
  font-weight: 600;
  font-family: var(--headline-h5);
  color: var(--neutrals-ffffff);
  text-align: left;
  display: none;
  flex-shrink: 0;
}
.my-profile {
  cursor: pointer;
  border: 0;
  padding: 0 var(--padding-9xs) 0 0;
  background-color: transparent;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.lower {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-5xl);
  z-index: 1;
}
.lower,
.main-nav-bar-new,
.template-manager {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.template-manager {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-8xs);
}
.main-nav-bar-new {
  position: absolute;
  top: 0;
  left: 0;
  width: 84px;
  height: 768px;
  flex-direction: column;
  padding: 32px var(--padding-mid) var(--padding-29xl);
  box-sizing: border-box;
  gap: 133px;
  z-index: 1;
  text-align: left;
  color: var(--neutrals-cfcfcf);
}
.first-screen {
  width: 100%;
  height: 768px;
  position: relative;
  background-color: var(--neutrals-ffffff);
  overflow: hidden;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--headline-h5-size);
  color: var(--neutrals-b5b5b6);
  font-family: var(--headline-h5);
}

@media screen and (max-width: 991px) {
  .common_tabs .ant-tabs-content-holder {
    overflow-y: auto;
  }
}

@media screen and (max-width: 1025px) {
  .frame-parent {
    flex: 1;
  }
  .background-parent {
    flex-wrap: wrap;
  }
  .first-screen {
    height: auto;
    min-height: 768;
  }
}
@media screen and (max-width: 725px) {
  .axis-line {
    flex-wrap: wrap;
  }
  .frame-parent {
    min-width: 100%;
  }
  .share-sage {
    margin-left: 0;
  }
  .header3,
  .map,
  .savings,
  .y-axis-graph-lines {
    flex-wrap: wrap;
  }
  .scale-scientist {
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .div35,
  .november-3rd- {
    font-size: var(--headline-h3-size);
  }
  .data,
  .header1 {
    flex-wrap: wrap;
  }
  .number-range {
    height: auto;
  }
  .header2 {
    flex-wrap: wrap;
  }
  .tags {
    flex: 1;
  }
  .paths {
    flex-wrap: wrap;
  }
  .filter-merger {
    font-size: 19px;
  }
  .rectangle-div,
  .vertical-dividers-child,
  .vertical-dividers-child1,
  .vertical-dividers-child2,
  .vertical-dividers-child3,
  .vertical-dividers-inner,
  .vertical-dividers-item {
    width: 100%;
    height: 1px;
  }
  .vertical-dividers {
    flex-wrap: wrap;
  }
}

.op_tab_title {
  color: var(--neutrals-5c5c5c);
  font-size: var(--headline-h5-size);
  font-weight: 500;
  text-transform: none;
  font-family: var(--headline-h5);
  margin-bottom: 10px;
  padding: 0px 0px 0px 0px;
  text-align: left;
  min-width: 116px;
  line-height: 15px;
}
.op_tabs_sec .ant-tabs-tab.ant-tabs-tab-active .op_tab_title {
  color: var(--neutrals-333333);
}
.op_tab_subTitle {
  color: var(--neutrals-333333);
  font-size: var(--headline-h2-size);
  font-family: var(--headline-h5);
  line-height: normal;
  font-weight: 600;
}

.op_tabs_sec {
  width: 100%;
  position: relative;
}

.op_tabs_sec .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex: inherit !important;
}

.op_tabs_sec .ant-tabs-content-holder {
  height: 100%;
  background-color: #fff !important;
  text-align: left;
  padding: 21px 20px !important;
}
.op_tabs_sec .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: unset !important;
  top: 0 !important;
}

.op_tabs_sec .ant-tabs-tab + .ant-tabs-tab {
  margin: 14px 0 0 18px !important;
}
.op_tabs_sec .ant-tabs-nav {
  padding: 0 18px 0px 18px !important;
}
.op_tabs_sec .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: inherit !important;
  box-shadow: none !important;
}

.op_tabs_sec .ant-tabs-tab {
  margin-top: 16px !important;
}

.opHrsSec {
  padding-top: 19px;
  padding-left: 18px;
  width: 136px;
}
.opHrsTxt {
  width: 136px;
  padding-left: 18px;
  padding-right: 0px;
  justify-content: center;
}
.operations-graph .op_tabs_sec .ant-tabs-tab {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.op_tabs_sec .ant-tabs-nav-list {
  align-items: flex-start;
}
.op_tabs_sec .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
  display: none;
}

// .op_tabs_sec .ant-tabs.ant-tabs-top {
//   display: flex;
//   gap: 1.15vh;
// }

.toggleContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
  border: 3px solid #f7f7f7;
  border-radius: 6px;
  background: #f7f7f7;
  font-weight: 600;
  color: var(--neutrals-b5b5b6);
  cursor: pointer;
  gap: 4px;
}
.toggleContainer::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius: 6px;
  background: white;
  transition: all 0.3s;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
}
.toggleCheckbox:checked + .toggleContainer::before {
  left: 50%;
}
.toggleContainer div {
  padding: 6px;
  text-align: center;
  z-index: 1;
}
.toggleCheckbox {
  display: none;
}
.toggleCheckbox:checked + .toggleContainer div:first-child {
  color: var(--neutrals-cfcfcf);
  transition: color 0.3s;
}
.toggleCheckbox:checked + .toggleContainer div:last-child {
  color: var(--neutrals-b5b5b6);
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:first-child {
  color: var(--neutrals-b5b5b6);
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:last-child {
  color: var(--neutrals-cfcfcf);
  transition: color 0.3s;
}

.toggleContainer img {
  margin-right: 4px;
}

.op_tabs_sec .ant-tabs-content {
  height: 100%;
}
.imgResp {
  max-width: 100%;
  width: 100%;
}

.op-search-bar {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  width: 120px;
  cursor: pointer;
}

.op-search-bar input.ant-input {
  background: none;
  color: var(--neutrals-99999c);
  font-size: var(--body-3-size);
  font-weight: 600;
  height: 14px;
  padding: 0px !important;
  display: flex;
  align-items: center;
  outline: none;
  border: none !important;
  cursor: pointer;
}

.op-search-bar .anticon-search {
  color: #99999c;
  width: 14px;
  height: 14px;
  background-image: url(../images/search_hc.svg);
  background-size: 14px;
  position: relative;
  top: 0px;
  background-repeat: no-repeat;
}
.op-search-bar .anticon-search svg {
  display: none;
}
.op-search-bar .undefined-action {
  height: 14px;
  margin-right: 6px;
}

.op-search-bar .ant-input:focus,
.op-search-bar .ant-input-focused {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}
.op-search-bar .ant-input::placeholder {
  color: var(--neutrals-99999c);
  font-size: var(--body-3-size);
  font-weight: 600;
}
.toggle .ant-switch {
  height: 14px;
  width: 28px;
  min-width: 28px;
  background-color: var(--neutrals-eeeeee);
}

.toggle .ant-switch-checked {
  background-color: var(--primary-techblue-67afb7);
}
.toggle .ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 10.5px;
  height: 10.5px;
  transition: all 0.2s ease-in-out;
}
.toggle .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 10.5px - 2px);
}
.toggle .ant-switch:focus {
  outline: none !important;
  box-shadow: none !important;
}
// .coverage-map img,
// .savings-graph img {
//   max-width: 100%;
//   width: 100%;
//   height: 100%;
// }

.savings {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

/* table styles starts here ************************************ */

.tableParent .ant-table table {
  padding: 0px !important;
}

.tableParent .ant-table-thead > tr > th {
  font-weight: 400;
  color: var(--neutrals-b5b5b6);
  padding: 3px 5px !important;
  font-size: var(--headline-h5-size);
  font-family: 'Montserrat', sans-serif;
  height: inherit !important;
  border-bottom: none !important;
  margin-bottom: 10px;
}

.tableParent .ant-table-tbody > tr > td {
  font-weight: 400;
  color: var(--neutrals-5C5C5C);
  padding: 5px 5px !important;
  font-size: var(--headline-h5-size);
  font-family: 'Montserrat', sans-serif;
  height: inherit !important;
  line-height: 14px;
}
.tableParent .ant-pagination {
  display: none !important;
}

.tableParent .ant-table table {
  padding: 0px !important;
}

.tableParent .ant-table-thead > tr > th {
  font-weight: 400;
  color: var(--neutrals-b5b5b6);
  padding: 3px 5px !important;
  font-size: var(--headline-h5-size);
  font-family: 'Montserrat', sans-serif;
  height: inherit !important;
  border-bottom: none !important;
  margin-bottom: 10px;
}

.tableParent .ant-table-tbody > tr > td {
  font-weight: 400;
  color: var(--neutrals-5C5C5C);
  padding: 5px 5px !important;
  font-size: var(--headline-h5-size);
  font-family: 'Montserrat', sans-serif;
  height: inherit !important;
  line-height: 14px;
}
.tableParent .ant-pagination {
  display: none !important;
}

/* table styles end here ************************************ */

.back-header {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 16px;
  cursor: pointer;
}
.back-button {
  box-shadow: var(--level-2, 0px 4px 6px 0px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  background: #ffffff;
  margin: 0 16px 23px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 9.1px 13.6px 9.1px 10.9px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
}
.header {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.mowing {
  // margin-bottom: 6px;
  display: inline-block;
  align-self: flex-start;
  overflow-wrap: break-word;
  font-family: var(--headline-h-2-font-family, 'Montserrat');
  font-weight: var(--headline-h-2-font-weight, 600);
  font-size: var(--headline-h-2-font-size, 20px);
  color: #333333;
  line-height: 24px;
}
.date-date-picker {
  display: flex;
  flex-direction: row;
  width: fit-content;
  box-sizing: border-box;
}
.november-3-rd-9-th {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.333;
  color: #333333;
}
.november-3-rd-9-th-sub3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.333;
  color: #333333;
}
.date-picker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 26px;
  height: 26px;
  box-sizing: border-box;
}

.date-picker-section {
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 130px;
}

.date-month-selection {
  position: relative;
  font-size: var(--iosweb-headline-h4-size);
  color: var(--neutrals-5c5c5c);
  z-index: 1;
  line-height: 15px;
  font-weight: 400;
}

.date-picker-section .date-picker {
  width: 20px;
  height: 20px;
}
.date-picker-section .date-picker img {
  width: 30px;
  height: 30px;
}

.chip_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  right: 18px;
  top: 18px;
  z-index: 1;
}

.container-17 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  box-sizing: border-box;
}

.container-18 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 16px;
  box-sizing: border-box;
}

.mini-graphs {
  border-radius: 8px;
  border: 1px solid var(--neutrals-eeeeee, #eeeeee);
  background: var(--neutrals-ffffff, #ffffff);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px;
  box-sizing: border-box;
}

.dflex {
  display: flex;
}
.align-top {
  align-items: flex-start !important;
}
.justify-content {
  justify-content: flex-start !important;
}
.align-left {
  text-align: left;
}

.operations4 {
  font-weight: 400;
  color: var(--neutrals-5C5C5C);
}

.graph-call-outs6 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: var(--gap-6xs);
  padding: 0 var(--padding-11xs);
}

.graphImg {
  height: 75px;
  max-width: 100%;
  width: 100% !important;
}

.bottom-left-graphs1 {
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  box-sizing: border-box;
  overflow: hidden;
  padding: 12px 16px 16px 16px;
  gap: var(--gap-9xs);
  z-index: 1;
}

.no-gap {
  gap: 0;
}

/* OPERATION drill down  ************************************************** */

.frame-parent2 {
  gap: 12px;
  display: grid;
  // grid-template-rows: 48px minmax(327px, auto) minmax(95px, 95px) minmax(
  //     156px,
  //     156px
  //   );
  height: 100%;
}

.header-info {
  display: grid;
  align-items: center;
  align-self: stretch;
  gap: 12px;
  position: relative;
  padding: 12px 16px 12px 16px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 12px;
  grid-template-columns: 75% 106px;
}

.name-implement-operators {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;
  height: 100px;
}
.text-10343 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #000000;

  font-size: 16px;
  font-weight: 600;
  line-height: 19.504px;
  text-align: left;
  white-space: nowrap;
  z-index: 441;
}
.implement-operators {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  z-index: 442;
}
.edit-implement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 2px;
  position: relative;
  width: 156.6px;
  padding: 6px 6px 6px 6px;
  z-index: 443;
  border-radius: 4px;
}
.implement-name {
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 6px;
  position: relative;
  width: 144.6px;
  z-index: 444;
  border-radius: 4px;
}
.hitch-implement {
  flex-shrink: 0;
  position: relative;
  width: 15.6px;
  height: 12px;
  background: url(../images/tractor_img.png) no-repeat center;
  background-size: cover;
  z-index: 445;
  overflow: hidden;
}
.bush-hog-rotary-mower {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #5c5c5c;

  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 446;
}
.frame-69 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 86px;
  padding: 0 0 0 21px;
  z-index: 447;
}
.edit-implement-6a {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 10px;
  color: #cfcfcf;
  font-size: 8px;
  font-weight: 500;
  line-height: 9.752px;
  text-align: left;
  white-space: nowrap;
  z-index: 448;
  cursor: pointer;
}
.edit-implement-6a:hover {
  color: #67afb7;
}
.frame-6b {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  gap: 26px;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 5px;
  margin-bottom: -5px;
}
.frame-6c {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 6px;
  position: relative;
  min-width: 168px;
}
.operator-icons {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  // gap: 4px;
  position: relative;
  // min-width: 60px;
  // z-index: 451;
}
.avatar-circles-6d {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 20px;
  z-index: 452;
}
.vector-6e {
  flex-shrink: 0;
  position: relative;
  width: 21px;
  height: 21px;
  background: url(../images/tractor_img.png) no-repeat center;
  background-size: cover;
  z-index: 453;
}
.mm-6f {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: absolute;
  width: 16px;
  height: 10px;
  top: 25%;
  left: 10%;
  color: #366376;

  font-size: 8px;
  font-weight: 600;
  line-height: 9.752px;
  text-align: center;
  white-space: nowrap;
  z-index: 454;
}
.matt-monroe {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #5c5c5c;

  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 455;
}
.h-m-ac {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #5c5c5c;

  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 456;
}
.frame-70 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 6px;
  position: relative;
  width: 164px;
  z-index: 457;
}
.operator-icons-71 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: -4px;
  position: relative;
  width: 20px;
  z-index: 458;
}
.avatar-circles-72 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 20px;
  z-index: 459;
}
.vector-73 {
  flex-shrink: 0;
  position: relative;
  width: 21px;
  height: 21px;
  background: url(../images/tractor_img.png) no-repeat center;
  background-size: cover;
  z-index: 460;
}
.ms {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: absolute;
  width: 13px;
  height: 10px;
  top: 25%;
  left: 20%;
  color: #366376;

  font-size: 8px;
  font-weight: 600;
  line-height: 9.752px;
  text-align: center;
  white-space: nowrap;
  z-index: 461;
}
.mike-smith {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #5c5c5c;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 462;
}
.h-m-ac-74 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #5c5c5c;

  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 463;
}
.tractor-render {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 102px;
  padding: 10px 8px 10px 8px;
  z-index: 464;
  border-radius: 8px;
}
.image {
  flex-shrink: 0;
  position: relative;
  width: 86px;
  height: 72px;
  background: url(../images/tractor_img.png) no-repeat center;
  background-size: cover;
  z-index: 465;
}
.rectangle-75 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 1px;
  background: #eeeeee;
  z-index: 466;
  border-radius: 10px;
}
.duration {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 18px;
  position: relative;
  min-width: 0;
  padding: 8px 16px;
  z-index: 467;
}
.graph-call-outs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  border-radius: 10px;
}
.title-icon {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 6px;
  position: relative;
  min-width: 0;
  z-index: 469;
}
.time {
  flex-shrink: 0;
  position: relative;
  width: 16px;
  height: 16px;
  background: url(../images/tractor_img.png) no-repeat center;
  background-size: cover;
  z-index: 470;
  overflow: hidden;
}
.operations-duration {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 55px;
  height: 15px;
  color: #5c5c5c;

  font-size: 12px;
  font-weight: 600;
  line-height: 14.628px;
  text-align: center;
  white-space: nowrap;
  z-index: 471;
}
.caption {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;

  font-size: 10px;
  font-weight: 500;
  line-height: 12.19px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 472;
}
.operation-lasted {
  position: relative;
  color: #5c5c5c;

  font-size: 10px;
  font-weight: 500;
  line-height: 12.19px;
  text-align: left;
}
.h-m {
  position: relative;
  color: #333333;

  font-size: 12px;
  font-weight: 600;
  line-height: 14.628px;
  text-align: left;
}

.jngs .h-m {
  position: relative;
  color: #99999c;
  font-size: 10px;
  font-weight: 500;
  line-height: 14.628px;
  text-align: left;
}

.operation-lasted-76 {
  position: relative;
  color: #5c5c5c;

  font-size: 10px;
  font-weight: 500;
  line-height: 12.19px;
  text-align: left;
}
.pie-chart {
  position: relative;
  color: #5c5c5c;

  font-size: 10px;
  font-weight: 500;
  line-height: 12.19px;
  text-align: left;
}
.group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  top: 10px;
  // right: 26px;
  // z-index: 473;
  // overflow: hidden;
}
.percentage-15 {
  flex-shrink: 0;
  position: relative;
  width: 172.417px;
  height: 172.417px;
  background: url(../images/tractor_img.png) no-repeat center;
  background-size: cover;
  z-index: 474;
}
.percentage-26 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: absolute;
  width: 19px;
  height: 12px;
  top: 41px;
  left: 23px;
  color: #ffffff;

  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 477;
}
.percentage-8 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: absolute;
  width: 21px;
  height: 12px;
  top: 96px;
  left: 29px;
  color: #ffffff;

  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 476;
}
.percentage-51 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: absolute;
  width: 16px;
  height: 12px;
  top: 17px;
  left: 53px;
  color: #ffffff;

  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 478;
}
.stats {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: absolute;
  width: 19px;
  height: 12px;
  top: 66px;
  left: 115px;
  color: #ffffff;

  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 475;
}
.frame-77 {
  display: grid;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 36px;
  gap: 36px;
  padding: 4px 30px 4px 30px;
  z-index: 479;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
}
.rectangle-78 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  position: relative;
}
.manual-bar {
  width: 3px;
  background: var(--primary-techblue-67afb7);
  height: 35px;
  border-radius: 5px;
}
.autonomy-bar {
  width: 3px;
  background: var(--ec9322);
  height: 35px;
  border-radius: 5px;
}
.opassist-bar {
  width: 3px;
  background: var(--f1ce90);
  height: 35px;
  border-radius: 5px;
}
.idle-bar {
  width: 3px;
  background: var(--neutrals-cfcfcf);
  height: 35px;
  border-radius: 5px;
}

.title-4h-03m {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 2px;
  position: relative;
  width: 55px;
  padding: 2px 0 2px 0;
  z-index: 482;
}
.title-manual {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 17px;
  color: #333333;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  white-space: nowrap;
  z-index: 483;
}
.frame-7a {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #5c5c5c;

  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 484;
}
.rectangle-7b {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  position: relative;
  width: 74px;
  z-index: 490;
}

.title-2h-05m {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 2px;
  position: relative;
  width: 54px;
  padding: 2px 0 2px 0;
  z-index: 492;
}
.op-assist {
  flex-shrink: 0;
  position: relative;
  width: 54px;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 493;
}
.frame-7d {
  position: relative;
  color: #333333;

  font-size: 14px;
  font-weight: 500;
  line-height: 17.066px;
  text-align: left;
}
.rectangle-7e {
  position: relative;
  color: #333333;

  font-size: 14px;
  font-weight: 500;
  line-height: 17.066px;
  text-align: left;
}
.frame-7f {
  position: relative;
  color: #333333;

  font-size: 14px;
  font-weight: 500;
  line-height: 17.066px;
  text-align: left;
}
.title-1h-12m {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #5c5c5c;

  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 494;
}
.title-autonomy {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  position: relative;
  width: 74px;
  z-index: 485;
}
.frame-80 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 3px;
  background: url(../images/tractor_img.png) no-repeat center;
  background-size: cover;
  z-index: 486;
}
.rectangle-81 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 2px;
  position: relative;
  width: 54px;
  padding: 2px 0 2px 0;
  z-index: 487;
}
.frame-82 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 17px;
  color: #333333;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  white-space: nowrap;
  z-index: 488;
}
.title-35-m {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #5c5c5c;

  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 489;
}
.group-1a {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  position: relative;
  width: 74px;
  z-index: 495;
}
.pic-7 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 3px;
  background: url(../images/tractor_img.png) no-repeat center;
  background-size: cover;
  z-index: 496;
}
.group-1b {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 2px;
  position: relative;
  width: 46px;
  padding: 2px 0 2px 0;
  z-index: 497;
}
.wrapper-22 {
  flex-shrink: 0;
  position: relative;
  width: 35px;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 498;
}
.text-52 {
  position: relative;
  color: #333333;

  font-size: 14px;
  font-weight: 500;
  line-height: 17.066px;
  text-align: left;
}
.text-53 {
  position: relative;
  color: #333333;

  font-size: 14px;
  font-weight: 500;
  line-height: 17.066px;
  text-align: left;
}
.idle-time {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #5c5c5c;

  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 499;
}

.operations-graph1 {
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  overflow: hidden;
  flex-direction: column;
  padding: 0 0 12px 0px;
  z-index: 1;
  // height: calc(100vh - 452px);
}

.duration-block {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
  width: 100%;
  grid-template-columns: 1fr 160px;
}

.sixEight {
  width: 68%;
}

.statistics {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
  height: 95px;
}
.statistics-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 10px 10px;
  background: var(--neutrals-ffffff);
  border: 1px solid #eeeeee;
  border-radius: 6px;
  min-width: 118px;
  flex: 1;
  min-height: 95px;
}
.statistics-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.statistics-title {
  color: var(--neutrals-5C5C5C);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  gap: 3px;
}
.statistics-value {
  color: var(--neutrals-333333);
  font-size: 16px;
  font-weight: 600;
  line-height: 19.504px;
  text-align: left;
}

.tickets {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 12px 12px 16px 12px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
}

.tickets-block {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  min-width: 0;
  padding: 2px 2px 2px 2px;
  z-index: 308;
  overflow-y: auto;
  justify-self: center;
}

.ticket-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 12px;
  position: relative;
  width: 227px;
  padding: 10px 8px 10px 8px;
  background: rgba(247, 247, 247, 0.8);
  z-index: 309;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}
.top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  z-index: 310;
}
.assigned-to-title {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 148px;
  z-index: 311;
}
.assigned-to {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  // width: 24px;
  // height: 24px;
  // padding: 0 6px 0 6px;
  // background: #366376;
  z-index: 312;
  border-radius: 20px;
}
.a-name {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 10px;
  color: #ffffff;
  font-family: Montserrat, var(--default-font-family);
  font-size: 8px;
  font-weight: 600;
  line-height: 9.752px;
  text-align: left;
  white-space: nowrap;
  z-index: 313;
}
.ticket-number {
  display: flex;
  align-items: flex-start;
  // justify-content: center;
  position: relative;
  min-width: 118px; //89px;
  height: 15px;
  color: #000000;
  font-family: Montserrat, var(--default-font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.628px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.status-rsl {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 53px;
  padding: 3px 8px 3px 8px;
  cursor: pointer;
  background: rgba(55, 149, 38, 0.15);
  border: none;
  z-index: 315;
  overflow: hidden;
  border-radius: 25px;
}
.resolved {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 10px;
  color: rgba(55, 149, 38, 0.5);
  font-family: Montserrat, var(--default-font-family);
  font-size: 8px;
  font-weight: 400;
  line-height: 9.752px;
  text-align: left;
  white-space: nowrap;
  z-index: 316;
}
.copy-severity {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  z-index: 317;
}

.reboot-hitch-not-responding {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 159px;
  // height: 24px;
  color: #1f1f1f;
  font-family: Montserrat, var(--default-font-family);
  font-size: 10px;
  font-weight: 400;
  line-height: 12.19px;
  text-align: left;
}

.description-date {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.creator-time-change {
  min-width: 85px !important;
  margin-left: 8px !important;
}

.creator-time-193 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 75px;
  z-index: 320;
}
.c-name {
  position: relative;
  height: 10px;
  color: #5c5c5c;
  font-family: Montserrat, var(--default-font-family);
  font-size: 8px;
  font-weight: 400;
}

.pm-196 {
  position: relative;
  height: 10px;
  color: #5c5c5c;
  font-family: Montserrat, var(--default-font-family);
  font-size: 8px;
  font-weight: 400;
  line-height: 9.752px;
  text-align: left;
}

.severity-flag {
  flex-shrink: 0;
  position: relative;
  width: 36px;
  height: 9.91px;
  z-index: 324;
  display: flex;
  gap: 6px;
}

.assigned-to-monarch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 24px;
  height: 24px;
  padding: 0 6px 0 6px;
  background: rgba(236, 147, 34, 0.25);
  border-radius: 20px;
}

.status-fwd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  position: relative;
  padding: 3px 8px 3px 8px;
  cursor: pointer;
  background: var(--EB922305);
  border: 1px solid var(--EB922325);
  border-radius: 25px;
}

.forwarded {
  color: var(--EB922375);
  font-size: 8px;
  font-weight: 400;
  line-height: 9.752px;
}

.bottom-left-tickets {
  display: flex;
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  box-sizing: border-box;
  overflow: hidden;
  padding: 12px var(--padding-base) var(--padding-base) var(--padding-base);
  gap: var(--gap-16xs);
  z-index: 1;
  flex-direction: column;
}

.coverage-map2 {
  display: flex;
  background: var(--neutrals-ffffff);
  border-radius: 8px;
  flex-direction: column;
  padding: var(--padding-xs) 0 0 0;
  gap: var(--gap-16xs);
  // height: calc(100vh - 163px);
  z-index: 1;
}

.incidents-section {
  flex: 1;
  flex-direction: column;
  gap: 12px;
  font-size: var(--body-3-size);
  color: var(--neutrals-99999c);
  display: grid;
  // grid-template-rows: 52px 1fr;
}
.map-tag-line {
  font-size: 14px;
  font-weight: 400;
  padding: 0px var(--padding-base);
  color: #333333;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.map-camera-section {
  display: grid;
  grid-template-columns: calc(82% - 11px) 18%;
  gap: 11px;
  padding-right: var(--padding-2xs);
  box-sizing: border-box;
  height: 100%;
}

.camera-views-container {
  display: flex;
  flex-direction: column;
  gap: 11px;
  overflow-x: scroll;
  // height: 488px;
  white-space: nowrap;
  height: calc(100vh - 357px);
}

.video-play-controls {
  background: var(--neutrals-eeeeee);
  padding: var(--padding-5xs) var(--padding-base);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 8px 8px;
}

.play-controls {
  display: flex;
  gap: 10px;
}
.screen-types {
  display: flex;
  gap: 10px;
}

.odd-map-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.camera-block {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  background-color: #e7e7e7;
  border-radius: 4px;
  padding: 0px !important;
  min-height: 63px;
  max-height: 15em;
}
.map-camera-grid.camera-block .video-react .video-react-video,
.map-camera-grid .video-react,
.map-camera-grid .camera-block.item1 img {
  min-height: 63px;
  max-height: 15em;
  height: 100% !important;
  max-width: 100%;
}
.camera-block.item1 .nocam-icon img {
  min-height: auto;
  height: auto;
}

.video-react {
  padding: 0px !important;
}

.main-map .camera-block .video-react .video-react-video,
.main-map .video-react,
.main-map .video-react-video,
.main-map.camera-block {
  min-height: 100% !important;
  max-height: 100% !important;
}

.grid-container-map .posHd,
.grid-container-map .coverage-map {
  border-radius: 0px 8px 8px 0px;
}
.camera-block {
  position: relative;
}
.camLbl-color {
  // color: #99999c !important;
  box-shadow: unset !important;
}
.camLbl {
  position: absolute;
  left: 5px;
  top: 8px;
  z-index: 2;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: var(--neutrals-ffffff);
  box-shadow: var(--level-2);
}

.camera-block {
  border: 3px solid transparent;
  border-radius: 8px;
}
.camera-block:hover,
.camera-block.sel {
  border-radius: 8px;
  border: 3px solid #007aff;
  cursor: pointer;
}
.camera-block.main-map:hover,
.camera-block.main-map {
  border: none;
  cursor: default;
}

.camera-block .video-react {
  border-radius: 4px;
}
.play-controls img {
  cursor: pointer;
}
.screen-types img,
.settings img {
  cursor: default;
  opacity: 30%;
}

.camera-block iframe,
.camera-block video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

/* popups code **************************************************/

.popup-common.ant-modal {
  padding-bottom: 0px !important;
  top: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-common .ant-modal-content {
  width: 623px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  background: #ffffff;
  border-radius: 10px;
}

.popup-common .ant-modal-body {
  padding: 0px !important;
}

.imp-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  margin: 0 auto;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 3px;
  position: relative;
  padding: 0 0 0px 0;
  margin-top: 0px;
}
.icon-title {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
}
.edit-implement-2 {
  position: relative;
  min-width: 0;
  height: 20px;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.504px;
  text-align: left;
  white-space: nowrap;
  z-index: 8;
}

.selecting-operation {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #99999c;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.628px;
}

.body {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  z-index: 10;
}
.jngs {
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 450px;
  overflow-y: auto;
}

.operator-item {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 11px;
  padding: 8px 8px 8px 8px;
  cursor: pointer;
  border-radius: 6px !important;
  background: transparent;
}
.operator-item:hover {
  border-radius: 6px !important;
  background: var(--neutrals-eeeeee);
}

.operator-item.active {
  border-radius: 6px !important;
  background: var(--neutrals-f7f7f7);
}

// .operator-item.active {
//   background: aliceblue;
// }

.avatar-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background-color: var(--neutrals-E8E8E8);
}

.operator-item.active .avatar-circle {
  border: 1px solid #fff !important;
}
.operator-item:hover .avatar-circle {
  border: 1px solid #fff !important;
}

.op-nm {
  color: #366376;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.628px;
}
.name-duration {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.operator-name {
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.628px;
}
// .h-m {
//   height: 12px;
//   color: #99999c;
//   font-size: 10px;
//   font-weight: 500;
//   line-height: 12px;
// }

.right-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 323px;
}

.implement-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}
.drop-down-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.implement-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  position: relative;
  background-color: var(--color-gray-247);
  border-radius: 10px;
  width: 100%;
  padding: var(--padding-xs);
}
.imp-dtl-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
}
.imp-type,
.imp-model {
  color: var(--neutrals-333333);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}

.imp-ellipse img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox-lbl {
  position: relative;
  height: 15px;
  color: var(--neutrals-333333);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  gap: 8px;
}
.checkbox .ant-checkbox + span {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.imp-button-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 9px 10px 9px 10px;
  cursor: pointer;
  background: var(--primary-deepteal-366376);
  border-radius: 8px;
  width: 100%;
  outline: none;
  border: none;
}
.imp-button-sec .btn-save {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: var(--neutrals-ffffff);
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
}
.imp-button-sec:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-deepteal-366376);
  border-color: var(--primary-deepteal-366376);
}
.checkbox .ant-checkbox-inner {
  width: 12px;
  height: 12px;
  /* border: 1px solid #959595 !important; */
  border-radius: 2px;
}
.checkbox .ant-checkbox-inner::after {
  width: 3.75px !important;
  height: 7.5px !important;
  left: 22% !important;
}
.uppercase {
  text-transform: uppercase;
}

// Operator View CSS

.idleTimeContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 12px;
  .idleTimeLabel {
    font-style: italic;
    font-size: 16px;
  }
  .idleTime {
    font-size: 32px;
    font-weight: 900;
    color: black;
  }
  .historyIcon {
    font-size: 32px;
  }
}

.text-align-left {
  text-align: left;
}

/* date picker ************************************************/

.singleMonth,
.doubleMonth {
  .ant-picker-dropdown {
    font-family: 'Montserrat', sans-serif !important;
    border-top: 2px solid var(--primary-deepteal-366376);
    top: 103px !important;
  }
  .ant-picker-dropdown .ant-picker-panel-container {
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  }

  .ant-picker-date-panel .ant-picker-body {
    padding: 12px 16px 16px 16px;
  }
  .ant-picker-range-arrow::after {
    display: none;
  }
  .ant-picker-dropdown-range {
    padding: 0px;
  }
  .ant-picker-header-view button {
    font-size: 14px;
  }
  .ant-picker-dropdown {
    font-family: 'Montserrat', sans-serif !important;
    border-top: 2px solid var(--primary-deepteal-366376);
    top: 103px !important;
    left: -20px !important;
  }
  .ant-picker-dropdown .ant-picker-panel-container {
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  }

  .ant-picker-date-panel .ant-picker-body {
    padding: 12px 16px 16px 16px;
  }
  .singleMonth .ant-picker-ranges {
    padding: 12px 16px 0px 16px !important;
  }
  .ant-picker-range-arrow::after {
    display: none;
  }
  .ant-picker-dropdown-range {
    padding: 0px;
  }
  .ant-picker-header-view button {
    font-size: 14px;
  }

  .ant-picker-content thead tr th {
    background-color: transparent;
    color: var(--neutrals-99999c);
  }

  .ant-picker-cell-in-view {
    color: var(--neutrals-333333);
  }

  .ant-picker-cell-disabled::before {
    background: transparent;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: var(--primary-deepteal-366376);
    border-radius: 50% !important;
  }
  .ant-picker-cell-disabled {
    color: var(--neutrals-dddddd);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-radius: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: var(--color-darkslategray-100);
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: var(--color-darkslategray-100);
  }

  .ant-picker-content
    tbody
    tr
    td:first-child.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    border-radius: 20px 0px 0px 20px;
  }

  .ant-picker-content
    tbody
    tr
    td:last-child.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    border-radius: 0px 20px 20px 0px;
  }

  .ant-picker-content
    tbody
    tr
    td
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    border-radius: 20px 0px 0px 20px;
  }

  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: var(--color-darkslategray-100);
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 1px solid var(--primary-deepteal-366376) !important;
    background: transparent !important;
    color: var(--primary-deepteal-366376);
    border-radius: 50% !important;
  }

  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    background: var(--neutrals-f7f7f7);
    color: var(--neutrals-b5b5b6);
    border: none !important;
    border-radius: 6px !important;
  }
  .ant-tag {
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    font-size: 10px;
    font-weight: 500;
  }
  .ant-picker-ranges > li {
    margin-bottom: 8px;
  }
}
/* second datepicker hide ******/
.singleMonth .ant-picker-panels .ant-picker-panel:last-child {
  display: none;
}
.singleMonth .ant-picker-header > button {
  visibility: visible !important;
}

.savingPopover > .ant-popover-content {
  border: 1px solid var(--primary-deepteal-366376) !important;
  border-radius: 4px !important;
  background-color: var(--neutrals-ffffff);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.ant-popover-inner-content {
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.ant-popover-inner {
  box-shadow: none !important;
  border-radius: 0px !important;
  background-clip: inherit !important;
  background-color: transparent !important;
}

.ant-popover-placement-bottomLeft {
  padding-top: 0px !important;
}

/* No Implement Selected *********************************/

.noImpSel-block {
  // height: calc(100vh - 507px) !important;
}
.grid3 {
  // grid-template-rows: 52px 1fr minmax(250px, 322px) !important;
}

.howto-improve-sec {
  padding: 8px 10px;
  border: 1px solid var(--007AFF);
  width: 100%;
  gap: 6px;
  border-radius: 8px;
  text-align: left;
  display: flex;
  flex-direction: column;
  background-color: #007aff08;
}
.howto-head {
  font-size: 10px;
  font-weight: 600;
  color: var(--007AFF);
  line-height: 12px;
  display: flex;
  gap: 8px;
}
.howto-desc {
  font-size: 10px;
  font-weight: 400;
  color: var(--neutrals-474747);
  line-height: 12px;
}

.container-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  box-sizing: border-box;
}
.container-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  box-sizing: border-box;
}
.cardBoxNew {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 100%;
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  box-sizing: border-box;
  overflow: hidden;
  padding: 24px 10px;
  gap: 23px;
  z-index: 1;
}
.box-title-sec {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}
.box-title {
  font-size: 12px;
  font-weight: 400;
  color: var(--neutrals-5C5C5C);
  line-height: 15px;
}
.box-value {
  font-size: 20px;
  font-weight: 600;
  color: var(--neutrals-333333);
  line-height: 25px;
}

/* search operation styles goes here  ******************************************/

.teamsRel {
  position: relative;
}

.searchOpTxt {
  font-size: 20px;
  font-weight: 600;
  color: var(--neutrals-474747);
  margin-bottom: 18px;
  padding-left: 9px;

  // margin-left: 176px;
  // margin-top: 20px;
}

.operationsGrid {
  display: flex;
  // margin-left: 176px;
  background: #fff;
  text-align: center;
  border-radius: 60px;
  font-family: 'Montserrat-Regular', sans-serif;
  // margin-top: 14px;
  // width: 1020px;
}

.gridPadding {
  padding: 16px 45px 16px 45px;
  text-align: left;
}

.border {
  height: 46px;
  background: #eee;
  width: 2px;
  margin-top: 16px;
}

.operationSearch {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #67afb7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.searchInGrid {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.gridTitle {
  color: #333;
  font-size: 14px;
  font-weight: 500px;
}

.gridDesc {
  color: #99999c;
  font-size: 12px;
  font-weight: 400px;
}

.w932px {
  width: 932px;
  display: flex;
  justify-content: space-around;
}

.impInput,
.impInput :focus,
.impInput:active,
.impInput:focus-visible {
  border: none;
}

.back_button {
  background-image: url(../images/Back_Button.svg);
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  cursor: pointer;
}

.hc_top_bg_op {
  background-image: url(../images/hc_top_bg_op.svg);
  width: 100%;
  min-height: 31.51vh;
  background-size: cover;
  background-position: bottom;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 0;
}
.h180 {
  min-height: 180px;
}

.search-bar-section {
  margin-top: 46px;
}

.search-menu {
  background-color: var(--neutrals-f7f7f7);
  border-radius: 40px;
  display: flex;
  align-items: center;
  height: 70px;
  position: relative;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.list-item {
  padding: 16px 50px !important;
  position: relative;
  z-index: 1;
  margin-right: 2px;
  cursor: pointer;
  min-width: 232px;
  min-height: 70px;
}
.list-item::after {
  border: none !important;
  content: '';
}
.op-options-head,
.filter-content-section {
  .chkbox .ant-checkbox-inner {
    width: 16px !important;
    height: 16px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #546cb2 !important;
    border-color: #546cb2 !important;
  }
}

.list-item:hover::after {
  background-color: var(--neutrals-eeeeee);
  border-radius: 40px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-bottom: none !important;
}
.list-item.sel::after {
  background-color: var(--neutrals-ffffff);
  border-radius: 40px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-bottom: none !important;
}
.list-item-block {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.list-item-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--neutrals-333333);
}
.list-item-tagline {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--neutrals-99999c);
}
.list-item::before {
  width: 2px;
  height: 46px;
  content: '';
  position: absolute;
  top: 12px;
  right: -2px !important;
  border-radius: 10px;
  background-color: var(--neutrals-dddddd);
}

.list-item.sel::before {
  background: none !important;
}

ul:hover li::before {
  background: transparent !important;
}
.list-item:nth-child(4)::before {
  background: none !important;
}

.searchBtn {
  position: absolute;
  right: 32px;
  background: rgba(103, 175, 183, 0.25);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.searchBtn.sel {
  background: rgba(103, 175, 183, 1);
}
.searchBtn.disableSearch {
  background: #cfcfcf;
  cursor: not-allowed;
}
.search-empty {
  display: flex;
  height: calc(100vh - 264px);
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 0px;
}
.no-results-sec {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
}
.no-result-box {
  width: 315px;
  height: 246px;
  // background-color: var(--neutrals-eeeeee);
  color: var(--color-black);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-result-tagline {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: var(--neutrals-5c5c5c);
}

.search-menu-container {
  display: block;
  margin-bottom: 24px;
  padding-top: 48px;
}
.smtop {
  margin-top: 18px;
}
// .search-result-container {
//   display: block;
// }
.filter-container {
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  padding: 12px;
  width: 100%;
  height: calc(100vh - 221px);
  border-radius: 10px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.head-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-heading {
  font-size: 16px;
  font-weight: 600;
  color: var(--neutrals-333333);
}
.clear-all {
  font-size: 8px;
  font-weight: 600;
  color: var(--neutrals-99999c);
  padding: 6px;
  cursor: pointer;
}
.filter-content-section,
.ticket-container {
  overflow-y: auto;
  max-height: 100%;
  word-break: break-all;
  margin-right: -10px;
  padding-right: 8px;
}
.each-item-sec {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid var(--neutrals-eeeeee);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.each-item-sec2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid var(--neutrals-eeeeee);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.filter-item-heading {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: var(--neutrals-5c5c5c);
}
.content-wrapper {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.chip-item {
  background-color: var(--neutrals-ffffff);
  border-radius: 6px;
  border: 1px solid var(--neutrals-eeeeee);
  padding: 4px 8px;
  height: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: var(--neutrals-99999c);
  cursor: pointer;
}
.content-wrapper2 {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
}
.nor-item {
  width: calc(50% - 4px);
  height: 20px;
  padding: 4px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: var(--neutrals-99999c);
  gap: 8px;
}
.chkbox .ant-checkbox-inner,
.chkbox .ant-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.chkbox .ant-checkbox .ant-checkbox:hover .ant-checkbox-inner {
  width: 12px;
  height: 12px;
  border: 1px solid var(--neutrals-eeeeee);
  border-radius: 2px !important;
}
.chkbox .ant-checkbox-wrapper {
  font-size: 12px;
  line-height: 12px;
}
.tickets-dur {
  width: calc(50% - 16px);
  display: flex;
  align-items: center;
  gap: 2px;
}
.tickets-lbl {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: var(--neutrals-333333);
  width: 30px;
}
.ticket-count input {
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  border-radius: 4px;
  width: 73px;
  outline: none;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
}
.ticket-count {
  width: calc(100% - 50px);
}
.content-wrapper3 {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 32px;
}
.slider-bar .ant-slider {
  margin: 0px 8px;
}

.slider-bar .ant-slider-handle {
  border: 2px solid var(--primary-deepteal-366376);
}
.slider-bar .ant-slider-track,
.slider-bar .ant-slider:hover .ant-slider-track {
  background-color: var(--primary-deepteal-366376);
}
.slider-bar .ant-slider-handle::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: var(--primary-deepteal-366376);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  z-index: 1;
}
.slider-bar .ant-slider-rail {
  background-color: var(--neutrals-eeeeee);
}

/* Scrollbar width */
.filter-content-section::-webkit-scrollbar,
.ticket-container::-webkit-scrollbar,
.implement-chips-container::-webkit-scrollbar {
  width: 3px;
}
/* Scrollbar Track */
.filter-content-section::-webkit-scrollbar-track,
.ticket-container::-webkit-scrollbar-track,
.implement-chips-container::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Scrollbar Handle */
.filter-content-section::-webkit-scrollbar-thumb,
.ticket-container::-webkit-scrollbar-thumb,
.implement-chips-container::-webkit-scrollbar-thumb {
  background: #cfcfcf;
}

/* Scrollbar Handle on hover */
.RoutineCls::-webkit-scrollbar-thumb:hover,
.ticket-container::-webkit-scrollbar-thumb:hover,
.implement-chips-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// .list-content {
//   display: none;
// }

.list-item .list-content {
  // display: block;
  position: absolute;
  background: #fff;
  min-width: 360px;
  left: 10px;
  top: 80px;
  transform: translate(0%, 0%);
  border-radius: 20px;
  padding: 24px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.implement-chips-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 266px;
  box-sizing: border-box;
  margin-right: -10px;
  padding-right: 10px;
}
.implement-chip-item {
  background-color: var(--neutrals-ffffff);
  border-radius: 20px;
  border: 1px solid var(--neutrals-eeeeee);
  padding: 6px 10px;
  height: 29px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--neutrals-474747);
}
.result-count {
  margin: 12px 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--neutrals-333333);
  display: flex;
  align-items: center;
  gap: 4px;
}

.tickets-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tickets-box {
  background-color: var(--neutrals-ffffff);
  border-radius: 10px;
  border: 1px solid var(--neutrals-eeeeee);
  padding: 12px 16px;
  height: 153px;
}
.tickets-box:hover {
  background-color: #f7f7f7;
  border-radius: 10px;
  border: 1px solid var(--neutrals-cfcfcf);
  padding: 12px 16px;
  height: 153px;
}
.tickets-box:active {
  background-color: #f7f7f7;
  border-radius: 10px;
  border: 1px solid var(--primary-deepteal-366376);
  padding: 12px 16px;
  height: 153px;
}
.tickets-box:hover .opCardBox,
.tickets-box:active,
.opCardBox {
  background-color: #f7f7f7;
}

.ticket-box {
  display: grid;
  grid-template-columns: 1fr 290px;
  gap: 12px;
  cursor: pointer;
}

.ticket-no {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--color-black);
}

.ticket-op-dtl {
  display: flex;
  gap: 32px;
  flex-direction: column;
}
.op-dtl {
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 40vw;
  overflow-x: auto;
  white-space: nowrap;
}

.tk-implement-type {
  display: flex;
  align-items: center;
  gap: 6px;
}
.tk-imp-name {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--neutrals-5c5c5c);
}
.tk-op-names {
  display: flex;
  align-items: center;
  gap: 20px;
}
.tk-op-item {
  display: flex;
  align-items: center;
  gap: 6px;
}

.tk-avatar-circle {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  background-color: var(--neutrals-E8E8E8);
  border-radius: var(--br-3xs);
  width: 20px;
  height: 20px;
  border: 1px solid var(--neutrals-ffffff);
  position: relative;
}
.tk-name-duration {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.tk-op-nm {
  font-size: 8px;
  font-weight: 600;
  line-height: 10px;
  color: var(--primary-deepteal-366376);
}
.tk-operator-name {
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.628px;
}
.tk-h-m {
  position: relative;
  color: #333333;

  font-size: 12px;
  font-weight: 400;
  line-height: 14.628px;
  text-align: left;
}
.tk-imp-icon {
  display: flex;
  align-items: center;
}
.op-coverage-sec {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.op-coverage-items {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}

.opCardBox {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  overflow: hidden;
  padding: 10px;
  gap: 10px;
  height: 95px;
  width: 118px;
  box-sizing: border-box;
  flex-grow: 1;
}
.opCardBox img {
  width: 28px;
  height: 28px;
}

.tk-box-title-sec {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
}
.tk-box-title {
  font-size: 10px;
  font-weight: 500;
  color: var(--neutrals-5C5C5C);
  line-height: 12px;
}
.tk-box-value {
  font-size: 16px;
  font-weight: 600;
  color: var(--neutrals-333333);
  line-height: 20px;
}
.recorded-cat-sec {
  display: flex;
  padding: 0px 10px;
  justify-content: space-between;
}
.rec-cat-item {
  display: flex;
  gap: 10px;
  padding: 0px 12px;
  border-right: 1px solid #d9d9d9;
}
.rec-cat-item:nth-child(1) {
  padding-left: 0px;
}
.rec-cat-item:last-child {
  border-right: none;
}
.ticket-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 360px;
}
.analyticsTabs .ant-tabs-content-holder {
  padding: 0px !important;
  background: none !important;
}
.search-operations-container {
  padding: 17px 28px;
  background-color: var(--neutrals-ffffff);
  position: relative;
}
.background-parent {
  padding: 16px 27px 23px 27px !important;
  // background-color: var(--neutrals-f7f7f7);
}

.scDatepicker .div#placeholder {
  content: 'Select range';
}
.scDatepicker .ant-picker {
  padding: 0px !important;
  border: none !important;
}
.scDatepicker .ant-picker-focused {
  box-shadow: none !important;
  border: none !important;
}
.scDatepicker .ant-picker-input > input {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--neutrals-99999c);
}
.scDatepicker .ant-picker-suffix {
  display: none !important;
}
.op-options-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slect-all {
  display: flex;
  align-items: center;
  gap: 5px;
}
.implement-type-txt {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: var(--neutrals-474747);
}
.fleet-card {
  width: 95px;
  height: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 25px;
  background-color: var(--neutrals-f7f7f7);
  border-radius: 10px;
}
.tractor-no {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--neutrals-474747);
}
.operators-names {
  border-bottom: 1px solid var(--neutrals-eeeeee);
  padding: 12px 10px;
  width: 100%;
}

.verticalEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.newOpAnalytic .opAnalyticTab {
  background: none !important;
  border: none !important;
  height: 100% !important;
}

.newOpAnalytic {
  background-color: #fff;
}

.op_tabs_sec .ant-tabs .ant-tabs-content-holder {
  padding: 8px !important;
  padding-bottom: 0px !important;
}

.bgNone {
  background: none !important;
}
.impact-dashboardTab,
.libraryTab,
.reportsTab {
  padding: 16px 27px 23px 27px;
  width: 100%;
  display: block;
}
.libraryTab .common_table {
  height: calc(100vh - 202px);
}
.libraryTab .ant-tabs-content-holder {
  min-height: calc(100vh - 135px);
  height: calc(100vh - 135px) !important;
}

.ant-tabs-content-holder {
}

.libraryTab .common_wrapper {
  height: calc(100vh - 135px) !important;
}

.reportsTab .common_wrapper {
  height: calc(100vh - 96px) !important;
}

.libraryTab .infinite-scroll-component {
  min-height: calc(100vh - 224px);
  max-height: calc(100vh - 224px);
}

.chartsTabs .chartGraph {
  height: calc(100vh - 135px);
}

.farmObsTab .common_table {
  height: calc(100vh - 100px);
}

.libraryTab .common_tabs .ant-tabs-nav,
.libraryTab .common_tabs .ant-tabs-nav::before {
  border-bottom: none !important;
}

.reportsTab .filters_card {
  background: #fff !important;
  border-radius: 8px 8px 0 0;
}
.reportsTab .infinite-scroll-component {
  min-height: calc(100vh - 185px);
  max-height: calc(100vh - 255px);
}
.reportsTab .common_table {
  height: calc(100vh - 163px);
}

.scrollBarPos {
  position: relative;
  right: 0px;
  padding-right: 10px;
  padding-left: 3px;
  width: 102%;
}

.scrollBarPos1 {
  position: relative;
  width: 100%;
}

.uppercase {
  font-size: 8px;
  color: #366376;
}

.scrollBarPos .ant-space.ant-space-horizontal.ant-space-align-center,
.scrollBarPos1 .ant-space.ant-space-horizontal.ant-space-align-center {
  gap: 0 !important;
  justify-content: flex-start;
  display: flex;
}

.opTable .ant-space.ant-space-horizontal.ant-space-align-center {
  gap: 0 !important;
  justify-content: flex-start;
  display: flex;
}

.backButtonBig {
  width: 44px;
  height: 44px;
  background-image: url(../images/BackButtonBig.svg);
  background-size: 44px;
  position: relative;
  top: 0px;
  background-repeat: no-repeat;
}

.scrollBarPos1 .ant-table-body {
  margin-right: -10px;
  padding-right: 10px;
}

.operations-graph .ant-tabs-content-holder {
  height: 100% !important;
}

.newOpAnalytic .tblContainer.viewportContainer {
  height: 100vh;
}

.ellipsis {
  text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
  max-width: 114px;
  word-break: break-word;
}

.op-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 114px;
  word-break: break-word;
}
.op-item-active {
  background-color: #f7f7f7 !important;
  color: #474747 !important;
  border: 1px solid #366376 !important;
}

.op-chip-item {
  background-color: var(--neutrals-f7f7f7);
  border-radius: 20px;
  border: 1px solid var(--neutrals-eeeeee);
  padding: 6px 10px;
  height: 29px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--neutrals-474747);
}

.align-stretch {
  display: flex;
  justify-content: space-between;
}

.coverage-block1 {
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  box-sizing: border-box;
  overflow: hidden;
  padding: 24px var(--padding-3xs);
  gap: var(--gap-5xl);
  z-index: 1;
}
.coverage-block2 {
  border-radius: var(--br-5xs);
  background-color: var(--neutrals-ffffff);
  border: 1px solid var(--neutrals-eeeeee);
  box-sizing: border-box;
  overflow: hidden;
  padding: 12px var(--padding-base) var(--padding-base) var(--padding-base);
  gap: var(--gap-17xs);
  z-index: 1;
}

.cIdelTime {
  display: flex;
  gap: 23px;
  flex-direction: column;
  align-items: center;
}

.IdelTimeLable {
  font-weight: 400;
  color: #5c5c5c;
  font-size: 12px;
  line-height: 15px;
}
.IdelTimeValue {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  color: #333333;
}
.idelTimeSec {
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}
.IdelTimeLable {
  display: flex;
  gap: 6px;
  align-items: center;
}

.opcgmap img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.IdelTimeLable span {
  line-height: 14px;
}
.IdelTimeLable span img {
  height: 14px;
}

.container-18 .ant-progress-bg {
  background-color: #546cb2 !important;
  opacity: 0.6;
}
.container-18 .ant-progress-bg::before,
.container-18 .ant-progress-inner {
  border-radius: 0px !important;
}

.coverage-map2 .camLbl {
  z-index: 0 !important;
}

.opSelHeadSec {
  display: flex;
  align-items: center;
  gap: 20px;
}

.opName {
  color: #99999c;
}

// .opTable .ant-table-body {
//     margin-right: -16px;
//     padding-right: 16px;
// }
.lineBlock span {
  min-height: 14px;
}
