.cameraViewTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.cameraViewTitle h5 {
  color: #333333;
  margin: 0;
}
.cameraViewTitle .anticon-camera {
  cursor: pointer;
  color: #366376;
}
.cameraViewTitle .anticon-camera:hover {
  color: rgb(103, 175, 183);
}
.camHeadViewTxt:hover,
.openCamtgl .camHeadViewTxt {
  color: #1f1f1f;
  cursor: pointer;
  text-transform: uppercase;
}
.camHeadViewTxt {
  color: #333333;
  cursor: pointer;
  text-transform: uppercase;
}

.camera-views {
  position: relative;
}
.camera-views.camShow .alert {
  transition: 0.25s;
  transition-timing-function: ease-out;
  transform: translateY(0);
  opacity: 1;
  height: 100%;
}
.camera-views .alert {
  transition: 0.2s;
  transform: translateY(-130%);
  transition-timing-function: ease-in;
}
.camera-views {
  overflow: hidden;
}
.camera-views .anticon-right,
.camera-views .anticon-down {
  font-size: 13px;
}

.cameraViewSec {
  width: 100%;
  /* border: 1px solid #ccc; */
  border-bottom: none;
  background-color: #e8e8e880;
}

.cameraViewSec .ant-space-align-center {
  width: 100%;
  display: block;
}

.cameraViewSec iframe {
  border-radius: 0 0 15px 15px;
  border: none;
  box-shadow: none;
  display: block;
}

.cameraViewSec img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.cameraViewScreen iframe {
  border-radius: 5px 5px 0 0 !important;
}

.popoutIcon {
  background-image: url('../../../assets/images/popout_nor.svg');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  display: block;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  cursor: pointer;
}
.popoutIcon:hover {
  background-image: url('../../../assets/images/popout_sel.svg');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  display: block;
  right: 15px;
  top: 15px;
  z-index: 1;
  cursor: pointer;
}

.captureIcon {
  background-image: url('../../../assets/images/capture_nor.svg');
  background-repeat: no-repeat;
  height: 15px;
  width: 19px;
  display: block;
  position: absolute;
  right: 52px;
  top: 15px;
  z-index: 1;
  cursor: pointer;
}

.hitchImageBlock .captureIcon {
  background-image: url('../../../assets/images/capture_sel.svg');
}

.captureIcon:hover {
  background-image: url('../../../assets/images/capture_sel.svg');
  background-repeat: no-repeat;
  height: 15px;
  width: 19px;
  display: block;
  right: 52px;
  top: 15px;
  z-index: 1;
  cursor: pointer;
}

.camera-view-spinner .ant-spin-text {
  margin-top: 16px;
  font-size: 16px;
  color: #99999c;
}
.camera-view-spinner {
  background-color: #e8e8e8;
}

.camera_box_container {
  display: flex;
  justify-content: space-between;
}

.camera_view_buttons {
  box-shadow: inset 0px 0px 6px #00000029;
  border-radius: 0 0 5px 5px;
  overflow-x: auto;
  height: 38px;
}
.camera_view_buttons .camera_box {
  border-radius: 0 !important;
}
.camera_view_buttons .camera_box:first-child {
  border-radius: 0 0 0 5px !important;
}
.camera_view_buttons .camera_box:last-child {
  border-radius: 0 0 5px 0 !important;
}
.camera_view_buttons .camera_box_cam_icon {
  padding-left: 8px !important;
}

.newCamBtns button.camera_box {
  height: 36px;
}

.newCamBtns .camera_box {
  border: none;
}

.camera_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding: 10px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.camera_box:hover {
  background-color: #e7e7e7;
  color: #7a86c4;
}
.camera_box.active {
  background-color: #5562b4;
}

.cam-down .t_camera_lbl:before {
  content: url('../../temp/components//common/CameraViews/images/cam_down_web_black.svg');
  padding-right: 4px;
}
.cam-down .camera_box.active .t_camera_lbl:before {
  content: url('../../temp/components//common/CameraViews/images/cam_down_web_white.svg');
  padding-right: 4px;
}

.ts_off_color .camera_box.active {
  background-color: #cfcfcf;
}
.ts_shadow_color .camera_box.active {
  background-color: #67afb7;
}
.ts_rowfollow_color .camera_box.active {
  background-color: #67afb7;
}
.ts_autodrive_color .camera_box.active {
  background-color: #ec9322;
}
.ts_manual_color .camera_box.active {
  background-color: #67afb7;
}
.ts_routine_color .camera_box.active {
  background-color: #ec9322;
}
.ts_charging_color .camera_box.active {
  background-color: #a9bf51;
}
.ts_available_color .camera_box.active {
  background-color: #343b5e;
}
.ts_faulty_color .camera_box.active {
  background-color: #d43d38;
}

.t_camera_lbl {
  color: #474747;
  font-size: 8px !important;
  margin: 0;
  text-transform: uppercase;
}
.camera_box.active .t_camera_lbl {
  color: #fff !important;
}
.camera_box_cam_icon {
  margin-top: 6px;
  padding-left: 4px;
  cursor: pointer;
}
