/* wingspanai login page styles */
.wai_loginRight .layout {
  height: 100%;
  background-color: transparent;
}

.toastPlace {
  position: fixed;
  top: 70px;
  left: 50px;
}

.wai_loginContainer {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  /* background-image: url(../../assets/images/wai_logo_bg.png); */
  background-size: cover;
  background-position: center center;
}

.versionCon {
  position: absolute;
  bottom: 0;
  left: 30px;
}

.versionCon h1 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #d3d3d3;
  float: left;
  font-family: 'Montserrat-Semibold';
}

.versionCon h3 {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.56px;
  color: #d3d3d3;
  float: left;
  padding-top: 0px;
  padding-left: 15px;
  font-family: 'Montserrat-Semibold';
}

.wai_loginRight .layout {
  height: 100%;
  background-color: transparent;
}

.wai_loginRight .ant-col,
.wai_loginRight .container {
  width: 100%;
  height: 100%;
}

.wai_loginRight .ant-space.ant-space-vertical {
  width: 100%;
}

.wai_loginRight .ant-space {
  grid-gap: inherit !important;
  gap: inherit !important;
}

.wai_loginRight {
  /* padding: 86px; */
  position: relative;
  width: 55%;
  padding: 0px;
  align-items: flex-start;
  height: auto;
}

.wai_loginRight .ant-space-item Input {
  border-radius: 8px;
}

.wai_rememberTxt .ant-checkbox-inner {
  background-color: transparent !important;
  border: 1px solid #fff !important;
}

.wai_rememberTxt .ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid #fff !important;
}

.wai_loginRight {
  position: relative;
  width: 55%;
  padding: 0px;
  align-items: flex-start;
  height: auto;
}

.wai_loginRight .ant-input-affix-wrapper.ant-input-password {
  background-color: #00000033 !important;
  border-radius: 0px !important;
  height: 68px !important;
  font-family: 'Montserrat-Medium';
  font-size: 16px !important;
  color: #fff;
  padding: 5px 15px !important;
  box-sizing: border-box;
}

.wai_loginRight .ant-input-affix-wrapper.ant-input-password input {
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  height: 68px !important;
  color: #fff;
}

.wai_loginRight .ant-space {
  gap: inherit !important;
}

.wai_loginRight .ant-input-affix-wrapper input input::placeholder {
  font-size: 16px !important;
  font-family: Montserrat-Medium;
}

.btnTxt {
  background-color: #ef8403;
  border: 1px solid #ef8403;
  border-radius: 12px;
  font-size: 16px;
  height: 40px;
  min-width: auto !important;
  text-transform: uppercase;
  font-family: Montserrat-SemiBold;
  margin-top: 10px;
  box-shadow: 0px 0px 6px #00000029;
  letter-spacing: 1.74px;
  padding: 4px 20px;
}

.btnTxt:hover {
  background-color: #cc6e0cc7;
}

.wai_loginRight .ant-col {
  display: block;
  width: 100%;
  height: 100%;
}

.wai_loginRight .container {
  width: 100%;
  height: 100%;
}

.wai_loginRight .ant-space.ant-space-vertical {
  width: 100%;
}

.wai_loginRight .ant-input {
  border: 2px solid #dfe1e6 !important;
  background-color: #00000033;
  border-radius: 0px !important;
  height: 68px;
  font-family: 'Montserrat-SemiBold';
  font-size: 16px !important;
  color: #fff;
  padding: 5px 10px !important;
  box-sizing: border-box;
}

.wai_loginRight .ant-input-affix-wrapper.ant-input-password {
  background-color: #00000033 !important;
  border-radius: 0px !important;
  height: 68px;
  font-family: 'Montserrat-Medium';
  font-size: 16px !important;
  color: #fff;
  padding: 5px 10px !important;
  box-sizing: border-box;
  align-items: center;
}

.wai_loginRight .ant-input-affix-wrapper.ant-input-password input {
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  height: 68px;
  color: #fff;
}

.wai_loginRight .ant-space {
  gap: inherit !important;
}

.wai_loginRight .ant-input-affix-wrapper input input::placeholder {
  font-size: 16px;
  font-family: Montserrat-Medium;
}

.wai_loginRight .ant-space-item .LoginText {
  margin-bottom: 20px;
}

.wai_loginRight .ant-space-item .atomText {
  margin-bottom: 50px;
}

.wai_loginRight button:active,
.wai_loginRight button:hover,
.wai_loginRight button:focus {
  background: #f78c00;
  border: 1px solid #ef8403;
}

.wai_loginRight .ant-input-affix-wrapper {
  padding: 0px !important;
}

.wai_loginRight .ant-input-affix-wrapper > input.ant-input {
  padding: 4px 11px;
}

.wai_loginRight .ant-input-suffix {
  padding-right: 11px;
}

.wai_rememberTxt.ant-checkbox {
  border: 1px solid red !important;
}

.wai_rememberTxt .ant-checkbox {
  margin-right: 12px;
}

/* wingspanai login styles */
.wai_login_wrapper {
  background: rgb(53 99 117 / 80%);
  box-shadow: 3px 3px 10px #000000;
  opacity: 1;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 29px;
  text-align: center;
  padding: 103px 90px 30px 90px;
  position: relative;
  overflow: hidden;
  min-width: 700px;
  max-width: 1096px;
  width: 57vw;
  min-height: 520px;
  max-height: 822px;
  height: 76.1vh;
  color: #fff;
  box-sizing: border-box;
}

.monarchImg img {
  object-fit: cover;
  max-width: 100%;
}

.wai_loginContWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
}

.wai_loginTxtSec {
  min-width: 50%;
  padding-top: 40px;
}

.loginForm {
  min-width: 50%;
}

.wai_loginTxt {
  font-family: 'Montserrat-SemiBold';
  font-size: 48px;
  color: #fff;
  line-height: 61px;
  text-align: left;
}

/* .wingspanai_logo {
	margin-bottom: 60px;
	min-height: 145px;
} */

.wingspanai_logo img {
  max-width: 100%;
  object-fit: cover;
  width: 16vw;
  height: auto !important;
  /* height: 141px; */
}

button.wai_btnTxt,
button.wai_btnTxt:focus,
button.wai_btnTxt:hover {
  background-color: #66afb7;
  border: 1px solid #66afb7;
  border-radius: 20px;
  font-size: 22px;
  font-family: Montserrat-SemiBold;
  text-transform: uppercase;
  min-width: 136px;
  height: 57px;
  box-shadow: 0px 0px 6px #00000029;
  letter-spacing: 1.74px;
  margin-top: 0 !important;
}

.loginForm .wai_loginFRow input:-webkit-autofill,
.loginForm .wai_loginFRow input:-webkit-autofill:hover,
.loginForm .wai_loginFRow input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white !important;
}

.loginForm .wai_loginFRow input:-webkit-autofill:first-line {
  color: #fff;
}

.wai_loginFRow {
  margin-bottom: 40px;
  text-align: initial;
}

.wai_forgotTxt {
  font-family: Montserrat-Medium;
  font-size: 14px;
  letter-spacing: 0.1px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: right;
}

.wai_loginContWrap .wai_rememberTxt {
  margin-bottom: 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wai_loginContWrap .wai_loginTxt {
  font-size: 48px;
}

.wai_brand_logo {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.wai_loginTxt1 {
  display: none;
}
.errMsgDiv {
  height: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 320px) and (max-width: 999px) {
  .wai_login_wrapper {
    width: 100%;
    height: calc(100vh - 100px);
    /* padding: 5vh 30px 20px 30px; */
    box-sizing: border-box;
    margin: 0px 30px !important;
  }

  .wai_loginTxt {
    display: none;
  }

  .wai_loginRight .ant-input {
    font-size: 13px !important;
  }

  button.wai_btnTxt,
  button.wai_btnTxt:focus,
  button.wai_btnTxt:hover {
    border-radius: 10px !important;
    height: 40px !important;
    font-size: 15px !important;
    min-width: 126px !important;
  }

  .wai_forgotTxt {
    font-size: 13px !important;
    margin-bottom: 8px !important;
  }

  .errMsgDiv {
    height: 15px !important;
    margin-bottom: 10px !important;
  }

  .wai_loginRight {
    padding: 30px 50px !important;
    margin: 0 auto;
  }

  /* .wingspanai_logo {
		margin-bottom: 4vh !important;
		min-height: 8vh !important;
	} */

  .wai_loginFRow {
    margin-bottom: 15px !important;
    text-align: initial;
  }

  .wingspanai_logo img {
    max-width: 100%;
    object-fit: cover;
    /* height: 13vh !important; */
  }

  .wai_loginContWrap {
    display: flex;
    flex-direction: column !important;
  }

  .wai_loginTxtSec {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0px !important;
  }

  .wai_loginTxt1 {
    font-size: 32px !important;
    line-height: 40px !important;
    word-break: break-word !important;
    width: 75%;
    display: block !important;
  }

  .wai_brand_logo {
    width: calc(100% - 60px) !important;
  }

  .loginForm {
    min-width: 100% !important;
  }
  .wai_loginRight .wai_loginFRow .ant-input {
    height: 50px !important;
  }

  .wai_loginRight
    .wai_loginFRow
    .ant-input-affix-wrapper.ant-input-password
    input,
  .wai_loginRight .wai_loginFRow .ant-input-affix-wrapper.ant-input-password {
    height: 50px !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1299px) {
  /* .wai_login_wrapper {
    width: 700px;
    height: 520px;
    padding: 6.75vh 60px 20px 60px !important;
    box-sizing: border-box;
  } */

  .wai_loginTxt {
    font-size: 34px !important;
    line-height: 40px !important;
  }

  .wai_loginRight .ant-input {
    font-size: 13px !important;
  }
  .wai_loginRight .wai_loginFRow .ant-input {
    height: 45px !important;
  }

  .wai_loginRight
    .wai_loginFRow
    .ant-input-affix-wrapper.ant-input-password
    input,
  .wai_loginRight .wai_loginFRow .ant-input-affix-wrapper.ant-input-password {
    height: 45px !important;
  }

  button.wai_btnTxt,
  button.wai_btnTxt:focus,
  button.wai_btnTxt:hover {
    border-radius: 10px !important;
    height: 40px !important;
    font-size: 15px !important;
    min-width: 126px !important;
  }

  .wai_forgotTxt {
    font-size: 13px !important;
    margin-bottom: 8px !important;
  }

  .errMsgDiv {
    height: 15px !important;
    margin-bottom: 10px !important;
    font-size: 14px !important;
  }

  .wai_loginTxtSec {
    padding-top: 25px !important;
  }

  /* .wingspanai_logo {
		margin-bottom: 5.55vh !important;
		min-height: 13.42vh !important;
    margin-bottom: 30px !important;
    min-height: 100px !important;
	} */

  .wai_loginFRow {
    margin-bottom: 15px !important;
    text-align: initial;
  }

  .wingspanai_logo img {
    max-width: 100%;
    object-fit: cover;
    /* height: 13vh !important; */
    /* height: 100px !important; */
  }

  .wai_loginTxt1 {
    display: none;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1559px) {
  /* .wai_login_wrapper {
    width: 820px;
		height: 560px;
    padding: 8.75vh 70px 25px 70px !important;
    box-sizing: border-box;
  } */

  .wai_loginTxt {
    font-size: 38px !important;
    line-height: 48px !important;
  }
  .wai_loginRight .wai_loginFRow .ant-input {
    height: 50px !important;
  }

  .wai_loginRight
    .wai_loginFRow
    .ant-input-affix-wrapper.ant-input-password
    input,
  .wai_loginRight .wai_loginFRow .ant-input-affix-wrapper.ant-input-password {
    height: 50px !important;
  }

  button.wai_btnTxt,
  button.wai_btnTxt:focus,
  button.wai_btnTxt:hover {
    border-radius: 12px !important;
    height: 40px !important;
    font-size: 18px !important;
    min-width: 105px !important;
  }

  /* .wingspanai_logo {
		margin-bottom: 4.5vh !important;
		min-height: 12vh !important;
	} */

  /* .wingspanai_logo img {
		height: 12vh !important;
    height: 13vh !important;
	} */

  .wai_loginFRow {
    margin-bottom: 20px !important;
  }

  .errMsgDiv {
    height: 15px !important;
    margin-bottom: 10px !important;
    font-size: 14px !important;
  }

  .wai_loginTxtSec {
    padding-top: 25px !important;
  }

  .wai_loginTxt1 {
    display: none;
  }
}

@media screen and (min-width: 1681px) and (max-width: 1919px) {
  .wai_login_wrapper {
    height: 700px;
    padding: 8.5vh 80px 30px 80px !important;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1680px) {
  .wai_login_wrapper {
    width: 950px !important;
    min-height: 600px !important;
    max-height: 650px !important;
    padding: 8.75vh 70px 25px 70px !important;
  }
  .wingspanai_logo img {
    width: 230px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .wai_login_wrapper {
    min-width: 700px !important;
    min-height: 460px !important;
    height: 550px !important;
    padding: 8.75vh 70px 25px 70px !important;
  }
  .wingspanai_logo img {
    width: 180px !important;
  }
}

@media screen and (min-width: 1560px) and (max-width: 1919px) {
  /* .wai_login_wrapper {
    width: 900px;
		height: 620px;
    padding: 8.5vh 80px 30px 80px;
    box-sizing: border-box;
  } */

  /* .wingspanai_logo {
		margin-bottom: 4.5vh !important;
		min-height: 10vh !important;
	} */

  .wai_loginFRow {
    margin-bottom: 30px !important;
    text-align: initial;
  }

  .wingspanai_logo img {
    max-width: 100%;
    object-fit: cover;
    /* height: 10vh !important; */
    /* height: 13vh !important; */
  }

  .wai_loginRight .wai_loginFRow .ant-input {
    height: 55px !important;
  }

  .wai_loginRight
    .wai_loginFRow
    .ant-input-affix-wrapper.ant-input-password
    input,
  .wai_loginRight .wai_loginFRow .ant-input-affix-wrapper.ant-input-password {
    height: 55px !important;
  }

  .wai_loginContWrap .wai_loginTxt {
    font-size: 42px !important;
    line-height: 50px;
  }

  .wai_loginTxt1 {
    display: none;
  }
  button.wai_btnTxt,
  button.wai_btnTxt:focus,
  button.wai_btnTxt:hover {
    border-radius: 14px !important;
    height: 44px !important;
    font-size: 20px !important;
    min-width: 115px !important;
  }
  .errMsgDiv {
    height: 15px !important;
    margin-bottom: 10px !important;
  }
}
/* wingspanai login page styles end here */


@media screen and (min-width: 1920px) and (max-width: 4000px) {

  .wingspanai_logo img {
    width: 10vw !important;
  }
  .wai_login_wrapper {
    padding-top: 60px !important;
  }
  .monarchImg img {
    margin-bottom: 30px !important;
  }
}