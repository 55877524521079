/* ******************** path tooltip container start *******************************/

.tooltip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  min-width: 179px;
  margin: 0 auto;
  padding: 8px 8px 8px 8px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  bottom: 17px !important;
}

.tooltip-container-map-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  min-width: 179px;
  margin: 0 auto;
  padding: 8px 8px 8px 8px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  bottom: 10px !important;
}

.head-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}

.head-title {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.628px;
}

.op-count {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.628px;
}
.content-section {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}
.operations-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  padding: 0 var(--padding-11xs);
}
.operations-row-mr {
  margin: 6px !important;
}

.left-sec {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
}

.right-sec {
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
}

.op-date {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  width: 30px;
}

.op-number {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  min-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 115px;
}
.avatar-circles-tt:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 21px;
  height: 21px;
  background-color: var(--neutrals-E8E8E8);
  border-radius: 50px;
  border: 1px solid var(--neutrals-f7f7f7);
  position: relative;
  left: 0;
}
.avatar-circles-tt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 21px;
  height: 21px;
  background-color: var(--neutrals-E8E8E8);
  border-radius: 50px;
  border: 1px solid var(--neutrals-ffffff);
  position: relative;
  margin-right: -2px;
}
.op-name {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-deepteal-366376);
  font-size: 8px;
  font-weight: 600;
  line-height: 9.752px;
  text-transform: uppercase;
}

/* ******************** path tooltip container end *******************************/
/* ******************** ticket tooltip container start *******************************/

.head-sec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 4px;
  width: 162px;
}
.head-sec1 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
  width: 162px;
}
.tt-sec2 {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  gap: 4px;
  width: 100%;
}

.sec1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.creator-time {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  width: 100%;
}
.ellipse {
  background-color: var(--neutrals-b5b5b6);
  width: 2px;
  height: 2px;
  border-radius: 50%;
}

.pm {
  color: var(--474747);
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}
.creator-time img {
  height: 12px;
  width: auto;
}
.bar {
  align-self: stretch;
  height: 0.23px;
  background: var(--neutrals-cfcfcf);
  margin-top: 8px;
  margin-bottom: 8px;
}

.tt_button_sec {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
}

.tt_button {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 39px;
  height: 10px;
  color: var(--007AFF);
  font-size: 8px;
  font-weight: 600;
  line-height: 9.752px;
  text-align: center;
}
/* ******************** ticket tooltip container end *******************************/

/* Hide all controls */
.hide-controls .video-react-controls {
  display: none;
}

.grid-container-map {
  display: grid;
  grid-template-columns: calc(81% - 11px) 18%;
  // grid-template-columns: auto auto;
  // grid-template-columns: unset;
  gap: 10px;
  // background-color: #2196f3;
  // padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  // height: 488px;
  white-space: nowrap;
  // height: calc(100vh - 342px);
  z-index: 2;
}

.grid-container-map > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 0px 0;
  // font-size: 30px;
}

.main-map {
  grid-row-start: 1;
  grid-row-end: 6;
}

.autonomous {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 19px;
  line-height: 10px;
}

// .map-tag-line span:first-child {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   max-width: 60% !important;
//   cursor: pointer;
// }

// .map-tag-line span:first-child:hover {
//   overflow: visible;
//   white-space: nowrap;
//   background: #ffff;
//   z-index: 2;
// }

.camera-down-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  gap: 6px;
}

.camera-down {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #dddddd;
  border-radius: 5px;
  z-index: 0;
}

.ad-site-layout .coverage-map .ol-popup {
  display: flex !important;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  gap: 8px;
  // min-width: 179px;
  margin: 0 auto;
  padding: 8px 8px 8px 8px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 24px;
  left: -50px;
  min-width: 162px !important;
  border: none;
}

.head-sec .severity-flag .ant-space-item {
  margin: 0px !important;
}
.head-sec .severity-flag .ant-space-item img {
  width: 7px !important;
  height: 8px !important;
}
.head-sec .severity-flag .ant-space-item img.mr7 {
  margin-right: 5px !important;
}

.wloader-small {
  position: relative;
}
.wloader-small-position {
  left: -100px;
}
.wave-loader-small {
  position: absolute;
  top: 14px;
  left: 70px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  z-index: 1;
  zoom: 55%;
}

.wave-loader-small > span {
  height: 30px;
  width: 7px;
  margin-right: 10px;
  background-color: #67afb7;
  animation: loading 1s linear infinite;
}
.wave-loader-small > span:nth-child(1) {
  animation-delay: 0.1s;
}
.wave-loader-small > span:nth-child(2) {
  animation-delay: 0.2s;
}
.wave-loader-small > span:nth-child(3) {
  animation-delay: 0.3s;
}
.wave-loader-small > span:nth-child(4) {
  animation-delay: 0.4s;
}
.wave-loader-small > span:nth-child(5) {
  animation-delay: 0.5s;
}

@keyframes loading {
  0% {
    height: 0;
  }
  25% {
    height: 25px;
  }
  50% {
    height: 50px;
  }
  100% {
    height: 0;
  }
}

.jumpandgo-section {
  height: 50px;
  display: flex;
  align-items: flex-end;
  position: relative;
  top: 0px;
}

.VideoTimeLine {
  height: 95px !important;
  margin-right: 15px;
  margin-left: 15px;
  top: 12px !important;
}

.map-overlay {
  background-image: url('../../../assets/images/fungiMap.png');
  background-size: contain;
  height: 100%;
  width: 100%;
}
.OperationalCoverageMap {
  opacity: 0.4;
}
