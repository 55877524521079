.metaContent {
  font-size: 16px;
  color: #ec7d0a;
}

.emailStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 192px;
}

.disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  /* background: #366376; */
}
.goldbuildContainer {
  grid-template-columns: repeat(2, 1fr) !important;
}
.goldbuildItem {
  height: 100px !important;
}

.assignTxt1 {
  font-family: Montserrat-Regular;
  color: #333333;
  font-size: 15px;
  line-height: 29px;
}

.roof_model {
  margin-left: 10px;
  font-family: Montserrat-Regular;
  color: #333333;
  font-size: 17px;
}

.interweave-component {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menuTitle {
  padding: 19px 27px !important;
  background-color: #f7f7f7;
  height: calc(100vh - 60px);
}

.menuTab {
  background: #fff;
  display: flex;
  align-items: center;
  height: 59px !important;
  border-bottom: 1px px solid #e7e7e7 !important;
  padding: 0 30px;
}

.gridTabsContainer {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  margin-bottom: 15px;
}
.tractorHlth {
  cursor: pointer;
}
.gridbackground {
  background-color: #d9e5e6;
}
.gridItem {
  height: 80px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 18px;
}
.widget_content {
  margin-left: 16px;
}
.assignTxt {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #5c5c5c;
  line-height: 15px;
  margin-bottom: 3px;
}
.assignNum {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  line-height: 29px;
}
@media screen and (max-width: 1280px) {
  .gridTabsContainer {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

.tractorHealthMark {
  grid-template-columns: repeat(3, 1fr);
}

.gridbackground {
  background-color: #d9e5e6 !important;
}

/* .filterIconNew {
  background-color: #fdfdfd;
  border: 1px solid #d7dae2;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterIconNew .refreshIcon {
  background-image: url(../../../assets/images/refreshNew.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
} */
.filtersNew {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filtersHealthScreen {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.common_search.w160 {
  width: 160px !important;
}

.goldbuidTbl .infinite-scroll-component {
  height: auto !important;
  max-height: calc(100vh - 325px);
}

.goldbuildWrap {
  height: auto !important;
}

.goldBuildLink {
  border-radius: 26px !important;
  outline: none;
  background-color: #1f3a46;
  border: 1px solid #5c5c5c;
  font-size: 10px;
  font-weight: 500;
  padding: 4px 10px;
  height: 26px;
  color: #fff;
}

.goldBuildLink:hover,
.goldBuildLink:focus,
.goldBuildLink:active {
  border-radius: 26px !important;
  outline: none;
  background-color: #3c6273;
  border: 1px solid #5c5c5c;
  font-size: 10px;
  font-weight: 500;
  padding: 4px 10px;
  height: 26px;
  color: #fff;
}

.filtersHealthScreen .float-label label.label-float {
  color: #99999c !important;
}

.filtersHealthScreen .ant-picker .ant-picker-input input,
.filtersHealthScreen .ant-picker.ant-picker-focused .ant-picker-input input {
  font-size: 10px !important;
}
.filtersHealthScreen .ant-picker .ant-picker-input input {
  color: #99999c !important;
}
.filtersHealthScreen .ant-picker.ant-picker-focused .ant-picker-input input {
  color: #5c5c5c !important;
}

.Svt {
  margin-bottom: 0px !important;
}
.tractorHealthMark .forwardTxt {
  margin-bottom: 3px;
}
.tractorHealthMark .mtRight div:last-child {
  min-width: 100px;
}

.ticketsMark {
  grid-template-columns: repeat(4, 1fr);
}

.ticketsMark .forwardTxt {
  margin-bottom: 3px;
}
.ticketsMark .mtRight div:last-child {
  min-width: 100px;
}

.downloadIcon {
  background-image: url(../../../assets/images/download_o.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}
.filter_icon {
  background-image: url(../../../assets/images/filter_icon.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}

.filterIcon {
  background-color: #fff;
  border: 1px solid #d7dae2;
  border-radius: 4px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FleetStatusFilterIcons {
  display: flex;
  align-items: center;
}

.ant-modal.downloadPop {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px !important;
  top: 0px;
}

.downloadPop .ant-modal-content {
  width: 400px;
  min-height: 250px;
  border-radius: 15px;
}

.downloadCircle {
  position: relative;
}

.downloadPop .ant-modal-body {
  width: 400px;
  min-height: 250px;
}

.downloadPop .downloadCircle {
  width: 120px;
  height: 120px !important;
  box-sizing: border-box;
  margin: 0px !important;
}

.downloadContainer {
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  margin-top: 20px;
}

.altop {
  align-items: flex-start !important;
}

.datepickerWidth {
  width: 200px;
}

.altop .ant-tree-select {
  min-width: 250px !important;
}
.bstCont .ant-table-body {
  height: calc(100vh - 450px) !important;
}

.description {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.description-text {
  width: 90%;
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.EmployeeModalContainer .ant-modal-body {
  min-width: 700px;
  text-align: center;
}
.EmployeeModalContainer .ant-modal-content {
  min-width: 700px;
  border: 0;
  font-family: Montserrat-SemiBold;
}
.fm-modal.ant-modal {
  width: 940px !important;
  margin: auto auto;
}

.thmDetails .ant-modal-close {
  position: static;
  float: right;
}
.tractorHealthContainer .ant-card-body {
  padding: 5px 0 20px 20px !important;
}
.stationHealthContainer .orgData {
  width: 150px;
  text-align: left;
}
.stationHealthContainer {
  overflow-y: auto;
  max-height: 600px;
  padding-left: 20px;
}

.stationHealthContainer .indiHead {
  text-align: center;
}

.indiContainer {
  display: block;
  flex-direction: row;
  width: 97.7%;
  /* background: #f7f7f7; */
  border: 1px solid #cccddc;
  margin-bottom: 15px;
  padding: 12px;
  border-radius: 6px;
  margin-right: 5px;
}

.bsBg {
  background: #f7f7f7;
}

.indiContainer .orgDetails {
  font-family: 'Montserrat-Medium';
}
.indiContainer .orgDetails p {
  color: #172b4d;
  font-family: 'Montserrat-Medium';
  line-height: 18px;
  text-align: left;
}
.indiContainer p {
  margin-bottom: 0 !important;
}
.indiContainer .orgData {
  color: #172b4d !important;
}
.indiContainer .orgData p {
  color: #000 !important;
  font-size: 14px !important;
  font-family: 'Montserrat-Regular' !important;
}
.indiContainer .col2-styles {
  align-items: center;
}
.indiContainer.uploadStatusBlk .indiBlockRow {
  gap: 10px;
}

.indiBlockRow {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
}

.indiBlockRow .col2-styles {
  margin-bottom: 12px;
}
.detailTxt {
  width: 50% !important;
}

.fltClr p {
  color: #ababab !important;
}

.goldBuildSummary {
  display: grid;
  grid-template-columns: 320px 320px;
  gap: 24px;
  margin-bottom: 16px;
}

.goldSummaryCard {
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
}

.goldBuildGridItem {
  display: grid;
  gap: 8px;
  grid-template-columns: 80px auto;
}
