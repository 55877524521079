.ADCCantainer {
  // width: 800px;
  gap: 20px;
  // border-radius: 12px 0px 0px 0px;
}

.ADCHeaderContainer {
  display: grid;
  grid-template-columns: 38px auto;
  gap: 10px;
}

.ADCHeaderIcon {
  width: 100%;
}

// .ADCGridItemLeft {
//   display: flex;
//   justify-content: center;
//   align-items: baseline;
// }

.ADCContent {
  display: grid;
  grid-template-rows: auto auto;
  gap: 14px;
  padding: 20px 40px 28px 40px;
}

.ADCGridSection {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}

.ADCGridItem {
  display: grid;
  grid-template-columns: 18px auto;
  gap: 6px;
  align-items: start;
  padding: 10px;
}

// .ADCGridItemIcon {
//   width: 50%;
// }

.ADCGridItemRight {
  display: grid;
  gap: 6px;
}

.ADCBackgroundImage {
  min-width: 500px;
  margin: auto;
}

.ADCGridItemHeading {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #5c5c5c;
  line-height: 17px;
}

.ADCGridItemDescription {
  font-size: 14px;
  font-family: 'Montserrat-Regular', sans-serif;
  color: #5c5c5c;
  line-height: 17px;
}

@mixin ADCFooterBtn {
  background-color: #4078c0;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 24px;
  height: auto;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.ADCFooterBtn,
.ADCFooterBtn:hover,
.ADCFooterBtn:active,
.ADCFooterBtn:focus {
  @include ADCFooterBtn;
}

.ADCFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

/* ADC CARD */

// .ADCCloseIcon {
//   display: flex;
//   justify-content: end;
//   // padding: 16px 16px 0px 0px;
//   color: #ccc;
// }

.ADCCard {
  border-radius: 10px;
  width: 288px;
  // position: fixed;
  // z-index: 10;
  // right: 26px;
  // bottom: 17px;
  box-sizing: border-box;
}
.ADCCard .ant-card-cover {
  position: relative;
  padding: 28px 16px 10px 16px;
}
.an_n_heading {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.ADCCloseIcon {
  position: absolute;
  top: 17px;
  right: 17px;
  display: flex;
  justify-content: end;
  width: 10.33px;
  height: 10.33px;
}
.ADCCard.reportAlert .ant-card-cover {
  background-color: transparent;
  padding-top: 0px;
  padding-bottom: 0;
}

.ADCCardFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  // margin-top: 6px;
}
.adcContentWrapper {
  height: 138px;
}

.ADCCard .ant-card-cover {
  background-color: #f8f8f8;
  border-radius: 10px 10px 0 0;
}
.ADCFooterBtn,
.ADCFooterBtn:hover,
.ADCFooterBtn:active,
.ADCFooterBtn:focus {
  @include ADCFooterBtn;
  padding: 9px 10px;
  min-width: 125px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  border: none !important;
}

.ADCCardContent {
  display: grid;
  gap: 12px;
  padding: 16px;
}
.reportAlert .animationSec {
  min-height: 239px;
}

.newTag {
  background: #007aff26;
  color: #007aff;
  font-size: 10px;
  font-weight: 600;
  width: 39px;
  height: 18px;
  border-radius: 4px;
  padding: 3px 6px;
  line-height: 12px;
  display: flex;
  align-items: center;
  margin-right: 0px;
}
.newTagSec {
  margin-bottom: 10px;
}

.noPadding .ant-modal-body {
  padding: 0px !important;
}

.ADCCardMetaData .ant-card-meta-title {
  font-size: 16px;
  line-height: 20px;
  color: #474747;
  margin-bottom: 10px;
  font-family: Montserrat-SemiBold, sans-serif;
}
.ADCCardMetaData .ant-card-meta-description {
  font-size: 12px;
  line-height: 15px;
  color: #474747;
  font-family: Montserrat-Regular, sans-serif;
  position: relative;
  top: 3px;
}

.ant-modal.ADCpopup {
  padding-bottom: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ADCpopup .ant-modal-content {
  width: 784px;
  border-radius: 12px;
}
.ADCpopup .ant-card {
  border-radius: 12px;
}
.ADCpopup .ant-card-head {
  padding: 28px 40px 20px 40px;
}
.ADCpopup .ant-card-head-title {
  padding: 0px;
}
.ADCHeaderHeading {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ADCHeaderTxt {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  line-height: 20px;
  color: #333;
}
.ADCmonthTxt {
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
  line-height: 15px;
  color: #99999c;
}
.h36,
.h36:hover,
.h36:focus {
  height: 36px;
}

.ADCGridItemLeft {
  display: flex;
  align-items: flex-start;
}

// Service BulletIn Css Start Here

.serviceBulletinContainer {
  width: 780px !important;
  height: 540px;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  padding: 0 !important;
  box-shadow: 0 3px 20px #00000017;
}

.serviceBulletinContent {
  max-height: 314px;
  padding: 40px 80px 12px 80px;
  font-size: 16px;
  font-family: Montserrat-Regular;
  color: #000;
  letter-spacing: 0.25px;
  overflow: auto;
}

.uAHeader {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  margin-left: 12px;
  position: relative;
  top: 10px;
}

.uAHeaderNight {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  margin-left: 12px;
  position: relative;
  top: 10px;
  color: #e3e3e3;
}

.cULine {
  border: 1px solid #e8e8e8;
  margin-top: 36px;
  margin-bottom: 34px;
  width: 890px;
  position: relative;
  right: 24px;
}

.uASubTxt {
  font-family: Montserrat-Regular;
  font-size: 16px;
  text-align: initial;
  padding: 0 23px;
  margin-bottom: 22px;
}

.uASubTxtNight {
  font-family: Montserrat-Regular;
  font-size: 16px;
  text-align: initial;
  padding: 0 23px;
  margin-bottom: 22px;
  color: #e3e3e3;
}

.serviceBulletinTitle {
  box-shadow: 0 4px 12px #0000001c;
  height: 114px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: Montserrat-Bold;
  border-radius: 10px 10px 0 0;
  letter-spacing: 0.18px;
  color: #000;
  padding-left: 64px;
}

.serviceBulletinTitle span {
  margin-left: 156px;
}

.cancelUpdateBtn {
  height: 48px;
  border: 1px solid #366376;
  border-radius: 8px;
  min-width: 320px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.28px;
  color: #fff;
  font-size: 16px;
  margin-bottom: 6px;
  margin-top: 14px;
  background-color: #366376;
}

.pdfDocument {
  position: relative;
  bottom: 28px;
  height: 592px;
  width: 863px;
  overflow: auto;
  margin: 10px auto 0;
}

.backContainer {
  position: absolute;
  left: 22px;
  top: 30px;
}

.backIcon {
  height: 30px;
  width: 30px;
}

.winterInfo {
  margin-top: 20px;
}

.moreInfolink {
  font-weight: 600;
  text-decoration: underline;
  color: #67afb7;
  cursor: pointer;
}

.backTxt {
  font-size: 16px;
  font-family: Montserrat-Regular;
  margin-left: 8px;
  color: #000;
}

.backTxtNight {
  font-size: 16px;
  font-family: Montserrat-Regular;
  margin-left: 8px;
  color: #fff;
}

.checkBoxContainer {
  position: relative;
  padding: 10px 0 20px 30px;
  top: -16px;
}

.checkBoxLabel {
  font-family: Montserrat-Regular;
  font-size: 16px;
  position: absolute;
  top: 7px;
}

.checkBox {
  margin-right: 12px;
  height: 18px;
  width: 18px;
}

.serviceBulletinModal .ant-modal-body {
  margin: 32px 32px 18px 32px !important;
  max-height: 180px !important;
}

// Service BulletIn Css Ends Here
.title {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  color: #333333;
  margin-top: 12px;
  /* margin-bottom: 9px; */
}

.info {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: center;
  color: #99999c;
  margin-bottom: 0px;
}

.ant-modal.monthreportModal {
  padding-bottom: 0px;
  top: 0px;
}

.monthreportModal .ant-modal-content {
  width: 288px !important;
  height: 357px !important;
}

.monthreportModal .ant-modal-body {
  padding: 16px 16px 14px 16px !important;
}

.monthlyContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.badgeIcon {
  width: 32px;
  width: 32px;
  margin: auto;
}

.box1 {
  width: 100px;
  position: relative;
  top: -20px;
  height: 90px;
}
.box2 {
  width: 100px;
  height: 161px;
  position: relative;
  top: 20px;
}
.box3 {
  width: 250px;
  height: 200px;
  position: relative;
  top: 0px;
}
img.blast {
  width: 100px;
}
.report {
  height: 106px;
}
.mTop23 {
  /* margin-top: 25px; */
  width: 151px;
  height: 30px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.btnHide {
  visibility: hidden;
}

.animationSec {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
}
.btnGrp {
  display: flex;
  flex-direction: row;
}
.shareBtn {
  width: 96px;
  align-self: center;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.19px;
  text-align: center;
  color: #99999c;
  margin-right: 9px;
  cursor: pointer;
}

.shareBtn:hover {
  color: #333333;
}

.rprtBtn {
  width: 151px;
  justify-content: center;
}

.newWinterTag {
  background: rgba(0, 122, 255, 0.15);
  color: #007aff;
  font-size: 10px;
  font-weight: 600;
  width: 76px;
  height: 18px;
  border-radius: 4px;
  padding: 3px 6px;
  line-height: 12px;
  display: flex;
  align-items: center;
  margin-right: 0px;
}

@mixin WinterFooterBtn {
  background-color: #4078c0;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 24px;
  height: auto;
  font-family: 'Montserrat-SemiBold', sans-serif;
  padding: 9px 10px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  border: none !important;
}

.WinterFooterBtn,
.WinterFooterBtn:hover,
.WinterFooterBtn:active,
.WinterFooterBtn:focus {
  @include WinterFooterBtn;
}

.WinterCardFooter {
  display: flex;
  width: 100%;
  margin-top: 6px;
}

.WinterCardMetaData .ant-card-meta-title {
  font-size: 16px;
  line-height: 20px;
  color: #474747;
  margin-bottom: 10px;
  font-family: Montserrat-SemiBold, sans-serif;
}
.WinterCardMetaData .ant-card-meta-description {
  font-size: 12px;
  line-height: 15px;
  color: #474747;
  font-family: Montserrat-Regular, sans-serif;
  position: relative;
}

.complaince-container {
  background: white;
  border-radius: 10px;
  bottom: 25px;
  box-sizing: border-box;
  height: 414px;
  position: fixed;
  right: 25px;
  width: 288px;
  z-index: 10;
}

.complaince-container {
  .slick-dots li button {
    background-color: #d9d9d9;
    border-radius: 50%;
    height: 8px;
    width: 8px;
  }

  .slick-dots li.slick-active button {
    background-color: #99999c;
  }
  .ant-carousel .slick-dots li {
    width: 8px !important;
    height: 8px !important;
  }
  .ant-carousel .slick-dots li {
    margin: 0px !important;
    padding: 0px !important;
    height: 8px !important;
  }
  .ant-carousel .slick-dots {
    gap: 6px !important;
  }
  .ant-carousel .slick-dots li.slick-active {
    width: inherit !important;
  }
  .slick-dots li.slick button {
    background-color: #fcfcfc;
  }

  .slick-dots {
    position: relative;
    bottom: 4px;
    margin-bottom: 0px !important;
  }

  .anticon {
    font-size: 24px !important;
    color: #ccc !important;
  }

  .ant-carousel .slick-arrow {
    top: inherit;
    bottom: -4px;
    z-index: 9;
  }
  .ant-carousel .slick-arrow.slick-prev {
    left: 16px;
  }
  .ant-carousel .slick-arrow.slick-next {
    right: 16px;
  }
  .ant-card-bordered {
    border: none !important;
  }
  .slick-dots li.slick-active button {
    background-color: #99999c !important;
  }
  .ant-card-hoverable:hover {
    box-shadow: inherit !important;
    border-color: none !important;
  }
  .ADCCardFooterLeft,
  .ADCFooterBtn {
    width: 100%;
  }
}
