.alertsCotainerTabs .ant-tabs-content {
  height: inherit !important;
  overflow-y: auto;
}
.alertsCotainerTabs .ant-tabs-content-holder {
  height: calc(100vh - 650px) !important;
}
.alertsCotainerTabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px 19px 0px 19px !important;
  padding: 0px !important;
}
.alertsCotainerTabs .ant-tabs-nav-list .ant-tabs-tab:first-child {
  margin-right: 19px !important;
}
.alertsCotainerTabs .ant-tabs-nav-list {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
}
.link-aggregator .alertsCotainerTabs .ant-tabs-tab {
  padding: 0px 0px 4px 0px !important;
}
.alertsCotainerTabs .tickets-block,
.alertsCotainerTabs .error-code-block {
  // height: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 8px;
  margin-bottom: -8px;
}
.alertsCotainerTabs .ant-tabs-content {
  margin-top: 0 !important;
}

.alertsCotainerTabs .ant-tabs-content-holder {
  padding: 0px !important;
}
.alertsCotainerTabs > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.alertsCotainerTabs .ant-tabs-tab:after {
  content: '';
  position: absolute;
  height: 15px;
  width: 1px;
  background: #d9d9d9;
  right: -20px;
}
.alertsCotainerTabs .ant-tabs-tab:last-child::after {
  content: '';
  background: none !important;
}
.alert-tab-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
.alt_ticket_count {
  background: #eeeeee;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #99999c;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  line-height: 12px;
  margin-left: 4px;
}
.alt_title_txt {
  color: #99999c;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  text-transform: capitalize;
}
.link-aggregator .alertsCotainerTabs .ant-tabs-content-holder {
  background: transparent !important;
  padding-top: 14px !important;
  position: relative;
}
.alertsCotainerTabs .ant-tabs-tab.ant-tabs-tab-active .alt_title_txt {
  color: #5c5c5c;
}
.alertsCotainerTabs .ant-tabs-tab.ant-tabs-tab-active .alt_ticket_count {
  background: #80a8ad33;
  color: #366376;
}
.alert-tab-container div img {
  width: 14px;
  height: 14px;
}

.snapshotImg {
  width: 100%;
}

.snapshotContainer {
  display: flex;
  gap: 4px;
  min-height: 40px;
}

.summary-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 227px;
  padding: 10px 8px 10px 8px;
  background: #F7F7F7CC;
  z-index: 309;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  min-height: 92px;
}

.hazard-item {
  align-items: flex-start;
  flex-direction: row;
  width: 227px;
}

.hazard-top-section {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 101px;
  z-index: 310;
}

// .hazard-img-block {
//   width: 100px;
// }

.snapshotImg {
  width: 48px;
  height: 28px;
  border-radius: 4px;
}

.snapshot-item {
  align-items: flex-start;
  min-width: 227px;
}

.error-code-block .description {
  font-size: 10px;
  line-height: 12px;
  color: #1F1F1F;
  font-family: 'Montserrat-Regular';
  margin-bottom: -2px;
}

.error-code-block .reboot-hitch-not-responding  {
  font-size: 10px;
  line-height: 12px;
  color: #5C5C5C;
  font-family: 'Montserrat-Regular';
  width: 210px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.error-code-block .creator-time-193  {
  font-size: 8px;
  line-height: 10px;
  color: #5C5C5C;
  font-family: 'Montserrat-Regular';
}

.error-code-block .ant-space-item img {
  width: 8px !important;
  height: 10px !important;
  margin-right: 0px !important;
}
.error-code-block .ant-space {
  gap: 6px !important;
}

.alertsCotainerTabs.errorCodeCont .ant-tabs-content-holder {
  height: 198px !important;
}