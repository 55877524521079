.lanes_list-sec {
  overflow-y: scroll !important;
  height: calc(100vh - 485px);
  margin-right: -6px;
  padding-right: 8px;
}

.p_tab_name {
  font-size: 8px !important;
  font-family: 'Montserrat-Regular', sans-serif;
  color: #5c5c5c;
  margin-bottom: 0px;
}
.p_lanes_tabs_section {
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-right: 9px;
}
.p_lanes_tabs_section {
  margin-bottom: 30px;
}
.p_lanes_tab {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 84px;
  height: 84px;
}
.p_lanes_tab:hover,
.p_lanes_tab.sel {
  border: 1px solid #1f1f1f;
  color: #fff;
  padding: 19px 20px 16px 20px;
  border-radius: 11px;
  width: 84px;
  height: 84px;
}
.p_lanes_tab.sel .p_tab_name,
.p_lanes_tab:hover .p_tab_name {
  color: #474747;
  font-size: 10px !important;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.p_tab_icon {
  margin-bottom: 3px;
  width: 37px;
  height: 34px;
}
/* .p_lanes_tab.sel:first-child {
  border-radius: 4px 0 0 4px;
  
} */
/* .p_lanes_tab.sel:last-child,
.p_lanes_tab:last-child:hover {
  border-radius: 0px 4px 4px 0px;
} */
.ant-modal-wrap .feed_amount {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-modal.feed_amount {
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px !important;
  min-height: 176px;
  width: 342px !important;
}
.feed_amount .ant-modal-body {
  padding: 20px 28px 20px 28px;
  width: 342px !important ;
  box-sizing: border-box;
}
.feed_amount .ant-modal-content {
  border-radius: 10px;
}
.popup_head2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.popup_head2 .head_text {
  margin-bottom: 4px;
}
.popup_head2 p {
  margin-bottom: 11px;
}
.feed_amount_feet_sec {
  display: block;
  text-align: center;
  margin-top: 21px;
  width: 100%;
  margin-bottom: 29px;
}
.feed_feet_txt {
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #333333;
  margin: 0px;
}
.evenly {
  justify-content: space-evenly !important;
}
.p_cross_close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 11px;
}

.dairy_imp_section {
  overflow-y: scroll;
  height: 100vh;
  padding-bottom: 64px;
}

.dairy_imp_section::-webkit-scrollbar {
  display: none;
}

.dairy_start_button {
  background-color: #ec9322 !important;
}

.on-hover-hand-change {
  cursor: pointer;
}
