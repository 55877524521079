.help_center_container {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 29px 27px 29px 27px;
  position: relative;
  background: #ffffff;
  height: 100vh;
  /* overflow-y: auto; */
}

/* .help_center_container .ant-col {
  padding-left:20px !important;
  padding-right: 20px !important;
} */

.hc_top_bg {
  background-image: url(./../../../HelpCenter/images/hc_top_bg.svg);
  width: 100%;
  min-height: 25.1vh;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.hc_row {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  gap: 20px;
  height: calc(100vh - 195px);
  overflow-y: auto;
  background: #f00;
}

.hc_logo_section {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}

.hc_title_top {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  color: #474747;
  margin: 0px;
  margin-left: 4px;
  line-height: 20px;
  position: relative;
  top: 2px;
}

.hc_search_section {
  display: flex;
  width: 100%;
  margin-bottom: 62px;
}

.hc_search_bar {
  height: 40px;
}

.hc_search {
  display: flex;
  align-items: center;
  height: 40px;
}
.hc_search .ant-select-auto-complete {
  width: 100% !important;
}

.ant-input-search {
  display: flex;
  align-items: center;
}

.hc_search .ant-input-group .ant-input {
  background: #fff;
  border-radius: 8px;
  padding: 8px 12px 8px 36px !important;
  border: none;
  height: 40px;
  color: #333;
  display: flex;
  align-items: center;
  min-width: 21.4vw;
}

.hc_search .ant-input::placeholder {
  color: #99999c;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  position: relative;
  top: -2px;
}

.hc_search .ant-input:hover,
.hc_search .ant-input:focus,
.hc_search .ant-input:focus-visible {
  border: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.hc_search .ant-btn {
  border: none;
  border-radius: 20px;
  background: transparent;
  height: 40px !important;
  padding-left: 12px;
  position: relative;
  z-index: 1;
  cursor: default !important;
}

.hc_search .ant-input-group-addon {
  background: none;
  width: 0;
  cursor: default !important;
}

.hc_search .anticon-search {
  color: #99999c;
  width: 16px;
  height: 16px;
  background-image: url(./../../../HelpCenter/images/search_hc.svg);
}
.hc_search .anticon-search svg {
  display: none;
}

.hc_search .ant-btn::after {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.hc_searched_category {
  display: block;
}

.hc_searched_faq {
  display: block;
}

/* .hc_searched_category .ant-row {
  margin-bottom: 16px;
} */
.hc_cat_heads {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  color: #333333;
  margin: 0px;
  margin-bottom: 16px;
}

.hc_cat_card {
  border-radius: 10px;
  padding: 11px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  min-height: 54px;
  background: #fff;
  width: 100%;
  cursor: pointer;
}

.hc_cat_card:hover {
  background-color: #eeeeee;
}

.hc_cat_card p,
.hc_cat_card h2 {
  margin: 0px;
  padding: 0px;
}

.hc_cat_card .cat_card_head {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  color: #333333;
  margin: 0px;
  margin-bottom: 4px;
  line-height: 17px;
}

.hc_cat_card .cat_card_desc {
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  color: #99999c;
  margin: 0px;
  line-height: 15px;
}

.hc_cat_card img {
  margin-right: 12px;
  width: 40px;
  height: 40px;
}

.sec_border {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #ddd;
}

/* .acc_sec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
} */

.acc_sec .ant-collapse-item {
  /* float: left; */
  /* width: calc(50% - 20px); */
  /* margin-left: 10px;
  margin-right: 10px; */
}

.hc_faq_section {
  border: 1px solid #eeeeee;
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  background: #fff;
  /* height: 100%; */
}

.hc_faq_section .anticon-right {
  transform: rotate(90deg) !important;
}

.anticon-right {
  transition: transform 0.3s;
  /* transform: rotate(90deg) !important; */
  color: #99999c !important;
  font-size: 16px !important;
  top: 35% !important;
  right: 12px !important;
}

.hc_faq_section .ant-collapse-item-active .anticon-right {
  transition: transform 0.3s;
  transform: rotate(-180deg) !important;
  color: #99999c !important;
  font-size: 16px !important;
}

.hc_faq_section .ant-collapse-header {
  padding: 12px !important;
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.hc_faq_section .ant-collapse-header:hover {
  padding: 12px !important;
  background: #f7f7f7;
  border-radius: 8px !important;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.hc_faq_section .ant-collapse > .ant-collapse-item {
  border-bottom: 0px !important;
  margin-bottom: 8px;
}

.hc_faq_section .ant-collapse > .ant-collapse-item:last-child {
  border-bottom: 0px !important;
  margin-bottom: 0px;
}

.hc_faq_section .ant-collapse-content {
  border-top: 0px !important;
}

.hc_faq_section .ant-collapse-content {
  padding: 0px 12px 12px 12px !important;
  background: #f7f7f7;
  border-radius: 0px 0px 8px 8px !important;
}

.hc_faq_section .ant-collapse-item-active .ant-collapse-header {
  background: #f7f7f7;
  border-radius: 8px 8px 0px 0px !important;
}

.hc_panel_custom_header h3 {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  color: #333333;
  margin: 0;
  padding: 0;
}

.hc_faq_section .ant-collapse-content .ant-collapse-content-box {
  padding: 0px !important;
}

.hc_faq_section .ant-collapse-content .ant-collapse-content-box p {
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  color: #99999c;
  margin: 0;
  padding: 0;
}

/* .hc_faq_section .ant-collapse-item:last-child {
  clear: both;
} */

.minHeight2 {
  min-height: 200px;
}
/* .hc_faq_section {
  min-height: 200px;
} */

.flex-wrap {
  flex-wrap: wrap;
}

.flexfaq {
  display: flex;
  gap: 20px;
}
.flex {
  display: flex;
}

.hc_faq_section .ant-collapse {
  width: calc(100% - 10px);
  background: transparent !important;
}
.top_search_bar {
  /* min-width: 22.77vw; */
  display: block;
}
.mb47 {
  margin-bottom: 47px;
}

.hc_tabs_section {
  display: flex;
  width: 100%;
  margin-bottom: 62px;
}

.hc_tabs_section .ant-tabs {
  width: 100%;
}

.hc_tabs_section .ant-tabs-tab {
  padding: 10px 16px !important;
  min-height: 39px;
  box-sizing: border-box;
}
.hc_tabs_section .ant-tabs-tab-btn {
  color: #b5b5b6;
  font-size: 12px !important;
  font-family: 'Montserrat-Medium';
  text-transform: none !important;
}

.hc_tabs_section .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #333333 !important;
}

.hc_tabs_section .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px !important;
}
.hc_tabs_section .ant-tabs-ink-bar {
  border-bottom: none !important;
  background: none !important;
  border-radius: 0px !important;
}
.hc_tabs_section .ant-tabs-tab.ant-tabs-tab-active {
  background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0.2));
  border-radius: 5px 5px 0 0;
}
.hc_tabs_section .ant-tabs .ant-tabs-nav::before {
  border-bottom: none !important;
}
.hc_tabs_section .ant-tabs-content-holder {
  padding: 0px !important;
  background-color: #ffffff;
  height: calc(100vh - 224px);
  /* height: 100%; */
  margin-top: 40px;
  overflow-y: auto;
  overflow: hidden;
  /* justify-content: space-between; */
}

.byCat_section {
  padding: 16px;
  margin-bottom: 24px;
}
.tabHead_section {
  padding: 0;
}
.byCat_head {
  color: #333333;
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold';
  line-height: 14px;
  margin-bottom: 12px;
}
.tabTitle_heading {
  color: #333333;
  font-size: 14px !important;
  font-family: 'Montserrat-SemiBold';
  line-height: 17px;
  margin-bottom: 0px;
  padding: 10px 0px;
}

.tabTitle_head {
  color: #99999c;
  font-size: 14px !important;
  font-family: 'Montserrat-SemiBold';
  line-height: 17px;
  margin-bottom: 0px;
  padding: 10px 0px;
}

.tabTitle_desc {
  color: #99999c;
  font-size: 12px !important;
  font-family: 'Montserrat-Regular';
  line-height: 15px;
  margin-bottom: 12px;
}

.tabHead_count span {
  color: #99999c;
  font-size: 14px !important;
  font-family: 'Montserrat-Medium';
  line-height: 15px;
  margin-bottom: 12px;
}
.tabHead_count span:first-child {
  padding-right: 5px;
}

/* .tabHead_Content {
} */

.video-thumb img {
  background-color: #d8d9da;
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-bottom: 4px;
}
.video-thumb p.name {
  color: #333333;
  font-size: 12px !important;
  font-family: 'Montserrat-Medium';
  font-weight: 600;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 15px);
}
.video-thumb.selected {
  background: #eeeeee;
  border-radius: 8px;
  padding: 8px;
}

.fs16 {
  font-size: 16px !important;
}

.categoryItemsList .ant-menu-vertical {
  border-right: 0 !important;
}

.categoryItemsList .ant-menu-item {
  min-height: 31px;
  padding: 8px;
  background: transparent;
  border-radius: 5px;
  height: inherit;
  line-height: inherit;
  display: flex;
  align-items: center;
  color: #b5b5b6;
  font-size: 12px !important;
  font-family: 'Montserrat-Regular';
}
.categoryItemsList .ant-menu-item:hover {
  background: #eeeeee;
  color: #99999c;
}

.categoryItemsList .ant-menu-item-selected,
.categoryItemsList .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #eeeeee !important;
  color: #99999c !important;
}

.categoryItemsList .ant-menu-title-content span:first-child {
  margin-right: 4px;
}
.categoryItemsList .upperCase {
  text-transform: uppercase;
}

.categoryItemsList .capitalize {
  text-transform: capitalize;
}

.categoryItemsList.menuItems .ant-menu-item {
  font-size: 14px !important;
  color: #99999c;
}
.hc_logo_section div {
  display: flex;
}

iframe.ifram_video_big {
  min-height: 307px;
  height: calc(100vh - 265px);
  border-radius: 8px;
  max-width: 100%;
  width: 100%;
}
.video_thumb_scroll {
  height: calc(100vh - 220px);
  overflow-y: auto;
  margin-right: 0px;
  padding-right: 10px;
}

.video_title_download_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.video_title_download_sec img {
  margin-right: 6px;
}
.v_download_btn {
  min-height: 30px;
  padding: 9px 10px;
  display: flex;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  cursor: pointer;
}
.v_download_btn:hover,
.v_download_btn:focus {
  background: none;
  outline: none;
  border: none;
}

.video_title_head {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  color: #333333;
  margin: 0px;
}

@media (min-width: 500px) and (max-width: 992px) {
  .help_center_container {
    overflow-y: auto !important;
  }
  .video_thumb_scroll {
    height: inherit !important;
    overflow-y: hidden !important;
  }
}

.cursorPointer {
  cursor: pointer;
}

.video-thumb {
  max-width: 250px;
}

.video-thumb {
  background: transparent;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}
.video-thumb:hover,
.video-thumb.sel {
  background: #eeeeee;
  border-radius: 8px;
  padding: 8px;
}

.helpCenter_icon {
  background-image: url(./../../../HelpCenter/images/hc_n.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .helpCenter_icon {
  background-image: url(./../../../HelpCenter/images/hc_w.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.hc_search_black {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.searchedResultCount {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  margin: 0px;
  margin-top: 12px;
}

.hc_results_section {
  display: flex;
  width: 100%;
  margin-bottom: 62px;
}

.mt30 {
  margin-top: 30px;
}
.hc_searched_item {
  padding: 8px;
  display: block;
  margin-bottom: 8px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 8px;
  cursor: pointer;
}
.searched_cat_head {
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  color: #dddddd;
  line-height: 12px;
  margin: 0px;
  margin-bottom: 5px;
}
.searched_file_head {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  color: #333333;
  line-height: 17px;
  margin: 0px;
  margin-bottom: 4px;
}
.searched_file_para {
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  color: #99999c;
  line-height: 15px;
  margin: 0px;
}

.hc_results_section .ant-row {
  width: 100%;
}

.type_chip_green {
  padding: 4px 6px;
  border-radius: 20px;
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  color: #379526;
  margin-left: 8px;
  background: #3795260d;
}
.searched_file_para strong {
  color: #000000;
}
.type_chip_orange {
  padding: 4px 6px;
  border-radius: 20px;
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  color: #ec9322;
  margin-left: 8px;
  background: #ec93220d;
}
.mt-16 {
  margin-top: -16px;
}

.issue_item {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #dddddd;
}
.issue_row {
  padding: 8px;
}

.body3 {
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  color: #99999c;
  margin: 0px;
  line-height: 15px;
}
.body4 {
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  color: #99999c;
  margin: 0px;
  line-height: 12px;
}
.body5 {
  font-size: 8px;
  font-family: 'Montserrat-Regular';
  color: #b5b5b6;
  margin: 0px;
  line-height: 10px;
}
.issue_head {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.head_line5 {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  color: #333333;
  margin: 0px;
  line-height: 12px;
}
.head_line5_1 {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  color: #5c5c5c;
  margin: 0px;
  line-height: 15px;
}

.chip {
  background-color: #eeeeee;
  border-radius: 20px;
  padding: 4px 6px;
}

.mb24 {
  margin-bottom: 24px;
}
.mb12 {
  margin-bottom: 12px;
}
.issue_detail_sec {
}
.list_ol {
  list-style-type: decimal;
}
.list_ul {
  list-style-type: disc;
  margin: 0px;
  padding-left: 20px;
}
.hc_faq {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 8px;
  height: auto;
}

.hc_faq_col {
  display: grid;
  gap: 8px;
  height: auto;
}

.tabHead_Content {
  /* overflow-y: auto; */
  /* height: calc(100vh - 60px); */
  /* overflow-x: hidden; */
  margin-right: 0px;
  padding-right: 8px;
}

.knowissues_container {
  height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
}

.hc_searched_category {
  display: block;
  height: calc(100vh - 220px);
  overflow-y: auto;
  overflow-x: hidden;
}
.left_section_scroll {
  overflow-y: auto;
  /* height: calc(100vh - 220px); */
}
@media (min-width: 576px) {
  .left_section_scroll {
    margin-bottom: 24px;
  }
}

.no_results_sec img {
  margin-bottom: 10px;
}

.no_results_sec {
  text-align: center;
}
.no_results_text {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  color: #474747;
  margin: 0px;
  line-height: 17px;
  margin-bottom: 10px;
}

.no_results-helptext {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  color: #b5b5b6;
  margin: 0px;
  line-height: 17px;
}
.no_results_count {
  margin-right: 5px;
}

.fleetIndicators_container .infinite-scroll-component .ant-table {
  background: transparent !important;
}

.fleetIndicators_container .ant-table-sticky-holder {
  /* background-image: linear-gradient(to top, #fbfbfb, #fafbfc); */
  background-image: linear-gradient(#f7f8f91c, #fafbfc, #ffffff);
}

.videoBox {
  position: relative;
}
.videoTime {
  background: rgba(13, 13, 13, 0.5);
  width: 36px;
  height: 20px;
  border-radius: 4px;
  padding: 4px;
  position: absolute;
  right: 8px;
  bottom: 8px;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  color: #fff;
}
.video-thumb .videoLayer {
  display: none;
}
.video-thumb:hover .videoLayer {
  display: block;
}
.videoLayer {
  position: absolute;
  width: 100%;
  height: calc(100% - 6px);
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.videoLayer img.helpCenterplayIcon {
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}

.searchFileHeader {
  font-size: 14px;
  color: #333333;
}

.hc_search_hover {
  padding: 8px;
}

.hc_search_hover:hover {
  background-color: #eeeeee;
}

.hc_searched_item1 {
  display: block;
  margin-bottom: 8px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 8px;
  cursor: pointer;
}

.search_cat_style {
  font-size: 12px;
  color: #cfcfcf;
}

.trainingVideosContainer {
  display: flex;
  flex-wrap: wrap;
}

.trainingVideo {
  min-width: 250px;
}

@media (min-width: 500px) and (max-width: 991px) {
  .hc_tabs_section .ant-tabs-content-holder {
    height: 100% !important;
  }
  .catVideo {
    height: 100% !important;
  }
  .video-thumb {
    max-width: 100%;
    width: 100%;
  }
}

.resourceTab {
  display: flex;
  align-items: center;
  gap: 10px;
}
.categoryName {
  text-transform:capitalize;
}
.fileCard {
  background-color: #BFBFBF33;
  border-radius: 5px;
  width: 188px;
  height: 115px;
  padding: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fileTypeIcon {
  margin-bottom: 15px;
}
.fileDescription {
  font-size: 12px;
  line-height: 15px;
  font-family: 'Montserrat-SemiBold';
  color: #333333;
  text-transform: capitalize;
  max-width: 158px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  .gap20 {
    gap: 20px;
  }
  .gap10 {
    gap: 10px;
  }

  .fileDownload {
    position: absolute;
    top: 8px;
    right: 8px;
    background-image: url("../../../../assets/images/fileDownload.svg");
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  
  .filesHead {
  color: #333333;
  font-size: 16px !important;
  font-family: 'Montserrat-SemiBold';
  line-height: 20px;
  margin-bottom: 0px;
  padding: 10px 0px;
}