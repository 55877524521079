.impSaveTxt {
  /* font-size: 14px;
    font-family: Montserrat-SemiBold;
    letter-spacing: 1.25px;
    color: #CECECF;
    text-align: right; */
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  border: none;
  outline: none;
  background: none;
  color: #cececf;
  cursor: pointer;
  right: 38px;
  position: absolute;
  bottom: 20px;
  text-transform: uppercase;
}

.addImpButton {
  height: 60px;
  width: 60px;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 25px;
  bottom: 25px;
}

.impManufacture {
  font-size: 18px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.15px;
}

/* .impEditBtn button {
    font-size: 14px;
    font-family: Montserrat-SemiBold;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    color: #366376;
    right: 38px;
    position: absolute;
    bottom: 20px;
  } */

.impAutonomyBtn button {
  /* font-size: 14px;
    font-family: Montserrat-SemiBold; */
  outline: none;
  background: none;
  cursor: pointer;
  color: #366376;
  /* position: absolute; */
  /* bottom: 20px; */
  /* bottom: 1.6vh; */
  border: 1px solid #366376;
  /* padding: 10px 16px; */
  /* padding: 1.1vh 0.83vw; */
  border-radius: 9px;
  /* letter-spacing: 1px;
    line-height: 18px; */
}
.userIcon {
  position: relative;
}
.mr0 {
  margin-right: 0;
}
.formStyles .ant-input,
.formStyles .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.formStyles .ant-input:placeholder-shown {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.15px;
  line-height: 19px;
  color: #5c5c5c;
}
.formStyles .ant-input::placeholder,
.formStyles
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  /* font-size: 16px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.15px;
    line-height: 19px; */
  color: #b6b6b7;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 12px;
}
.userIcon .ant-upload {
  right: 20px;
  bottom: 30px;
  height: auto !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  width: auto !important;
}
/* .formStyles .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 47px !important;
  } */

.EmpUserIcon {
  position: relative;
}

.EmpUserIcon .ant-upload {
  right: 20px;
  bottom: 54px;
  height: auto !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  width: auto !important;
}

.posabs {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  color: #959595;
  z-index: 9;
}

.ipw input {
  padding-right: 40px;
}
.mfAdd {
  background: #ededed;
  color: #000;
  position: relative;
  top: 4px;
  padding: 8px;
  cursor: pointer;
  min-height: 42px;
  margin-top: -5px;
  word-break: break-word;
}
.ant-divider {
  margin: 0px !important;
}

.dM {
  font-family: 'Montserrat-Medium';
}
.dS {
  font-family: 'Montserrat-SemiBold';
}
.autocanelBtn {
  display: none;
}
.autonomyModal input,
.autonomyModal input:hover,
.autonomyModal input:focus {
  height: 44px;
  border: 1px solid #366376 !important;
  border-radius: 10px;
  box-shadow: none !important;
  font-size: 16px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.15px;
  line-height: 19px;
  color: #707070;
}
.autonomyModal .ant-select-selector {
  height: 44px;
  border: 1px solid #366376 !important;
  border-radius: 10px;
  box-shadow: none !important;
  font-size: 16px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.15px;
  line-height: 19px;
  color: #707070;
}
.okBtn.autonomySaveBtn {
  box-shadow: none;
  border: 0;
  color: #366376 !important;
  background-color: transparent !important;
  padding: 0 !important;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  text-transform: uppercase;
}
.okBtn.disableSave {
  color: #b9c4c9 !important;
  cursor: default;
}
/* .autonomymodal {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
} */
/* .autonomywidth {
  width: 50% !important;
} */
.autonomySettingsModal .ant-modal-content {
  border: 2px solid #707070;
  border-radius: 20px !important;
  box-shadow: 0 3px 6px #00000029 !important;
}
.autonomySettingsModal .ant-modal-close {
  right: 16px !important;
  top: 16px !important;
}
.autonomySettingsModal .anticon-close {
  color: #366376;
  font-size: 22px;
}
.autonomySettingsModal .ant-modal-body {
  padding-left: 64px !important;
  padding-right: 73px !important;
}
.autonomySettingsModal .ant-modal-footer {
  border-top: 0 !important;
  padding-bottom: 28px !important;
  padding-right: 37px !important;
}
.implementsWidget .ant-avatar {
  width: 145px !important;
  height: 145px !important;
}
.impSetpopup .posabs {
  top: 8px !important;
}
.autonomy_modal_sec .generictoggle {
  /* height: 5vh !important; */
  border-radius: 10px;
  padding: 0 11px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #366376;
}
.autonomy_modal_sec .ovfAuto {
  overflow-y: auto !important;
  height: 350px !important;
  padding-right: 2px;
  gap: 30px;
}
.autonomy_modal_sec .ant-input {
  /* height: 5vh !important; */
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.autonomy_modal_sec .ant-input:hover {
  border-radius: 10px;
  border: 1px solid #d9d9d9 !important;
}
.genric_toggle_sec {
  /* height: 47px !important; */
  border-radius: 10px;
  padding: 0 11px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border: 1px solid #366376;
}

/* .autonomySettingPopup .ant-modal-body {
  height: calc(100vh - 20vh);
} */
/* .autonomySettingPopup .ant-modal-content {
  width: 700px;
} */
.posrel {
  position: relative;
}

.autoHeads.ovfAuto {
  overflow-y: auto;
  height: calc(100vh - 42vh);
  padding-right: 24px;
}

.mt24.generictoggle {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
}
.mtb_0 {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}

/* Chrome, Safari, Edge, Opera */
.fieldRange input::-webkit-outer-spin-button,
.fieldRange input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.fieldRange input[type='number'] {
  -moz-appearance: textfield;
}

.implmentInput {
  padding-top: 13px !important;
}

.manufactureTxt .ant-select-selection-search-input {
  margin-top: 16px !important;
}

.modelSelect .selectImplementContainer .ant-select-selection-item {
  padding-top: 10px !important;
  font-size: 16px;
  font-family: 'Montserrat-Regular' !important;
  color: #000000d9 !important;
}

.addImplmnt .ant-switch-checked {
  background-color: #366376 !important;
}

/* june19th Css */
.userProfileIcon {
  background-color: #e8e8e8;
  border-radius: 50%;
  cursor: pointer;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 24px;
  position: relative;
  border: 1px solid #e8e8e8;
}
/* .userProfileIcon:hover {
  background-color: #E8E8E8;
} */
.userProfileIcon img.userProfileImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 11px;
}
.mbh24 {
  margin-bottom: 24px !important;
}
.implementSideHead {
  position: relative;
}
.dimesionsPopup {
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  z-index: 9;
  padding: 20px 16px;
}
.dimesionsPopup h4 {
  text-align: center;
  margin-bottom: 16px;
}
.dimesionsPopup h6 {
  margin-bottom: 7px;
  text-transform: uppercase;
}
.dimesionsPopup p {
  margin: 0;
}
.dimensionFlex {
  display: flex;
  gap: 11px;
  align-items: flex-start;
}
.addImplementWidget .implementSideHead {
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.addImplementWidget .implementSideHead span {
  color: #5c5c5c;
  font-style: italic;
  font-size: 10px;
  line-height: 12.19px;
}
.addImplementWidget .implementSideHead .exclamationIcon {
  background-color: #ec9322;
  border-radius: 50%;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  color: #fff;
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addImplementWidget .implementSideHead .exclamationIcon .anticon-exclamation {
  color: #fff !important;
  font-size: 9px;
}
.addImplementWidget .addImplementBtn {
  cursor: pointer;
  position: absolute;
  /* left: 20px; */
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  /* width: 299px; */
  width: 88.2%;
  height: 35px !important;
  /* box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16); */
  box-shadow: 0px 0px 6px 0px #00000029;
  border-radius: 6px;
  text-align: center;
  line-height: 14px !important;
  padding: 10px 0;
  letter-spacing: 0 !important;
}
.addImplementWidget .addImplementBtn:not([disabled]):hover {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.33) 0%,
      rgba(0, 0, 0, 0.33) 100%
    ),
    #366376 !important;
  box-shadow: none !important;
}
.addImplementWidget .addImplementBtn.ant-btn[disabled],
.addImplementWidget .addImplementBtn.ant-btn[disabled]:hover,
.addImplementWidget .addImplementBtn.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  background-color: #e8e8e8 !important;
  border-color: #e8e8e8 !important;
  box-shadow: none !important;
  color: #b5b5b6 !important;
}
.addImplementWidget .addImplementBtn:not([disabled]) {
  background-color: #366376;
  color: #fff;
}
.unitsHidden .dimPos {
  visibility: hidden;
}
.unitsHidden input:focus ~ .dimPos {
  visibility: visible;
}
.unitsHidden input:not(input[value='']) ~ .dimPos {
  visibility: visible !important;
}
.weightAlertMsg {
  color: #ec9322;
  font-size: 10px;
  font-weight: 500px;
  line-height: 12px;
  display: flex;
  gap: 8px;
  align-items: flex-start;
}
.ptoOn {
  background: #67afb7;
  border-radius: 4px;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.16);
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 10px;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  line-height: 12px;
  width: 36px;
  height: 39px;
}
.ptoOff {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  color: #b5b5b6;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 10px;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  line-height: 12px;
  width: 36px;
  height: 39px;
}
.ptoOnOff span {
  margin-top: 2px;
}
.ptoSec {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 23px;
}
.ptoLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  min-width: 105px;
}
.ptoWidgetBlk {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
/* .ptoWidgetBlk .autodriveSubHead {
  width: 30px;
  margin-right: 22px;
} */
.ptoWidgetBlk input.ant-input {
  width: 63px !important;
}
.ptoWidgetBlk.driveBlk input.ant-input {
  width: 100% !important;
  height: 39px !important;
}
.ptoWidgetBlk .ant-select-selector {
  height: 39px !important;
}
.ptoWidgetBlk.hitchBlk input.ant-input {
  width: 100% !important;
  height: 39px !important;
}
.hitchImg {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.hitchImgActive {
  background-color: #67afb7 !important;
}
.hintTxtRed {
  color: #ff8488 !important;
}
.hintTxt {
  margin-top: 12px !important;
  color: #5c5c5c;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  line-height: 10px;
  position: relative;
  left: 3px;
}
.hitchIcon span {
  color: #5c5c5c;
  font-size: 8px;
  font-family: 'Montserrat-Medium';
  line-height: 10px;
}
.hitchFlex {
  display: flex;
  gap: 18px;
  align-items: center;
  justify-content: space-between;
}
.editProfileIcon {
  color: #fff;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: #a3d5d8;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userProfileIconNormal {
  display: block;
}
.userProfileIconHover {
  display: none;
}
.userProfileIcon:hover .userProfileIconHover {
  display: block !important;
}
.userProfileIcon:hover .userProfileIconNormal {
  display: none !important;
}
.userProfileIcon:hover .editProfileIcon {
  background: #67afb7;
}
.editProfileIcon .anticon-edit {
  font-size: 9px;
}
.editProfileIcon .ant-upload.ant-upload-select-picture-card {
  line-height: 0 !important;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  background: transparent;
  border: 0 !important;
}
.editProfileIcon .ant-upload.ant-upload-select-picture-card > .ant-upload {
  width: auto !important;
  height: auto !important;
  line-height: 0 !important;
  margin: 0 !important;
}
.rightWidgetView .editProfileIcon .ant-upload .editImgs {
  position: unset !important;
  transform: unset !important;
}
.rightWidgetView .editProfileIcon .ant-upload .editImgs img {
  width: 10px !important;
}
.editProfileIcon .ant-upload-list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.operationLeftTxt {
  color: #474747;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  line-height: 12px;
  text-align: center;
  margin-bottom: 0;
}
.operationRightTxt {
  color: #474747;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  line-height: 12px;
  text-align: center;
  margin: 0;
  /* width: 105px; */
}

.filters_card .width100per {
  width: 100% !important;
}

.flex2Colum {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex3Colum {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.flex2Row {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}
.impDiscardBtn,
.impDiscardBtn:hover,
.impDiscardBtn:focus {
  background-color: #366376;
  border-color: #366376 !important;
  color: #fff;
  padding: 10px 24px;
  border-radius: 6px;
  letter-spacing: 0 !important;
  line-height: 12px !important;
  height: 32px !important;
  margin-left: 24px !important;
}
.impCancelBtn,
.impCancelBtn:hover,
.impCancelBtn:focus {
  color: #366376;
  padding: 10px 0;
  height: 32px !important;
  border: 0 !important;
  box-shadow: none !important;
  letter-spacing: 0 !important;
  line-height: 12px !important;
}
.implementPopup {
  width: 400px !important;
}
.implementPopup .ant-modal-content {
  min-width: 400px !important;
}
.implementPopup .ant-modal-body {
  text-align: left !important;
  padding: 20px !important;
}
.implementPopup .ant-modal-footer {
  border-top: 0 !important;
  padding: 15px 20px 20px 20px !important;
}
.implementPopup p {
  color: #99999c;
  margin-top: 14px;
  margin-bottom: 0;
}
.implementPopup h4 img {
  margin-right: 15px;
}
.inCompleteAutodriveMsg {
  background: rgba(242, 204, 201, 0.2);
  border-radius: 6px;
  color: #de827a;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  line-height: 12px !important;
  margin-bottom: 16px;
}
.inCompleteAutodriveMsg img {
  margin-right: 11px;
}
.deactivatedAutodriveMsg {
  background: #e8e8e8;
  border-radius: 6px;
  color: #474747;
  padding: 10px 12px;
  line-height: 12px !important;
  margin-bottom: 16px;
}
.viewImplementProfileIcon {
  /* width: 70px;
  height: 70px; */
  background-color: #d9d9d9 !important;
  /* border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 6px auto; */
}
.viewImplementProfileIcon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.viewImpName {
  color: #000000;
  text-align: center;
}
.w50per {
  width: 50%;
}
.textRight {
  text-align: right;
}
.textCenter {
  text-align: center;
}
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexBtnCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexBtnStart {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.viewImpSubHead {
  color: #333333;
  letter-spacing: 0.1px !important;
}
.impBox {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.gridCenter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.gap28 {
  gap: 28px;
}
.gap20 {
  gap: 20px;
}
.impDimesions h6 {
  color: #333333;
  margin: 0;
  margin-bottom: 2px;
}
h6.ptoTxt {
  color: #333333 !important;
}
.impDimesions p {
  color: #5c5c5c;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  line-height: 10px;
  margin: 0;
}

.impDimesions p:not(:hover) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.impDimesions p span {
  color: #5c5c5c;
  font-size: 8px;
  font-family: 'Montserrat-Medium';
  line-height: 7px;
}
.impInrBox {
  padding: 16px 20px;
  border-bottom: 1px solid #f7f7f7;
}
.impAutodriveBlk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
}
.impAutodriveBlk .impAutodriveInrBlk {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.impAutodriveBox {
  padding: 16px 20px;
  border-bottom: 1px solid #f7f7f7;
}
.NoImpData h6 {
  color: #de827a;
}
.impAutodriveBlk .NoImpData .impAutodriveInrBlk {
  border: 1px solid #de827a !important;
}
.NoImpData .impAutodriveBox {
  border-bottom: 1px solid #de827a !important;
}
.NoImpData .impAutodriveBox:last-child {
  border-bottom: 0 !important;
}
.impBtmBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  width: 88.2%;
}
.impBtmBtns .impDeactivateBtn {
  color: #366376;
  line-height: 12px !important;
  text-align: right;
  padding-right: 25px;
  width: 50%;
  cursor: pointer;
  letter-spacing: 0 !important;
}
.impBtmBtns .impEditBtn {
  background-color: #366376;
  border-color: #366376;
  color: #fff;
  width: 50%;
  border-radius: 6px;
  letter-spacing: 0 !important;
  padding: 10px 0;
  line-height: normal;
}

.loaderIcon .edit-loader {
  margin-left: 0 !important;
  margin-top: 0 !important;
  transform: translate(-50%, -50%);
}

.addImplementWidget .btnDisabled,
.addImplementWidget .btnDisabled:not([disabled]):hover,
.addImplementWidget .btnDisabled:focus {
  background: #e8e8e8 !important;
  border-color: #e8e8e8 !important;
  color: #b5b5b6 !important;
}

.editImplementIcon .editImgs {
  bottom: -2px !important;
  right: 3px !important;
  top: unset !important;
  left: unset !important;
  transform: unset !important;
}

.seedTxt {
  color: #366376;
  display: flex;
  gap: 5px;
  align-items: center;
}
.greenExclamationIcon {
  background-color: #366376;
  border-radius: 50%;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  color: #fff;
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seedFieldFlex {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}
.ptoWidgetBlk .autodrivem20 {
  width: 90px !important;
}

.impListContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backArrowTxt {
  display: flex;
}

.impListHeader {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  color: #333333;
}

.padding30 {
  padding: 30px;
}

.impListTxt {
  font-size: 12px;
  color: #5c5c5c;
  margin-top: 5px;
  font-family: 'Montserrat-Regular';
}

.createNewBtn,
.createNewBtn:hover {
  background-color: #366376;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  color: #fff;
  cursor: pointer;
  padding: 10px;
  letter-spacing: 0.1px !important;
  line-height: 12px !important;
  height: 36px;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
}

.showDimTxt {
  font-family: 'Montserrat-SemiBold';
  font-size: 10px;
  color: #99999c;
  line-height: 12.19px;
  cursor: pointer;
}

.mt34 {
  margin-top: 34px;
}

.acampoTxt {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #333333;
  /* margin-bottom: 12px; */
}

.aprImpList {
  padding: 20px;
  height: calc(100vh - 262px);
  overflow: auto;
}

.acampoGrids {
  display: grid;
  grid-template-columns: repeat(auto-fit, 285px);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.acampoGrids .grid_item {
  width: 285px;
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  /* margin-bottom: 12px; */
}

.grid_item_Details {
  display: flex;
  gap: 16px;
  /* justify-content: space-between; */
}

.implement_Details_wrapper {
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  width: calc(100% - 58px);
  position: relative;
}
.implement_Details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.acampoGrids .grid_item img {
  border-radius: 50%;
}
.plusAbs {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.impAutoDriveTxt {
  font-size: 8px;
  color: #ec9322;
  font-family: 'Montserrat-SemiBold';
  display: flex;
  align-items: center;
  gap: 4px;
}

.spTxt {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  color: #5c5c5c;
  /* margin-top: 4px;
  margin-bottom: 10px; */
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.impMdl-sec {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* width: 165px; */
}

.rotaryTxt {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  color: #99999c;
}

.addImplBtn,
.addImplBtn:hover,
.addImplBtn:focus {
  font-family: 'Montserrat-SemiBold';
  width: 164px;
  height: 36px;
  background-color: #366376;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
}
.addImplBtn[disabled] {
  background-color: rgba(54, 99, 118, 25%) !important;
}

.impCancelBtn {
  width: 63px;
  height: 36px;
  font-size: 12px;
  color: #99999c;
}

.createImpModal .ant-modal-content {
  border-radius: 10px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 75px;
}
.createImpModal .ant-modal-body,
.createImpModal .ant-modal-footer {
  padding: 0px;
}

.createImpModal .ant-modal-footer {
  border: 0;
}

.newImpContainer {
  display: flex;
  gap: 31px;
}
.impFileds {
  width: calc(100% - 90px);
}
.impFileds-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mar0 {
  margin: 0;
}

.w304 {
  width: 304px;
}

.flex4Colum {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
}

.padding30 .float-label .ant-select {
  width: 160px;
}

.certifiedToggleTxt {
  font-size: 10px;
  color: #99999c;
  font-family: 'Montserrat-Regular';
  display: flex;
  gap: 8px;
  align-items: center;
}

.dimensionTxt {
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  color: #333333;
  margin-top: 25px;
  margin-bottom: 4px;
}

.dimensionBorder {
  border: 1px solid #cccccc91;
  border-radius: 4px;
}

.lengthHeightBr {
  border-top: 1px solid #cccccc91;
}

.pd5 {
  padding: 5px;
}

.widthWeightTxt {
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  color: #5c5c5c;
  display: flex;
  justify-content: space-between;
}

.dimSize {
  font-family: 'Montserrat-SemiBold';
  margin-left: 20px;
}

.ant-modal.createImpModal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px;
  top: 0px;
  width: 809px !important;
}
.flex-direction {
  flex-direction: column;
  gap: 10px;
}
.implementSideHead p,
p.weightAlertMsg {
  margin: 0px !important;
  padding: 0px !important;
}
.implement-row-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}

.impImage img {
  width: 58px;
  height: 58px;
}

.autoDrive-setting-new {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.autoDrive-setting-new .anticon-right {
  transition: transform 0.3s;
  transform: rotate(90deg) !important;
  color: #99999c !important;
  font-size: 14px !important;
  top: 35% !important;
  right: 12px !important;
}
.settings-row-new {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
}

.ad-row-box {
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.setting-txt-head {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #474747;
}
.ad-setting-sec {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.ad-setting-item {
  display: flex;
  gap: 20px;
  align-items: center;
  min-height: 39px;
}
.ad-setting-item .status2 {
  min-width: 35px;
}
.ad-setting-item .float-label {
  width: 100%;
}
.fields-width {
  width: 100%;
}
.ad-setting-help-txt {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #5c5c5c;
}
.driveTxt {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #99999c;
}
.hposTxt {
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
  color: #5c5c5c;
}
.hitchImgActive ~ .hposTxt {
  font-size: 8px;
  font-weight: 500;
  line-height: 10px;
  color: #333333;
}
.imp-min-helptxt-sec {
  display: flex;
  align-items: center;
  gap: 6px;
}
.imp-minTxt {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: #007aff;
}
.ad-settings .ant-modal-content {
  gap: 20px !important;
}
.measureColor {
  color: #000 !important;
}

.createImpSpl {
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  color: #5c5c5c;
}

.createImpSpl span{
  font-family: 'Montserrat-Bold';
}

.newImpDrive .anticon-right {
  transform: rotate(0deg) !important;
  color: #000 !important;
  font-size: 12px !important;
}

.noImpTxt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 262px);
  color: #99999c;
}

.lc {
  text-transform: lowercase;
}

.impAttachment {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.dbManufactureWidth .ant-select {
  width: 100%;
}

.maxSpeedPos {
  margin-top: -17px !important;
}
