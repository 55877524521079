/* Typography Styles */
.headline1 {
  font-size: 24px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.25px;
  line-height: 29px;
}

.headline2 {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0;
  line-height: 24px;
}

.headline2_B {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0;
  line-height: 24px;
}

.headline3 {
  font-size: 16px !important;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.15px;
  line-height: 19px;
}

.headline3_R {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.1px;
  line-height: 22px;
}

.headline4 {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.1px;
  line-height: 18px;
}

.headline5 {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.4px;
  line-height: 15px;
}

.overline1 {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.1px;
  line-height: 18px;
}

.overline2 {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.125px;
  line-height: 18px;
}

.overline3 {
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.075px;
  line-height: 13px;
}

.pinBody {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.25px;
  line-height: 22px;
}

.body1 {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px;
}

.body2 {
  font-size: 12px !important;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
}

.body3 {
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.4px;
  line-height: 15px;
}

.body4 {
  font-size: 8px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.4px;
  line-height: 13px;
}

.Button {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
}

.Button2 {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.95px;
  line-height: 15px;
}

.Button3 {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.125px;
  line-height: auto;
}

.status2 {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.125px;
  line-height: auto;
}

.tractorState {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 13px;
}

.fReg {
  font-family: 'Montserrat-Regular' !important;
}

.fMed {
  font-family: 'Montserrat-Medium' !important;
}

.fBld {
  font-family: 'Montserrat-Bold' !important;
}

.fSbd {
  font-family: 'Montserrat-SemiBold' !important;
}

/* Typography Styles End */

/* Sidemenu styles goes here  *********************************/

.login_logo_box img {
  height: auto;
  max-width: 100%;
}

.iconBoxProfile .ant-avatar {
  /* width: 48px;
  height: 48px; */
  width: 40px !important;
  height: 40px !important;
}

.main-menu:hover .nav-text {
  position: relative;
  /* left: -0.73vw; */
  /* left: -1vw; */
  left: 0;
}

.iconBox img {
  max-width: 60%;
}

.login_logo_box {
  margin-left: 1.1vw !important;
  margin-right: 1.3vw !important;
  /* margin-top: 32px;
  margin-bottom: 60px; */
  margin-top: 30px !important;
  margin-bottom: 28px !important;
  min-height: 30px;
}

.wingSpanAI .login_logo_box {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

/* .wingSpanAI.main-menu:hover .company_logo {
  background-size: 100% !important;
} */

.main-menu:hover .login_logo_box .sLogo {
  display: none;
  min-width: 45px;
  max-width: 100%;
  min-height: 28px;
}

.main-menu:hover .login_logo_box .lLogo {
  display: block;
  min-width: 171px;
  max-width: 100%;
  min-height: 28px;
}

.main-menu .login_logo_box .lLogo {
  display: none;
}

.login_user_sec {
  position: absolute;
  /* bottom: 30px; */
  /* bottom: 0px; */
  bottom: 5px;
  height: 90px;
}

.sMenuUL {
  padding-top: 0px !important;
}

.login_user_sec ul li {
  margin-bottom: 0;
}

.iconBox {
  margin-left: 27px;
  margin-right: 27px;
  width: 46px;
  height: 46px;
}

.iconBoxProfile {
  margin-left: 27px;
  margin-right: 27px;
}

.iconBoxProfile .ant-avatar-circle {
  border: 1px solid #67afb7;
}

.side_menu_sec {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 210px);
  /* height: calc(100vh - 245px); */
  overflow-y: auto;
  overflow-x: hidden;
}

.wingSpanAI .side_menu_sec {
  /* height: calc(100vh - 245px) !important; */
  height: calc(100vh - 215px) !important;
}

.login_user_dtl .nav-text {
  color: #fff !important;
}

.main-menu:hover .logoutLink:hover .nav-text.overline2 {
  color: #fff;
}

.company_logo {
  background-image: url(../../assets/images/login_logo.svg);
  /* background-image: url(../../assets/images/WingspanAI_white_logo.svg); */
  width: 45px;
  /* width: 60px; */
  height: 28px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
  margin: auto;
}

.wingSpanAI .company_logo {
  background-image: url(../../assets/images/WingspanAI_white_logo.svg);
  /* width: 60px !important; */
  width: 50px !important;
  height: 23px !important;
}

.main-menu:hover .company_logo {
  background-image: url(../../assets/images/login_logo_large.svg);
  /* background-image: url(../../assets/images/WingspanAI_white_exp.svg); */
  width: 171px;
  height: 28px;
  /* width: 99px;
  height: 45px; */
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
  margin: auto;
}

.wingSpanAI.main-menu:hover .company_logo {
  background-image: url(../../assets/images/WingspanAI_white_exp.svg);
  width: 99px !important;
  height: 45px !important;
}

.liveops_icon {
  background-image: url(../../assets/images/liveops_n.svg);
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .liveops_icon {
  background-image: url(../../assets/images/liveops_w.svg);
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.farmprofile_icon {
  background-image: url(../../assets/images/farmprofile_icon_n.svg);
  width: 23px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.chatIcon {
  background-image: url(../../assets/images/chatIcon_w.svg);
  width: 23px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .farmprofile_icon {
  background-image: url(../../assets/images/farmprofile_icon_w.svg);
  width: 23px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.works_schedule_icon {
  background-image: url(../../assets/images/works_schedule_icon_n.svg);
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .works_schedule_icon {
  background-image: url(../../assets/images/works_schedule_icon_w.svg);
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.maintenance_icon {
  background-image: url(../../assets/images/maintenance_icon_n.svg);
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.maintanance_red {
  background-image: url(../../assets/images/maintanance_red.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .maintenance_icon {
  background-image: url(../../assets/images/maintanance_red.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

/* .analytics_icon {
  background-image: url(../../assets/images/analytics_icon_n.svg);
  width: 17px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .analytics_icon {
  background-image: url(../../assets/images/analytics_icon_w.svg);
  width: 17px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
} */

.analytics_icon {
  background-image: url(../../assets/images/a_icon_nor.svg);
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.analytics_red {
  background-image: url(../../assets/images/a_icon_red.svg);
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .analytics_icon {
  background-image: url(../../assets/images/a_icon_sel.svg);
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}
.analytics_icon_popup {
  background-image: url(../../assets/images/a_icon_red.svg);
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.dealers_icon {
  background-image: url(../../assets/images/dealer_g.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .dealers_icon {
  background-image: url(../../assets/images/dealer_w.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.fleetusage_icon {
  background-image: url(../../assets/images/fleetusage_icon_n.svg);
  width: 22px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .fleetusage_icon {
  background-image: url(../../assets/images/fleetusage_icon_w.svg);
  width: 22px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.logoutLink .logout_icon {
  background-image: url(../../assets/images/logout_icon_n.svg);
  width: 19px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.logoutLink:hover .logout_icon {
  background-image: url(../../assets/images/logout_icon_w.svg);
  width: 19px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

/* Sidemenu styles goes end here  *********************************/

/* tabs and table container css goes here *******************************/

.elTabs > .ant-tabs-nav {
  /* height: 123px; */
  height: 92px !important;
  /* margin-bottom: 28px; */
}

.elTabs .ant-tabs-nav-wrap {
  padding-bottom: 36px;
}

.elTabs .ant-tabs-tab-btn,
.elTabs .ant-tabs-tab:hover,
.attContainer .ant-tabs-tab-btn {
  color: #959595;
  /* font-size: 14px; */
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold';
  /* letter-spacing: 1.25px;
  line-height: 22px; */
  letter-spacing: 1.07px;
  line-height: 15px;
  text-transform: uppercase;
}

.elTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000 !important;
}

.elTabs .ant-tabs-ink-bar,
.attContainer .ant-tabs-ink-bar {
  border-bottom: 3px solid #67afb7 !important;
  background: #67afb7 !important;
}

.attContainer .elTabs .ant-tabs-content-holder,
.attContainer .elTabs .ant-tabs-content,
.profileCont .elTabs .ant-tabs-content-holder {
  height: calc(100vh - 123px);
}

.farm_profile_container {
  /* width: calc(100% - (12.7vw + 10vw)) !important;
  margin-left: 12.7vw !important;
  margin-right: 10vw !important; */
  width: calc(100% - (9.6vw + 9.6vw)) !important;
  margin-left: 9.6vw !important;
  margin-right: 9.6vw !important;
}

/* .elTabs .ant-layout {
    height: calc(100vh - 123px);
} */

.container_full_1 {
  width: 100%;
  padding-left: 12.7vw;
  padding-right: 10vw;
}

.container_full_1 {
  width: 100%;
  padding-left: 12.7vw;
  padding-right: 10vw;
}

.tblContainer {
  /* width: calc(100% - (4.69vw + 30px)) !important; */
  width: calc(100% - 54px) !important;
  /* margin-left: 4.69vw !important;
  margin-right: 30px !important; */
  margin-left: 27px !important;
  margin-right: 27px !important;
}

.container_full_2 {
  /* width: calc(100% - (7.85vw + 7.85vw)) !important;
  margin-left: 7.85vw !important;
  margin-right: 7.85vw !important; */
  width: 100% !important;
}

.mt12 {
  margin-top: 12px !important;
}

.dbWidget .ant-card-body .ant-table-tbody > tr > td,
.customTbl tbody > tr > td,
.userPf table tr > td {
  /* font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px; */
  font-size: 12px !important;
  font-family: 'Montserrat-Regular' !important;
  letter-spacing: 0px !important;
  line-height: 22px !important;
}

.dbWidget .ant-card-body .ant-table-thead > tr > th,
.customTbl thead > tr > th,
.userPf table tr > th {
  /* font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.15px;
  line-height: 19px; */
  font-size: 14px !important;
  font-family: 'Montserrat-SemiBold' !important;
  letter-spacing: 0px !important;
  line-height: 18px !important;
}

.tblContainer .m_tb {
  margin-top: 8px;
  margin-bottom: 36px;
}

/* dropdowns css ****************************/

.searchField .ant-input-search,
.searchSec .ant-input-search {
  max-width: 351px;
  /* height: 50px; */
}

.viewportContainer .searchSec .ant-input-group-addon button,
.searchSec .ant-input-group-addon button {
  width: auto;
  /* height: 50px; */
  padding-left: 16px;
}

.searchSec .ant-input-search input {
  background: none;
  border: none;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.22px;
  line-height: 18px;
  min-height: 32px;
  max-height: 32px;
  padding-left: 35px;
  width: 100%;
}

.searchSec .ant-input-search input::placeholder {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.22px;
  line-height: 18px;
}

.searchFilters .tab-label,
.searchFilters .tab-label:hover {
  height: 50px;
  padding-left: 24px;
  padding-right: 24px;
}

.searchFilters .flbTopFixed {
  color: #b6b6b7;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.09px;
  line-height: 13px;
}

.searchFilters .flbSelected {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
}

.searchFilters .selGray {
  color: #b6b6b7;
  font-style: italic;
}

.smallFilter .flblWrapper {
  min-width: 167px;
  width: 167px;
  height: 50px;
  margin-left: 19px;
}

.medFilter .flblWrapper {
  min-width: 224px;
  width: 224px;
  height: 50px;
  margin-left: 24px;
}

.singleDatePicker {
  width: 200px;
  height: 50px;
}

.searchFilters .tractorField .flblWrapper {
  width: 240px;
  height: 50px;
  margin-right: 0;
}

.searchFilters .customField {
  margin-left: 24px;
}

.searchFilters .customField .ant-select-selector {
  width: 200px;
  height: 50px;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
  margin-right: 0;
}

.searchFilters .dateFilter {
  height: 50px;
  margin-left: 24px;
  margin-right: 0;
}

.searchFilters .dateFilter .ant-picker-input > input {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
}

.searchFilters .dW250_date {
  width: 14vw;
}

.attContainer .elTabs .tblContainer {
  height: calc(100vh - 123px);
  display: inline-block;
}

/* .viewportContainer .farmTabsTbl .infinite-scroll-component,
.farmTabsTbl .infinite-scroll-component,
.snapshotContent .infinite-scroll-component {
    max-height: calc(100vh - 350px);
}

.viewportContainer div#empTbl {
    min-height: calc(100vh - 350px);
} */

.tblDft.lbTbls.h42vh {
  height: calc(100vh - 350px);
  max-height: calc(100vh - 350px);
}

.tblDft.lbTbls.h42vh .infinite-scroll-component {
  max-height: calc(100vh - 360px);
}

.lbTbls div#empTbl {
  min-height: calc(100vh - 360px);
}

/* .tblDft.farmTabsTbl.posRel div#empTbl,
  .tblDft.farmTabsTbl.hoverable div#empTbl {
    min-height: calc(100vh - 365px);
  } */

.dsTbl .mr11 {
  margin-right: 3px !important;
}

.viewportContainer .tblDft {
  height: calc(100vh - 290px);
  max-height: calc(100vh - 290px);
}

.viewportContainer .tblDft .infinite-scroll-component {
  max-height: calc(100vh - 315px);
}

.viewportContainer .tblDft div#empTbl {
  min-height: calc(100vh - 315px);
}

.tblDft.farmTabsTbl.dsTbl {
  height: calc(100vh - 510px);
  max-height: calc(100vh - 510px);
}

.tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
  max-height: calc(100vh - 535px);
}

.tblDft.farmTabsTbl.dsTbl div#empTbl {
  min-height: calc(100vh - 535px);
}

.tblDft.farmTabsTbl {
  height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
}

.tblDft.farmTabsTbl .infinite-scroll-component {
  max-height: calc(100vh - 325px);
}

.tblDft.farmTabsTbl div#empTbl {
  min-height: calc(100vh - 325px);
}

.lbCont .tblDft.lbTbls.farmTabsTbl {
  height: calc(100vh - 330px);
  max-height: calc(100vh - 330px);
}

.lbCont .tblDft.lbTbls.farmTabsTbl .infinite-scroll-component {
  max-height: calc(100vh - 340px);
}

.lbCont .tblDft.lbTbls.farmTabsTbl div#empTbl {
  min-height: calc(100vh - 340px);
}

.tblDft.farmTabsTbl.reportsTbl {
  height: calc(100vh - 290px);
  max-height: calc(100vh - 290px);
}

.tblDft.farmTabsTbl.reportsTbl .infinite-scroll-component {
  max-height: calc(100vh - 315px);
}

.tblDft.farmTabsTbl.reportsTbl div#empTbl {
  min-height: calc(100vh - 315px);
}

.libraryContainer.lbCont.tblContainer {
  height: calc(100vh - 132px);
}

.analyticsTab .elTabs .libraryContainer.lbCont.tblContainer .ant-tabs-content {
  height: calc(100vh - 193px) !important;
}

/* LIve ops styles goes here **********************************/

.fleetlistBlk {
  position: absolute;
  /* left: 24px; */
  left: 25px;
  /* top: 50px; */
  top: 25px;
  z-index: 9;
  /* width: 358px; */
  width: 358px !important;
}

.searchMapBlk {
  position: relative;
  margin-bottom: 20px !important;
}

.operationsTitle .ant-collapse-header {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  color: #6a6a6a !important;
  padding: 16px 16px 15px 16px;
  transition: all 0.3s, visibility 0s;
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 1.25px;
  line-height: 18px;
}

.parentIcon .anticon {
  right: 10px;
}

.autodriveButton {
  /* margin-left: 100px; */
  border: 1px solid #959595;
  border-radius: 18px;
  color: #5c5c5c;
  background-color: transparent;
  display: flex;
  margin: auto;
  align-items: center;
  min-width: 155px;
  font-size: 10px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.33px;
}

.autodriveButton:hover,
.autodriveButton:focus,
.autodriveButton:active {
  color: #fff !important;
  background: #ec9322 !important;
  border: 1px solid #ec9322 !important;
}

.autodriveButton .routeIcon,
.autodriveButton .routeIcon2 {
  margin-right: 7px !important;
}

.routeIcon .autodriveButton:hover {
  color: #fff;
  background: #ec9322;
  border: 1px solid #ec9322 !important;
}

.fleetTabs .ant-tabs-nav {
  /* padding: 0 16px; */
  padding: 0 12px;
  margin-top: 14px;
  margin-bottom: 22px;
}

.fleetTabs .ant-tabs-ink-bar {
  border: none !important;
}

.parentAccordion .fleetOverallBlk {
  padding-left: 15px;
  padding-right: 14px;
  padding-bottom: 6px !important;
  padding-top: 0 !important;
  /* height: 392px; */
}

.parentAccordion .fleetBlk {
  width: 100%;
  background: #ffffff;
  border: 1px solid #cececf;
  border-radius: 10px !important;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 12px 14px 8px 15px;
  cursor: pointer;
  min-height: 92px;
  max-height: 97px;
  box-sizing: border-box;
  position: relative;
  /* width: 326px; */
}

.fleetOverallBlk .fleetBlk:hover {
  border: 1px solid #366376;
}
/* .fleetOverallBlk .fleetBlk-selected,
.fleetTractorsInrBlk .fleetBlk-selected {
  border: 2px solid #366376 !important;
} */

.fleetBlk .tractorDtlSec.mb18 {
  margin-bottom: 16px;
}

.fleetBlk .tractorDtlSec.mbottom.mtop {
  margin-top: 17px !important;
  /* margin-bottom: 7px !important; */
  margin-bottom: 0 !important;
  height: 36px !important;
}

.fleetBlk .tractorDtlSec.mbottom {
  margin-bottom: 16px !important;
}

.t_name {
  /* color: #4d4d4d; */
  color: #333333 !important;
  /* width: 185px; */
  width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold' !important;
  letter-spacing: 0px;
  line-height: 18px !important;
  /* direction: rtl; */
  text-align: left;
}

.t_driver_name {
  /* color: #333333; */
  color: #5c5c5c !important;
  display: flex;
  align-items: center;
  min-height: 13px;
  font-size: 10px !important;
  font-family: 'Montserrat-Medium' !important;
  letter-spacing: 0px !important;
  line-height: 13px !important;
}

.ttractor_state {
  border-radius: 12px;
  min-height: 20px;
  color: #fff;
  text-transform: uppercase;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 82px;
  width: auto;
  font-size: 8px !important;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.63px;
  line-height: 10px;
}

.tImplementSec {
  /* width: 83px;
  height: 20px; */
  width: 77px !important;
  height: 22px !important;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  color: #000;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 8px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0px;
  line-height: 10px;
}

.tImplementSec img {
  margin-right: 6px;
}

.tSpeedtSec {
  display: flex;
  align-items: center;
}

.tBatterySec {
  display: flex;
  align-items: center;
  gap: 3px;
}

.tSignalSec {
  display: flex;
  align-items: center;
}

.fleetBlk .t_signals.t_error {
  margin: 0;
  padding: 0;
}

.fleetBlk .t_signals.t_error .mr4 {
  margin: 0;
}

.battery-blk {
  display: flex;
}

.speedTxt {
  font-family: Montserrat-Medium;
  font-size: 15px;
  color: #4d4d4d;
  margin-left: 3px;
  width: auto;
  display: inline-block;
  text-align: right;
}

.tspeed {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  letter-spacing: 0.4px;
  margin-left: 3px;
  margin-right: 3px;
  line-height: 10px;
}

.tsMPH {
  color: #5c5c5c;
  font-family: 'Montserrat-Medium';
  font-size: 8px !important;
  letter-spacing: 0;
  padding-top: 4px;
  line-height: 10px;
}

.t_battery {
  font-size: 10px;
  font-family: Montserrat-Medium;
  color: #1e1e1e;
  letter-spacing: 0.6px;
  line-height: 13px;
  display: flex;
  align-items: center;
}

.bPer {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.4px;
  line-height: 15px;
  /* margin-left: 3px; */
  color: #5c5c5c !important;
  width: 24px;
}

.charging_remain {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #959595;
  position: absolute;
  top: 8px;
  left: 0;
  font-size: 8px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.48px;
}

.hImpNameSel {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
  /* color: #000000; */
  color: #5c5c5c !important;
}

.hImpNameDbl {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
  color: #b6b6b7;
}

.mapLayerIcon {
  background-image: url(../../assets/images/layer_icons/layer-icon-default.svg);
  position: absolute;
  bottom: 18px;
  left: 25px;
  z-index: 5;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
}

.mapLayerIcon2 {
  background-image: url(../../assets/images/chatWebIcon.svg);
  position: absolute;
  bottom: 18px;
  left: 140px;
  z-index: 5;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
  background-size: contain;
}

.mapLayerIcon:hover {
  background-image: url(../../assets/images/layer_icons/layer-icon-hover.svg);
}

.bottomMapLayers {
  position: absolute;
  bottom: 95px;
  left: 25px;
  width: 0px;
  /* min-width: 360px; */
  background: #ffffff;
  height: 0;
  z-index: 99;
  box-shadow: -1px 0px 6px rgb(0 0 0 / 29%);
  transition: 1s;
  border-radius: 10px;
  opacity: 0;
}

.bottomMapLayers .dataLayerSec {
  overflow-y: auto;
  height: calc(100vh - 215px);
}

.mpOpen {
  visibility: visible;
  animation: resize 0.2s;
  width: auto;
  height: calc(100vh - 120px);
  opacity: 1;
}

.mpClose {
  animation: resizeClose 0.2s;
  visibility: hidden;
}

.fleetTabs .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  color: #5c5c5c;
  border: 1px solid transparent;
  padding: 10px 11px !important;
  font-size: 10px !important;
  font-family: 'Montserrat-Medium' !important;
  letter-spacing: 0 !important;
  line-height: 31px !important;
  width: 95px !important;
}

.fleetTabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0px !important;
}

.fleetlistBlk .fListSearchMain .ant-input,
.fListSearchMain .ant-input {
  font-family: 'Montserrat-Medium' !important;
  font-size: 14px !important;
  letter-spacing: 0.1px;
  line-height: 18px !important;
  color: #fff;
}

.fleetlistBlk .searchMapBlk.fListSearchMain input.fListSearch::placeholder {
  font-family: 'Montserrat-Medium' !important;
  font-size: 14px !important;
  letter-spacing: 0.1px;
  line-height: 18px !important;
  color: #e7e7e7 !important;
}

.fleetlistBlk .fListSearchMain .ant-input,
.indicatorimges .t_signals {
  display: flex;
  align-items: center;
}

/* map layers styles **********************/
.dataHead {
  font-size: 12px;
  font-family: Montserrat-Medium;
  margin-left: 10px;
  letter-spacing: 0.6px;
  line-height: 15px;
  color: #6a6a6a;
}

@keyframes resize {
  from {
    width: 0;
    height: 0;
  }

  to {
    width: auto;
    height: calc(100vh - 136px);
  }
}

@keyframes resizeClose {
  from {
    width: auto;
    height: calc(100vh - 136px);
  }

  to {
    width: 0;
    height: 0;
    bottom: 30px;
  }
}

/* Right side widget details section styles goes here **********************/

.width50 {
  width: 50% !important;
}

.width100 {
  width: 100% !important;
}

.rowGapBtm1 {
  margin-bottom: 46px;
}

.rowGapBtm2 {
  margin-bottom: 38px;
}

.rowGapBtm3 {
  margin-bottom: 20px;
}

.rowGapBtm25 {
  margin-bottom: 25px;
}

.widgetCloseBtn {
  cursor: pointer;
  /* padding: 38px 38px 31px 38px; */
  padding: 20px 20px 10px 20px !important;
}

.rightSideWidegt {
  /* right: -486px;
  width: 486px; */
  right: -339px;
  width: 339px;
}

.rightWidgetView {
  /* padding: 0px 40px 20px 40px; */
  padding: 0px 20px 20px 20px !important;
}

.txtLftGap {
  /* margin-left: 22px !important; */
  padding-left: 22px !important;
}

.fwdBtnsSec .TktFwdBtn {
  /* padding: 14px 29px; */
  padding: 12px 18px !important;
  max-height: 39px !important;
}

.fwdBtnsSec .TktRslBtn {
  /* padding: 14px 56px; */
  padding: 12px 22px !important;
}

.widgetBtns {
  height: auto !important;
  /* bottom: 30px !important; */
  bottom: 23px !important;
}

.tcktInfo {
  /* margin-bottom: 43px; */
  margin-bottom: 30px !important;
}

.profileEdit .ant-tag-checkable,
.tagsBlk .ant-tag-checkable,
.tag-chip-selected {
  padding: 8px 10px 7px 10px !important;
}

.rightWidgetView .tagsBlk .tags {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.4px;
  line-height: 15px;
  padding: 8px 16px !important;
}

.rightWidgetView .tagsBlk .tags:last-child {
  margin-bottom: 0 !important;
}

.saveTxtBtn {
  bottom: 0 !important;
  height: auto !important;
}

.saveTxtBtn button {
  /* font-size: 14px; */
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold';
  /* letter-spacing: 1.25px; */
  letter-spacing: 1.07px;
  /* line-height: 18px; */
  line-height: 15px;
  /* bottom: 50px !important;
  right: 38px !important; */
  bottom: 41px !important;
  right: 30px !important;
  padding: 0 !important;
  height: auto !important;
}

.impEditBtn button {
  background: none;
  border: none;
  color: #366376;
  cursor: pointer;
  outline: none;
  position: inherit !important;
  bottom: 50px !important;
  right: 38px !important;
}

.impAutonomyBtn button {
  position: inherit !important;
  bottom: 40px !important;
  left: 38px !important;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.07px;
  line-height: 18px;
  padding: 12px 15px;
}

.rightWidgetView .createTcktFlag img.ml_0 {
  margin-left: 0 !important;
}

.rightWidgetView .createTcktFlag img {
  padding: 12px 14px;
  margin-left: 24px;
}

.rightWidgetView .ant-select-selector,
.rightWidgetView .ant-select-selector .ant-select-selection-item {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
  /* min-height: 50px; */
}

.rightWidgetView textarea {
  color: #000000 !important;
  font-size: 16px !important;
  font-family: 'Montserrat-Regular' !important;
  letter-spacing: 0.5px !important;
  line-height: 22px !important;
}

.rightWidgetView textarea::placeholder {
  color: #959595 !important;
  font-size: 14px;
  font-family: 'Montserrat-Regular' !important;
  letter-spacing: 0.22px;
  line-height: 18px;
}

.rightWidgetView .ant-select-selection-placeholder {
  font-style: italic;
  font-size: 14px;
  font-family: 'Montserrat-Regular' !important;
  letter-spacing: 0.22px;
  line-height: 18px;
}

.rightWidgetView .fleetTbl th,
.rightWidgetView .fleetTbl td {
  /* padding: 0 0 32px 0 !important; */
  padding: 0 0 25px 0 !important;
}

.rightWidgetView .ant-select {
  width: 100% !important;
}

.rightWidgetView .proPic .ant-avatar {
  width: 179px;
  height: 179px;
}

.rightWidgetView .proPic img {
  width: 100% !important;
  height: 100% !important;
}

.functionalityTag .ant-tag {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  /* padding: 10px 12px !important; */
  padding: 6px 10px !important;
}

.rightWidgetView .uploadImg .ant-upload-picture-card-wrapper {
  display: inline !important;
}

.rightWidgetView .uploadImg .ant-upload {
  position: unset !important;
  margin-top: unset !important;
  margin-left: unset !important;
}

.rightWidgetView .ant-upload .editImgs {
  position: absolute;
  right: 15px;
  bottom: 20px;
  top: unset !important;
  left: unset !important;
  cursor: pointer;
}

.autonomyBtns {
  background: transparent;
  position: absolute;
  bottom: 35px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rowGapBtm30 {
  margin-bottom: 30px;
}

.rowGapBtm4 {
  margin-bottom: 24px !important;
}

.rowGapBtm5 {
  margin-bottom: 20px !important;
}

.rowGapBtm13 {
  margin-bottom: 13px !important;
}

.rowGapBtm6 {
  margin-bottom: 10px !important;
}

.genric_toggle_sec {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
  height: 50px;
}

.tractorPic img {
  width: 200px;
  height: 200px;
  margin: auto;
}

.tractorList {
  display: grid;
  grid-template-columns: 44% auto;
  gap: 25px 5px;
  padding-left: 20px;
  word-break: break-all;
  word-break: break-word;
}

.editFleetView {
  height: calc(100vh - 90px) !important;
}

.rowGapTop1 {
  margin-top: 46px;
}

.addEmpBlk.rightWidgetView .editImgs {
  right: 20px;
  bottom: 15px;
}

.txtCenter {
  text-align: center !important;
}

.rightWidgetView input::placeholder {
  color: #b6b6b7;
  font-size: 14px;
  font-family: 'Montserrat-Regular' !important;
  font-style: italic;
  letter-spacing: 0.22px;
  line-height: 18px;
}

/* Autodrive tractor details styles goes here *****************************/

/* remotedrive settings & details */
.rautoDriveRightSec {
  position: fixed;
  right: 0;
  top: 0px;
  width: 482px;
  /* width: 339px; */
  height: 100vh;
  z-index: 999;
  box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.29);
  /* transition: 1s; */
  background: #fff;
  padding: 35px;
}

.rautoDriveRightWrapper {
  height: 100%;
}

.grayContainer {
  background: #f00;
  position: absolute;
  z-index: 2;
  width: 100%;
}

.grayBlock {
  padding: 0 !important;
  position: fixed;
  background: #e5e5e5;
  height: 100vh;
  z-index: 0;
}

.autoDriveTractorHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.whiteWrapper {
  width: 100%;
  position: absolute;
  background: #fff;
  height: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
}

.adtMainContainer {
  height: calc(100vh - 64px);
}

.adtTopSection {
  display: block;
  height: calc(100vh - 265px);
  margin-bottom: 16px;
}

.adtTopSection.tdtl_nopin {
  height: calc(100vh - 80px) !important;
}

.adtTopSection.tdtl_nopin .adTabsContentSec {
  max-height: calc(100vh - 165px) !important;
  overflow-x: hidden;
}

.adtTopBox {
  background: #fff;
  border-radius: 20px;
  margin: 0 15px;
  box-shadow: -3px 3px 6px transparent;
  border: 2px solid transparent;
  position: relative;
  z-index: 2;
}

.adtClose {
  background-image: url(../../assets/images/mpCloseIcon.svg);
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  /* right: 5px; */
  /* right: 10px; */
  right: 13px;
  top: 5px;
  cursor: pointer;
}

.adTabsHeadSecBottom {
  /* padding: 14px 42px 16px 30px; */
  padding: 14px 42px 16px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adTabsHeadSec {
  /* padding: 30px 25px 34px 25px; */
  padding: 30px 10px 16px 10px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adTabsContentSec {
  max-height: calc(100vh - 390px);
  /* padding: 0px 25px 0 25px; */
  padding: 0px 10px 0 10px;
  overflow-y: auto;
  margin-bottom: 16px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.adTabsContentSec::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.adTabsContentSec {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.adtHeadSec {
  display: flex;
  align-items: center;
  /* padding: 25px 29px 0 25px; */
  padding: 18px 20px 0 20px;
  margin-bottom: 16px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}

.whiteLayer {
  display: none;
}

.adtHeadTextIcon {
  /* margin-right: 12px; */
  margin-right: 8px;
}

.adtHeadText {
  /* color: #366376; */
  color: #99999c;
}

.adTabsContainer {
  background: #fff;
  border-radius: 20px;
  margin: 0 15px;
  box-shadow: -3px 3px 6px transparent;
  border: 2px solid transparent;
  position: relative;
}

.adRoundBox {
  box-shadow: -3px 3px 6px #00000029;
  border: 2px solid #cececf;
  transition: 2s;
}

.adTabsContainer .ant-collapse {
  border-radius: 20px;
}

.adtabsHeadText {
  font-size: 20px;
  /* font-family: Montserrat-Medium; */
  color: #000;
  /* max-width: 14.5vw;
  min-width: 245px; */
  max-width: 210px !important;
  min-width: 210px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.adtStatus {
  border-radius: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 82px;
  font-size: 8px;
  font-family: Montserrat-SemiBold;
  background: #ec9328;
  color: #fff;
}

.tractorPin {
  background-image: url(../../assets/images/tractorPin_normal.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  /* right: 29px; */
  right: 20px;
  top: 18px;
  cursor: pointer;
}

.tractorPin:hover {
  background-image: url(../../assets/images/tractorPin_hover.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  /* right: 29px; */
  right: 20px;
  top: 18px;
  cursor: pointer;
}

.tractorPin:active {
  background-image: url(../../assets/images/tractorPin_selected.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
}

.tractorPinSelected {
  background-image: url(../../assets/images/tractorPin_selected.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 29px;
  top: 18px;
  cursor: pointer;
}

.adtBottomSection {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 185px;
  overflow-y: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
}

.adtBottomBox {
  background: #fff;
  border-radius: 20px 20px 0 0;
  margin: 0 15px;
  box-shadow: -3px 3px 6px #00000029;
  border: 2px solid #cececf;
  position: relative;
  transition: 2s;
  height: 60px;
  width: calc(100% - 30px);
}

.adtBottomBox:nth-child(1) {
  position: absolute;
  bottom: 0;
  z-index: 100;
}

.adtBottomBox:nth-child(2) {
  position: relative;
  z-index: 99;
  margin-bottom: 50px;
}

.adtBottomBox:nth-child(n + 3) {
  position: relative;
  z-index: auto;
  margin-bottom: -10px;
}

.adtabsHeadTextBottom {
  /* font-size: 12px; */
  /* font-family: Montserrat-Medium; */
  color: #000;
  white-space: nowrap;
  width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.adtCloseBottom {
  background-image: url(../../assets/images/mpCloseIcon.svg);
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 18px;
  top: 17px;
  cursor: pointer;
}

.adtStatusBottom {
  border-radius: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 82px;
  font-size: 8px;
  font-family: Montserrat-SemiBold;
  background: #ec9328;
  color: #fff;
  margin-right: 10px;
}

.t_lock {
  width: 10px;
  margin-top: -4px;
  margin-right: 10px;
}

.autoDriverName {
  display: flex;
  align-items: center;
}

.autoDriverName img {
  margin-right: 5px;
}

.aDname {
  /* font-size: 12px;
  font-family: 'Montserrat-Medium'; */
  color: #5c5c5c;
}

.autodriveStatusIcons .t_signals {
  padding: 0px 8px;
  border: 1px solid;
  border-color: #e7e7e7;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
}

.autodriveStatusIcons .t_signals .t_signals_img {
  flex: 1;
}

.autodriveStatusIcons .t_signals.t_error {
  border-color: #d43d38;
}

.fleetBlk .t_signals {
  padding: 0px 8px;
  border-radius: 12px;
  display: flex;
  cursor: pointer;
  /* min-width: 75px; */
  justify-content: flex-end;
  align-items: center;
}

/* .t_signals img:first-child {
  margin-right: 5px;
} */

.autodrive-cancel {
  cursor: pointer;
  font-size: 10px;
  letter-spacing: 0.79px;
  color: #366376;
  font-family: Montserrat-SemiBold;
}

.t-connect-loader {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  width: 64px;
}

.t-connect-loader > img {
  width: 100%;
}

.adr-title {
  padding: 32px;
  text-align: center;
}

.auto-drive-routine-container {
  width: 90%;
  margin: auto;
}

.w100per {
  width: 100% !important;
}

.adr-content-block-border {
  border: 2px solid #d33c39 !important;
}

.adr-content-block {
  border: 2px solid #cecece;
  border-radius: 16px;
  background-color: #fff;
  padding: 0;
  /* max-height: calc(100vh - 300px);
  min-height: calc(100vh - 240px); */
}

.implement-verify-error {
  color: #d33c39;
  font-size: 10px;
  cursor: pointer;
}

.auto-drive-routine-cancel {
  /* text-align: center; */
  /* font-size: 12px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.95px; */
  color: #959595;
  text-transform: uppercase;
  opacity: 1;
  position: absolute;
  bottom: 55px;
  cursor: pointer;
}

.adr-content-block > h1 {
  font-weight: 600;
}

.livePic .ant-avatar.defaulticon {
  width: 296px !important;
  height: 237px !important;
  background: #fff;
  border: 1px solid #707070;
  border-radius: 2px;
}

.chooseTxt {
  font-size: 16px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.25px;
  color: #7b7b7b;
  text-transform: uppercase;
  width: 99%;
  text-align: right;
  margin-top: 25px;
  cursor: pointer;
  /* padding-right: 20px; */
}

.autoDriveCancel {
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.95px;
  color: #959595;
  text-transform: uppercase;
  /* margin-bottom: 20px; */
  cursor: pointer;
  position: absolute;
  top: 10px;
}

.verifyTxt button.verifyBtn {
  font-family: 'Montserrat-Bold';
  font-size: 15px;
}
.verifyTxt button {
  min-width: 166px;
  height: 37px;
  background-color: #738023 !important;
  border-radius: 25px !important;
  position: relative;
  color: #fff;
  bottom: 75px;
  /* right: 116px; */
  letter-spacing: 1.61px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  top: 0;
  margin-bottom: 10px !important;
}

.autoDHead {
  margin: 0px 0 30px 0;
  padding: 0;
}

.autoDHeadText {
  /* font-family: Montserrat-Medium;
  font-size: 26px;
  letter-spacing: 0.19px; */
}

.adr_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.adrArrow {
  background-image: url(../../assets/images/adr_uparrow.svg);
  background-size: 100%;
  width: 5px;
  height: 9px;
  background-repeat: no-repeat;
}

.p30 {
  padding: 30px 20px 22px 20px;
}

.plr30 {
  padding-left: 30px;
  padding-right: 20px;
}

.adrConnection {
  min-width: 50px;
}

.adrImplement {
  width: calc(100% - 100px);
  flex-direction: column;
  display: flex;
}

/* .adr_implementTxt {
  font-family: Montserrat-Medium;
  font-size: 14px;
  letter-spacing: 0.13px;
} */
.bdrB {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
}

.implement-verify-error {
  height: 15px;
}

/* .implement-txt ~ .implement-verify-error {
  visibility: hidden;
}
.implement-txt:hover ~ .implement-verify-error {
  visibility: visible !important;
} */

.adr_plus_nor {
  background-image: url(../../assets/images/adr_plus_nor.svg);
  background-size: 100%;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  margin-right: 13px;
  display: inline-block;
  cursor: pointer;
}

.adr_plus_nor:hover {
  background-image: url(../../assets/images/adr_plus_sel.svg);
  background-size: 100%;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  margin-right: 13px;
  display: inline-block;
  cursor: pointer;
}

.adr_sel_route_txt {
  /* font-family: Montserrat-Regular;
  font-size: 14px;
  letter-spacing: 0.44px; */
  cursor: pointer;
}

.adr_selectRow {
  display: flex;
  align-items: center;
}

/* .adrt_name {
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.13px;
} */

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mt15 {
  margin-top: 15px;
}

.pd0 {
  padding: 0px !important;
}

.proRow.livePic .ant-avatar.impImgW {
  width: calc(100% - 20px) !important;
}

.livePic {
  align-items: flex-start !important;
}

.impImgW img {
  border: 1px solid #707070;
}

.p_30 {
  padding-left: 30px;
  padding-right: 30px;
}

.commandStepsFlex {
  position: relative;
  /* margin-bottom: 50px; */
}

.routeSelect {
  display: flex;
  align-items: center;
  width: 100%;
}

.fleetlistBlk .routeSelect .ant-input,
.fleetlistBlk .routeSelect .ant-input:hover {
  padding-left: 10px !important;
  background-color: none !important;
  border: 1px solid #b6b6b7 !important;
  height: 35px;
  border-radius: 10px;
  box-shadow: none;
}

.commandStepsFlexRoute .anticon.anticon-close {
  position: absolute;
  right: 40px;
  top: 12px;
  font-size: 9px;
}

.adrStartBtn Button {
  background: none;
  border: none;
  outline: none;
  font-family: Montserrat-Bold;
  font-size: 10px;
  color: #ec9322;
  letter-spacing: 0.89px;
  margin-bottom: 30px;
  text-transform: uppercase;
  padding: 0;
  height: auto;
}

.adrStartBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.adr_implementTxt ~ .adrEdit {
  position: absolute;
  /* visibility: hidden; */
  top: 20px;
  left: 26px;
  font-size: 8px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.08px;
  color: #366376;
}

/* .adr_implementTxt:hover ~ .adrEdit {
  visibility: visible !important;
} */

.adrHeaderRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.adrHeaderTxt {
  font-size: 18px;
  font-family: Montserrat-Regular;
  letter-spacing: 0.56px;
  color: #fff;
}

.adrHeaderCancel {
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.56px;
  color: #70b4bb;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.dblock {
  display: block !important;
}

.cpointer {
  cursor: pointer;
}

.adr-content-block .commandStepsFlex {
  position: relative;
  margin-bottom: 50px;
  margin-top: 20px;
}

.disableRoutine {
  opacity: 0.3;
  cursor: default;
}

.adrResetBtn {
  display: block;
  width: 110px;
  text-transform: uppercase;
}

.adrOpTime {
  /* width: 120px; */
  width: 70px;
  text-align: center;
  /* font-size: 26px;
  font-family: Montserrat-Medium;
  letter-spacing: -1px; */
  /* margin-right: 5px; */
  margin-right: 21px;
  color: #000000 !important;
}

.adroutineTimer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adroutineTimerBtns {
  display: flex;
  /* margin-top: -20px; */
}

.adroutineTimerBtns img {
  cursor: pointer;
  height: 36px;
}

.adroutineTimerDtls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.adrTime_rBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 16px;
}

.flblWrapper .flbUL li.item {
  text-align: left;
  text-transform: capitalize;
}

.adrDisconnectBtn,
.adrDisconnectBtn:focus {
  background: #fff;
  border: 1px solid #959595;
  border-radius: 30px;
  /* min-width: 150px; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  outline: none;
  color: #474747;
  text-transform: uppercase;
  /* box-shadow: -3px 3px 6px #00000029; */
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.adrBtnIcon {
  margin-right: 5px;
}

.feedbackDtlSec {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.feedbackCompInfo {
  font-size: 20px;
  font-family: Montserrat-SemiBold;
  color: #000;
  letter-spacing: 0.15px;
  margin-bottom: 25px;
  text-align: center;
}

.rateExperience {
  font-size: 12px;
  font-family: Montserrat-Regular;
  color: #000;
  letter-spacing: 0.22px;
  margin-bottom: 15px;
  text-align: center;
}

.afterRatingBtns {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 10px;
}

.rateBtn {
  border-radius: 6px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  border: none;
  letter-spacing: 1.07px;
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.ratingStartBtn {
  background: #ec9322;
}

.ratingDiscBtn {
  background: #343b5e;
}

.ratingStars {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.ratingStars .anticon-star svg {
  width: 35px;
  height: 35px;
}

.rating {
  unicode-bidi: bidi-override;
  text-align: center;
}

.rating > span {
  display: inline-block;
  position: relative;
  font-size: 40px;
  color: #707070;
  font-weight: 300;
  cursor: pointer;
}

/* .rating > span:hover, */
.rating > span.starsel {
  color: transparent;
}

/* .rating > span:hover:before, */
.rating > span.starsel:before {
  content: '\2605';
  position: absolute;
  left: 0;
  color: #ec9322;
}

.ratingPopup {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ratingPopup .ant-modal-content {
  min-width: 850px !important;
  border-radius: 28px;
}

/* .ratingPopup .ant-modal-close-x {
  display: none;
} */

.ratingPopup .ant-modal-body {
  padding: 40px 60px 20px 60px;
}

.adrEndTitle {
  font-size: 24px;
  font-family: Montserrat-Medium;
  margin-bottom: 25px;
  padding-left: 25px;
  color: #000;
}

.adrEndQuestion {
  font-size: 14px;
  font-family: Montserrat-Regular;
  margin-bottom: 20px;
  padding-left: 25px;
  color: #000;
  letter-spacing: 0.22px;
}

.rateTextarea {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #707070;
  height: 165px;
  margin-bottom: 30px;
  padding: 20px;
}

.pdl25 {
  padding-left: 25px;
  justify-content: flex-start;
}

.mb5 {
  margin-bottom: 5px;
}

.mb30 {
  margin-bottom: 30px;
}

.feedSubmitBtn {
  border: none;
  background: #366376;
  border-radius: 5px;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 150px;
  color: #fff;
  text-transform: uppercase;
}

button:disabled.feedSubmitBtn {
  opacity: 0.4;
}

.feedBtnSec {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.autoDrivetime {
  text-align: center;
  min-width: 63px;
  height: 27px;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.autoDrivetime img {
  margin-right: 6px;
}

.hourIcon {
  width: 12px;
  height: 12px;
}

.autodriveStatusIcons .t_signals .t_signals_img:first-child img {
  margin-right: 4px;
}

.tractorSpeedometer .trip-meter {
  color: #474747;
  margin-top: 8px;
}

.autoDriveCamViews iframe#cammms {
  height: 197px;
}

.t_speed_cont .speed {
  letter-spacing: 0px;
}

.rightSideWidegt .proRoleValue {
  margin: 0 0 0 22px;
}

.editRoles2 {
  color: #366376;
  cursor: pointer;
  text-transform: uppercase;
  right: 38px;
  text-align: right;
  width: calc(100% - 64px);
}

.rowGapBtm15 {
  margin-bottom: 15px !important;
}

.addEmpBlk.rightWidgetView .proPic .ant-avatar {
  width: 120px;
  height: 120px;
}

.rightWidgetView .teamTxt .ant-select-selector .ant-select-selection-item {
  min-height: 27px !important;
  height: 27px !important;
}

.rowGapTop4 {
  margin-top: 24px !important;
}

.rightWidgetView .proPic .ant-avatar .ant-avatar-string {
  width: 179px;
  height: 179px;
}

/* remote drive operation timer box style goes here ******************************/

.remoteOperationsPopup {
  background-color: #e7e7e7;
  /* border: 3px solid #e0963f; */
  border: 2px solid #e0963f;
  border-radius: 17px;
  /* bottom: 0; */
  /* z-index: 999; */
  /* padding: 10px 20px 10px 30px; */
  padding: 10px 20px 10px 22px;
  position: absolute;
  bottom: 110px;
  /* width: 352px; */
  width: 358px;
  /* bottom: 83px; */
  /* min-height: 106px; */
  min-height: 100px;
  z-index: 98;
  left: 108px;
}
/* .remoteOperationsPopup{
  /* z-index: 9999; 
  left: 121px !important;
} */

.remoteOperationsPopup h5 {
  font-size: 10px;
  color: #e0963f;
  margin: 0;
  text-align: center;
  font-family: 'Montserrat-SemiBold';
}

.remoteOperationsPopup .play_time_dur {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0;
}

.remoteOperationsPopup .play_time_dur img {
  cursor: pointer;
}

.remoteOperationsPopup .operationsDuration {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 10px;
  gap: 20px;
  width: 260px;
}

.remoteOperationsPopup p {
  background-color: #e0963f;
  border-radius: 7px;
  color: #fff;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  line-height: 13px;
  padding: 5px 16px;
  text-align: center;
  margin: 0;
  height: 27px;
  width: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.remoteOperationsPopup h6 {
  color: #5c5c5c;
  font-size: 8px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.08px;
  line-height: 10px;
  padding-left: 12px;
  text-align: center;
  padding-top: 9px;
  margin-bottom: 0;
}

.fleetTabs .ant-tabs-content-holder {
  max-height: calc(100vh - 385px);
}

.fleetTabs .childAccordion2 {
  max-height: calc(100vh - 385px);
}

.dmenHandle .fleetTabs .ant-tabs-content-holder {
  max-height: calc(100vh - 510px);
}

.dmenHandle .childAccordion2 {
  max-height: calc(100vh - 510px);
}

.impSetpopup .autonomy_modal_sec .posabs {
  top: 50% !important;
}

.elTabs > .ant-tabs-nav {
  margin: 0px;
}

/* profile page css ************/

.mb46 {
  margin-bottom: 46px;
}

.padt60 {
  padding-top: 60px;
}
.padt30 {
  padding-top: 30px;
}

.profileDetails {
  display: grid;
  /* grid-template-columns: 32% calc(68% - 20px); */
  /* grid-template-columns: 24.9% calc(75.1% - 142px); */
  grid-template-columns: 24.9% calc(75.1% - 10.4vw);
  /* gap: 142px; */
  /* margin-top: 66px; */
  margin-top: 20px;
  /* height: calc(100vh - 205px); */
  justify-content: space-between;
}

.profMap {
  border-radius: 10px;
  position: relative;
  min-height: 429px;
  max-height: 640px;
  height: calc(100vh - 270px);
  width: 100%;
}

.profMap img {
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  object-position: top center;
}

.mb54 {
  margin-bottom: 54px;
}

.profContainer.ovfY_auto {
  /* overflow-y: auto; */
  height: calc(100vh - 180px);
}

.profContainer.ovfY_auto .profileDetails {
  height: calc(100vh - 200px);
}

.filterGap40 {
  /* height: calc(128px - 70px); */
  display: flex;
  align-items: center;
  /* margin-top: 35px;
  margin-bottom: 35px; */
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.rowGap40 {
  margin-top: 40px;
}

.rowGap55 {
  margin-top: 55px;
}

.addEmpBlk .addEmployeeSelect .ant-select-selector {
  min-height: 65px !important;
}

.posrel {
  position: relative;
}

.posabs {
  position: absolute;
}

.disinBlock {
  display: inline-block !important;
}

.editProBackBtn {
  display: inline-block !important;
  position: absolute;
  left: -70px;
  top: 0;
}

.editProfContHight {
  height: calc(100vh - 123px);
}

.editProfContScroll {
  height: calc(100vh - 260px);
  overflow-y: auto;
}

.btnpos {
  position: absolute;
  bottom: 30px;
  right: 36%;
  z-index: 99;
}

.rowGapTop9 {
  margin-top: 90px;
}

.filterGap2 {
  height: calc(174px - 96px);
  display: flex;
  align-items: center;
  margin-top: 53px;
  margin-bottom: 43px;
}

.filterGap3 {
  height: calc(148px - 76px);
  display: flex;
  align-items: center;
  margin-top: 38px;
  margin-bottom: 38px;
}

.teamlist {
  /* height: calc(100vh - 310px);
  display: inline-grid; */
  width: 100%;
  align-content: flex-start;
}

.addCommentBtn.ticketComments {
  margin-left: 14px !important;
}

.rowGapTop36 {
  margin-top: 36px !important;
}

.subPermissionTitle .permissionBackBtn {
  display: inline-block !important;
  position: absolute;
  left: -70px;
  top: 0;
}

.teamsBlk .teamTitle {
  /* margin-left: 76px; */
  margin-left: 19px;
}

.lh56 {
  line-height: 56px !important;
}

/* .perTeamsBack {
  position: absolute;
  left: 0;
  top: 3px;
} */

.perRoleBack {
  position: absolute;
  left: -76px;
  top: 0px;
}

.rowGap50 {
  margin-top: 50px;
}

.seeDtl {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  height: calc(100vh - 200px);
  padding-bottom: 0 !important;
}

/* .employee-main-permissions {
  max-height: calc(100vh - 535px);
} */

.libraryContainer .ant-tabs-nav {
  margin-top: 10px !important;
}

/* analytics styles *******************/

.tractorOperDtls {
  width: 448px;
}

.sparyMapContainer {
  width: calc(100% - 448px);
}

.sparyMapContainer .tblContainer {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.sparyMapContainer .lMapCont {
  height: calc(100vh - 150px) !important;
  margin-bottom: 0 !important;
}

/* User profile styles ***********************************/

.employee-details {
  display: flex;
  /* margin-bottom: 30px; */
  padding-bottom: 36px;
  /* justify-content: center; */
}

.teamarrow {
  background-image: url(../../assets/images/back_btn_new.svg);
  /* width: 56px;
  height: 56px; */
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
  margin-right: 15px;
}

.employee-profile {
  background: #f7f7f7;
  border: 1px solid #cececf;
  border-radius: 15px;
  width: 486px;
  padding: 56px;
}

.profile-details {
  text-align: center;
  margin-bottom: 48px;
}

.profile-details .ant-avatar {
  /* margin-bottom: 24px; */
  /* width: 179px;
  height: 179px; */
  width: 9.3vw;
  min-height: 9.3vw;
  min-width: 130px;
  height: 130px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 auto 24px auto;
  background: #67afb7 !important;
}

.profile-details .ant-avatar-string {
  color: #fff;
  font-size: 3.125vw;
  font-family: 'Montserrat-SemiBold';
  top: 50%;
  transform: translate(-50%, -50%) !important;
}

.profile-details h1 {
  color: #000000;
  margin-bottom: 3px;
}

.profile-details span {
  color: #000000;
}

.profile-contacts {
  margin-bottom: 48px;
}

.profile-contacts h1 {
  margin-bottom: 12px;
  color: #000000;
}

.profile-contacts h3 {
  margin-bottom: 12px;
  padding-left: 30px;
  color: #000000;
  word-break: break-all;
}

.operator-code {
  display: flex;
  margin-bottom: 15px;
}

.operator-code h1 {
  color: #000000;
  margin-right: 45px;
}

.operator-code h4 {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.56px;
  color: #000000;
  font-family: 'Montserrat-Regular';
  margin-right: 40px;
}

.employee-details-main .operator-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.employee-details-main .operator-code h1 {
  margin-right: 0 !important;
}

.employee-details-main .operator-code .proRoleValue {
  text-align: right !important;
  white-space: nowrap;
}

.employee-permissions {
  margin-left: 2.45vw;
  /* width: calc(100% - 650px); */
  display: flex;
  flex-direction: column;
}

.permissions-title {
  /* margin-bottom: 24px; */
}

.employee-teams {
  border: 1px solid #cececf;
  border-radius: 15px;
  padding: 20px 0px;
  /* margin-bottom: 32px; */
  /* min-height: 180px;
  max-height: 200px; */
  box-sizing: border-box;
  overflow-y: auto;
  height: 152px;
}

.employee-main-permissions {
  border: 1px solid #cececf;
  border-radius: 15px;
  /* padding: 32px 0px 16px 0; */
  /* height: 100%; */
}

.permission-sub {
  overflow-y: auto;
  min-height: 140px;
  /* height: calc(100vh - 508px); */
  padding-left: 32px;
  /* padding-right: 32px; */
  position: sticky;
  top: 0;
}

.employee-main-permissions h1 {
  color: #000000;
  padding-bottom: 12px;
  border-bottom: 1px solid #cececf;
  margin: 20px 32px 20px 32px;
}

.operation-title {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

/* my profile styles goes here *********************************/

.userPf.my-profile-container {
  width: calc(100% - (8.43vw + 30px)) !important;
  margin-left: 8.43vw !important;
  margin-right: 30px !important;
}

.dbWidget.profileCont.wBg {
  border: none !important;
}

.userPf .profile-head {
  padding-top: 20px;
}

.userPf .employee-details {
  height: calc(100vh - 230px);
  padding-bottom: 0px;
}

.userPf .employee-permissions {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.userPf .employee-main-permissions {
  flex: 1;
}

.my-profile .profileContacts:last-child {
  margin-bottom: 20px;
}

.userPf .profileDtlWrapper {
  overflow-y: auto;
  min-height: 120px;
  max-height: calc(100vh - 500px);
}

.rowGapNf40 {
  margin-top: 40px;
}

.notification-filter .ant-radio-button-wrapper:first-child {
  border-radius: 10px 0 0 10px;
}

.notification-filter .ant-radio-button-wrapper:last-child {
  border-radius: 0px 10px 10px 0px;
}

.notification-filter
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #1e3a46;
}

.notification-filter
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #1e3a46;
}

.notification-filter
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1e3a46;
  background: #fff;
  border-color: #1e3a46;
}

.notification-filter .ant-radio-button-wrapper:hover {
  color: #1e3a46;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #1e3a46;
}

.notification-filter label {
  font-size: 14px;
}

/* popups styles goes hear **********************************/

.teamEmpContainer .btnteamCancel {
  width: 136px !important;
  height: 32px !important;
}
.teamEmpContainer .btnteamSave {
  width: 136px !important;
  height: 32px !important;
  margin-left: 0 !important;
}
.teamEmpContainer .ant-modal-footer {
  padding: 0 16px 18px 16px !important;
}
.teamEmpContainer .ant-input::placeholder,
.teamEmpContainer .ant-select-selection-placeholder,
.teammodal .ant-input::placeholder,
.teammodal .ant-select-selection-placeholder {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px;
}
.team-text-area .ant-input,
.teammodal .ant-input,
.team-text-area .ant-input::placeholder {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px;
  padding: 10px 8px !important;
}
.team-text-area-2 .ant-input,
.teammodal .ant-input,
.team-text-area-2 .ant-input::placeholder,
.taskModal .ant-input {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px;
}
.teamEmpContainer .createtitle,
.teammodal .createtitle {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.mBtm48 {
  margin-bottom: 48px !important;
}
.mBtm36 {
  margin-bottom: 36px !important;
}

.ant-modal.teammodal,
.ant-modal.versionHistPopup {
  padding-bottom: 0px !important;
  top: 0 !important;
  min-width: 768px !important;
}
.ant-modal.versionHistPopup {
  display: flex;
  align-items: center;
  justify-content: center;
}
.versionHistPopup .ant-modal-content {
  width: 768px;
}

/* atonomy settings model styles goes here *******************/

.autoHeads {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.autonomy_modal_sec .ant-input {
  border: 1px solid #366376 !important;
  height: 50px;
}
.autonomy_modal_sec .ant-select-selector {
  border: 1px solid #366376 !important;
  height: 50px;
}
.autonomy_modal_sec .generictoggle {
  height: 50px;
}
.autonomy_modal_sec .ant-switch-checked {
  background-color: #366376 !important;
}
.okBtn.autonomySaveBtn {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
}
.autonomySettingModal .ant-modal-body {
  padding-right: 0 !important;
}
.maintenanceLockModal .createtitle {
  flex-direction: column !important;
}

.rowGapBtm20 {
  margin-bottom: 20px !important;
}

table tr th,
table tr td {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.wwp {
  word-break: break-word;
}

.mapImg {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tcktsBlk .ticketsSearchBar .anticon-search {
  padding-left: 5px;
  /* margin-top: -2px; */
  margin-top: 0 !important;
}
.ticketsSearchBar .anticon-search svg {
  width: 16px;
  height: 16px;
}
.searchSec.ticketsSearchBar .ant-input-search input {
  padding-left: 16px;
}

/* fleet usage popup like (Jump&Go, Shadow,Copycat, Guardrails ) changes */

.tblStyles .ant-table-thead > tr > th {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.15px;
  line-height: 19px;
}
.tblStyles .ant-table-tbody > tr > td {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px;
}
.taskSearchField .ant-select-selector {
  border: 1px solid #e7e7e7 !important;
  border-radius: 10px !important;
  background: #f7f7f7 !important;
  height: 40px !important;
}
.popupStyles .ant-modal-title {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold' !important;
  letter-spacing: 0;
  line-height: 24px;
}
.popupStyles .formRow .ant-form-item-label > label {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.125px;
  line-height: 18px;
  margin-bottom: 8px;
}
.popupStyles .ant-modal-footer button {
  min-width: 100px !important;
  width: auto !important;
  height: 34px !important;
}
.popupStyles button.btnUpload {
  width: -webkit-fill-available !important;
}
.popupStyles .cfqRadioBtn .ant-radio-wrapper {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.15px;
  line-height: 19px;
}
.popupStyles .formRow {
  gap: 15px;
}
.popupStyles .formRow .commInput,
.popupStyles .formRow .ant-select {
  display: flex !important;
  min-width: 245px !important;
  height: 50px;
}
.popupStyles .formRow .formCol {
  margin-right: 0 !important;
}
.popupStyles .ant-form-item-control .ant-input {
  height: 50px;
}
.jumpandgoModal {
  display: flex !important;
  align-items: center;
  padding-bottom: 0 !important;
}
.jumpandgoModal .mainContainer {
  border: 0 !important;
  margin-bottom: 0 !important;
}
.jumpandgoModal .ant-card.jumpandgoCard {
  border: 0 !important;
}
.jumpandgoModal .ant-tabs-content-holder {
  height: 60vh;
  overflow-y: auto;
}
.jumpandgoModal .ant-tabs-tabpane {
  padding-right: 10px;
}
.jumpandgoModal .ant-card-body {
  padding: 0 !important;
  border: 0 !important;
  padding-right: 20px !important;
}
.jumpandgoModal .ant-card.jumpandgoCard .ant-card-body {
  padding: 0 !important;
}
.jumpandgoModal .ant-tabs-nav {
  height: auto !important;
}
.jumpandgoModal .ant-tabs-nav-wrap {
  padding-bottom: 15px;
}
.jumpandgoModal .ant-card.jumpangoDetails .ant-card-body {
  padding: 20px !important;
}
.jumpandgoModal .elTabs > .ant-tabs-nav {
  border-bottom: 0 !important;
}
.guardrailsModal .edit-loader {
  margin-left: 0 !important;
  margin-top: 0 !important;
  transform: translate(-50%, -50%);
}

.profile-details .profile-avatar {
  position: relative;
  display: inline-block;
}
.profile-details .profileEditImg {
  left: unset !important;
  right: 1vw;
  bottom: 56px;
}

h4.errorTcktTitle {
  margin-bottom: 5px !important;
}

.orgTitle {
  color: #333333 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile_view {
  display: none;
}
.web_view {
  display: block;
}
.driveRoutineFlex .t_signals {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

/* .main-menu:hover .login_user_sec {
  bottom: 14px;
} */

.main-menu .login_user_sec .monarch_tech_img {
  /* display: none; */
  visibility: hidden;
}
.main-menu:hover .login_user_sec .monarch_tech_img {
  /* display: block; */
  visibility: visible;
  text-align: center;
  margin: auto;
  /* margin-top: 24px; */
  margin-top: 15px;
  height: 24px;
  /* height: 48px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.main-menu:hover .login_user_sec .monarch_tech_img img {
  width: 88px;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  width: 40px !important;
  height: 40px !important;
}

.rightWidgetView
  .selectImplementContainer
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: auto !important;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  width: 40px !important;
  height: 40px !important;
}

/* implements, add employee, tickets related css ============*/

.autonomy_modal_sec
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 50px;
}
.addEmpBlk .addEmployeeSelect.multiSelectField .ant-select-selector {
  min-height: 50px;
  height: auto;
  max-height: 60px;
}
.addEmpBlk
  .addEmployeeSelect.multiSelectField
  .ant-select-selector
  .ant-select-selection-item {
  min-height: 24px !important;
  height: 24px !important;
}
.multiSelectField .ant-select-selection-overflow-item:last-child {
  display: none;
}
.userProfileBlk .employee-teams {
  min-height: 160px;
  overflow-y: hidden;
}
.rightWidgetView
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}
.profileView.implementsWidget .ant-select-selector .ant-select-selection-item {
  min-height: 100% !important;
  padding-top: 10px !important;
}
.profileHeight {
  overflow-y: auto;
  min-height: 120px;
  max-height: calc(100vh - 340px);
}
.userPf .profileHeight .profileDtlWrapper {
  max-height: inherit !important;
}
.ticketWidgetView .ant-select-selector .ant-select-selection-item {
  min-height: 100% !important;
}

.autoDempty {
  margin-top: 16px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb12 {
  margin-bottom: 12px;
}

.selClr {
  color: #366376;
  letter-spacing: 0.95px;
}
.mb36 {
  margin-bottom: 36px;
}

.implementImage {
  width: 100%;
  max-height: 178px;
  min-height: 178px;
  border-radius: 0px;
  position: relative;
}
.implementImage img {
  max-width: 100%;
  width: 100%;
  max-height: 178px;
  height: auto;
  border-radius: 0px !important;
}

/* .adrImpDtl .proRow {
  margin-right: 10px;
} */

/* fleet analytics maplayers css ======================= */
.analyticsMapSec .dataLayerRowHead .anticon-right {
  margin-right: 8px;
}
.analyticsMapSec .datalayerContent {
  padding: 0px 40px 0px 40px;
}
.analyticsMapSec .datalayerContent h4 {
  color: #000;
  border-bottom: 1px solid #cececf;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 15px;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding: 0px 12px 8px 0px;
}
.analyticsMapSec .datalayerContent ul {
  padding: 0;
}
.analyticsMapSec .datalayerContent ul li {
  color: #000;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  line-height: 15px !important;
  letter-spacing: 1.25px;
  margin-left: 8.5px;
  margin-bottom: 15px;
  padding: 0px 12px 8px 0px;
}
.analyticsMapSec .datalayerContent ul li .dlItem {
  width: 40px;
  display: inline-block;
}
.analyticsMapSec .datalayerContent .ant-checkbox-inner {
  width: 15px !important;
  height: 15px !important;
  border-radius: 3px !important;
}
.analyticsMapSec .datalayerContent .pathBox {
  width: 14px;
  height: 14px;
}
.datalayerContent ul.pathData li {
  line-height: 5px !important;
}
.analyticsMapSec .ant-checkbox-checked .ant-checkbox-inner::after {
  width: 4.714286px !important;
  height: 7.142857px !important;
  border-radius: 0 1px 2px 0px !important;
  left: 25% !important;
}

.fwdBtnsSecResolve .TktRslBtn {
  padding: 14px 56px;
  margin: auto;
}

.dpf100 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 86%;
}
.impSTxt {
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.autonomySettingModal .ant-select-selector.errorClass:hover,
.autonomySettingModal .ant-input.errorClass:hover,
.autonomySettingPopup .ant-input.errorClass:hover,
.autonomySettingPopup .ant-select-selector.errorClass:hover {
  border: 1px solid red !important;
}
.fdHeight {
  background-image: url(../../assets/images/Back_btn.svg);
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.anaExpand {
  right: 360px !important;
}

.afterAnaMapRightAlign1 .positionMap-inner {
  right: 465px;
  transition: 1s;
}

.analyticsMapSec .tglexpand {
  right: 460px;
}

.fullScreen .analyticsMapSec .tglexpand {
  right: 485px;
}

.fullScreen .afterAnaMapRightAlign1 .positionMap-inner {
  right: 490px;
  transition: 1s;
}

.permissionsTeamTbl .btnteamSave {
  padding: 8px 25px !important;
}

/* tractor list selection border colors css ========== */

.t_off_bdr {
  border: 2px solid #cfcfcf !important;
}

.t_faulty_bdr {
  border: 2px solid #d33c39 !important;
}

.t_routines_bdr {
  border: 2px solid #ec9322 !important;
}

.t_charging_bdr {
  border: 2px solid #a9bf51 !important;
}

.t_manual_bdr {
  border: 2px solid #67afb7 !important;
}

.t_copycat_bdr {
  border: 2px solid #ec9322 !important;
}

.t_follow_me_bdr {
  border: 2px solid #f1ce90 !important;
}

.t_vinerow_bdr {
  border: 2px solid #f1ce90 !important;
}

.t_remote_av_bdr {
  border: 2px solid #ec9322 !important;
}

.t_available_bdr {
  border: 2px solid #343b5e !important;
}
.t_service_bdr {
  border: 2px solid #cfcfcf !important;
}
.t_update_bdr {
  border: 2px solid #007aff54 !important;
}

.t_off_bdr:before {
  background: #cfcfcf;
}
.t_available_bdr:before {
  background: #343b5e;
}
.t_service_bdr:before {
  background: #cfcfcf;
}
.t_update_bdr:before {
  background: #007aff54;
}
.t_faulty_bdr:before {
  background: #d33c39;
}
.t_routines_bdr:before {
  background: #ec9322;
}
.t_charging_bdr:before {
  background: #a9bf51;
}
.t_manual_bdr:before {
  background: #67afb7;
}
.t_copycat_bdr:before {
  background: #ec9322;
}
.t_follow_me_bdr:before {
  background: #f1ce90;
}
.t_vinerow_bdr:before {
  background: #f1ce90;
}
.t_remote_av_bdr:before {
  background: #ec9322;
}
.redBdr:before {
  background: #d33c39 !important;
}

.t_off_bdr:before,
.t_faulty_bdr:before,
.t_routines_bdr:before,
.t_charging_bdr:before,
.t_manual_bdr:before,
.t_copycat_bdr:before,
.t_follow_me_bdr:before,
.t_vinerow_bdr:before,
.t_remote_av_bdr:before,
.t_available_bdr:before,
.t_service_bdr:before,
.t_update_bdr:before {
  /* width: 8px; */
  width: 6px;
  height: 100%;
  content: '';
  position: absolute;
  left: 0px;
  top: 0;
  border-radius: 4px 0 0 4px;
}

.startAdriveBtn,
.startAdriveBtn:focus {
  background: #fff;
  border: 1px solid #343b5e;
  /* border: 1px solid #ec9322; */
  border-radius: 27px;
  /* min-width: 150px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 54px; */
  min-width: 228px;
  min-height: 44px;
  outline: none;
  color: #5c5c5c;
  text-transform: uppercase;
  box-shadow: 0px 3px 6px #00000016;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
  /* margin-bottom: 36px; */
  margin-bottom: 30px;
}

.startAdriveBtn:hover {
  border: 2px solid #ec9322 !important;
  /* background-image: url('../../assets/images/autodrive_btn_hover.svg'); */
}
.startAdriveBtn img {
  /* margin-right: 20px; */
  margin-right: 13px;
  width: 20px;
  height: 20px;
}

.autodrive-btn-bg {
  background-image: url('../../assets/images/autodrive_btn.svg');
  background-repeat: no-repeat;
  width: 100%;
  height: 14px;
  background-size: contain;
}

.startAdriveBtn:hover .autodrive-btn-bg {
  background-image: url('../../assets/images/autodrive_btn_hover.svg');
}

/* .startAdriveBtn .maint_text {
  padding-right: 30px;
} */
.maint_autodrive_btn_section {
  display: flex;
  justify-content: center;
}

.dp_flex {
  display: flex;
  justify-content: center;
}
.mb15 {
  margin-bottom: 15px !important;
}
.autoDriveCamViews {
  margin-bottom: 22px;
}

.impInfoTxt {
  text-align: center;
  margin-bottom: 30px;
}

.impRoundImg {
  object-fit: cover;
  width: 124px;
  height: 124px;
  object-position: center center;
  border-radius: 100px;
}

.auto-drive-routine .profileView {
  overflow-y: auto;
  height: calc(100vh - 200px) !important;
}

.verifyTxt .btnBgNone {
  background: none !important;
  bottom: 25px !important;
  color: #959595;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.95px !important;
}

.verifyTxt {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 83%;
  bottom: 0px;
}

.implementImage iframe {
  min-height: 178px;
  outline: none !important;
  border: none;
}

.btnBgNone {
  display: flex;
  flex-direction: row;
}
.btnBgNone .endDnone {
  display: none;
}
.btnBgNone:hover .endDnone {
  display: block;
}
.btnBgNone:hover {
  color: #d33c39 !important;
  font-family: 'Montserrat-Bold' !important;
}
.btnBgNone:hover .endDnone img {
  margin-right: 5px;
}

.adrt_name {
  width: 48%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.adrt_name:first-child {
  margin-right: 25px;
}
.adr-content-block .adr_row_bdr {
  border-bottom: 1px solid #cecece;
}
.adrt_name span img {
  margin-right: 7px;
}

.ptb20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.plr32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.addRoutineSection {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #4f8591;
  width: 100%;
  padding: 12px 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000016;
  cursor: pointer;
}
.addRoutineSection:hover,
.gotoBlockSection:hover {
  border: 2px solid #4f8591;
  cursor: pointer;
}
.addRPlusIcon {
  margin-right: 15px;
}

.adr-content-block .routeSelect,
.adr-content-block .routeSelect .ant-input {
  min-height: 52px !important;
  font-size: 15px;
  font-family: 'Montserrat-Medium';
}

.routineEdit,
.routineEdit:hover,
.routineEdit:focus {
  position: absolute;
  right: 40px;
  top: 18px;
  /* background: none; */
  border: none;
  outline: none;
  color: #8d8d8d;
  text-transform: uppercase;
  cursor: pointer;
  background: #fff;
  padding: 0 0px 0 20px;
}
.cmsTxt {
  margin-top: 20px;
  color: #5c5c5c;
  margin-bottom: 20px;
}

.cmsTxt1 {
  color: #5c5c5c;
  margin-bottom: 30px;
}

.verifyTxt .adStartBtn {
  background: #fff !important;
  border-radius: 11px !important;
  border: 2px solid #ec9322;
  box-shadow: 3px 3px 6px #00000016;
  min-height: 46px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  font-size: 14px;
  font-family: 'Montserrat-Bold';
  text-transform: uppercase;
  color: #474747;
  bottom: 65px;
}

.verifyTxt .adStartBtn:hover,
.verifyTxt .adStartBtn:focus {
  background: #ec9322 !important;
  border: 2px solid #ec9322 !important;
  color: #fff !important;
}

.adriveScroll {
  max-height: calc(100vh - 350px);
  overflow-y: auto;
}

.errMsgNotCorrect {
  margin-bottom: 30px;
  text-align: center;
}

.endAutodrivePopup.ant-modal {
  display: flex;
  align-items: center;
  justify-self: center;
  padding-bottom: 0;
  top: 0;
}

.endAutodrivePopup .ant-modal-body {
  padding: 0 !important;
}
.endAutodrivePopup .ant-modal-content {
  border-radius: 10px;
  box-shadow: 0 3px 20px #00000009;
}
.endAutodriveContainer {
  padding: 90px 70px 50px 70px;
}
.endAutodriveHead {
  text-align: center;
  margin-bottom: 32px;
}

.adStayConBtn,
.adStayConBtn:focus {
  border-radius: 23px;
  padding: 0px 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #366376;
  outline: none;
  background: #fff;
  min-height: 45px;
  min-width: 230px;
  margin-right: 35px;
  text-transform: uppercase;
  box-sizing: border-box;
  cursor: pointer;
  color: #366376;
}
.adStayConBtn:hover {
  background: #67afb7;
  border: none;
  color: #fff;
}

.adConBtn,
.adConBtn:focus {
  border-radius: 23px;
  padding: 0px 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #366376;
  outline: none;
  background: #366376;
  min-height: 45px;
  min-width: 230px;
  text-transform: uppercase;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
}
.adConBtn:hover {
  background: #67afb7;
  border: none;
  color: #fff;
}

.gotoBlockSection,
/* .gotoBlockSection:hover, */
.gotoBlockSection:focus {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #4f8591;
  width: 100%;
  padding: 12px 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000016;
  cursor: pointer;
}

.auto-drive-routine-container .commandBackBtn .backBtn {
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 33px;
  font-size: 11px;
  font-family: 'Montserrat-Medium';
  color: #959595;
  letter-spacing: 1.25px;
}
.auto-drive-routine-container .pt20 {
  padding-top: 20px !important;
}

.auto-drive-routine-container .commandBackBtn .searchMapBlk .ant-input {
  display: flex;
  align-items: center;
  background-color: #fff !important;
  border: 1px solid #959595 !important;
  width: 100%;
  padding: 12px 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000016;
  cursor: pointer;
  min-height: 51px;
  font-size: 14px !important;
  font-family: 'Montserrat-Medium' !important;
  letter-spacing: 0.1px !important;
  line-height: 18px !important;
}

.auto-drive-routine-container .fleetlistBlk .ant-input::placeholder {
  font-family: 'Montserrat-Medium' !important;
}
.auto-drive-routine-container .gotoPointBlk,
.auto-drive-routine-container .saveCommandBlk,
.auto-drive-routine-container .selectMapBlk {
  position: relative;
  top: 20px;
  width: 100%;
  padding: 0px;
}
.auto-drive-routine-container .searchFieldBlk {
  padding: 0px;
}
.auto-drive-routine-container .ml8 {
  margin-left: 8px !important;
}

.auto-drive-routine-container .ml4 {
  margin-left: 4px !important;
}

/* .auto-drive-routine-container .saveCommandBlk {
  display: block  !important;
 } */

.auto-drive-routine-container .rowSelType .ant-select .ant-select-selector {
  display: flex;
  align-items: center;
  background-color: #fff !important;
  border: 1px solid #959595 !important;
  width: 100%;
  /* padding: 12px 20px; */
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000016;
  cursor: pointer;
  min-height: 51px;
  font-size: 14px !important;
  font-family: 'Montserrat-Medium' !important;
  letter-spacing: 0.1px !important;
  line-height: 18px !important;
}
.rowSelType {
  display: inline-block;
  /* margin-top: 10px; */
  /* width: calc(100% - 55px); */
}

.auto-drive-routine-container .rowSelType .ant-select-selection-search {
  display: flex;
  align-items: center;
}

.auto-drive-routine-container .saveCommandBlk .searchMapBlk {
  display: flex;
}
.gotoPointBlk img {
  cursor: pointer;
}

.autoDriveFleetlistBlkTop {
  top: 260px !important;
}

.hitchCameraView {
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(1px) brightness(54%) hue-rotate(0deg);
  -webkit-backdrop-filter: blur(1px) brightness(54%) hue-rotate(0deg);
  left: 0;
  position: absolute;
  top: 0;
  height: 245px;
  z-index: 7;
  /* overflow-y: auto; */
  /* margin-right: 100px; */
  /* padding-right: 70px;*/
}

.hitchSelectionsContainer {
  height: 192px;
  left: 24px;
  top: 45px;
  position: absolute;
  /* max-width: 1920px; */
  margin: 0 auto;
  /* overflow-y: auto; */
  display: grid;
  grid-auto-flow: column;
  /* grid-gap: 8px; */
  border-radius: 5px;
  /* background: #f1f1f1; */
  max-width: calc(100vw - 220px) !important;
  /* padding-right: 40px; */
  overflow-x: auto;
  /* width: calc(100vw - 97px) !important; */
  /* padding-right: 24px; */
  /* padding-bottom: 35px; */
}

.hitchSelectionsContainer .cameras_down {
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #cececf;
  border-radius: 5px;
  text-align: center;
  height: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0;
  width: 326px;
  box-sizing: border-box;
}

.hitchSelectionsContainer div {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.autoDriveFleetlistBlkTop ~ .posHd {
  z-index: inherit !important;
}
.autoDriveFleetlistBlkTop ~ .posHd .dataLayerBtn {
  z-index: 8;
}

.hitchCameraViewScroll {
  overflow-y: auto;
}

.implement_monitoring {
  background: #ec9322;
  border-radius: 5px;
  min-height: 27px;
  color: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  left: 24px;
  position: absolute;
  top: 10px;
}

.autoDriveFleetlistBlkTop .dmenHandle .childAccordion2 {
  max-height: calc(100vh - 690px);
}

.seeMapContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.seePathSec .pathPer .posHd {
  width: 70% !important;
}

.seePathSec .pathPer .erroCond {
  width: 30% !important;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.spnone {
  display: none !important;
}

.pathPer .spnone {
  display: block !important;
}

.seePathSec .pathPer .erroCond span:first-child {
  width: 27px;
  height: 27px;
}

.seePathSec .pathPer .erroCond span:last-child {
  text-align: left;
  display: inline-flex;
}
.seePathSec .pathPer .erroCond .incorrect {
  font-size: 12px;
  color: #d33c39;
  letter-spacing: 1.07px;
  font-family: 'Montserrat-SemiBold';
}
.seePathSec .pathPer .erroCond .correct {
  font-size: 12px;
  color: #474747;
  letter-spacing: 1.07px;
  font-family: 'Montserrat-Regular';
}

.dp_flex_start {
  display: flex;
  justify-content: flex-start;
}

.localizationDown .errImgBlock img {
  width: 32px !important;
  margin-top: 0px;
  max-width: 100%;
}
.errImgBlock {
  margin-right: 20px;
  width: 42px;
}

.errImgBlock img {
  margin-top: 5px !important;
}

.errorBoxBlock {
  border-radius: 30px;
  border: 1px solid #d43d38;
  padding: 20px 25px;
  /* min-height: 215px; */
  box-sizing: border-box;
  /* position: absolute; */
  background: #fff;
  margin-bottom: 30px;
  position: relative;
  /* top: -5px;
  left: -16px;
  width: 428px; */
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

/* .minh140 {
  min-height: 140px;
} */

.color_blue h2 {
  color: #546cb2 !important;
}

.color_black h2 {
  color: #546cb2 !important;
}

.pTextFull {
  width: 100%;
}

.snapshotsSec {
  /* overflow-x: auto; */
  display: flex;
  justify-content: center;
}

.snapImg {
  border: 2px solid #d33c39;
  border-radius: 5px;
  margin-right: 5px;
  box-shadow: 0px 3px 6px #00000016;
}
.pathPRowCenter {
  display: flex;
  align-items: center;
}

.snapImg {
  position: relative;
}
.listItemsSec {
  overflow-y: auto;
  max-height: 400px;
  margin-bottom: 10px;
  width: 100%;
}
.listItemsSec ul,
.listItemsSec ul li {
  margin: 0;
  padding: 0;
  margin-left: 10px;
  /* list-style-type: none; */
}

div.snapshotsBox {
  position: relative;
  /* top: 20px; */
  height: 48px;
  width: 84px;
  background-color: #00000016;
  box-shadow: 10px 10px 35px -15px rgba(0, 0, 0, 0.75);
  margin-right: 7px;
}
div.snapshotsBox img {
  cursor: pointer;
}
div.snapshotsBox i {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
  cursor: pointer;
}
/* div.snapshotsBox img:hover,
div.snapshotsBox img i:hover {
  opacity: 0.4;
  filter: grayscale(100%);
} */
div.snapshotsBox * {
  transition: all 0.35s ease-in-out;
}
div.snapshotsBox:hover i {
  background: url('../../assets/images/eye_view.svg');
  width: 29px;
  height: 20px;
  position: absolute;
  transform: translate(-50%, -10%) scale(0) !important;
}
div.snapshotsBox:hover i {
  transition: transform 0.35s ease-in;
  background: url('../../assets/images/eye_view.svg');
  width: 29px;
  height: 20px;
  transform: translate(-50%, -10%) scale(1) !important;
  position: absolute;
}

.camName {
  display: flex;
  align-items: center;
  padding: 0px 5px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6;
}
.camErrIcon {
  margin-right: 5px;
}
.snapCamName {
  font-family: 'Montserrat-SemiBold';
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  color: #fff;
}

.AutodriveSnapshotPopup {
  width: 918px !important;
  min-height: 750px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-modal.AutodriveSnapshotPopup,
.AutodriveSnapshotPopup .ant-modal-content {
  width: 918px !important;
  border-radius: 10px;
  box-shadow: 0px 3px 20px #00000009;
}

.AutodriveSnapshotPopup .ant-modal-body {
  padding: 30px 40px !important;
}

.adsnapHead {
  font-family: 'Montserrat-SemiBold';
  font-size: 22px;
  letter-spacing: 0.17px;
  line-height: 27px;
  color: #000;
}
.adsnapFooter {
  font-family: 'Montserrat-SemiBold';
  font-size: 18px;
  letter-spacing: 0.17px;
  line-height: 27px;
  color: #000;
}

.adsnapName img {
  margin-right: 5px;
}

.adsnapName {
  font-family: 'Montserrat-SemiBold';
  font-size: 16px;
  letter-spacing: 0.11px;
  color: #808080;
  display: flex;
  align-items: center;
  line-height: 19px;
  /* margin-bottom: 30px; */
}

/* .adsnapContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  background-color: #2196F3;
  padding: 10px;
}

.adsnapContainer > .div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}
.adSnapCam {
  grid-column: 1 / 5;
  grid-row: 1 / 4;
} */

.adsnapContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
}

.adsnapContainer > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 0;
  font-size: 30px;
  border: 1px solid #707070;
  max-height: 116px;
  min-height: 116px;
  height: 10.75vh;
  border-radius: 5px;
}
.adsnapContainer > div:first-child {
  margin-bottom: 6px;
  min-height: 482px;
  height: 44.62vh;
  max-height: 482px;
  border-radius: 5px;
}

.adSnapCam1 {
  grid-column: 1 / 5;
  grid-row: 1 / 4;
  border-radius: 5px;
}

.adsnapContainer > div img {
  max-width: 100%;
  width: 100%;
  height: auto;
  border: 1px solid #707070;
  border-radius: 5px;
}
.adsnapContainer > div:first-child img {
  border: 3px solid #d33c39;
  border-radius: 5px;
}

.ttipMeter {
  /* font-size: 14px; */
  font-family: 'Montserrat-Regular';
  /* letter-spacing: 0.22px;
  line-height: 18px; */
}

.correct img,
.incorrect img {
  margin-top: 5px;
}

.erroCond {
  margin-left: 10px;
  width: 250px;
}

.correct,
.incorrect {
  margin-bottom: 10px;
}

.correct span:last-child,
.incorrect span:last-child {
  margin-left: 15px;
}

.adriveScroll .camera_box {
  padding: 10px 6px !important;
}

.minHAuto {
  min-height: inherit;
}

.w75per {
  width: calc(100% - 55px);
}
.w75per .ant-select {
  display: block;
  margin-right: 10px;
}
.w75per + img {
  margin-right: 5px;
}
.commandStepsFlex.myclass {
  width: 100%;
  margin-top: 40px;
}
.myclass .savedCommand {
  margin-bottom: 10px !important;
}
.myclass .ant-input:hover {
  font-family: Montserrat-Medium !important;
}
.plrNew20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

/* tractor name marquee */

.marquee {
  white-space: nowrap;
  overflow: hidden;
}
.marquee span {
  display: inline-block;
  width: 100%;
}
.marquee span span {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 0px;
}
.marquee:active span,
.marquee:hover span {
  width: auto;
}
.marquee:active span span,
.marquee:hover span span {
  transition: left 3s linear;
  left: calc(185px - 15px - 100%);
}

.marquee2 {
  white-space: nowrap;
  overflow: hidden;
}
.marquee2 span {
  display: inline-block;
  width: 100%;
}
.marquee2 span span {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 0px;
  direction: rtl;
}
.marquee2:active span,
.marquee2:hover span {
  width: auto;
}
.marquee2:active span span,
.marquee2:hover span span {
  transition: left 3s linear;
  left: calc(64px - 15px - 100%);
}

.ddMarquee {
  min-width: 64px;
}

.maintenanceWidget .maintenanceActionsFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.maintenanceWidget .maintenanceActionsFlex h5 {
  margin: 0;
}
.maintenanceWidget .maintenanceActionsInrFlex {
  display: flex;
  gap: 10px;
  align-items: center;
}
.maintenanceWidget .maintenanceActionsInrFlex h6 {
  color: #3f91d6;
  cursor: pointer;
  font-style: italic;
  margin: 0;
  text-decoration: underline;
}
.txtPaddLftGap {
  padding-left: 22px !important;
}
.maintenanceWidget .tcktCommentBox {
  padding-bottom: 0 !important;
}
.maintenanceWidget .tcktCommentBox .tckComt {
  background-color: #f7f7f7 !important;
  border: 1px solid #d9d9d9 !important;
  height: 94px !important;
  padding-left: 16px !important;
}
.profileView.maintenanceWidget.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #fff;
}
.profileView.maintenanceWidget
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  border: 2px solid #211d1d;
  border-top: 0;
  border-left: 0;
}
.profileView.maintenanceWidget .ant-progress-success-bg,
.profileView.maintenanceWidget .ant-progress-bg {
  background-color: #81b981 !important;
}
.maintenanceWidget .addCommentTxt {
  font-family: 'Montserrat-Medium' !important;
}
.maintenanceProgressBar .ant-progress,
.maintenanceProgressBar .ant-progress-outer {
  position: unset !important;
}
.maintenanceProgressBar .ant-progress-inner {
  position: unset !important;
  background-color: #f0f0f0;
  border: 1px solid #d8d8d8;
  border-radius: 10px !important;
}
.maintenanceProgressBar .ant-progress-bg {
  height: 10px !important;
}
.maintenanceBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.maintenanceBtns .btnteamCancel {
  margin-right: 12px !important;
}
.statusMaintenance {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 8px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 20px;
  width: 82px;
  text-align: center;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statusOpen {
  background-color: #589886;
  color: #fff;
}
.statusDue {
  background-color: #e0ce00;
  color: #fff;
}
.statusCompleted {
  background-color: #d8d8d8;
  color: #797979;
}
.closemaintenance .ant-modal-close {
  left: unset !important;
}

.closemaintenance .ant-modal-header {
  height: 56px !important;
}
.maintenanceModal .pdf-document {
  width: 100%;
  overflow-y: auto;
  height: 100%;
}
.maintenanceModal .pdf-document .pdf-page canvas {
  width: 100% !important;
}
.maintenanceModal .pdf-document .pdf-page .react-pdf__Page__textContent {
  width: 100% !important;
}

.maintenanceCard {
  margin-top: 16px;
  padding-left: 10px;
}
.maintenanceCard .ant-collapse-item {
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 5px !important;
  margin-bottom: 5px;
}
.maintenanceCard .ant-collapse-item .ant-collapse-header {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.44px;
  line-height: 22px;
  padding: 6px 16px !important;
}
.maintenanceCard .ant-collapse-item .ant-collapse-header .ant-collapse-extra {
  color: #3f91d6;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  font-style: italic;
  letter-spacing: 0.38px;
  line-height: 22px;
  text-decoration: underline;
}
.maintenanceCard
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  padding-top: 6px !important;
}
.maintenanceCard .ant-collapse-content {
  border-top: 0 !important;
  border-radius: 0 0 5px 5px !important;
}
.maintenanceProgressBar {
  padding-left: 10px;
  padding-top: 11px;
}
.maintenanceTitle {
  color: #000000;
  font-size: 34px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.25px;
  line-height: 42px;
  text-transform: capitalize;
}

.supportHistoryModal .ant-modal-body {
  padding: 15px 24px !important;
}
.supportHistoryModal .elTabs > .ant-tabs-nav {
  height: auto !important;
  border-bottom: 0 !important;
}
.supportHistoryModal .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: flex-start;
  padding-left: 0 !important;
  padding-bottom: 10px !important;
}
.supportHistoryViewBtn {
  background-color: #e16d01cf !important;
  border-color: transparent !important;
}
.supportHistoryBtn {
  background-color: #1e3a46 !important;
  border-color: #1e3a46 !important;
}
.historyPlannerTbl {
  overflow-y: auto;
}
.historyPlannerTbl table thead tr {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9999;
}
.supportHistoryDetailsTbl {
  margin-bottom: 25px;
}
.supportHistoryDetailsTbl #total {
  top: 25px !important;
}

.searchFilters .tractorField .flblWrapper.trasctorlistwidth {
  width: 350px !important;
}
.sortingIcon .ant-table-column-sorter {
  visibility: visible !important;
}

.operationFlex {
  flex-direction: row;
}
.continueOprBtn {
  background: #8a9a48;
  color: #5c5c5c;
  /* margin-bottom: 13px; */
}
.login_logo {
  height: 90px;
}
.rightSideWidegt .errorCodesBlk .proRoleValue {
  margin: 0 !important;
  padding-right: 22px !important;
}
.rightSideWidegt .errorCodesBlk h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.errorCodesBlk .errorCodeIcon {
  width: 35px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.bottomMapLayers .dataLayerSec::-webkit-scrollbar,
.profileView::-webkit-scrollbar,
.childAccordion2::-webkit-scrollbar,
.infinite-scroll-component::-webkit-scrollbar,
.sprayoperlist .ant-tabs-content-holder::-webkit-scrollbar,
.piechartBlk1::-webkit-scrollbar,
.side_menu_sec::-webkit-scrollbar,
.automatedPath::-webkit-scrollbar,
.opertaionReviewBlock::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bottomMapLayers .dataLayerSec,
.profileView,
.childAccordion2,
.infinite-scroll-component,
.sprayoperlist .ant-tabs-content-holder,
.piechartBlk1,
.side_menu_sec,
.automatedPath,
.opertaionReviewBlock {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wingSpanAI .login_logo {
  height: 103px !important;
}
.wingSpanAI .login_logo .login_logo_box {
  margin-top: 30px !important;
  min-height: auto !important;
}
.searchSec.teamSearchSec .ant-input-search {
  width: 250px !important;
  height: 32px !important;
}
.searchSec.teamSearchSec .ant-input-search input {
  color: #969696 !important;
  font-size: 14px !important;
  font-family: 'Montserrat-Medium' !important;
  line-height: 18px !important;
  width: 260px !important;
  min-height: 32px !important;
  max-height: 32px !important;
  padding-left: 35px !important;
}
.searchSec.teamSearchSec .ant-input-search input::placeholder {
  color: #969696 !important;
  font-size: 14px !important;
  font-family: 'Montserrat-Medium' !important;
}
.teambtn .searchSec.teamSearchSec .anticon-search {
  font-size: 16px !important;
  padding-left: 10px !important;
}
.viewportContainer .searchSec.teamSearchSec .ant-input-group-addon button,
.searchSec .ant-input-group-addon button {
  /* height: 44px !important; */
}
.teamsFilterGap {
  margin-top: 20px;
  margin-bottom: 27px;
}

.fleetlistBlk .operationsTitle .ant-collapse-header {
  /* color: #99999C !important; */
  color: #333333 !important;
  font-size: 12px !important;
  font-family: 'Montserrat-Medium' !important;
  letter-spacing: 1.07px !important;
  line-height: 15px !important;
  height: auto !important;
  padding: 25px 16px 15px 16px !important;
  text-transform: uppercase !important;
}
.fleetlistBlk .operationsBlk .parentIcon .anticon {
  width: auto !important;
  height: auto !important;
  right: 16px !important;
}
.fleetlistBlk .parentAccordion .ant-collapse-header .ant-collapse-extra {
  padding-right: 8px !important;
}
.fleetlistBlk .fleetTabs .ant-tabs-nav {
  /* height: 34px !important; */
  margin: 0 !important;
  margin-bottom: 15px !important;
}
.fleetlistBlk .parentAccordion .fleetOverallBlk {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.fleetlistBlk .parentAccordion .fleetBlk {
  border-radius: 10px !important;
  min-height: 90px !important;
  max-height: 95px !important;
  margin-bottom: 10px !important;
  padding: 12px 12px 9px 16px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tractorBtlBlk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.t_driverDtl {
  height: 15px;
}
.tractorLockIcon {
  width: 10px;
  height: 12px;
}

.checkBoxItem span.mapLayerNameHead {
  width: 100%;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 0.6px;
  font-family: 'Montserrat-Medium';
  color: #5c5c5c;
}

.fl_left {
  width: 62%;
}
.fl_right {
  width: 38%;
}

.dataLayerInnerRow.mplChkBox {
  min-height: 46px;
}

.mb53 {
  margin-bottom: 53px;
}

.fleetlistBlk .operationsTitle .ant-collapse-header {
  color: #99999c !important;
  cursor: pointer !important;
}
.operationsBlk .ant-collapse-header:hover {
  color: #333333 !important;
  cursor: pointer !important;
}

/* latest css for figma disigns tabs, table, container styles ***************/

.rightSideContainer {
  height: 100vh;
  width: 100%;
}

.common_tabs > .ant-tabs-nav {
  height: 59px !important;
  border-bottom: 1px solid #e7e7e7 !important;
  padding: 0 30px;
}

.common_tabs .ant-tabs-tab {
  padding: 3px 5px 0px 5px !important;
  cursor: pointer;
}

.common_tabs .ant-tabs-tab-btn,
.common_tabs .ant-tabs-tab:hover {
  color: #b5b5b6;
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold';
  text-transform: uppercase;
}

.common_tabs .ant-tabs-ink-bar {
  border-bottom: 2px solid #67afb7 !important;
  background: #67afb7 !important;
  border-radius: 10px !important;
}

.common_tabs .ant-tabs-nav-wrap {
  align-items: center;
  /* justify-content: center; */
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1f1f1f !important;
  text-shadow: 0 0 0.25px currentColor;
}

.common_tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 6vw;
}

.common_tabs .ant-tabs-nav {
  margin: 0 0 0px 0 !important;
}

.common_tabs .ant-tabs-content-holder {
  padding: 19px 27px !important;
  background-color: #f7f7f7;
  height: calc(100vh - 60px);
}

.tblContainer {
  width: calc(100% - 0px) !important;
  margin: 0 !important;
}

.common_tabs .gridContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  margin: 0;
  margin-bottom: 24px;
}

.common_tabs .gridItem {
  height: 80px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background-color: #fff;
}

.widget_content {
  margin-left: 16px;
}

.common_wrapper {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  width: 100%;
  height: calc(100vh - 195px);
  /* padding: 30px; */
  position: relative;
}

.common_table div#total {
  top: -8px !important;
  background: #ffffff;
  padding-left: 20px;
  padding-top: 0 !important;
  border-top: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  min-height: 30px;
  border-radius: 0 0 10px 10px;
}

.filters_card {
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  gap: 8px;
}
.filters_card.noBorder {
  border-bottom: none;
}
.common_table .ant-spin-nested-loading {
  position: relative;
  height: calc(100vh - 262px);
}

.filters_card .ant-input-group-addon {
  border-right: none !important;
  background-color: none !important;
}
.common_search input {
  border-left: none !important;
}
.common_search .ant-input-group-addon {
  background: none !important;
}

.common_search .ant-input:focus,
.common_search .ant-input-focused {
  border-color: #e8e8e8;
  outline: 0;
  box-shadow: none !important;
}
.filters_card .ant-space.ant-space-horizontal.ant-space-align-center {
  margin-right: 10px;
}
.filtersHealthScreen .ant-space.ant-space-horizontal.ant-space-align-center {
  margin-right: 0px !important;
}

.filtersHealthScreen .ant-btn-primary {
  background: none !important;
  padding: 4px 10px !important;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: #99999c;
  font-size: 10px;
  text-shadow: none !important;
}
.filtersHealthScreen .ant-select-selection-item {
  font-size: 10px !important;
}

/* float label css styles goes here ***************/
.filters_card .float-label {
  position: relative;
  margin-right: 10px;
}
.filters_card .label {
  font-size: 10px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  /* top: 6px; */
  top: 8px;
  transition: 0.2s ease all;
  font-family: 'Montserrat-Regular';
  color: #99999c;
}
.filters_card
  .ant-picker.ant-picker-range.min_width
  .ant-picker-range-separator {
  display: none !important;
}

.filters_card
  .ant-picker.ant-picker-range.min_width.ant-picker-focused
  .ant-picker-range-separator {
  display: block !important;
}
.filters_card .label-float {
  top: -8px;
  background: #fff;
  padding: 0px 5px 0px 5px;
  left: 8px;
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  color: #5c5c5c !important;
}
.filters_card .float-label div {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  color: #99999c;
}

/* .filters_card .float-label .ant-picker, */
.filters_card .float-label input,
.filters_card .float-label input:hover,
.filters_card .float-label input:focus {
  height: 32px !important;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 12px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  transition: all 0.3s;
  font-size: 10px !important;
  font-family: 'Montserrat-Medium';
}

.common_search .ant-input-group-addon {
  border: 1px solid #e8e8e8 !important;
  border-right: none !important;
  border-radius: 4px 0px 0px 4px;
}

.filters_card
  .float-label
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 4px;
}

.min_width {
  min-width: 130px;
  box-sizing: border-box;
}

.common_search .ant-input {
  border: 1px solid #e8e8e8 !important;
  border-left: none !important;
  padding-left: 0px;
  border-radius: 0px 4px 4px 0;
  /* font-family: 'Montserrat-Regular'; */
  font-family: 'Montserrat-Medium';
  font-size: 10px;
  height: 32px;
  color: #5c5c5c;
}

.filters_card .float-label .ant-select-selection-item,
.filters_card .float-label input div {
  font-size: 10px !important;
  font-family: 'Montserrat-Medium';
  color: #5c5c5c;
  height: 32px;
}

.filters_card .common_search > .ant-input > div {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular' !important;
  color: #b5b5b6 !important;
}

.filters_card .common_search .ant-input::placeholder {
  color: #b5b5b6;
  font-size: 10px;
  font-family: 'Montserrat-Regular';
}
.filters_card .ant-space-item .common_search > div {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular' !important;
  color: #b5b5b6 !important;
}

.filters_card .common_search > .ant-input:focus,
.filters_card .common_search > .ant-input-focused,
.filters_card .common_search > .ant-input:hover {
  border: 1px solid #e8e8e8 !important;
  border-left: none !important;
  padding-left: 0px;
  border-radius: 0px 4px 4px 0;
}

/* date picker styles ****/

.filters_card .ant-picker {
  padding: 4px 10px !important;
  height: 32px;
  border-radius: 4px;
}
.filters_card .ant-picker.ant-picker-range.min_width.ant-picker-focused {
  height: 32px;
  border-radius: 4px;
}

.filters_card .ant-picker-input input {
  border: none !important;
  padding: none !important;
  background: none !important;
  color: #5c5c5c !important;
}
.filters_card .ant-picker-range .ant-picker-active-bar {
  background: transparent !important;
}

.filters_card .ant-picker-input input::-webkit-input-placeholder {
  color: white;
}
.filters_card .ant-picker-input input:-moz-placeholder {
  color: white;
}
.filters_card .ant-picker-input input::-moz-placeholder {
  color: white;
}
.filters_card .ant-picker-input input:-ms-input-placeholder {
  color: white;
}

.mr7 {
  margin-right: 7px;
}

.ant-select-dropdown .ant-select-item {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  border-left: 1px solid #fff;
}

.ant-select-dropdown .ant-select-item:not(.ant-select-item-option-disabled) {
  color: #5c5c5c;
}

.ant-select-dropdown .ant-select-item-option-selected {
  font-size: 10px !important;
  font-family: 'Montserrat-Medium';
  border-left: 1px solid #67afb7;
}

/* .widget_card .ant-select-item {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  border-left: 1px solid #fff;
}

.ant-select-dropdown .ant-select-item-option-selected {
  font-size: 10px !important;
  font-family: 'Montserrat-Medium';
  border-left: 1px solid #67AFB7;
} */

.selectLabel {
  display: block;
  padding: 0 !important;
  border: none;
}
.common_table .ant-table-thead > tr > th {
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold' !important;
  letter-spacing: 0px !important;
  line-height: 14px !important;
}
.common_table .ant-table-tbody > tr > td {
  font-size: 12px !important;
  font-family: 'Montserrat-Regular' !important;
  letter-spacing: 0px !important;
  line-height: 15px !important;
  padding: 11px !important;
  min-height: 37px !important;
  height: auto !important;
  cursor: pointer;
  color: #333333 !important;
}
.squareBlue {
  background: #67afb7;
  border-color: #67afb7 !important;
}
.squareOrg {
  background: #f2b76b;
  border-color: #f2b76b !important;
}
.addNewBtn {
  background-color: #366376;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  /* margin-bottom: -6px; */
  padding: 10px;
  letter-spacing: 0.1px !important;
  line-height: 12px !important;
}
.displayFlex {
  display: flex;
  gap: 8px;
}
.spaceBtnAlignCenter {
  justify-content: space-between;
  align-items: center;
}

/* right widget float label css styles goes here ***************/
.rightSideWidegt {
  background: #fff !important;
}
.widget_card .float-label {
  position: relative;
  /* margin-right: 10px; */
}
.widget_card .label {
  font-size: 12px;
  font-weight: normal;
  font-family: 'Montserrat-Regular';
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 10px;
  transition: 0.2s ease all;
  color: #5c5c5c !important;
}
.widget_card .label-float {
  top: -8px;
  /* font-size: 10px; */
  background: #fff;
  padding: 0px 5px 0px 5px;
  left: 8px;
  font-size: 12px !important;
  font-family: 'Montserrat-Regular';
  line-height: 14px !important;
}
.widget_card .float-label div {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  color: #99999c;
}

.widget_card .float-label input,
.widget_card .float-label input:hover,
.widget_card .float-label input:focus {
  min-height: 39px !important;
  height: 39px !important;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 12px;
  color: #5c5c5c !important;
  background: #ffffff;
  border: 1px solid #cfcfcf !important;
  border-radius: 4px !important;
  transition: all 0.3s;
  font-size: 14px !important;
  font-family: 'Montserrat-Medium';
  line-height: 17px !important;
}
.widget_card .ant-select-selector .ant-select-selection-item {
  font-size: 14px !important;
  min-height: 30px !important;
  height: 30px !important;
  margin-bottom: 1px !important;
}

.widget_card
  .float-label
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 4px;
}

.widget_card .float-label .ant-select-selection-item,
.widget_card .float-label input div,
.rightWidgetView.widget_card .ant-select-selector .ant-select-selection-item {
  font-size: 14px !important;
  /* line-height: 17px !important; */
  font-family: 'Montserrat-Medium';
  color: #5c5c5c;
}
.float-span {
  color: red;
}

.dimPos {
  position: absolute;
  right: 8px;
  bottom: 15px;
  font-size: 10px;
  color: #99999c;
}

.widget_card .ant-select .ant-select-selector {
  background: #ffffff !important;
  border: 1px solid #cfcfcf !important;
  border-radius: 4px !important;
  min-height: 39px !important;
  height: 39px !important;
}
.widget_card .ant-select input,
.widget_card .ant-select input:hover,
.widget_card .ant-select input:focus {
  border: 0 !important;
}
.widget_card .ant-select-selection-placeholder {
  display: none;
}
.widget_card .dimPos {
  top: 50% !important;
  transform: translateY(-50%);
  bottom: unset !important;
}
.autodriveSettings h5 span.subTxt {
  color: #5c5c5c;
  font-style: italic;
  margin-left: 10px;
}
.min_width104 {
  min-width: 104px;
  box-sizing: border-box;
}
.width104 {
  width: 104px;
  box-sizing: border-box;
}
.flex40 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.txtCenter {
  text-align: center;
}
.mt13 {
  margin-top: 13px;
}
/* .widgetCloseBtn.impCloseBtn img {
  width: 16px !important;
  height: 16px !important;
} */
.widgetCloseBtn.impCloseBtn {
  padding: 20px 20px 6px 20px !important;
}

.personnelFlex {
  display: flex;
  gap: 10px;
  align-items: center;
}
.personnelCard .tab-label,
.personnelCard .tab-label:hover {
  height: 32px !important;
}

#empTbl .ant-table-body {
  overflow-y: auto !important;
  overflow-x: auto !important;
  /* height: calc(100vh - 190px); */
}

/* without widget wrapper height */
.wWidget_wrapper {
  height: calc(100vh - 99px) !important;
}
.wWidget_wrapper .infinite-scroll-component {
  height: calc(100vh - 190px) !important;
  max-height: calc(100vh - 190px) !important;
}

.tabFilterWidget_wrapper {
  height: calc(100vh - 129px) !important;
}
.tabFilterWidget_wrapper .infinite-scroll-component {
  height: calc(100vh - 229px) !important;
  max-height: calc(100vh - 229px) !important;
}

.tab2FilterWidget_wrapper {
  height: calc(100vh - 169px) !important;
}
.tab2FilterWidget_wrapper .infinite-scroll-component {
  height: calc(100vh - 269px) !important;
  max-height: calc(100vh - 269px) !important;
}

.tab2ColumnFilterWidget_wrapper {
  height: calc(100vh - 229px) !important;
}
.tab2ColumnFilterWidget_wrapper .infinite-scroll-component {
  height: calc(100vh - 329px) !important;
  max-height: calc(100vh - 329px) !important;
}

.tabCardWidget_wrapper {
  height: calc(100vh - 239px) !important;
}
.tabCardWidget_wrapper .infinite-scroll-component {
  height: calc(100vh - 319px) !important;
  max-height: calc(100vh - 319px) !important;
}

.tabWidget_wrapper {
  height: calc(100vh - 139px) !important;
}
.tabWidget_wrapper .infinite-scroll-component {
  height: calc(100vh - 169px) !important;
  max-height: calc(100vh - 169px) !important;
}

.widget_card .ant-btn:hover,
.widget_card .ant-btn:focus {
  border-color: inherit !important;
}

.widget_card .filters_card .float-label {
  margin-right: 0px;
}
.widget_card .ant-picker-input input,
.widget_card .ant-picker-input input:hover,
.widget_card .ant-picker-input input:focus {
  border: none !important;
}

.widget_card .float-label div {
  width: 100%;
  height: auto;
  /* height: 39px; */
}
.widget_card .profileEdit .ant-select-selector,
.widget_card .profileEdit .ant-input,
.widget_card .profileView .ant-select-selector,
.widget_card .profileView .ant-input,
.input {
  border: 1px solid #cfcfcf !important;
  border-radius: 5px;
}

.widget_card .ant-input.ticketArea {
  border: 1px solid #cfcfcf !important;
  border-radius: 5px !important;
}

.widget_card .ant-input.ticketArea:focus {
  border: 1px solid #67afb7 !important;
  border-radius: 5px !important;
}

.widget_card .headline4 {
  font-size: 12px;
}
.widget_card .body2,
.widget_card .commentsContent h5,
.widget_card .commentsContent h6,
.widget_card .overline1 {
  font-size: 12px;
  font-family: 'Montserrat-Regular';
}

.mt20.filters_card.selectLabel .float-label {
  margin-right: 0px;
}

.selectLabel .ant-input.ticketArea {
  border: 1px solid #cfcfcf !important;
  border-radius: 5px !important;
}
.forwardTicketButtons .fwdTcktForwardBtn,
.forwardTicketButtons .fwdTcktCancelBtn {
  font-size: 10px !important;
  font-family: 'Montserrat-SemiBold' !important;
}

.forwardTicketModal.opPopup .ant-modal-content {
  min-width: 400px;
  border-radius: 10px;
}

.hvh1 {
  height: calc(100vh - 97px) !important;
}

.abc {
  background-color: #366376;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  /* margin-bottom: -6px; */
  padding: 10px;
  letter-spacing: 0.1px !important;
  line-height: 12px !important;
}

.teamlist .infinite-scroll-component {
  height: auto !important;
  max-height: calc(100vh - 205px);
}

td.ant-table-column-sort {
  background: inherit;
}

.st_active {
  width: 100% !important;
}

.see_more_block {
  height: calc(100vh - 168px);
  padding: 20px;
  display: flex;
}
.filters_card .searchFilters {
  gap: 0 !important;
}
.diag_wrapper {
  height: calc(100vh - 296px) !important;
}
.diag_wrapper .infinite-scroll-component {
  height: calc(100vh - 385px) !important;
  max-height: calc(100vh - 385px) !important;
}
.diag_wrapper .flblWrapper,
.diag_wrapper .smallFilter .flblWrapper,
.diag_wrapper .medFilter .flblWrapper {
  box-shadow: none !important;
  border: 1px solid #d9d9d9;
  border-radius: 4px !important;
  height: 32px !important;
}
.diag_wrapper .dropdownStyle,
.diag_wrapper .dropdownStyle.dW250_date,
.diag_wrapper .dropdownStyle.dW250 {
  box-shadow: none !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px !important;
}
.diag_wrapper .searchSec .ant-input-search {
  background: transparent !important;
}
.diag_wrapper .searchFilters .tab-label,
.diag_wrapper .searchFilters .tab-label:hover {
  height: 30px !important;
}
.farm_wrapper {
  height: calc(100vh - 99px) !important;
}
.farm_wrapper .infinite-scroll-component {
  height: calc(100vh - 120px) !important;
  max-height: calc(100vh - 120px) !important;
}

.tabSpace .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 3vw !important;
}
.implements_tbl .ant-table-tbody > tr.ant-table-row.notFilledRow:hover > td {
  background: rgba(242, 204, 201, 0.1) !important;
}

#empTbl table .ant-table-tbody tr:first-child td {
  height: 10px !important;
  padding: 0px !important;
}

div#total span b {
  margin-right: 5px;
  font-family: 'Montserrat-Bold';
}

#empTbl .ant-table-sticky-holder,
.infinite-scroll-component {
  border-radius: 10px 10px 0 0;
}

.taskmasterblk .calTbl {
  margin-left: 27px !important;
  margin-right: 27px !important;
}

/* table styles june-28 */
.common_table .sort-pad {
  display: none !important;
}
.common_table .ant-table-column-sorters {
  display: inline-flex !important;
}
.common_table .ant-table-column-sorter {
  visibility: visible !important;
  margin-left: 4px;
}
.common_table .ant-table-column-sorter-up.active,
.common_table .ant-table-column-sorter-down.active {
  color: #99999c !important;
}
.implements_tbl .ant-table-column-sorter-up,
.implements_tbl .ant-table-column-sorter-down {
  color: #d9d9d9 !important;
}
.common_table .ant-table .ant-table-header table {
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-bottom: 1px solid #f4f4f4;
}
.common_table .ant-table .ant-table-header table thead th {
  border-bottom: 0 !important;
  color: #333333 !important;
  padding: 13px 8px 7px 8px !important;
  height: 39px !important;
}
.common_table .ant-table .ant-table-body table {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-bottom: 10px !important;
}

.ant-select-open .ant-select-arrow {
  /* transform: rotate(180deg) !important; */
  content: url(../../assets/images/sArrow_up.svg) !important;
  margin-top: -4px;
}

.ant-select .ant-select-arrow {
  content: url(../../assets/images/sArrow_down.svg);
  width: 7px;
  height: 7px;
  margin-top: -4px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #67afb7 !important;
}

.viewTeamsBtn,
.viewTeamsBtn:hover,
.viewTeamsBtn:focus {
  background-color: #fff;
  border-radius: 6px;
  color: #366376;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  padding: 10px;
  letter-spacing: 0.1px !important;
  line-height: 12px !important;
  border: 1px solid #366376;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
}
.rightWidgetView.widget_card
  .twoValueField
  .ant-select-selector
  .ant-select-selection-item
  span:first-child {
  margin-right: 48px;
}

.twoValueField {
  max-width: 240px;
}

.autodriveSettings .twoValueField span.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.autodriveSettings.widget_card
  .twoValueField
  .ant-select-selector
  .ant-select-selection-item
  span:first-child {
  margin-right: 48px;
}
.rc-virtual-list-holder-inner
  .ant-select-item
  .ant-select-item-option-content
  span:first-child {
  /* margin-right: 66px; */
  width: 15%;
}

.rc-virtual-list-holder-inner .ant-select-item-option-content {
  padding: 4px 0px;
  display: flex;
  gap: 40px;
}

/* New autodrive widget styles */
.newAutodriveWidget {
  padding: 50px 30px !important;
}
.newAutodriveWidget .snapshotTxt {
  color: #5c5c5c;
  letter-spacing: 0.22px !important;
  line-height: 18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 23px;
}
.newAutodriveWidget .snapshotTxt img {
  margin-right: 15px;
}
.auto-drive-routine .implementsWidget {
  /* overflow-y: auto;
  height: calc(100vh - 200px) !important; */
  height: auto !important;
}
.auto-drive-routine .verifyInfoDetailsBlk {
  overflow-y: auto;
  height: calc(100vh - 525px) !important;
  margin-top: 20px;
  padding-bottom: 20px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
/* .auto-drive-routine .verifyInfoDetailsBlk::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .auto-drive-routine .verifyInfoDetailsBlk {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */
.verifyInfoBlk {
  padding: 30px 36px;
  width: 100%;
}
.verifyInfoBlk h5 {
  color: #5c5c5c;
  margin-bottom: 15px;
  text-align: center;
}
.verifyInfoBlk .profileICon {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #333333;
  margin-top: 37px;
  margin-bottom: 35px;
}
.verifyInfoBlk .profileICon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.verifyInfoBlk .verifyInfoDetails {
  padding-left: 20px;
  padding-right: 20px;
  /* margin-top: 35px; */
}
.verifyInfoBlk .verifyInfoDetailsFlex {
  display: flex;
  justify-content: space-between;
}
.verifyInfoBlk .verifyInfoDetailsFlex span {
  color: #b6b6b7;
}
.verifyInfoBlk .verifyInfoDetailsFlex h6 {
  color: #5c5c5c;
  word-break: break-word;
}
.verifyInfoDetails h3 {
  color: #5c5c5c;
  margin-top: 36px;
  margin-bottom: 12px;
}
.newAutodriveWidget .float-label,
.automatedOprBlk .float-label {
  position: relative;
  /* margin-right: 10px; */
}
.newAutodriveWidget .label,
.automatedOprBlk label {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 14px;
  transition: 0.2s ease all;
  color: #99999c !important;
  letter-spacing: 0.95px;
}
.newAutodriveWidget .label-float,
.automatedOprBlk .label-float {
  top: -8px;
  /* font-size: 10px; */
  background: #fff;
  padding: 0px 5px 0px 5px;
  left: 8px;
  font-size: 12px !important;
  font-family: 'Montserrat-Regular';
  line-height: 14px !important;
}
/* .newAutodriveWidget .float-label div {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  color: #99999c;
  height: 47px !important;
} */

.newAutodriveWidget .float-label input,
.newAutodriveWidget .float-label input:hover,
.newAutodriveWidget .float-label input:focus,
.automatedOprBlk .float-label input,
.automatedOprBlk .float-label input:hover,
.automatedOprBlk .float-label input:focus {
  min-height: 45px !important;
  height: 45px !important;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 12px;
  color: #5c5c5c !important;
  background: #ffffff;
  border: 1px solid #cfcfcf !important;
  border-radius: 4px !important;
  transition: all 0.3s;
  font-size: 14px !important;
  font-family: 'Montserrat-Medium';
  line-height: 17px !important;
}
.newAutodriveWidget .ant-select-selector .ant-select-selection-item,
.automatedOprBlk .ant-select-selector .ant-select-selection-item {
  font-size: 14px !important;
  /* min-height: 30px !important;
  height: 30px !important; */
  margin-bottom: 1px !important;
}
.newAutodriveWidget .float-label .ant-select-selector,
.automatedOprBlk .float-label .ant-select-selector {
  height: 47px !important;
}
.newAutodriveWidget
  .float-label
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.automatedOprBlk
  .float-label
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 4px;
}

.newAutodriveWidget .float-label .ant-select-selection-item,
.newAutodriveWidget .float-label input div,
.newAutodriveWidget .ant-select-selector .ant-select-selection-item,
.automatedOprBlk .float-label .ant-select-selection-item,
.automatedOprBlk .float-label input div,
.automatedOprBlk .ant-select-selector .ant-select-selection-item {
  font-size: 14px !important;
  line-height: 17px !important;
  font-family: 'Montserrat-Medium';
  color: #5c5c5c;
  height: 100% !important;
  display: flex;
  align-items: center;
}

.selectOptionTwoSide .optl {
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
/* .newAutodriveWidget .float-label .ant-select-selection-item div,
.automatedOprBlk .float-label .ant-select-selection-item div {
  height: 100%;
  display: flex;
  align-items: center;
} */
.impVerifyBtn button:first-child {
  font-family: Montserrat-Bold;
  font-size: 15px;
}
.impVerifyBtn button {
  min-width: 166px;
  height: 37px;
  background-color: #366376 !important;
  border-radius: 6px !important;
  /* position: absolute; */
  color: #fff;
  bottom: 74px;
  /* right: 116px; */
  letter-spacing: 1.61px;
  line-height: 19px !important;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  /* left: 50%; */
  /* transform: translateX(-50%); */
}
.impVerifyBtn button.verifyBtn:hover {
  background-color: #1e3a46 !important;
}
.impVerifyBtn .btnBgNone {
  background: none !important;
  bottom: 39px !important;
  color: #959595;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.95px !important;
  height: auto !important;
}

.impVerifyBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.automatedOprBlk h2 {
  color: #5c5c5c;
  font-size: 26px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.19px;
  line-height: 32px;
  margin-bottom: 37px;
  text-align: center;
}
.automatedOprBox {
  border: 1px solid #707070;
  border-radius: 10px;
}
.automatedOprTitle {
  border-bottom: 1px solid #cecece;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 18px 17px 20px;
}
.automatedOprTracName {
  width: 50%;
}
.automatedOprTracName h3 {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 3px;
  width: 125px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.automatedOprTracName h4 {
  color: #000000;
  font-size: 11px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
  margin: 0;
}
.newAutodriveWidget .frequencyField {
  max-width: 309px;
  margin: auto;
}
.automatedOprImpName {
  border: 1px solid #cecece;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 13px;
  width: 58%;
  gap: 10px;
}
.automatedOprImpName h6 {
  color: #9a9a9a;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.33px;
  line-height: 13px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.automatedOprImpName h6 img {
  width: 12px;
  margin-right: 4px;
}
.automatedOprImpName h5 {
  color: #000000;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.1px;
  line-height: 18px;
  margin-bottom: 0;
  width: 7.3vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.automatedOprImpName span {
  color: #366376;
  cursor: pointer;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.09px;
  line-height: 13px;
}
.automatedPath {
  /* padding: 27px 33px 37px 33px; */
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.automatedPath h5 {
  text-align: center;
  margin-bottom: 43px;
}
.automatedBtn {
  background-color: #366376;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  max-width: 253px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 47px !important;
  text-transform: uppercase;
}

.operationParamsModal .automatedBtn {
  margin-top: 0px !important;
}

.automatedBtn:hover {
  background-color: #1e3a46 !important;
  border-color: #1e3a46 !important;
  color: #fff !important;
}
.automatedBtn.ant-btn[disabled],
.automatedBtn.ant-btn[disabled]:hover,
.automatedBtn.ant-btn[disabled]:focus,
.automatedBtn.ant-btn[disabled]:active {
  background-color: #e7e7e7 !important;
  border-color: #e7e7e7 !important;
  color: #fff !important;
}
.automatedSelectBlock .blockTxt {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.automatedSelectBlock .blockTxt span {
  width: 30px;
  height: 30px;
  background-color: #546cb2;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
}
.blockTxt p {
  color: #546cb2;
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  text-align: center;
  width: 100%;
}
.automatedSelectBlock h3 {
  color: #000000;
  text-align: center;
  max-width: 261px;
  margin: 27px auto 44px auto;
  letter-spacing: 0.22px !important;
  line-height: 18px !important;
}
.automatedSelectBlock img {
  display: block;
  margin: auto;
}

/* time line *************************/

.dirContainer {
  position: relative;
  margin-bottom: 10px;
  z-index: 1002;
}

.dirItem {
  height: 48px;
  margin-top: 10px;
  /* overflow: hidden; */
  position: relative;
}
.dirItem:first-child {
  height: 48px;
  margin-top: 0px !important;
  /* overflow: hidden; */
  position: relative;
}

.dirStartPointer {
  padding: 15px 0 0 4px;
  width: 24px;
  position: absolute;
}
.dirItem .startCir {
  background-image: url(../../assets/images/start_cir.svg);
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position-x: 3px;
  position: relative;
  left: -1px;
  top: 1px;
  margin-top: 2px;
  margin-bottom: 1px;
}
.dirItem .dirDots {
  background-image: url(../../assets/images/dirDots.svg);
  background-repeat: no-repeat;
  top: 0px;
  left: 9px;
  height: 32px;
  position: relative;
}
.dirItem .dirNextDots {
  background-image: url(../../assets/images/dirDotsLast.svg);
  background-repeat: no-repeat;
  top: 0px;
  left: 6px;
  height: 32px;
  position: relative;
}

.dirItem .directions-searchbox {
  background-clip: padding-box;
  width: auto;
  border: none;
  position: relative;
  outline: none;
  margin: 0;
  -webkit-transition-property: none;
  transition-property: none;
  height: 48px;
  padding: 0 0 0 38px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.dirComm {
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.searchBlockSection {
  border-radius: 10px;
  border: 1px solid #b6b6b7;
  min-height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.searchBlockSection input {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
}

/* .connectAutoDriveLoader {
  position: absolute;
  bottom: 100px;
  width: 210px;
  height: 210px;
  margin: 0 auto;
  transform: translate(50%, 0%);
}
.connectAutoDriveLoader .loader {
  border:1px solid #707070;
  border-radius: 100%;
  width: 210px;
  height: 210px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connectAutoDriveLoader .loader img {
  max-width: 75%;
  max-height: 75%;
}

.createPath {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  text-align: center;
}


.selectOptionTwoSide {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.connectAutoDriveLoader.btm20vh {
  bottom: 20vh;
}

.connectAutoDriveLoader {
  position: absolute;
  /* bottom: 100px; */
  width: 180px;
  height: 180px;
  margin: 0 auto;
  transform: translate(-50%, 0%);
  left: 50%;
  z-index: 999;
}
.connectAutoDriveLoader .loader {
  /* border: 1px solid #707070; */
  border-radius: 100%;
  width: 180px;
  height: 180px;
  /* margin-bottom: 32px; */
  margin-bottom: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connectAutoDriveLoader .loader img {
  max-width: 65%;
  max-height: 65%;
}

.createPath {
  color: #5c5c5c;
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  text-align: center;
}
.cancelPathBtn {
  color: #d33c39;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  text-align: center;
  letter-spacing: 1.25px;
  line-height: 18px;
  margin-top: 40px;
  text-transform: uppercase;
}

.selectOptionTwoSide {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.ant-tabs.autodriveTabs .ant-tabs-nav-list {
  width: 100% !important;
  display: grid !important;
  grid-template-columns: 33.25% 33.3% 33.3%;
}
.ant-tabs.autodriveTabs .ant-tabs-nav {
  margin-bottom: 0 !important;
}
.ant-tabs.autodriveTabs .ant-tabs-tab {
  background-color: #67afb7;
  color: #e8e8e8;
  font-size: 10px !important;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.89px;
  line-height: 13px;
  padding: 8px 13px;
  text-transform: uppercase;
  justify-content: center;
}
.ant-tabs.autodriveTabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 !important;
  justify-content: center;
}
.ant-tabs.autodriveTabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #4f8591 !important;
  color: #fff !important;
}
.ant-tabs.autodriveTabs .ant-tabs-tab.ant-tabs-tab-disabled {
  background-color: #f7f7f7 !important;
  color: #e8e8e8 !important;
}
.ant-tabs.autodriveTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
.tabsContainer {
  padding: 30px 20px 30px 20px;
}

.pathBlk .blockTxt p {
  color: #546cb2;
  font-size: 24px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0;
  line-height: 29px;
  margin: 0;
  text-align: center;
  width: 100%;
}
.pathBlk h5 {
  margin-top: 27px;
  margin-bottom: 68px;
  letter-spacing: 0.22px !important;
  line-height: 18px !important;
}
.reselectBtn {
  color: #99999c;
  cursor: pointer;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.89px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 19px;
}
.ant-tabs.autodriveTabs .ant-tabs-nav .ant-tabs-ink-bar {
  background: transparent !important;
}
.paramImg {
  width: 100%;
}
.opertaionReviewBlockHeightNone {
  min-height: unset !important;
}
.opertaionReviewBlock {
  min-height: 340px;
  max-height: calc(100vh - 370px);
  overflow-y: scroll;
}

.opertaionReviewBlock h6 {
  color: #1e1e1e;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.22px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.opertaionReviewBlock h6 span {
  color: #cececf;
  cursor: pointer;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.09px;
  line-height: 13px;
}
.opertaionReviewBlock ul {
  list-style: none;
  padding-left: 0 !important;
}
.opertaionReviewBlock ul li {
  color: #1e1e1e;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.19px;
  line-height: 18px;
  margin-bottom: 10px;
}
.opertaionReviewBlock ul li span {
  font-family: 'Montserrat-Medium';
}
.operationPramBlk {
  margin: auto;
  width: 95%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

/* .ant-tabs.autodriveTabs .ant-tabs-tab.sel {
  background-color: #67afb7 ;
  color: #fff;
} */

.impVerifyBtn button:disabled {
  background-color: #366376 !important;
}

.impVerifyBtn button:disabled,
.impVerifyBtn button[disabled],
.impVerifyBtn button[disabled]:hover {
  background-color: #bbbbbb !important;
  color: #8e8e8e !important;
  cursor: not-allowed !important;
}

.searchBlockSection {
  position: relative;
}
.searchBlockSection input {
  width: 80% !important;
}

.rvEditBtn {
  position: absolute;
  right: 12px;
  outline: none;
  border: none;
  background: none;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.09px;
  line-height: 13px;
  color: #cececf;
}

.adStartBtn {
  margin-bottom: 0px !important;
}

.mapLoader {
  height: 100vh;
  top: inherit !important;
}

.rotrayMowerBtn {
  color: #000000;
  letter-spacing: 0.95px !important;
  line-height: 15px !important;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rotrayMowerBtn img {
  width: 21px;
  margin-right: 11px;
}
.editParamBtn {
  border: 1px solid #e9881f;
  border-radius: 6px;
  color: #5c5c5c;
  cursor: pointer;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.89px;
  line-height: 13px;
  width: 154px;
  padding: 7px;
  text-align: center;
  text-transform: uppercase;
  margin: 41px auto 36px auto;
}
.editParamBtn:hover {
  background-color: #fbb313;
  border-color: #fbb313;
  color: #ffffff;
}
.pauseAutodriveBlk {
  width: 100%;
  background: #e7e7e7;
  border: 2px solid #e0963f;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 16px 22px;
}
.pauseAutodriveBlk p {
  color: #5c5c5c;
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  text-transform: capitalize;
  padding-left: 19px;
}
.newOperationParamBlk {
  border: 1px solid #b6b6b7;
  border-radius: 10px;
  padding: 16px 18px;
  margin-bottom: 16px;
}
.operParamTitle {
  color: #5c5c5c;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.16px;
  line-height: 15px;
  margin: 0;
  padding-bottom: 16px;
  text-transform: uppercase;
}
.operParamTitle span {
  color: #5c5c5c;
  cursor: pointer;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.09px;
  line-height: 13px;
  float: right;
  text-transform: capitalize;
}
.editButtonStyle {
  color: #5c5c5c;
  cursor: pointer;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.09px;
  line-height: 13px;
  float: right;
  text-transform: capitalize;
}
.newOperationParamBlk ul {
  padding-left: 0 !important;
  list-style: none;
  display: flex;
  gap: 11px;
  align-items: center;
  margin-bottom: 20px !important;
}
.newOperationParamBlk ul li {
  color: #333333;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.19px;
  line-height: 15px;
}
.newOperationParamBlk ul li img {
  margin-right: 9px;
}
.operParamFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.operParamFlex .operParamBox {
  width: 50%;
  border-left: 2px solid #b6b6b7;
  padding-left: 8px;
}
.operParamFlex .operParamBox h5 {
  color: #333333;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.16px;
  line-height: 12px;
  margin-bottom: 8px;
}
.operParamFlex .operParamBox h6 {
  color: #474747;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.16px;
  line-height: 12px;
  margin-bottom: 8px;
  justify-content: flex-start !important;
}
.operParamFlex .operParamBox h6 img {
  margin-right: 9px;
}
.noBorder {
  border: none !important;
}
.autodrivesTxt {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* margin: 6px auto 12px auto; */
  background: #99999c !important;
  color: #fff;
  box-shadow: 0 1px 8px #00000029;
  padding: 8px 10px;
  border-radius: 5px;
}
.autodrivesTxt.cursorDefault {
  box-shadow: none;
  background-color: #cfcfcf !important;
}
.autodrivesTxt .autodrivesPlayPausecircle {
  /* border: 1px solid #959595; */
  border-radius: 50%;
  margin-right: 5px;
  /* width: 26px;
  height: 26px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.autodrivesTxt:hover .autodrivesPlayPausecircle {
  border-color: #ec9322;
}
.autodrivesTxt .autodrivesPlayResumecircle {
  border-color: #cd3532 !important;
}
.autodrivesPlayPausecircle .anticon-pause {
  padding-top: 0 !important;
}

.autodrivesTxt .autodrivesPlayResumecircle img {
  padding-left: 4px;
}

.autodrivesTxt p {
  /* color: #5c5c5c; */
  color: #fff;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.95px;
  line-height: 15px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #67afb7 !important;
}
div[data-testid='Other-CustomSelect1'][aria-selected='false'] {
  color: #b5b5b6 !important;
}

.error-text {
  font-size: 14px;
  color: red;
  padding: 4px;
  display: block;
}

.automatedOprBox {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.adEditBtn {
  position: relative;
  /* top: -10px;
    right: -5px; */
}

.widget_card .float-label .ant-input.errorClass,
.widget_card .errorClass .ant-select-selector {
  border: 1px solid red !important;
  animation: shake 0.2s ease-in-out 0s 0.5;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.3rem;
  }
  75% {
    margin-left: -0.3rem;
  }
  100% {
    margin-left: 0rem;
  }
}
.pause-txt {
  position: absolute;
  display: none;
  width: 100px;
  left: 55px;
}
.op-pause-btn {
  position: relative;
}
.op-pause-btn:hover .pause-txt {
  display: block;
}
.betaModal.taskModal .ant-modal-body {
  padding: 56px 64px 0 64px !important;
}
.betaModal.taskModal .ant-modal-footer {
  border-top: 0 !important;
  padding: 0 64px 56px 64px !important;
}
.betaModal.taskModal .ant-picker {
  border: 1px solid #b6b6b7 !important;
  border-radius: 4px !important;
}
.betaModal.taskModal .ant-picker:hover {
  border: 1px solid #67afb7 !important;
}
.betaModal.taskModal .ant-picker input {
  font-family: 'Montserrat-Regular' !important;
}
.taskModal .createtitle {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.taskModal .title {
  text-align: left;
  font-size: 24px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  line-height: 29px;
  margin-bottom: 0px;
  max-width: 550px;
}
.taskModal .betaRow {
  margin-bottom: 24px !important;
}
.betaModal.taskModal .ant-form-item-control .ant-input {
  border: 1px solid #b6b6b7 !important;
  border-radius: 4px !important;
  color: #000 !important;
  font-size: 14px !important;
  font-family: 'Montserrat-Medium' !important;
  letter-spacing: 0.5px !important;
  line-height: 14px !important;
}
.betaModal.taskModal .ant-select-selector,
.betaModal.taskModal
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #b6b6b7 !important;
  border-radius: 4px !important;
  color: #000 !important;
  height: 39px !important;
}

.taskModal .ant-form-item-control .ant-input:hover,
.taskModal .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.betaModal.taskModal
  .ant-select:not(.ant-select-disabled):hover
  .ant-select-selector {
  border: 1px solid #67afb7 !important;
  border-radius: 4px !important;
  height: 39px !important;
}

.betaModal.taskModal .ql-toolbar.ql-snow {
  border: 1px solid #b6b6b7 !important;
  border-radius: 4px 4px 0 0;
  border-bottom: 0 !important;
}
.betaModal.taskModal .ql-container.ql-snow {
  border: 1px solid #b6b6b7 !important;
  border-top: 1px solid #ccc !important;
  border-radius: 0 0 4px 4px;
}
.betaModal.taskModal .quill:hover .ql-toolbar.ql-snow {
  border: 1px solid #67afb7 !important;
  border-bottom: 0 !important;
}
.betaModal.taskModal .quill:hover .ql-container.ql-snow {
  border: 1px solid #67afb7 !important;
  border-top: 1px solid #ccc !important;
}
.betaModal.taskModal .ant-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.betaModal.taskModal .btnCancel,
.betaModal.taskModal .btnCancel:hover {
  width: 136px !important;
  height: 32px !important;
  font-family: 'Montserrat-SemiBold';
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 8px 35px;
  color: #366376 !important;
  text-transform: uppercase;
  margin-right: 20px;
  background: #fff !important;
  border: 1px solid #fff !important;
  letter-spacing: 0.76px;
}
.betaModal.taskModal .btnSave,
.betaModal.taskModal .btnSave:hover {
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold';
  border-radius: 10px !important;
  background: #366376 !important;
  color: #fff !important;
  height: 34px;
  padding: 8px 35px;
  width: 136px;
  letter-spacing: 0.86px;
  text-transform: uppercase;
  margin-left: 0px !important;
}
.betaModal.taskModal .ql-editor {
  color: #000 !important;
  font-family: 'Montserrat-Regular' !important;
}
.betaModal.taskModal .ant-picker-input input {
  min-width: 145px;
  min-height: auto !important;
  height: auto !important;
  padding: 8px 0 !important;
}
.betaModal.taskModal .ant-picker-range-separator {
  width: auto !important;
}
.betaModal.taskModal .ant-picker-range-separator .ant-picker-separator {
  display: flex;
  align-items: center;
  height: 100%;
}
.betaModal.taskModal .ant-picker-active-bar {
  background-color: transparent !important;
  height: auto !important;
}
.autodrive-loader > img {
  width: 74px !important;
}

.automatedOprBox .tabsContainer {
  padding: 20px !important;
}

.autodriveTabs .automatedBtn {
  margin-top: 30px !important;
}

.workScheduleFilters .ant-card-head-title {
  font-size: 16px !important;
}
.workScheduleFilters .ant-card-head {
  height: auto !important;
  padding: 10px 20px 10px !important;
}
.workScheduleFilters .filters_card {
  border-bottom: 0 !important;
  flex-wrap: wrap;
  gap: 10px 0 !important;
}
.workScheduleFilters .common_search .ant-input {
  font-size: 10px !important;
  font-family: 'Montserrat-Medium' !important;
}
.max_width {
  max-width: 130px;
}
.firmware_fault .button_font_size7 {
  font-size: 7px !important;
}

.widget_card
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 25px 0 11px !important;
}

.widget_card span.ant-select-selection-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

.seedTxt {
  color: #99999c;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  line-height: 16px;
  margin: 0;
  text-align: left;
}
.txtLeft {
  text-align: left !important;
}

.infoSection {
  color: #366376;
  font-size: 11px;
  font-family: 'Montserrat-Medium';
  line-height: 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: start;
}
.infoSection div {
  margin-left: 5px;
  margin-bottom: 5px;
}

.refil_img {
  width: 40px !important;
}

.refill_seeder {
  border-radius: 30px;
  border: 1px solid #e8e8e8;
  padding: 20px 25px;
  /* min-height: 215px; */
  box-sizing: border-box;
  /* position: absolute; */
  background: #fff;
  margin-bottom: 30px;
  position: relative;
  /* top: -5px;
  left: -16px;
  width: 428px; */
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  box-shadow: 0px 3px 6px #00000029;
}

.refill_seeder h2 {
  margin: 0 0 8px 0;
  padding: 0;
  font-size: 20px;
  font-family: Montserrat-SemiBold !important;
  color: #333333 !important;
  letter-spacing: 0.15px;
  text-align: left;
}
.refill_seeder .body2 {
  font-family: Montserrat-Medium;
  text-align: left;
}

.ErrorSeederFields {
  display: flex;
  gap: 10px;
}

.playcircle {
  width: 13px !important;
  margin-right: 10px !important;
}

.refill_seeder .pOpBtn {
  border-radius: 6px !important;
}

.refill_seeder button {
  width: 48%;
}

.seedPathPRowBtn {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.ErrorSeederFields {
  margin-bottom: 30px;
}
.ErrorSeederFields.widget_card .label-float {
  font-size: 11px;
}

.refill_seeder .errImgBlock {
  margin-right: 20px !important;
}

.video_unavilable {
  background-color: #f3ccc9;
  color: #d33c39;
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  border-radius: 50px;
  min-height: 41px;
  display: flex;
  align-items: flex-start;
  /* min-width: 354px; */
  padding: 5px 25px 5px 25px;
}
.video_unavilable p {
  margin: 0;
  padding: 0;
}

.errorIcon {
  width: 11px;
  margin-right: 5px;
}

.deleteBtn {
  background-color: #e7e7e7;
  border-radius: 8px;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  min-height: 35px;
  /* margin-right: 8px; */
  border: 1px solid #d4d3d3;
  outline: none;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.07px;
  cursor: pointer;
  width: 104px;
  justify-content: center;
}
.deleteBtn img {
  height: 14px;
  margin-right: 5px;
}

.puaseBtn {
  background: #99999c !important;
  color: #ffffff !important;
}

/*refill seeder progress bar styles */

.remainSeed {
  border: 1px solid #cececf;
  border-radius: 8px;
  padding: 10px 17px;
  margin-bottom: 20px;
}

.remainSeedFlex {
  display: flex;
  align-items: center;
  gap: 6px;
}

.remainSeedFlexBtn span {
  color: #474747;
}

.remainSeedFlexBtn h6 {
  color: #d33c39;
  cursor: pointer;
  margin-bottom: 0;
  text-transform: capitalize;
}

.remainSeedFlexBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remainSeedProgress .ant-progress,
.remainSeedProgress .ant-progress-outer {
  position: unset !important;
  height: auto !important;
  padding-right: 0 !important;
}

.remainSeedProgress .ant-progress .ant-progress-inner {
  border-radius: 2px !important;
  position: unset !important;
}

.remainSeedProgress .ant-progress-success-bg,
.remainSeedProgress .ant-progress-bg {
  background-color: #7c5032 !important;
}

.remainSeedProgress .ant-progress-text {
  display: none;
}

.remainSeedProgress {
  display: flex;
  gap: 6px;
}

.remainSeedProgress .remainSeedValue {
  color: #333333;
  display: flex;
  gap: 3px;
  align-items: flex-end;
  margin-bottom: 0;
}

.bufferBlk {
  position: relative;
}

.bufferBlk .seedTxt {
  /* color: #99999c !important; */
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  margin-bottom: 10px;
}

.bufferBlk .infoSection {
  margin-top: 15px;
  margin-bottom: 10px;
}

.bufferBlk p {
  color: #5c5c5c;
}

/* .bufferBlk .infoSection img {
  background: #99999c !important;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  padding: 4px;
}

.bufferBlk .infoSection span {
  color: #99999c !important;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
} */

.bufferFlex {
  display: flex;
  border-radius: 5px;
  box-shadow: 0 3px 6px #00000029;
  margin: 2px;
}

.bufferFlex .ant-btn:first-child {
  border-radius: 5px 0 0 5px;
}

.bufferFlex .ant-btn:last-child {
  border-radius: 0 5px 5px 0;
}

.bufferFlex .ant-btn {
  border: 1px solid #c3c3c3;
  border-radius: 0;
  color: #5c5c5c !important;
  font-family: 'Montserrat-Semibold';
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: -webkit-fill-available;
  padding: 8px 0;
}

.bufferFlex .ant-btn span:last-child {
  font-family: 'Montserrat-Regular';
}

.bufferFlex .ant-btn:hover,
.bufferFlex .ant-btn:focus,
.bufferFlex .ant-btn.selected {
  background-color: #67afb7 !important;
  border-color: #67afb7 !important;
  color: #fff !important;
}
.login_from_mobile {
  background-color: #fff;
  color: #000;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  border-radius: 50px;
  min-height: 41px;
  display: flex;
  align-items: flex-start;
  padding: 10px 25px 10px 25px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px #00000029;
  text-align: center;
}
.login_from_mobile p {
  margin: 0;
  padding: 0;
}

.fs10 {
  font-size: 10px !important;
  letter-spacing: 0.89px !important;
}

.tOperationDtlSec.grayout .iconAutodrive {
  background-color: #b1b1b1 !important;
}

.tOperationDtlSec.grayout .a_value,
.tOperationDtlSec.grayout .a_label,
.tOperationDtlSec.grayout .a_value2 {
  color: #b1b1b1 !important;
}

.tOperationDtlSec.grayout .tOpTypeSec {
  border-bottom: 1px solid #b1b1b1 !important;
}
.collision_msg {
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  background-color: #f3ccc9;
  border: 1px solid #d48d84;
  color: #333333;
  width: 100%;
  padding: 5px 10px;
  border-radius: 12px;
}

.tractor_status_img.grayout .t_speed_cont span {
  color: #b1b1b1 !important;
}
.toastPlace {
  position: fixed;
  right: 50px;
  bottom: 50px;
}

.dev-drop-down-width {
  width: 250px !important;
}

.editParametersPopup {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  width: 600px !important;
}
.editParametersPopup .ant-modal-body .opsSettings {
  height: 500px;
  overflow-y: auto;
}

.editParametersPopup .operationParamsContainer {
  padding: 0px 30px 16px 30px !important;
}

.editParametersPopup .opsSettings {
  padding: inherit !important;
  margin-bottom: 30px !important;
}
.editParametersPopup .operationParamsDesp {
  margin-bottom: 20px !important;
}

.selectDateSec {
  width: calc(100% - 65px);
  display: block;
  margin: 0 auto;
}

.selectedDateRow {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  /* margin-left: 30px; */
}
.selectedDateRow .schDateStyle {
  margin-right: 20px;
  margin-left: 40px;
}
.DatePicker_daysContainer__2fhY9 {
  display: flex;
  align-items: center;
  margin-left: 0px !important;
}

.DatePicker_button__2M4aC {
  margin-bottom: 0px !important;
  position: relative;
  left: 0px;
  top: -15px;
}
.DatePicker_buttonWrapper__2Zolw {
  height: 70px !important;
  top: 35px !important;
  position: relative !important;
}
.DatePicker_dateListScrollable__10YMf {
  margin-left: 0px !important;
  width: 554px;
}

.selectedDateRow1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DatePicker_dateDayItem__3UYPG {
  margin-left: 8px !important;
}

.schUpdateGrid {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.updateFail div#empTbl {
  min-height: calc(100vh - 360px);
}

.ant-modal.dailyfleetUR .ant-modal-content {
  margin-top: 20px;
  border-radius: 10px;
  height: calc(100vh - 40px);
}

.ant-modal.dailyfleetUR .ant-modal-content .ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.ant-modal.dailyfleetUR .ant-modal-body {
  height: calc(100vh - 95px);
  overflow-y: auto;
}

.ant-modal-wrap {
  z-index: 1002;
}

/* .proTeams {
  overflow-y: auto;
  min-height: 120px;
  height: 120px;
} */

.proOptPerSec {
  max-height: calc(100vh - 523px);
  overflow-y: auto;
}

.profileBlocks .permission-sub {
  padding-right: 0px !important;
}
.rNotesSec {
  min-height: 80px;
  /* overflow-y: auto; */
  max-height: 22vh;
}

/* ota styles updated  */

.otaHead {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  color: #333333;
}

.seeAll {
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold' !important;
  color: #99999c !important;
}

.straName {
  font-size: 12px !important;
  font-family: 'Montserrat-Regular' !important;
  color: #333333 !important;
}

.tractorContainer.borderBtm div img {
  margin-right: 7px;
}

.scrollRMap {
  height: calc(100vh - 500px);
  overflow-y: auto;
  margin-right: -25px;
  padding-right: 25px;
}

/* .gridBlock {
  display: grid;
  grid-template-rows: 177px 1fr;
} */

.gridBlock .employee-teams {
  overflow-y: auto !important;
  margin-bottom: 25px !important;
}

.gridBlock .permission-sub {
  height: calc(100vh - 528px);
  margin-bottom: 20px;
}

.srfill_qty_sec {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.operParamFlex2 .operParamBox2 {
  width: 50%;
  text-align: center;
}
.operParamFlex2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 50%;
}
.operParamFlex2 .operParamBox2 h6 {
  color: #474747;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.16px;
  line-height: 12px;
  margin-bottom: 8px;
  display: inherit !important;
}

.ant-picker-dropdown {
  z-index: 1003 !important;
}

/* 
.addImpSpl .autodriveSubHead {
  width: 100px !important;
}

.addImpSpl .twoValueField {
  width: 100% !important;
  max-width: 100% !important;
}
.addImpSpl .flex40 {
  width: 75% !important;
  gap: 20px !important;
}
.eightFive {
  width: 85%;
} */

.tractorDtlSec1 .t_signals,
.indicatorimges .t_signals,
.autodriveStatusIcons .t_signals {
  display: flex;
  gap: 5px;
}

.tBatterySec svg {
  height: 22px;
  width: 20px;
}

.battConnBlk .btSecTxt.diagbattery {
  display: flex;
  gap: 5px;
}

.diagbattery svg {
  height: 22px;
  width: 24px;
}

.teams-permissions {
  display: flex;
  flex-direction: column;
}
.us_section1 {
  height: 200px;
  margin-bottom: 26px;
}
.us_section2 {
  flex: 1;
  overflow: auto;
}

.us_section3 {
  height: 215px;
  margin-bottom: 26px;
}

.impFlexRow {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.impFlexLbl {
  width: 75px;
  margin-right: 10px;
}
.impFlexCont {
  flex: 1;
  /* overflow: auto; */
}
.impFlex1 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
}
.impFlex2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  gap: 10px;
}
.impFlex3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 10px;
}
.impFlex3Bt {
  margin-bottom: 20px;
}
.impFlex2 p {
  margin-bottom: 20px;
}
.flexA {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.impFlexRow input.ant-input,
.impFlexRow input.ant-input:hover,
.impFlexRow input.ant-input:focus {
  width: 65px !important;
  height: 39px !important;
  min-height: 39px !important;
}

.impFlex3 input.ant-input,
.impFlex3 input.ant-input:hover,
.impFlex3 input.ant-input:focus {
  width: 100% !important;
}

.ld .edit-loader {
  transform: translate(50%, 45%);
}
.total {
  text-transform: capitalize;
}
.msDD .ant-select-selection-item {
  display: flex;
  gap: 12px;
  align-items: center;
}

.demo-loadmore-list .ant-spin-container {
  height: calc(100vh - 170px);
  overflow-y: auto;
  margin-right: -6px;
  padding-right: 8px;
}

/* dairy feed pusher 22/02/2024 ******************************* */

.newAutodriveWidget.feed_pusher_container,
.rautoDriveRightSec.feed_pusher_container {
  padding: 0px 28px 0px 20px !important;
}

/*********************** snapshot_section start here ******************/

.exit_autodrive {
  padding: 9px 10px 9px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* cursor: pointer; */
  color: #99999c;
  margin-top: 25px;
  margin-bottom: 25px;
}
.exit_autodrive img {
  margin-right: 6px;
}

.d_Button3 {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
}
.d_body3 {
  font-size: 12px !important;
  font-family: 'Montserrat-Regular';
  line-height: 15px;
}
.snapshot_sec img {
  margin-right: 8px;
}

.snapshot_sec {
  display: flex;
  align-items: center;
  color: #000000;
  justify-content: center;
  margin-bottom: 15px;
}
.d_camera_sec {
  margin-bottom: 20px;
}
.d_camera_sec img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.implement_list_sec {
  text-align: center;
}

.select_implement_head {
  margin-bottom: 7px;
  font-size: 12px !important;
  font-family: 'Montserrat-Medium';
  color: #333333;
}
.implement_list_sec p {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  color: #99999c;
  min-height: 45px;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 7px;
}
.mb50 {
  margin-bottom: 50px;
}
.adj_speed {
  font-size: 14px !important;
  font-family: 'Montserrat-Medium';
  color: #99999c;
  margin-bottom: 14px !important;
}
.adj_op_speed_sec {
  margin-left: 1.72vw;
  margin-right: 0.62vw;
}

.d_button_sec {
  min-height: 60px;
  box-shadow: 0 2px 6px #00000016;
  padding-top: 16px;
  padding-bottom: 12px;
  margin: 0 auto;
  background: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.button_green {
  padding: 10px;
  border-radius: 6px;
  background: #366376;
  height: 32px;
  min-width: 132px;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.button_green:hover {
  background: #24424f;
}
.button_green:disabled {
  background: #afc1c8;
  cursor: not-allowed;
}
.button_default {
  padding: 10px;
  border-radius: 6px;
  background: transparent;
  height: 32px;
  min-width: 132px;
  color: #99999c;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.button_default:hover {
  color: #d43d38;
}

.button_default:hover,
.button_default:focus,
.button_default:focus-visible .button_green:hover,
.button_green:focus,
.button_green:focus-visible {
  outline: none;
  border: none;
}

/*********************** snapshot_section end here ******************/

/*********************** preset_operations_section start here ******************/
.preset_operations_section {
  height: calc(100vh - 150px);
  overflow-y: auto;
  margin-right: -6px;
  padding-right: 8px;
}
.review_list-sec {
  height: calc(100vh - 340px);
  overflow-y: auto;
  margin-right: -6px;
  padding-right: 8px;
}

.preset_operations_section::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  cursor: pointer;
  display: none;
}

.preset_operations_section::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 12px;
  cursor: pointer;
}

.preset_operations_section::-webkit-scrollbar-track {
  cursor: pointer;
}

.presetTxt {
  font-size: 10px !important;
  font-family: 'Montserrat-Medium';
  color: #99999c;
}
.presetTime {
  font-size: 14px !important;
  font-family: 'Montserrat-SemiBold';
  color: #333333;
}

/*********************** snapshot_section end here ******************/

.preset_operations_section {
  height: calc(100vh - 150px);
  overflow-y: auto;
}
.po_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.preset_head_text {
  font-size: 14px !important;
  font-family: 'Montserrat-Medium';
  color: #0d0d0d;
  margin-bottom: 0px;
  line-height: 17px;
}
.edit_list {
  font-size: 8px !important;
  font-family: 'Montserrat-SemiBold';
  color: #99999c;
  cursor: pointer;
}
.edit_list img {
  margin-right: 6px;
}

.choose_list_text {
  font-size: 10px !important;
  font-family: 'Montserrat-Medium';
  color: #99999c;
  margin-bottom: 15px;
  line-height: 20px;
}

.preset_list_item {
  border-radius: 4px;
  padding: 12px 14px;
  background: #ffffff;
  box-shadow: 0px 1px 6px #00000016;
  min-height: 55px;
  box-sizing: border-box;
  width: calc(100% - 5px);
  margin: 0 auto;
  margin-bottom: 6px;
}

.item_row {
  display: flex;
  align-items: center;
  gap: 11px;
}

.select_btn {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #366376;
  background: #fff;
  cursor: pointer;
  width: 58px;
  height: 22px;
}
.select_btn:hover,
.select_btn:focus {
  outline: none;
  border: 1px solid #1c6280;
  color: #274a58;
}
.select_btn {
  font-size: 8px !important;
  font-family: 'Montserrat-SemiBold';
  color: #366376;
}
.item_line1 {
  font-size: 12px !important;
  font-family: 'Montserrat-Medium';
  color: #333333;
  line-height: 15px;
  margin-bottom: 4px;
}
.item_line2 {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  color: #99999c;
  line-height: 12px;
  margin-bottom: 0px;
}
.item_dtl_sec {
  width: calc(100% - 0px);
}
.item_btn_sec {
  width: calc(100% - 191px);
  display: flex;
  justify-content: flex-end;
}
.no_preset_list {
  font-size: 14px !important;
  font-family: 'Montserrat-Medium';
  color: #99999c;
  text-align: center;
  border: 1px solid #dddddd;
  padding: 20px 10px;
  border-radius: 10px;
}
/*********************** preset_operations_section end here ******************/

.lanes_head_text {
  font-size: 14px !important;
  font-family: 'Montserrat-Medium';
  color: #5c5c5c;
  margin-bottom: 20px;
  line-height: 17px;
}
.lanes_text {
  font-size: 12px !important;
  font-family: 'Montserrat-Regular';
  color: #99999c;
  margin-bottom: 15px;
  line-height: 15px;
}

.tab_name {
  font-size: 8px !important;
  font-family: 'Montserrat-Regular';
  color: #5c5c5c;
  margin-bottom: 0px;
}
.lanes_tabs_section {
  background: #fff;
  box-shadow: 0px 1px 6px #00000016;
  height: 53px;
  border-radius: 4px;
  margin: 3px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.lanes_tabs_section {
  margin-bottom: 30px;
}
.lanes_tab {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.lanes_tab:hover,
.lanes_tab.sel {
  background: #67afb7;
  color: #fff;
}
.lanes_tab.sel .tab_name,
.lanes_tab:hover .tab_name {
  color: #fff;
}
.tab_icon {
  margin-bottom: 7px;
}
.lanes_tab.sel:first-child {
  border-radius: 4px 0 0 4px;
}
.lanes_tab.sel:last-child,
.lanes_tab:last-child:hover {
  border-radius: 0px 4px 4px 0px;
}
.lanes_helptext_sec {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 17px;
}
.lanes_order_helptext {
  font-size: 12px !important;
  font-family: 'Montserrat-Regular';
  color: #99999c;
  width: 70%;
  line-height: 15px;
}
.lanes_list_item,
.review_list_item {
  display: flex;
  align-items: center;
  margin-bottom: 5.5px;
}
.list_number {
  width: 20px;
}
.lanes_list_item_card {
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
  width: calc(100% - 19px);
  display: flex;
  align-items: center;
  gap: 12px;
}
.lanes_list_op_name_sec {
  width: calc(100% - 120px);
  padding-right: 20px;
}
.list_number {
  font-size: 14px !important;
  font-family: 'Montserrat-SemiBold';
  color: #474747;
}
.lanes_list_op_name {
  font-size: 14px !important;
  font-family: 'Montserrat-SemiBold';
  color: #474747;
  margin-bottom: 5px;
  line-height: 13px;
  width: 128px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lanes_list_op_time {
  font-size: 8px !important;
  font-family: 'Montserrat-Regular';
  color: #1f1f1f;
  line-height: 10px;
}
.lanes_list_op_icon {
  display: flex;
  align-items: flex-start;
}
.op_checkbox_sec {
  padding-left: 20px;
}
.op_checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #dddddd;
}
.op_checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #546cb2;
  border: none !important;
}
.op_checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid #dddddd !important;
}

.op_checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-width: 1.5px;
  left: 20% !important;
  top: 45% !important;
  height: 11px;
}
.lanes_list_op_icon img {
  vertical-align: top !important;
}

.selBdr {
  border: 2px solid #ec9322 !important;
}
.cursor_pointer {
  cursor: pointer;
}

.review_head_text {
  font-size: 16px !important;
  font-family: 'Montserrat-SemiBold';
  color: #546cb2;
  margin-bottom: 20px;
  line-height: 20px;
  text-align: center;
}
.review_text {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  color: #5c5c5c;
  line-height: 12px;
  text-align: center;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 38px;
}
.review_speed_sec {
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
}
.speed_block {
  display: flex;
  flex-direction: column;
}

.speed_text {
  font-size: 12px !important;
  font-family: 'Montserrat-Medium';
  color: #333333;
  margin-bottom: 4px;
  line-height: 14px;
}
.speed_count {
  font-size: 12px !important;
  font-family: 'Montserrat-Regular';
  color: #99999c;
  padding-left: 10px;
}

.review_list_item_card {
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
  width: calc(100% - 15px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.review_list_op_name {
  font-size: 12px !important;
  font-family: 'Montserrat-Medium';
  color: #333333;
  margin-bottom: 5px;
  line-height: 13px;
}

.v_hidden {
  visibility: hidden;
}
.equal {
  width: 15px;
}

/* 27/02/2024 ********************************/

.item_row1 {
  display: flex;
  align-items: center;
  gap: 15px;
}
.delete_block {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 76px;
  min-height: 22px;
}
.delete_block img {
  margin-right: 6px;
}
.delete_block span {
  font-size: 8px !important;
  font-family: 'Montserrat-SemiBold';
  color: #99999c;
}

.done_btn {
  min-width: 84px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px !important;
  font-family: 'Montserrat-SemiBold';
  color: #366376;
  border: 1px solid #366376;
  border-radius: 6px;
  cursor: pointer;
}

.ant-modal-wrap .edit_operator {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-modal.edit_operator {
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px !important;
  min-height: 176px;
  width: 292px !important;
}

.green_btn {
  font-size: 10px !important;
  font-family: 'Montserrat-SemiBold';
  border-radius: 8px !important;
  background: #366376 !important;
  color: #fff !important;
  height: 30px;
  padding: 9px 30px;
  min-width: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.normal_btn {
  font-size: 10px !important;
  font-family: 'Montserrat-SemiBold';
  border-radius: 8px !important;
  background: transparent !important;
  color: #99999c !important;
  border: none;
  height: 30px;
  padding: 9px 30px;
  min-width: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.normal_btn,
.normal_btn:focus,
.normal_btn:hover,
.green_btn,
.green_btn:focus,
.green_btn:hover {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-color: unset !important;
}
.edit_operator .ant-modal-body {
  padding: 20px;
  width: 292px !important ;
  box-sizing: border-box;
}

.edit_operator .ant-modal-content {
  border-radius: 10px;
}
.popup_head {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.head_text {
  font-size: 16px !important;
  font-family: 'Montserrat-SemiBold';
  color: #333 !important;
}
.popup_head img {
  margin-right: 15px;
}
.popup_desc_text {
  font-size: 12px !important;
  font-family: 'Montserrat-Regular';
  color: #99999c !important;
  line-height: 15px;
  margin-bottom: 20px;
}
.button_sec {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
}
.cross_close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}
.input_sec {
  margin-bottom: 43px;
}
.error_msg {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  color: #d43d38 !important;
  display: flex;
  align-items: center;
}

.error_msg img {
  margin-right: 3px;
}

.al-center {
  align-items: center;
}

.beta-btn {
  display: flex;
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #99999c;
  color: #99999c;
  text-transform: uppercase;
  box-sizing: border-box;
  font-size: 8px;
  letter-spacing: 4%;
  line-height: 10px;
  font-weight: 600;
}
.mixed-txt {
  position: relative;
  top: -26px;
  border-bottom: 2px solid #ececec;
  border-radius: 4px;
  text-align: center;
  padding: 5px 10px;
  font-size: 11px;
  color: #aaaaaa;
  font-weight: 600;
}

.posAbsBeta {
  position: absolute;
  right: -40px;
}

/* ******************** Operational Analytics fixes ******************************** */

.common_tabs.opAnalyticsTabs .ant-tabs-content-holder {
  padding: 0px !important;
}
.background-parent {
  height: calc(100vh - 60px);
  overflow-y: auto;
}
.msBarChart,
.ophBOmsBarChart {
  overflow-y: hidden;
  min-height: 262px;
  height: 262px;
}

.coverage-map {
  min-height: 490px;
  height: 490px;
}
.efficiencyContainer {
  height: 180px !important;
  min-height: 180px;
}

.scrollBarPos1 table td {
  padding: 2px 5px;
}
.scrollBarPos1 table th {
  padding: 0px 5px;
}

.howto-improve-sec ~ .scrollBarPos1 .ant-table-body {
  max-height: 85px !important;
}
.container-3.implement_actions,
.efc_savings,
.savings,
.idle_efficiency {
  height: 184px;
}
.coverage-map-video {
  height: 433px;
}

.camera-block.main-map .coverage-map-video {
  height: 515px;
}
.operations-graph1 {
  height: 324px;
}

.bottom-left-graphs.operation3_left,
.bottom-left-tickets {
  /* height: 240px; */
}

.operation2_left {
  height: 262px;
}
.operation3_left {
  height: 262px;
}
.video-play-controls {
  height: 45px;
}

.chartsTabs,
.farmObsTab {
  padding: 16px 27px 23px 27px !important;
  height: calc(100vh - 60px);
}

.chartsTabs .ant-tabs-nav-wrap,
.libraryTab,
.ant-tabs-nav-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 16px; */
}

.cost-graph-all,
.cost-graph-energy,
.cost-graph-labor {
  height: calc(100vh - 235px);
}

.farmObsTab .infinite-scroll-component {
  height: auto !important;
  max-height: calc(100vh - 124px);
  min-height: calc(100vh - 124px);
}

.farmObsTab .common_table .ant-spin-nested-loading {
  height: auto !important;
  max-height: calc(100vh - 122px);
  min-height: calc(100vh - 122px);
}

.libraryTab .ant-tabs-nav {
  margin: 0px !important;
  padding: 0px !important;
  height: inherit !important;
}

.chartsTabs .ant-tabs-tab,
.libraryTab .ant-tabs-tab {
  margin: 0 0 0 32px !important;
}

.recordedVideoTab {
  height: calc(100vh - 155px);
  background: #fff;
  margin-bottom: 0px !important;
  padding-bottom: 0px;
  border-radius: 8px;
}

.recordedVideoTab .fc .fc-toolbar.fc-header-toolbar {
  padding: 10px !important;
  margin-bottom: 0 !important;
}

.map-camera-grid.camera-block.main-map {
  height: 515px;
}

.childAccordion2 {
  min-height: 200px !important;
}

.chartsTabs .ant-tabs-nav-wrap {
  margin-bottom: 15px;
}
.libraryTab .ant-tabs-nav-wrap {
  margin-bottom: 15px;
}

.opTgl {
  font-weight: 600;
  color: #b5b5b6;
}
.operations-graph {
  height: 336px;
}

.loginRight .container {
  padding: inherit !important;
  text-align: inherit !important;
  font-size: inherit !important;
}
.loginRight .container .errMsgDiv {
  color: #ff4e4b;
  font-weight: 600;
}

.widget_section {
  display: grid;
  gap: 15px;
  width: 100%;
  margin-bottom: 15px;
}
.fleet_status {
  grid-template-columns: repeat(3, 1fr);
}

.fleet_issues {
  grid-template-columns: repeat(4, 1fr);
}

.filter_inputs .ant-select-selector {
  border-radius: 4px !important;
  height: 32px;
}
.align_top {
  align-items: flex-start;
  justify-content: space-between;
}
.filter_inputs span.ant-select-selection-item {
  height: 20px !important;
  border-radius: 3px !important;
  margin-top: 0px !important;
  display: flex !important;
  align-items: center !important;
  line-height: inherit !important;
}
.filters_card.filter_inputs .float-label {
  margin-right: 0px;
}

.fleet_status_container #empTbl .ant-table-body {
  overflow-x: inherit !important;
  overflow-y: inherit !important;
}

.common_wrapper_full {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  width: 100%;
  height: calc(100vh - 98px);
  /* padding: 30px; */
  position: relative;
}

.goldbuild_container .infinite-scroll-component {
  height: auto !important;
  max-height: calc(100vh - 215px);
  min-height: calc(100vh - 187px);
}

/* 
div#progress-bar {
  z-index: 9;
} */

.bulletTooltip {
  text-align: left;
  min-height: 40px;
}

.tooltipDrive {
  font-size: 10px;
  font-weight: 600;
  color: #99999c;
  line-height: 14px;
  margin: 0;
  padding: 0;
}

.tooltipUser {
  font-size: 14px;
  font-weight: 500;
  color: #474747;
  line-height: 20px;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}
.tooltipTime {
  font-size: 10px;
  font-weight: 400;
  color: #5c5c5c;
  line-height: 12px;
  margin: 0;
  padding: 0;
}

.amcharts-zoom-out-button {
  background-color: transparent !important;
  border: none !important;
}
.amcharts-zoom-out-button:hover {
  background-color: transparent !important;
  border: none !important;
}

.demoAnalytics .background-parent {
  padding: 0 !important;
  overflow-x: hidden;
  height: 100%;
}

.collapsedBtn {
  position: absolute;
  right: -5px;
  top: -50px;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 12.19px;
  text-align: center;
  color: #99999c;
}

.collapsedBtn:hover {
  color: #333333 !important;
}

.adUsrDtl {
  position: absolute;
  top: -50px;
  left: 0px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.adUsrIcon {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  font-size: 10px;
  background-color: #eeeeee;
  color: #366376;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
}
.adUsrName {
  font-size: 14px;
  font-weight: 500;
  color: #474747;
  line-height: 17px;
}
.descCount {
  color: #5c5c5c;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.24px;
  line-height: 18px;
  margin-top: 10px;
  display: flex;
  justify-content: right;
}

.submitBtn,
.submitBtn:hover,
.submitBtn:focus,
.submitBtn:active {
  min-width: 100px;
  background: rgb(54, 99, 118);
  color: rgb(255, 255, 255);
  border-radius: 10px;
  margin-left: 8px;
}

.chip-bg {
  border-radius: 25px;
  padding: 3px 8px;
  display: flex;
  align-items: center;
}
.red-chip {
  background-color: #de827a26;
  color: #de827a;
}
.green-chip {
  background-color: #37952626;
  color: #37952680;
}
.orange-chip {
  background-color: #eb922326;
  color: #379526;
}

.rfresh_n_bdr {
  border: none !important;
}

.ant-tag-blue,
.ant-tag {
  border-radius: 25px;
  padding: 3px 8px;
  align-items: center;
  display: inline-block;
  border: none;
}

.generate_btn,
.generate_btn:hover,
.generate_btn:focus {
  background-color: #366376;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  /* margin-bottom: -6px; */
  padding: 10px;
  letter-spacing: 0.1px !important;
  line-height: 12px !important;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.ant-modal.addManual {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  padding-bottom: 0px;
  width: 600px !important;
}

.addManual .ant-modal-content {
  /* padding: 24px; */
  width: 100%;
  border-radius: 10px;
}
.addManual .ant-modal-header {
  padding: 24px 24px 10px 24px;
  border-radius: 10px;
}

.addManual .ant-modal-body {
  padding: 10px 24px 10px 24px;
}
.addManual .ant-modal-footer {
  padding: 10px 24px 10px 24px;
}
.addManual .ant-modal-footer {
  padding-top: 10px;
}
.addManual .ant-modal-header {
  margin-bottom: 20px;
}

.addManual .ant-form-item-control .ant-input,
.addManual
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.manualEditorBlk .ant-form-item-control .ant-input,
.manualEditorBlk .ant-select-selector,
.searchItemSec .ant-input {
  border-radius: 4px !important;
}
.manualEditorBlk .ant-form-item-control .ant-input,
.searchItemSec .ant-input {
  background-color: #fff !important;
}

.manualEditorBlk
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark.hm_listitems {
  height: 100%;
}

.addManual .ant-btn-primary,
.manualEditorBlk .ant-btn-primary {
  background-color: #366376;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  letter-spacing: 0.1px !important;
  line-height: 12px !important;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  min-width: 100px;
}
.addManual .btnCancel,
.manualEditorBlk .btnCancel,
.err_popup .ant-modal-footer button:first-child {
  border-radius: 6px;
  color: #99999c;
  background: #e1e1e1 !important;
  border: none;
  text-transform: none !important;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.err_popup .ant-table-thead > tr > th,
.err_popup .ant-table-tbody > tr > td {
  font-size: 12px;
}

.manualEditorBlk .quill {
  height: 200px !important;
}

.manualEditorBlk .ql-container.ql-snow {
  height: 15vh;
}

.manualEditorBlk .ant-upload {
  margin-left: 0px !important;
}

.addManual.w800 {
  width: 800px !important;
}

.addManual .formRow .ant-select .ant-select-arrow {
  margin-top: -10px !important;
}

.err_popup .ant-table-content {
  overflow-y: auto;
  height: 400px;
}
.searchItemSec {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cv_ac {
  position: relative;
  top: -3px;
}

.paused-txt {
  color: #d43d38;
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
  min-width: 150px;
  text-align: center;
}

.timeBottom {
  position: absolute;
  bottom: 11px;
  left: 11px;
  background: rgba(13, 13, 13, 0.6);
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  padding: 8px;
  border-radius: 9px;
  color: #ffffff;
  z-index: 1;
  height: 31px;
}

div#video_progress_tooltip1.lastTtipSel {
  left: 85% !important;
  min-width: 114px !important;
}

.video_progress__chunk:hover:last-child .lastTtipHover {
  /* .lastTtipHover { */
  position: absolute;
  left: 85% !important;
  min-width: 124px !important;
  background: rgba(0, 122, 255, 0.5);
  /* } */
}
.video_progress__chunk:hover {
  opacity: 1 !important;
  /* min-width: 115px !important; */
}

.video_progress__chunk:hover:first-child .chunk_progress_tooltip {
  position: absolute;
  width: auto;
  z-index: 99 !important;
}

.proRowType .subFlex .body2 {
  word-break: break-all;
  word-break: break-word;
}

.dlSaveEdit {
  padding: 0 20px;
  display: flex;
  gap: 20px;
}
.dlSaveEdit .btnteamCancel:first-child {
  width: 100% !important;
  margin-right: 0px;
}
.dlSaveEdit .btnteamCancel {
  width: calc(100% - 64px);
  margin-right: 0px;
}
.dlSaveEdit .btnteamCancel:hover {
  margin-right: 0px;
  width: calc(100% - 64px);
}

/* analytics containers changes csss */

.operations-graph.noImpSel-block {
  height: 350px;
}
.bottom-left-graphs.implement_sec_tbl {
  height: 322px;
}

.scrollBarPos1 .ant-table-body {
  height: 165px;
  min-height: 165px;
  max-height: 165px;
}

.drive_sessions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.mowing-chart1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* }
.op_tabs_sec {
  height: 400px !important;
} */

#operationalHoursByOperators2,
#CoverageByOperators2 {
  height: 251px;
  overflow-y: hidden;
}

.ud_i_tooltip {
  border: 1px solid #99999c;
  border-radius: 8px;
  padding: 8px;
  font-size: 10px;
  color: #333333;
  width: 260px;
  line-height: 12px;
  min-height: 44px;
  text-align: left;
  position: absolute;
  bottom: 40px;
  z-index: 1;
  background: #fff;
  display: none;
}

.internetTxt:hover .ud_i_tooltip,
.backgroundInfo:hover .ud_i_tooltip {
  display: block;
}
.internetTxt,
.backgroundInfo {
  position: relative;
}
.redBdr {
  border: 1px solid #de827a !important;
}

.op_detail1 {
  overflow-y: hidden;
  min-height: 409px;
  height: 409px;
}
.op_detail2 {
  overflow-y: hidden;
  min-height: 409px;
  height: 409px;
}

#OperationalHoursByOperations {
  overflow-y: hidden;
  height: 265px;
  min-height: 265px;
}
#operationalHoursByOperators {
  overflow-y: hidden;
  height: 289px;
  min-height: 289px;
}
#operationalHoursByOperators1 {
  overflow-y: hidden;
  height: 349px;
  min-height: 349px;
}

#operationalHoursByOperators2 {
  overflow-y: hidden;
  height: 271px;
  min-height: 271px;
  margin-top: 20px;
}

.swUpdateTxt span:first-child {
  font-family: 'Montserrat-Medium', sans-serif;
  margin-right: 8px;
}

.eta_installSec {
  border-radius: 4px;
  box-shadow: 0px 4px 10px #00000005;
  padding: 12px;
  display: flex;
  gap: 8px;
  border: 1px solid #eeeeee;
  margin-bottom: 20px;
}
.eta_percentTxt {
  font-size: 12px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #333333;
  line-height: 15px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
}
.eta_installInfo {
  font-size: 10px;
  font-family: 'Montserrat-Regular', sans-serif;
  color: #99999c;
  line-height: 12px;
}
.etaDot {
  background: #cfcfcf;
  width: 3px;
  height: 3px;
  border-radius: 20px;
  margin-left: 4px;
  margin-right: 4px;
  display: inline-block;
}
.eta_progessbar {
  width: 24px !important;
  height: 24px !important;
}
.eta_progess .ant-progress-text {
  display: none;
}
.eta_progess .ant-progress-inner,
.eta_progess .ant-progress-outer {
  width: 24px !important;
  height: 24px !important;
}
.eta_progess .ant-progress-circle-path {
  fill: none !important;
  stroke: #007aff !important;
  stroke-width: 15 !important;
}

.impBlockBg {
  background-color: #fafafa;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
}

.impBlockBg .imp-ellipse {
  border-radius: 50%;
  border: 1px solid #e7e8e7;
}
.implement-selection .filters_card {
  padding: 0px !important;
}
.impBlockBg .implement-info {
  padding: 0px !important;
}
/* dealer side menu ***************** */
.dlside_menu_sec .dealerMenuUL {
  padding-top: 0 !important;
}
.dlside_menu_sec .listItemMain {
  position: relative !important;
  display: block !important;
  width: 200px !important;
  padding: inherit !important;
  margin: inherit !important;
  margin-bottom: 20px !important;
}
.dlside_menu_sec .nav-text {
  width: 115px;
  text-transform: uppercase;
  /* color: hsla(0, 0%, 91%, 0.7215686274509804); */
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.125px;
}
.dlside_menu_sec .listItemMain div.ant-menu-submenu-title {
  padding: inherit !important;
  margin-top: inherit !important;
  margin-bottom: 0px !important;
}
.listItemMain .listAnchor {
  display: flex;
  align-items: center;
}
.listItemMain ul li {
  margin-bottom: 20px !important;
  background-color: inherit !important;
  padding-left: 70px !important;
}
.listItemMain ul li a {
  font-size: 11px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #c7c7c7;
}
.listItemMain ul li a.selAnc {
  font-size: 11px;
  font-family: 'Montserrat-Bold', sans-serif;
  color: #fff;
}
.dealerMenuUL .ant-menu-item,
.dealerMenuUL .ant-menu-item-active {
  background: none !important;
  background-color: inherit !important;
}
.dlside_menu_sec .dealerMenuUL {
  background: #1e3a46 !important;
}
.dealerMenuUL li ul {
  background: #1e3a46 !important;
}
.dlside_menu_sec {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 260px);
}

.inventory_icon {
  background-image: url(../../assets/images/inventory_nor.svg);
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .inventory_icon {
  background-image: url(../../assets/images/inventory_white.svg);
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}
.purchase_icon {
  background-image: url(../../assets/images/purchase_nor_icon.svg);
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .purchase_icon {
  background-image: url(../../assets/images/purchase_white_icon.svg);
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}
.dplanning_icon {
  background-image: url(../../assets/images/planning_nor.svg);
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .dplanning_icon {
  background-image: url(../../assets/images/planning_white.svg);
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.material_icon {
  background-image: url(../../assets/images/material_nor.svg);
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .material_icon {
  background-image: url(../../assets/images/material_white.svg);
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

/* dealer side menu ***************** */
.dlside_menu_sec .dealerMenuUL {
  padding-top: 0 !important;
}
.dlside_menu_sec .listItemMain {
  position: relative !important;
  display: block !important;
  width: 200px !important;
  padding: inherit !important;
  margin: inherit !important;
  margin-bottom: 27px !important;
}
.dlside_menu_sec .nav-text {
  width: 115px;
  text-transform: uppercase;
  /* color: hsla(0, 0%, 91%, 0.7215686274509804); */
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.125px;
}
.dlside_menu_sec .listItemMain div.ant-menu-submenu-title {
  padding: inherit !important;
  margin-top: inherit !important;
  margin-bottom: 0px !important;
}
.listItemMain .listAnchor {
  display: flex;
  align-items: center;
}

.listItemMain ul li a {
  font-size: 11px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #c7c7c7;
}
.listItemMain ul li a:before {
  position: inherit !important;
}

.listItemMain ul li a:hover,
.listItemMain ul li.ant-menu-item-active a {
  font-size: 11px;
  font-family: 'Montserrat-Bold', sans-serif;
  color: #fff;
}
.dealerMenuUL .ant-menu-item,
.dealerMenuUL .ant-menu-item-active {
  background: none !important;
  background-color: inherit !important;
}
.dlside_menu_sec .dealerMenuUL {
  background: #1e3a46 !important;
}
.dealerMenuUL li ul {
  background: #1e3a46 !important;
}

.dlside_menu_sec {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 260px);
}

.listItemMain .nav-text {
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  color: #e8e8e8b8;
  width: 115px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.125px;
}
.listItemMain ul {
  margin-top: 5px !important;
}

.listItemMain ul li.ant-menu-item {
  margin: 0px 0px 8px 0px !important;
  background-color: inherit !important;
  padding: 0px 0px 0px 70px !important;
}

/*cards dashboard css styles goes here */

.cardsContainer {
  display: flex;
  height: 100vh;
  background-color: #f7f7f7;
  flex-direction: column;
}
.cardsHeader {
  height: 59px;
  border-bottom: 1px solid #e7e7e7;
  box-shadow: 0px 0px 3px #00000009;
  background-color: #fff;
  width: 100%;
  position: relative;
}
.cardsContentArea {
  padding: 27px;
  height: calc(100vh - 59px);
  overflow-y: auto;
}
.cardsHeader:before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  bottom: 0;
}

.cardsTitle {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #333;
  line-height: 24px;
}

.cardsBlockParent {
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 16px; */
  gap: 16px;
  flex-wrap: wrap;
}
.cardsBlock {
  background-color: #fff;
  min-height: 260px;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  padding: 30px;
  cursor: pointer;
  border: 1px solid transparent;
  width: calc(25% - 12px);
}
.backtoCardsBtn {
    position: relative;
    top: -2px;
    left: -2px;
}
/* .pl60 {
  padding-left:60px;
} */
.cardsBlock:hover {
  border: 1px solid #366376;
  box-sizing: border-box;
}
.cardsInfoSec {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.cardsmtb {
  /* margin-top: 30px; */
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.cardsmtb img {
    display: flex;
    justify-content: center;
    background-color: #f6feff;
    border-radius: 50%;
    height: 60px;
    border: 1px solid #b7e1f4;
    padding: 8px;
    width: 60px;
}

.cardsHeadTxt {
  font-size: 18px;
  font-family: 'Montserrat-Bold', sans-serif;
  color: #366376;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.cardsDesc {
  font-size: 15px;
  font-family: 'Montserrat-Regular', sans-serif;
  color: #000;
  line-height: 22px;
  word-wrap: break-word;
  /* height: 34px; */
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

/* dealer portal customers ************************ */

.dpCustomers .gridContainer {
  grid-template-columns: repeat(3, 1fr) !important;
}

.dp_customers {
  height: calc(100vh - 201px) !important;
}
.dp_customers .infinite-scroll-component {
  height: auto !important;
  max-height: calc(100vh - 290px);
}
.dp_add_customer_container {
  display: block;
  height: 100%;
}
.dp_addCustomerSec {
  display: flex;
  border: 1px solid #eeeeee;
  height: calc(100vh - 97px);
  position: relative;
  border-radius: 10px;
  flex-direction: column;
  background-color: #fff;
}

.dpGradientBg {
  background-image: url(../../assets/images/dp_gradientBg.svg);
  min-height: 188px;
  max-height: 22vh;
  background-size: 100%;
  position: absolute;
  height: 100%;
  width: calc(100% - 0px);
  top: 0;
  background-position: top left;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
}
.dp_customerActivities {
  /* background-color: #f00; */
  height: calc(100vh - 97px);
}
.dpbackButton {
  margin-top: 20px;
  margin-left: 18px;
  height: 44px;
  position: relative;
}
.dp_addContent {
  padding: 0px 40px 20px 82px;
  margin-top: 12px;
  position: relative;
  overflow-y: auto;
}

.dp_headerSec {
  display: flex;
  gap: 15px;
  margin-bottom: 48px;
}

.dp_headTxtSec {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.dp_Logo {
  width: 84px;
  height: 84px;
  border: 3px solid #fff;
  border-radius: 10px;
  background: #ddd;
}
.dp_customer_name {
  font-size: 24px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #000;
  line-height: 30px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.dp_formSec {
  display: flex;
  flex-direction: column;
}

.dp_formRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 119px;
  width: 100%;
  margin-bottom: 32px;
}
.dp_formRow:last-child {
  margin-bottom: 0px;
}
.dp_formLbl {
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
  line-height: 17px;
  color: #5c5c5c;
  width: 110px;
  max-width: 110px;
  min-width: 110px;
}
.dp_formInput {
  width: 100%;
}

.dp_formSec.filters_card {
  padding: 0px !important;
  border: none !important;
  gap: 0;
}

.dp_formSec.filters_card .float-label {
  position: relative;
  margin-right: 0px;
}

.dp_input_cols {
  display: flex;
  flex-direction: column;
}

.dp_formSec.filters_card .dp_insideRowCustom .float-label {
  width: 50%;
}
.dp_insideRowCustom {
  display: flex;
  gap: 16px;
}
.dp_insideRowCustom1 {
  display: grid;
  grid-template-columns: 40% calc(60% - 24px);
  gap: 24px;
}
.mb18 {
  margin-bottom: 18px;
}
.mb24 {
  margin-bottom: 24px;
}
.dp_insideRowFull {
  position: relative;
}

.dp_emailResend {
  font-size: 12px;
  line-height: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #99999c;
  text-align: center;
  width: 100%;
  display: inline-block;
  cursor: pointer;
}
.dp_emailStatus {
  background-color: #d43d3829;
  border-radius: 4px;
  padding: 4px;
  min-width: 75px;
  min-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #d43d38;
}

.dp_formSec.filters_card .float-label input:hover,
.dp_formSec.filters_card .float-label input:focus {
  border: 1px solid #e8e8e8 !important;
}
.tHreeFields .float-label:first-child input:first-child {
  width: 225px !important;
}

.dp_buttonDactive,
.dp_buttonDactive:hover,
.dp_buttonDactive:focus {
  background-color: transparent;
  border-radius: 8px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #99999c;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  line-height: 15px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.dp_buttonSaveChange,
.dp_buttonSaveChange:hover,
.dp_buttonSaveChange:focus {
  background-color: #366376;
  border-radius: 8px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  line-height: 15px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}
.dp_buttonSaveChange[disabled] {
  background-color: rgba(54, 99, 118, 25%) !important;
}
.dp_customerActivities {
  display: grid;
  grid-template-rows: 1fr 0.63fr 71px;

  height: 100%;
  gap: 12px;
}

.dp_cardBox {
  padding: 12px 0px 12px 12px;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  background: #fff;
}
.dp_cardItemHeadSec {
  display: flex;
  justify-content: space-between;
  /* height: 24px; */
  align-items: center;
  margin-bottom: 12px;
  padding-right: 12px;
}
.dp_cardItemHeadTxt {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #5c5c5c;
  line-height: 17px;
}
.dp_cardItemHeadCount {
  background: #fff;
  border-radius: 6px;
  border: 1px solid #b5b5b6;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #b5b5b6;
  line-height: 24px;
}
.h71 {
  height: 71px;
}
.dp_cards_fleet_overflow {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100vh - 480px);
  padding-right: 12px;
}
.dp_cards_bs_overflow {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100vh - 605px);
  padding-right: 12px;
}
.dp_card_item {
  padding: 10px;
  display: flex;
  gap: 14px;
  width: 100%;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #eee;
}
.dp_card_head {
  display: flex;
  gap: 8px;
  align-items: center;
  line-height: 17px;
}
.dp_card_item .dp_card_head .ant-checkbox-inner {
  border: 1px solid #dddddd !important;
  border-radius: 2px 2px 2px 2px !important;
}
.dp_ticket_checkbox {
  line-height: 17px;
  height: 17px;
  position: relative;
  top: -2px;
}
.dp_ticket_checkbox .ant-checkbox .ant-checkbox-wrapper {
  line-height: 17px;
  height: 17px;
  position: relative;
  top: -2px;
}

.dp_ticket_no {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  color: #333;
  line-height: 17px;
}
.dp_ticket_dtl_sec {
  display: flex;
  padding-left: 26px;
  gap: 10px;
  flex-direction: column;
}
.dp_ticket_dtls {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}
.dp_ticket_dtl_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 15px;
  font-size: 12px;
  font-family: 'Montserrat-Regular', sans-serif;
  color: #000;
}
.dp_ticket_dtls div {
  line-height: 15px;
}

/* Scrollbar width */
.dp_cards_fleet_overflow::-webkit-scrollbar,
.dp_cards_bs_overflow::-webkit-scrollbar {
  width: 3px;
}

.dp_cards_fleet_overflow::-webkit-scrollbar-thumb,
.dp_cards_bs_overflow::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 12px;
}
.pr12 {
  padding-right: 12px;
}
.pl0 {
  padding-left: 0px;
}

/* hot line support css */

.hotlineSupport {
  height: calc(100vh - 97px) !important;
  padding: 24px 32px;
}
.hotlineHead {
  font-size: 28px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  line-height: 34px;
  color: #1f1f1f;
  margin-bottom: 42px;
  padding-left: 40px;
}
/* .common_search.w160 {
  padding-left: 60px;
  width: 200px;
} */

.hotlineContentSec {
  display: flex;
  gap: 54px;
}
.hotlineDtlSec {
  display: flex;
  flex-direction: column;
}
.hotHelpTxt {
  font-size: 20px;
  font-family: 'Montserrat-Medium', sans-serif;
  font-style: italic;
  line-height: 34px;
  color: #7c7c7c;
  margin-bottom: 40px;
}
.hotweek {
  font-size: 20px;
  font-family: 'Montserrat-Bold', sans-serif;
  line-height: 20px;
  color: #333333;
  margin-bottom: 16px;
}
.hotweekValue {
  font-size: 20px;
  font-family: 'Montserrat-Medium', sans-serif;
  line-height: 20px;
  color: #333333;
  margin-bottom: 32px;
}
.hotStep {
  background: #366376;
  width: 100px;
  height: 30px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-family: 'Montserrat-Bold', sans-serif;
  line-height: 18px;
  margin-bottom: 16px;
}
.hotStepOpt {
  font-size: 20px;
  font-family: 'Montserrat-Medium', sans-serif;
  line-height: 22px;
  color: #333333;
  margin-bottom: 16px;
}
.hotBold {
  font-family: 'Montserrat-Bold', sans-serif;
}
.cdbgnone {
  visibility: hidden;
}

/* return to start feed lane css */
.returnToStartSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 10px 30px;
}
.returnToTxt {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.returnToTxt h3 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'Montserrat-Medium', sans-serif;
  color: #5c5c5c;
  line-height: 17px;
}
.returnToTxt p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-family: 'Montserrat-Regular', sans-serif;
  color: #99999c;
  width: 200px;
}
.returnToToggle .ant-switch {
  height: 32px !important;
  width: 64px !important;
}

.returnToToggle .ant-switch-handle {
  width: 24px !important;
  height: 24px !important;
}
.returnToToggle .ant-switch-handle,
.returnToToggle .ant-switch-checked .ant-switch-handle {
  top: 4px !important;
  left: 4px !important;
}

.returnToToggle .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 24px - 4px) !important;
}

.returnToToggle .ant-switch-checked .ant-switch-inner {
  margin: 0 32px 0 10px;
}
.returnToToggle .ant-switch-inner {
  margin: 0 10px 0 32px;
}
.returnToToggle .ant-switch-handle::before {
  border-radius: 15px !important;
}

.returnToToggle .ant-switch-checked {
  background-color: #69afb8;
}

.autoDriveReportTbl .ant-table-tbody > tr > td:first-child,
.autoDriveReportTbl .ant-table-tbody > tr > td {
  text-align: center !important;
}
.sort-pad {
  height: 6px !important;
  opacity: 0.9 !important;
}
.mapViewOperation {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 58px;
}

.coverage-map-video .mapViewOperation {
  right: 10px;
  top: 14px;
}

.sop_sec .autodriveSettings.widget_card .ant-input {
  background-color: #fff !important;
}

.searchOptionsHeadTxt {
  font-size: 20px;
  line-height: 24px;
  color: #474747;
  font-family: 'Montserrat-SemiBold';
  margin-bottom: 18px;
  padding-left: 10px;
}

/* incident tooltip css ********************** */

.incidentHeadersec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.incidentHeader,
.incidentHeaderCount {
  font-size: 12px;
  line-height: 15px;
  color: #000;
  font-family: 'Montserrat-Medium';
}

.incidentContnetsec {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  padding-left: 8px !important;
  padding-right: 8px !important;
  overflow-y: auto;
  max-height: 90px;
  min-height: 40px;
}
.incidentContentRows {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.incidentContentRows:hover{
  background: #eeeeee;
}

.incidentTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10px;
  line-height: 12px;
  color: #5c5c5c;
  font-family: 'Montserrat-Regular';
}
.incFlagSec {
  display: flex;
  align-items: center;
  gap: 6px;
}

.incidentPopup {
  padding: 8px 0px !important;
  min-width: 200px !important;
}
.inciHrLine {
  border-bottom: 1px solid #cfcfcf;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.inciViewAllBtn {
  font-size: 8px;
  line-height: 10px;
  color: #007aff;
  font-family: 'Montserrat-SemiBold';
  text-align: center;
  cursor: pointer;
}
.incidentPopup .head-sec,
.incidentPopup .head-sec1 {
  padding: 0px 8px;
  width: inherit !important;
}

.incidentContnetsec::-webkit-scrollbar {
  width: 3px;
}
.incidentImage {
  width: 8px !important;
  height: 10px !important;
}

.back-button-bdr {
  background-image: url(../../assets/images/back-button-bdr.svg);
  width: 44px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
  /* margin-right: 10px; */
}


.bssFilters {
  flex-wrap: wrap;
}
.bssFilters .common_search {
  width: 200px !important;
}

.bssFilters .ant-select-selector {
  height: 32px;
  border-radius: 4px !important;
}

.sNumber2nd{
    margin-left: 50px;
    position: relative;
    top: 4px;
}

.bssFilters .ant-select-selection-item {
    height: 20px !important;
    top: -2px;
    border-radius: 4px !important;
}
.bssFilters .float-label {
  margin-right: 0px !important;
}


.hitchImageContainer .edit-loader-new {
    position: absolute;
    transform: translate(50%, 50%);
    left: calc(50% - 40px);
    top: calc(50% - 40px);
}

.ol-popup.incidentPopup .head-section,
.ol-popup.incidentPopup .content-section
 {
  padding: 0px 8px;
}


.sResultsScroll::-webkit-scrollbar {  
    display: block !important;
   
}

.sResultsScroll {  
    display: block !important;
    scrollbar-width:inherit !important;
    padding-right: 10px !important;
    max-height: calc(100vh - 315px) !important;
}
.empAvtarCircle {
  display: flex;
  align-items: center;
  gap: 20px;
}
.empAvtarCircle .ant-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.empAvtarCircle .ant-avatar-string {
  width: 32px !important;
  height: 32px !important;
}


.rc-virtual-list-holder-inner .createTeamOption .ant-select-item-option-content span:first-child  {
  width: 0%;
}


.team-multi-select .ant-select-selection-item .emailAlign{
  min-width: inherit !important;
}
.team-multi-select .ant-select-selection-item {
  display: flex;
  align-items: center;
}