.fbs-icon-section .ant-space-item img {
  cursor: pointer;
}
.fbs-modal .ant-modal-title {
  text-align: center;
  font-size: 18px;
}
.fbs-modal .ant-modal-content {
  border-radius: 0px 0px 16px 16px;
}
.fbs-modal .ant-upload {
  display: none;
}

.farmObservaatioModal .ant-modal-content {
  border-radius: 15px !important;
}
.farmObservaatioModal .ant-modal-header {
  border-radius: 15px 15px 0 0 !important;
}
.farmObservaatioModal textarea {
  border: 1px solid #bfbfbf !important;
  border-radius: 5px !important;
  height: 110px !important;
}
.farmObservaatioModal textarea:hover,
.farmObservaatioModal textarea:focus {
  border: 1px solid #bfbfbf !important;
}
.farmObservaatioModal h4 {
  font-family: 'Montserrat-SemiBold' !important;
}
.farmObservaatioModal .tagsBlk .ant-tag {
  color: #4bb1b9 !important;
  font-family: 'Montserrat-SemiBold' !important;
}
.farmObservaatioModal .tagsBlk .ant-tag.ant-tag-checkable-checked {
  background-color: #4bb1b9 !important;
  border: 1px solid #4bb1b9 !important;
  color: #fff !important;
}
.farmObservaatioModal .fbs-save,
.farmObservaatioModal .fbs-save:hover,
.farmObservaatioModal .fbs-save:focus {
  background-color: #60b2b9 !important;
  border-color: #60b2b9 !important;
  border-radius: 24px;
  color: white;
  font-size: 18px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.28px;
  height: auto !important;
  padding: 8px 27px !important;
  margin-left: 42px;
}
.farmObservaatioModal .selectMenu .ant-select-selector {
  border: 1px solid #bfbfbf !important;
  border-radius: 5px !important;
  height: 50px !important;
}
.farmObservaatioModal .fbs-modal .ant-modal-title {
  color: #000000;
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.15px;
  line-height: 24px;
  text-transform: capitalize !important;
}
.elTabs .tblContainer.fbsTbl {
  height: calc(100vh - 160px) !important;
  margin-top: 40px !important;
}
.fbsTbl .tblDft.farmTabsTbl .infinite-scroll-component {
  max-height: calc(100vh - 285px);
}
.fbsTbl .tblDft.farmTabsTbl {
  height: calc(100vh - 260px);
  max-height: calc(100vh - 260px);
}
.fbs_saved_msg {
  background-color: #fafbfc;
  border: 1px solid #bfbfbf;
  border-left: 8px solid #00b4bb !important;
  border-radius: 4px;
  padding-left: 13px;
  display: flex;
  align-items: center;
  margin-top: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.fbs_saved_msg p {
  color: #00b4bb;
  margin: 0;
}
.recorder .App {
  text-align: left !important;
}
.farmObservaatioModal .attachemtBlk {
  margin-bottom: 8px !important;
}
.v-error {
  color: red;
  text-align: center;
}
.fbs-media-section {
  height: 172px;
}

.recorderBlock {
  display: flex;
  align-items: center;
  gap: 10px;

}
.recorderBlock .anticon-delete {
  color: red;
}
.fbsvideoicon{
  position: relative;
}

.fbsvideoicon .playIcon {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
width: 25px;
height: 25px;
z-index: 9;
}

.fomodal .ant-modal-close{
  left: unset !important;
}

.fomodal .ant-modal-header{
  border-bottom: 0 !important;
}

.fovideostyle {
  gap:10px
}

.fovideostyle .ant-image-img{
margin-bottom: 0 !important;
margin-right: 0 !important;
}
.fovideostyle .ant-image-mask-info {
text-align: center;
}
.fovideostyle .ant-image-mask{
  height: 75px;
  border-radius: 6px;
  width: 75px;
}

.fovideostyle .ant-image-mask-info .anticon-eye {
display: block;
margin: auto;
}
