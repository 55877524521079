.impactTitle {
  align-items: center !important;
  display: flex;
  font-family: 'Montserrat-SemiBold';
  font-size: 24px;
  margin-bottom: 28px;
}

.SummaryCont {
  height: 200px;
}

.impactCharts {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  justify-content: space-between;
  height: calc(100vh - 270px);
  overflow-y: auto;
  margin-right: -20px;
  padding-right: 15px;
}

.drivingHoursContainer {
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  width: 100%;
  /* height: calc(100vh - 310px); */
  padding-right: 30px;
}

.OperationsContainer {
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  width: 100%;
  /* height: calc(100vh - 320px); */
}

.chartTitle {
  align-items: center !important;
  display: flex;
  font-family: 'Montserrat-SemiBold';
  font-size: 18px;
}

.operationTabs {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.operationTabs .ant-tabs-content-holder {
  padding: 5px;
  height: inherit !important;
}

.operationTabs .ant-tabs-nav-operations {
  display: none !important;
}

.summaryCards {
  margin: 20px !important;
  width: unset !important;
}

.ant-tree-select-dropdown {
  padding: 0px 4px 0 !important;
}

.custom-select-dropdown {
  padding-left: 22px;
  padding-bottom: 14px;
  width: 100%;
  background: #ebecf0;
  padding-top: 7px;
}
.custom-select-dropdown .ant-checkbox-inner {
  width: 16px !important;
  height: 16px !important;
  background-color: #fff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
}
.custom-select-dropdown .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}
.custom-select-dropdown .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #fff !important;
  border-top: 0 !important;
  border-left: 0 !important;
  left: 3px !important;
  top: 6px !important;
  width: 5px !important;
  height: 9px !important;
}

.impactFilters .ant-picker {
  height: 32px !important;
  margin-right: 10px;
}

.impactFilters
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 32px !important;
}

.impactFilters .ant-select-selection-overflow-item .ant-select-selection-item {
  background-color: #e7e7e7 !important;
}

.impactFilters .ant-select-multiple .ant-select-selection-item-content {
  color: black !important;
}

/* graphs sec ==================================== */

.ad_common_wrapper {
  border-radius: 10px;
  width: 100%;
  height: calc(100vh - 98px);
  /* padding: 30px; */
  position: relative;
}

.filters_sec {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.filters_block,
.widgets_block {
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 16px;
}

.widgets_block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.widgets_blocknew {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

/* float label css styles goes here ***************/
.ad_filters_card1 .float-label {
  position: relative;
  /* margin-right: 10px; */
}

.ad_filters_card1 .label {
  font-size: 10px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  /* top: 6px; */
  top: 8px;
  transition: 0.2s ease all;
  font-family: 'Montserrat-Regular';
  color: #5c5c5c;
}

.ad_filters_card1
  .ant-picker.ant-picker-range.min_width
  .ant-picker-range-separator {
  display: none !important;
}

.ad_filters_card1
  .ant-picker.ant-picker-range.min_width.ant-picker-focused
  .ant-picker-range-separator {
  display: block !important;
}

.ad_filters_card1 .label-float {
  top: -8px;
  background: #ffffff;
  padding: 0px 6px 0px 6px;
  left: 8px;
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  border-radius: 5px 5px 0 0;
}

.ad_filters_card1 .float-label div {
  font-size: 10px !important;
  font-family: 'Montserrat-Regular';
  color: #5c5c5c;
  /* margin-right: 5px; */
}

/* .ad_filters_card1 .float-label .ant-picker, */
.ad_filters_card1 .float-label input,
.ad_filters_card1 .float-label input:hover,
.ad_filters_card1 .float-label input:focus {
  height: 32px !important;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 12px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  transition: all 0.3s;
  font-size: 10px !important;
  font-family: 'Montserrat-Medium';
}

.ad_spaceBtnAlignCenter {
  justify-content: space-between;
  align-items: center;
}
.ad_filters_card1 .ant-tree-select {
  min-width: 220px !important;
}
.ad_filters_card1 .ant-select-selector {
  border-radius: 4px !important;
}
.ad_displayFlexNew {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ad_filters_card1 .ant-tree-select .ant-select-selector {
  height: 32px !important;
}
.ad_min_width {
  min-width: 150px !important;
}

.ad_filters_card1 .ant-select-multiple .ant-select-selection-item-content {
  width: 138px;
}

.ad_filters_card1
  .ant-select-multiple
  .ant-select-selection-item-content:last-child {
  width: inherit !important;
}

.ad_filters_card1
  .ant-select-multiple.ant-select-allow-clear
  .ant-select-selector {
  padding-right: 0px !important;
}
.mb20 {
  margin-bottom: 20px;
}

.ad_displayFlexNew.impactFilters
  .ant-select-selection-overflow-item
  .ant-select-selection-item {
  display: flex;
  align-items: center;
  border-radius: 3px;
  height: 18px;
  padding: 0px 5px;
  font-size: 8px;
  margin: 0px;
}

.ad_filters_card1 {
  border-bottom: 1px solid #e8e8e8;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.widgets_block2 img {
  width: 56px;
  height: 56px;
}

.widgets_block2 .gridItem {
  height: 80px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #fff;
  box-sizing: border-box;
}

.stackTitle {
  margin-top: 10px;
  padding-left: 20px;
  font-family: 'Montserrat-SemiBold';
  font-size: 16px;
}

.ad_submit_btn {
  display: flex;
  background-color: #366376;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  color: #fff;
  cursor: pointer;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  padding: 10px;
  letter-spacing: 0.1px !important;
  line-height: 12px !important;
}

/* Impact Dashboard new styles starts here */
.ad_ResultsTitle {
  color: #000;
  font-size: 24px;
  font-family: 'Montserrat-SemiBold';
  padding-bottom: 10px;
  /* display: flex; */
  /* align-items: center; */
  /* grid-gap: 22px;
  gap: 22px; */
  line-height: 32px;
}

.ad_ResultsTitle .ad_calendarIcon {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ad_ResultsTitleFlex {
  background: #fff;
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px; */
  padding: 20px 20px 10px 20px;
}

.ad_filters_card_new {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.ad_content_holder {
  background-color: transparent !important;
  height: calc(100vh - 78px) !important;
}

.ad_new_charts {
  margin-top: 16px;
  /* display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px; */
  height: calc(100vh - 330px);
  overflow-y: auto;
  margin-right: -20px;
  padding-right: 15px;
}

.ad_new_charts .ad_new_charts_left {
  grid-column: 1/10;
}

.ad_new_charts_left .ad_new_charts_title {
  text-align: left !important;
}

.ad_new_charts .ad_new_charts_right {
  grid-column: 10/13;
}

.ad_new_charts_box {
  border-radius: 8px;
  border: 1px solid #eee;
  background: #fff;
  /* width: 292px; */
  padding: 10px 10px 10px 15px;
  margin-bottom: 12px;
}

.ad_new_charts_box img {
  max-width: 100%;
  object-fit: inherit;
}

.ad_new_charts_title {
  text-align: center;
}

.ad_new_charts_flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* padding-bottom: 5px; */
  margin-bottom: 5px;
}

.ad_new_charts_title h6 {
  color: #5c5c5c;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  line-height: normal;
  margin: 0;
}

.ad_new_charts_left .ad_new_charts_title h6 {
  margin-bottom: 10px;
}

.ad_new_charts_left .ad_new_charts_flex {
  /* padding-bottom: 20px; */
}

.ad_new_charts_title h3 {
  color: #333333;
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  line-height: normal;
  margin: 0;
}

.ad_new_charts_status_info {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-bottom: 4px;
}

.ad_new_charts_status_info h6 {
  color: #959595;
  font-size: 8px;
  font-family: 'Montserrat-Medium';
  line-height: normal;
  margin: 0;
}

.manual_drive_box {
  width: 9px;
  height: 9px;
  background-color: #67afb7;
  display: inline-block;
  border-radius: 2px;
}

.operator_assist_box {
  width: 9px;
  height: 9px;
  background-color: #f1ce90;
  display: inline-block;
  border-radius: 2px;
}

.auto_drive_box {
  width: 9px;
  height: 9px;
  background-color: #ec9322;
  display: inline-block;
  border-radius: 2px;
}

.total_tckts {
  width: 9px;
  height: 9px;
  background-color: #8595ca;
  display: inline-block;
  border-radius: 2px;
}

.open_tckts {
  width: 9px;
  height: 9px;
  background-color: #de827a;
  display: inline-block;
  border-radius: 2px;
}

.level_one_box {
  width: 9px;
  height: 9px;
  background-color: #cfcfcf;
  display: inline-block;
  border-radius: 2px;
}

.level_two_box {
  width: 9px;
  height: 9px;
  background-color: #fbb313;
  display: inline-block;
  border-radius: 2px;
}

.level_three_box {
  width: 9px;
  height: 9px;
  background-color: #d43d38;
  display: inline-block;
  border-radius: 2px;
}

.ad_new_charts_left_top {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  padding-bottom: 8px;
}

.ad_new_charts .ad_new_charts_left_top_one {
  grid-column: 1/7;
}

.ad_new_charts .ad_new_charts_left_top_two {
  grid-column: 7/13;
}

.ad_tab_title {
  color: #99999c;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  line-height: normal;
  margin-bottom: 10px;
  text-transform: none;
}

.ad_tab_subTitle {
  color: #99999c;
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  line-height: normal;
}

.ad_new_charts_left_bottom {
  padding-top: 0;
}

.ad_new_charts_left_bottom
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn
  .ad_tab_title {
  color: #5c5c5c;
}

.ad_new_charts_left_bottom
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn
  .ad_tab_subTitle {
  color: #333333;
}

.ad_new_charts_left_bottom .ant-tabs-tab {
  text-align: center;
}

.ad_new_charts_left_bottom .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 16px !important;
}

.ad_new_charts_left_bottom .ant-tabs-content-holder {
  height: auto !important;
  background-color: transparent !important;
  padding: 0 !important;
}

.ad_new_charts_left_bottom .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ad_new_charts_left_bottom
  .ant-tabs-top
  > div
  > .ant-tabs-nav
  .ant-tabs-ink-bar {
  bottom: unset !important;
  top: 0 !important;
}

.ad_new_charts_left_bottom .ant-tabs-top > .ant-tabs-nav::before,
.ad_new_charts_left_bottom .ant-tabs-bottom > .ant-tabs-nav::before,
.ad_new_charts_left_bottom .ant-tabs-top > div > .ant-tabs-nav::before,
.ad_new_charts_left_bottom .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0 !important;
}

.ad_new_charts_left_bottom .ant-tabs-ink-bar {
  border-radius: 0 0 10px 10px !important;
}

/* Impact Dashboard new styles ends here */

.img1 {
  height: 250px;
  width: 100%;
}
.img2 {
  height: 265px;
  width: 100%;
}
.img3 {
  height: 158px;
  width: 100%;
}

.gridItem.summaryCard .assignTxt {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #5c5c5c;
  line-height: 15px;
  margin-bottom: 3px;
}

.ad_filters_card1
  .ant-select-selection-overflow-item:nth-child(1)
  .ant-select-selection-item,
.ad_filters_card1
  .ant-select-selection-overflow-item:nth-child(2)
  .ant-select-selection-item {
  background-color: #f5f5f5 !important;
  border: none;
  font-size: 10px !important;
  color: #99999c !important;
  margin-top: 0px !important;
  margin-bottom: 8px !important;
  line-height: 20px !important;
}
.ad_filters_card1
  .ant-select-selection-overflow-item:nth-child(2)
  .ant-select-selection-item {
  background-color: #f4f4f4 !important;
  border-radius: 10px;
  height: 18px;
  position: relative;
  bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-selection-item .ellipsis {
  color: #5c5c5c !important;
}

.ad_filters_card1 .float-label .ant-picker-input.ant-picker-input-active input {
  color: #5c5c5c !important;
}

.ad_filters_card1
  .ant-picker.ant-picker-range.min_width.ant-picker-focused
  input {
  color: #5c5c5c !important;
}

.ad_displayFlexNew .float-label label.label-float {
  color: #99999c !important;
}

.ad_displayFlexNew .ant-picker .ant-picker-input input,
.ad_displayFlexNew .ant-picker.ant-picker-focused .ant-picker-input input {
  font-size: 10px !important;
}
.ad_displayFlexNew .ant-picker .ant-picker-input input {
  color: #99999c !important;
}
.ad_displayFlexNew .ant-picker.ant-picker-focused .ant-picker-input input {
  color: #5c5c5c !important;
}

.ad_new_charts_status {
  display: none;
}

.t_dflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.t_dflex h2 {
  margin: 0px;
}
.t_icon_box {
  padding: 5px;
  border-radius: 6px;
  background: #f8f9f9;
  border: 1px solid #dddddd;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.t_icon_box img {
  height: 15px;
}

.chargeTime .ad_tab_subTitle {
  text-transform: none !important;
}

.ad_tab_title {
  text-transform: none !important;
}

.ad_new_charts_status1 {
  display: block !important;
}

.downloadIcon {
  background-image: url(../images/download_gray.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.filterIcon {
  background-color: #f7f7f7;
  border: 1px solid #d7dae2;
  border-radius: 4px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.helpIcon {
  font-size: 18px !important;
}

.ml30 {
  margin-left: 30px;
}
