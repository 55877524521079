.customerCard .ant-card-head-title {
  font-size: 24px;
  font-family: 'Montserrat-SemiBold';
  line-height: 32px;
}

.customerAddBtn {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  background-color: #366376;
  color: #fff;
  border-radius: 5px;
}
.adminDetails {
  font-family: 'Montserrat-SemiBold';
}

.taskModal .ant-input {
  height: 39px !important;
}

.taskModal.customerModal .ant-modal-footer {
  padding: 22px 0 30px 0 !important;
}

.cusAdd {
  justify-content: end;
}

.menuTitle {
  padding: 19px 27px !important;
  background-color: #f7f7f7;
  height: calc(100vh - 60px);
}

.menuTab {
  background: #fff;
  display: flex;
  align-items: center;
  height: 59px !important;
  border-bottom: 1px px solid #e7e7e7 !important;
  padding: 0 30px;
}

.offlineBtn {
  background-color: transparent !important;
}

.migrateBtn {
  border-radius: 4px !important;
  font-size: 13px !important;
  font-family: 'Montserrat-SemiBold';
  font-weight: 700 !important;
  padding: 4px 24px !important;
  min-height: 30px;
  background-color: #366376;
  color: #fff;
}

.orgNameDropDown .ant-select-selector {
  background-color: #f4f5f7 !important;
  border-radius: 3.5px !important;
  height: 32px !important;
  font-family: 'Montserrat-Medium' !important;
  font-size: 14px !important;
}

.maintenanceTabs .ant-tabs-nav-wrap,
.inventoryTabs .ant-tabs-nav-wrap {
  margin-bottom: 16px !important;
}
.maintenanceTabs .ant-tabs-content-holder,
.inventoryTabs .ant-tabs-content-holder {
  padding: 0px !important;
}
