.fleetUsage .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 2.35vw !important;
}

.button {
  background-color: #366376;
  border-radius: 20px;
  box-shadow: 0 0 6px #00000029;
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold', sans-serif;
  height: auto;
  letter-spacing: 1.25px;
  line-height: 18px;
  margin-right: 0;
  padding: 15px 28px;
  text-transform: uppercase;
}

.borderStyle {
  border-radius: 2%;
  border: 1px solid lightgrey;
}
.blockCodeContainer {
  height: calc(100vh - 250px);
}
.blockCodeContainer .bc-header {
  padding: 10px;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
}
.preStyle {
  height: calc(100vh - 250px);
  overflow-y: scroll;
  background-color: #f7f7f7;
}

.copy-block {
  position: absolute;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
}

.ant-tabs-tabpane:nth-of-type(16) .common_tabs .ant-tabs-content-holder {
  /* Your styles here */
  overflow-y: auto;
}

.support_tabs .ant-tabs-content-holder {
  overflow-y: auto;
}
