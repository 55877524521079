.progressBarContainer {
  display: flex;
  align-items: center;
  width: 120px;
  position: relative;
}
.pBLeftContent {
  width: 50px;
  height: 36px;
}
